import styled from 'styled-components';
import { Modal, Button } from 'antd';

export const COLOR_DANGER = '#E74150';

export const PrimaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	background-color: #2f54eb;
	border: 1px solid #2f54eb;
	box-shadow: 0px 2px 0px 0px #0000000b;
`;

export const SecondaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	box-shadow: 0px 1px 0px 0px #0000000b;
`;

export const ModalStyled = styled(Modal)`
	.ant-modal-content {
		padding: 30px 0;
		min-width: 400px;
		max-width: 520px;
		border-radius: 30px;
	}
`;
