import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/bera';
import { BeraSixToo } from '@/core/domain/bera-six-too';

interface GetBeraSixTooParams {
	organization_id: string;
	workstation_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	start_date?: Date;
	end_date?: Date;
}

export const useGetBeraSixToo = (params: GetBeraSixTooParams) => {
	return useQuery<BeraSixToo, Error>([QUERY_KEYS.GET_BERA_SIX_TOO, params], () => Service.getSixTooSumRPN(params), {
		enabled: !!params.organization_id
	});
};
