import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '../../infra/services/action-plan';
import { OriginOptionResponseDTO } from '@/core/dto';
import { I18n } from '@aws-amplify/core';

const transformOriginOptions = (data: OriginOptionResponseDTO[]) => {
	return data.map((value) => ({ value: value.acronym, label: I18n.get(value.acronym) }));
};

export const useGetOriginOptions = () => {
	return useQuery([QUERY_KEYS.GET_CUSTOM_REPORT_OPTIONS], () => Service.getOriginOptions(), {
		enabled: true,
		select: transformOriginOptions
	});
};
