import { Mapper } from './base-mapper';
import { BeraGenderNeutralStation } from '@/core/domain/bera-gns';
import { BeraGenderNeutralStationDTO } from '@/core/dto/bera-gns';

export class BeraGenderNeutralStationMapper implements Mapper<BeraGenderNeutralStation> {
	private readonly report: BeraGenderNeutralStationDTO;
	private readonly labels: string[][];

	constructor(report: BeraGenderNeutralStationDTO, labels: string[][]) {
		this.report = report;
		this.labels = labels;
	}

	toDomain(): BeraGenderNeutralStation {
		return new BeraGenderNeutralStation({
			report: this.report,
			labels: this.labels
		});
	}
}
