import React from 'react';
import { Select } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ActionPlansStatus, TO_DO_PRIMARY_COLOR, DOING_PRIMARY_COLOR, DONE_PRIMARY_COLOR } from '../../constants';
import type { FormFieldProps } from '../../types';
import * as S from './styles';

const { Option } = Select;

export function Status({ formName = [''], showLabel = true, isRequired = false }: Readonly<FormFieldProps>) {
	const title = I18n.get('Status');

	const options = [
		{ value: ActionPlansStatus.TO_DO, label: I18n.get('To Do'), color: TO_DO_PRIMARY_COLOR },
		{ value: ActionPlansStatus.DOING, label: I18n.get('Doing'), color: DOING_PRIMARY_COLOR },
		{ value: ActionPlansStatus.DONE, label: I18n.get('Done'), color: DONE_PRIMARY_COLOR }
	];

	return (
		<S.CustomFormItem
			required={isRequired}
			labelCol={{ span: 24 }}
			name={[...formName, 'status']}
			label={showLabel ? title : undefined}
		>
			<S.CustomSelect allowClear placeholder={title}>
				{options.map((option) => (
					<Option key={option.value} value={option.value}>
						<S.ColorDot color={option.color} />
						{option.label}
					</Option>
				))}
			</S.CustomSelect>
		</S.CustomFormItem>
	);
}
