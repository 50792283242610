import React from 'react';
import { I18n } from '@aws-amplify/core';
import type { FormFieldProps } from './types';
import type { DefaultOptionType } from 'antd/lib/select';
import * as S from './styles';

interface ResponsibleProps extends FormFieldProps {
	options?: DefaultOptionType[];
}

export function Responsible({
	options = [],
	formName = [''],
	isLoading = false,
	isDisabled = false,
	isRequired = false
}: Readonly<ResponsibleProps>) {
	const title = I18n.get('Responsible');

	const errorMessage = I18n.get('Select the responsible');

	return (
		<S.CustomFormItem
			label={title}
			required={isRequired}
			labelCol={{ span: 24 }}
			name={[...formName, 'responsible_id']}
			rules={[{ required: isRequired, message: errorMessage }]}
		>
			<S.CustomSelect
				allowClear
				options={options}
				loading={isLoading}
				placeholder={title}
				disabled={isDisabled}
			/>
		</S.CustomFormItem>
	);
}
