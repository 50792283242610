import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';

export interface DelayedActionPlans {
	total: number;
	delayed: number;
}

interface GetDelayedActionPlansParams {
	organization_id: string;
	workstation_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	start_date?: Date;
	end_date?: Date;
}

export const useGetDelayedActionPlans = (params: GetDelayedActionPlansParams) => {
	return useQuery([QUERY_KEYS.GET_DELAYED_ACTION_PLANS, params], () => Service.delayed(params), {
		enabled: !!params.organization_id
	});
};
