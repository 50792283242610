import React from 'react';
import { Row, Col, Spin } from 'antd';
import { I18n } from '@aws-amplify/core';

import { HistoryWrapper } from '../styles';
import { HistoryItem } from './HistoryItem';
import { Empty } from '@/components/ui/Empty';
import { Title } from '@/components/Typography';
import { useEditActionPlanContext } from '../context';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetHistoryActionPlan } from '@/hooks/v2/useGetHistoryActionPlan';

export function History() {
	const { actionPlan } = useEditActionPlanContext();
	const { organization, company } = useApplicationContext();

	const parameters = {
		organization_id: organization?.id!,
		company_id: company.id,
		action_plan_id: actionPlan.id
	};

	const {
		isLoading,
		data
		// isError: hasErrorHistory,
	} = useGetHistoryActionPlan(parameters);

	if (data?.data.length === 0) {
		return (
			<Row>
				<Col span={24}>
					<Title level={4}>{I18n.get('History')}</Title>
				</Col>
				<Col span={24}>
					<Empty />
				</Col>
			</Row>
		);
	}

	return (
		<Row>
			<Col span={24}>
				<Title level={4}>{I18n.get('History')}</Title>
			</Col>
			<Col span={24}>
				<Spin spinning={isLoading}>
					<HistoryWrapper>
						{data?.data.map((item) => (
							<HistoryItem
								key={item.id}
								type={item.type}
								author={item.author}
								createdAt={item.created_at}
								description={item.description}
							/>
						))}
					</HistoryWrapper>
				</Spin>
			</Col>
		</Row>
	);
}
