import React from 'react';
import { Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';

import { BeraDoughnut } from './BeraDoughnut';
import { LineChart } from './Workstation/LineChart';
import { unShortenUrl } from '@/views/Dashboard/utils';
import { Card } from '@/views/Dashboard/components/Card';
import { DEFAULT_GRANULARITY } from '../ChecklistReports/enums';
import { Single } from '@/views/Dashboard/components/Filters/Single';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetBeraGenderNeutralStation } from '../../hooks/useGetBeraGns';
import { StyledDivPopOver } from '@/views/Dashboard/styles';
import { StyledCol, LegendContainer, LegendItem, LegendColor, LegendLabel, ChartContainer, colors } from './styles';

interface BeraGnsProps {
	handleClear(fieldNames: string[]): void;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const { useFormInstance } = Form;

export function BeraGns({ handleClear }: Readonly<BeraGnsProps>) {
	const { organization } = useApplicationContext();
	const query = useQuery();
	const location = useLocation();
	const form = useFormInstance();

	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');

	const urlParams = unShortenUrl(location.search.substring(1));
	const beraGnsParams = urlParams?.gns ?? urlParams?.general?.unique_period;

	const filterParams = {
		organization_id: organization?.id!,
		line_id: line_id ?? undefined,
		sector_id: sector_id ?? undefined,
		company_id: company_id ?? undefined,
		workstation_id: workstation_id ?? undefined,
		is_gender_neutral: beraGnsParams?.is_gender_neutral ?? true,
		granularity: beraGnsParams?.granularity ?? DEFAULT_GRANULARITY,
		fiscal_year: beraGnsParams?.fiscal_year ?? true,
		end_date: beraGnsParams?.end_date ?? undefined,
		start_date: beraGnsParams?.start_date ?? undefined
	};

	const {
		isLoading: isLoadingGNS,
		data: dataGNS,
		isError: isErrorENS,
		refetch: refetchGNS
	} = useGetBeraGenderNeutralStation({
		...filterParams
	});

	const {
		isLoading: isLoadingNotGNS,
		data: dataNotGNS,
		isError: isErrorNotGNS,
		refetch: refetchNotGNS
	} = useGetBeraGenderNeutralStation({
		...filterParams,
		is_gender_neutral: false
	});

	function handleOnRefetch() {
		refetchGNS();
		refetchNotGNS();
	}

	function handleOnSubmit(): void {
		form.submit();
	}

	function translateLabels() {
		const labels = dataGNS?.labels;
		const { granularity } = filterParams;

		if (granularity === 'year') {
			return labels;
		}

		const granularityMapper: Record<string, (inputs: string[][] | undefined) => string[][] | undefined> = {
			day: translateDayLabels,
			week: translateWeekLabels,
			month: translateMonthLabels,
			quarter: translateQuarterLabels,
			semester: translateSemesterLabels
		};

		const translations = granularityMapper[granularity](labels);
		return translations || labels;
	}

	function translateDayLabels(inputs: string[][] | undefined): string[][] | undefined {
		const dayTranslations = inputs?.map(([date, year]) => {
			const [day, month] = date.split(' ');
			return [`${day + ' ' + I18n.get(month)}`, year];
		});
		return dayTranslations;
	}

	function translateWeekLabels(inputs: string[][] | undefined): string[][] | undefined {
		const weekTranslations = inputs?.map(([date, year]: string[]) => {
			const [start_date, , end_date, , month] = date.split(' ');
			return [`${start_date}  ${I18n.get('to')} ${end_date} - ${I18n.get(month)}`, year];
		});
		return weekTranslations;
	}

	function translateMonthLabels(inputs: string[][] | undefined): string[][] | undefined {
		const monthTranslations = inputs?.map(([month, year]: string[]) => {
			return [`${I18n.get(month)}`, year];
		});
		return monthTranslations;
	}

	function translateQuarterLabels(inputs: string[][] | undefined): string[][] | undefined {
		const quarterTranslations = inputs?.map(([date, year]: string[]) => {
			const quarter = date.split(' ')[0];
			return [`${quarter} ${I18n.get('quarter')}`, year];
		});
		return quarterTranslations;
	}

	function translateSemesterLabels(inputs: string[][] | undefined): string[][] | undefined {
		const semesterTranslations = inputs?.map(([date, year]: string[]) => {
			const semester = date.split(' ')[0];
			return [`${semester} ${I18n.get('semester')}`, year];
		});
		return semesterTranslations;
	}

	const result = {
		labels: translateLabels() ?? [],
		reports: [{ ...dataGNS?.report }, { ...dataNotGNS?.report }]?.map((reportData, index) => ({
			label: reportData?.label ?? '',
			data: reportData?.data ?? [],
			total: reportData?.total ?? 0
		}))
	};

	const [totalGNS] = result?.reports?.filter((item) => {
		return item.label === 'GNS' ? item.total : 0;
	});

	const [totalNotGNS] = result?.reports?.filter((item) => {
		return item.label === 'Not GNS' ? item.total : 0;
	});

	const total = totalGNS?.total || 0 + totalNotGNS?.total || 0;

	const gnsRawPercentage = (totalGNS?.total || 0) / total * 100;
	const notGnsRawPercentage = (totalNotGNS?.total || 0) / total * 100;

	const totalRaw = gnsRawPercentage + notGnsRawPercentage;
	const scale = totalRaw > 100 ? 100 / totalRaw : 1;

	const gnsPercentage = Math.round(gnsRawPercentage * scale);
	const notGnsPercentage = Math.round(notGnsRawPercentage * scale);

	const resolveDoughnut = {
		total,
		gns: gnsPercentage,
		not_gns: notGnsPercentage
	};

	const chartLabels = ['GNS', 'Not GNS'];
	const chartData = [resolveDoughnut.gns, resolveDoughnut.not_gns];

	const isLoading = isLoadingGNS && isLoadingNotGNS;
	const isError = isErrorENS && isErrorNotGNS;
	const isEmpty = chartData.every(value => value === null || isNaN(value) || value <= 0);

	function getDisplayDate(): { start: string; end: string } {
		return {
			start: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(beraGnsParams?.start_date),
			end: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(beraGnsParams?.end_date)
		};
	}

	return (
		<Card.Container isLoading={isLoading} isError={isError} onRefetch={handleOnRefetch} size="medium">
			<Card.Header
				hasIcon
				hasDisplayDate
				displayDate={getDisplayDate()}
				title="Gender Neutral Station"
				icon={<CalendarOutlined />}
				popoverComponent={
					<StyledDivPopOver>
						<Single
							hasGranularity={true}
							fieldName={['gns']}
							handleClear={handleClear}
							handleFormSubmit={handleOnSubmit}
						/>
					</StyledDivPopOver>
				}
			/>
			<Card.Content isEmpty={isEmpty}>
				<Row>
					<StyledCol span={6}>
						<ChartContainer>
							<BeraDoughnut data={chartData} labels={chartLabels} />
						</ChartContainer>
						{Math.max(...chartData) > 0 ? (
							<LegendContainer>
								{chartLabels.map((label, index) => (
									<LegendItem key={label}>
										<LegendColor color={colors[index]} />
										<LegendLabel>{I18n.get(label)}</LegendLabel>
									</LegendItem>
								))}
							</LegendContainer>
						) : (
							<React.Fragment />
						)}
					</StyledCol>
					<StyledCol span={18}>
						<LineChart labels={result.labels} reports={result.reports} />
					</StyledCol>
				</Row>
			</Card.Content>
		</Card.Container>
	);
}
