import React, { useEffect, useState } from 'react';
import { CheckOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Row, Col, Dropdown, Form } from 'antd';
import moment from 'moment';

import type { ActionPlanTask } from '../../types';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { useCreateActionPlanContext } from '../context';
import { DueDateDropdown, ResponsibleDropdown, TaskName } from '../../components/Fields';
import * as S from './styles';

const { useFormInstance } = Form;

interface TaskItemProps {
	data: ActionPlanTask;
}

export function TaskItem({ data }: Readonly<TaskItemProps>) {
	const form = useFormInstance();
	const { users, tasks, onUpdateTasks } = useCreateActionPlanContext();

	const [isEditing, setIsEditing] = useState<boolean>(false);

	const fieldName = ['edit_task', data.id];

	function handleOnRemove(taskId: string): void {
		const index = tasks.findIndex((task: any) => task.id === taskId);
		const tasksCopy = [...tasks];
		tasksCopy.splice(index, 1);

		onUpdateTasks(tasksCopy);
	}

	function handleToggleEdition(): void {
		setIsEditing(!isEditing);
	}

	function setEditFieldsValues(): void {
		form.setFieldValue([...fieldName, 'task_name'], data.title);
		form.setFieldValue([...fieldName, 'due_date'], data.due_date);
		form.setFieldValue([...fieldName, 'responsible_id'], data.responsible_user_id);
	}

	function handleOnSaveEdition(): void {
		const values = form.getFieldValue(fieldName);

		const itemIndex = tasks.findIndex((task: any) => task.id === data.id);
		const tasksCopy = [...tasks];

		tasksCopy[itemIndex] = {
			...tasksCopy[itemIndex],
			title: values.task_name,
			responsible_user_id: values.responsible_id,
			due_date: values.due_date
		};

		onUpdateTasks(tasksCopy);
		handleToggleEdition();
	}

	function getResponsibleName(): string {
		if (!users || users.length === 0) {
			return '';
		}

		const [selectedUser] = users.filter((user: any) => user.value === data.responsible_user_id);
		return selectedUser?.label;
	}

	const dropdownOptions = [
		{
			key: 'edit',
			label: (
				<div style={{ color: '#2F54EB', fontSize: '1.5rem' }}>
					<EditOutlined />
				</div>
			),
			onClick: handleToggleEdition
		},
		{
			key: 'delete',
			label: (
				<div style={{ color: '#E74150', fontSize: '1.5rem' }}>
					<DeleteOutlined />
				</div>
			),
			onClick: () => handleOnRemove(data?.id)
		}
	];

	useEffect(() => {
		setEditFieldsValues();
	}, []);

	if (isEditing) {
		return (
			<Row justify="space-between" align="middle">
				<Col span={18}>
					<TaskName formName={fieldName} />
				</Col>
				<Col>
					<DueDateDropdown formName={fieldName} />
				</Col>
				<Col>
					<ResponsibleDropdown formName={fieldName} options={users} />
				</Col>
				<Col>
					<Button onClick={handleOnSaveEdition} type="primary" shape="circle" icon={<CheckOutlined />} />
				</Col>
			</Row>
		);
	}

	return (
		<Row justify="space-between" align="middle">
			<Col span={17}>
				<S.Description>{data?.title}</S.Description>
			</Col>
			<Col>
				<span style={{ fontSize: '0.8rem' }}>{moment(data?.due_date).format('L')}</span>
			</Col>
			<Col>
				<UserAvatar name={getResponsibleName()} />
			</Col>
			<Col>
				<Dropdown trigger={['click']} placement="bottomRight" menu={{ items: dropdownOptions }}>
					<S.TransparentButton style={{ fontSize: 25 }}>
						<EllipsisOutlined />
					</S.TransparentButton>
				</Dropdown>
			</Col>
		</Row>
	);
}
