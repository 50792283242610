import { BaseService } from './base-service';
import { NioshRiskCount } from '@/core/domain/niosh-risk-count';
import { NioshRiskCountMapper } from '@/core/mapper/niosh-risk-count';
import { GetNioshRiskCountRequestDTO } from '@/core/dto/niosh-risk-count';

class Niosh extends BaseService<any> {
	constructor(public readonly basePath: string = '/ergonomic-tool/niosh') {
		super();
	}

	async getCountByRisk(params: GetNioshRiskCountRequestDTO): Promise<NioshRiskCount[]> {
		const url = this.basePath + '/count-by-risk';
		const { data } = await this.getInstance().get<NioshRiskCount[]>(url, { params });
		return new NioshRiskCountMapper(data).toDomain();
	}
}

const Service = Object.freeze(new Niosh());
export { Service };
