interface Person {
	id: string;
	name: string;
}

interface ActionPlanCommentsItemAttributes {
	id: string;
	description: string;
	updated_at: string | null;
	user: Person;
}

export class ActionPlanComments {
	public readonly id: string;
	public readonly description: string;
	public readonly updated_at: string | null;
	public readonly user: Person;

	constructor({ id, description, updated_at, user }: ActionPlanCommentsItemAttributes) {
		this.id = id;
		this.description = description;
		this.updated_at = updated_at;
		this.user = user;
	}
}

export interface ActionPlanCommentsDomain {
	total: number;
	comments: ActionPlanComments[];
}
