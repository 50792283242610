import { Mapper } from './base-mapper';
import { NioshRiskCount } from '@/core/domain/niosh-risk-count';
import { NioshRiskCountDTO } from '@/core/dto/niosh-risk-count';

export class NioshRiskCountMapper extends Mapper<NioshRiskCount> {
	private readonly risks: NioshRiskCountDTO[];

	constructor(risks: NioshRiskCountDTO[]) {
		super();
		this.risks = risks;
	}

	toDomain(): NioshRiskCount[] {
		return this.risks;
	}
}
