import { Mapper } from './base-mapper';
import { ReportDTO } from '@/core/dto/reports-generated';
import { ReportsGenerated } from '@/core/domain/reports-generated';

export class ReportsGeneratedFindOneMapper extends Mapper<ReportsGenerated> {
	private readonly report: ReportDTO;
	private readonly labels: string[][];

	constructor(report: ReportDTO, labels: string[][]) {
		super();
		this.report = report;
		this.labels = labels;
	}

	toDomain(): { report: ReportDTO; labels: string[][] } {
		return {
			report: this.report,
			labels: this.labels
		};
	}
}
