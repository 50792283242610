import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { RISK_MAP, riskDescriptionMapper, riskTitleMapper } from './enum';
import {
	ExclamationFilledIcon,
	WarningFilledIcon,
	CircleFilledIcon,
	ButtonCard,
	ResumeCard,
	TitleCard,
	Container
} from './styles';
import { ActionPlanModal } from '@/components/ui/Modals/ActionPlanModal';
import { useEwaJdsD86Context } from '@/components/views/EwaJdsD86/context';

const iconMapper = {
	[RISK_MAP.low]: <CircleFilledIcon />,
	[RISK_MAP.moderate]: <ExclamationFilledIcon $isModerate={true} />,
	[RISK_MAP.high]: <ExclamationFilledIcon />,
	[RISK_MAP.very_high]: <WarningFilledIcon />,
	[RISK_MAP.extreme]: <WarningFilledIcon />
};

interface RiskLevelCardProps {
	fileId: string;
	stepKeyId: string;
	subStepKeyId?: string;
	value: 1 | 2 | 3 | 4 | 5;
	score: number;
}

export const RiskLevelCard: React.FC<RiskLevelCardProps> = ({ value, score, stepKeyId, subStepKeyId }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const { ewaJdsD86 } = useEwaJdsD86Context();

	function handleButtonCardClick(): void {
		setIsModalOpen(!isModalOpen);
	}

	return (
		<Container gutter={[10, 0]} $levelRisk={value}>
			<Col span={3} style={{ textAlign: 'center' }}>
				{iconMapper[value] || <ExclamationFilledIcon />}
			</Col>
			<Col span={21}>
				<Row>
					<Col span={24}>
						<TitleCard $levelRisk={value} level={5}>
							{I18n.get(!value ? 'Fill in the details above' : `${riskTitleMapper[value]} Risk`)}
						</TitleCard>
					</Col>
					<Col span={24}>
						<ResumeCard $levelRisk={value}>{I18n.get(riskDescriptionMapper[value])}</ResumeCard>
					</Col>
					<Col span={24} style={{ paddingTop: '5%', display: `${!value ? 'none' : 'unset'}` }}>
						<ButtonCard size="small" $levelRisk={value} onClick={handleButtonCardClick}>
							{I18n.get('Open action plan')}
						</ButtonCard>
					</Col>
				</Row>
			</Col>
			<ActionPlanModal
				score={score}
				isOpen={isModalOpen}
				fileId={ewaJdsD86.file_id}
				customReportStepKey={stepKeyId}
				onClose={handleButtonCardClick}
				customReportSubStepKey={subStepKeyId}
				companyId={ewaJdsD86.informations.company_id}
				organizationId={ewaJdsD86.informations.organization_id}
			/>
		</Container>
	);
};
