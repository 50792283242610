interface BeraSumRPNAttributes {
	sum_rpn: number;
}

export class BeraSumRPN {
	public readonly sum_rpn: number;

	constructor({ sum_rpn }: BeraSumRPNAttributes) {
		this.sum_rpn = sum_rpn;
	}
}
