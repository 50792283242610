import React, { ReactNode, createContext, useContext, useState } from 'react';
import moment, { Moment } from 'moment';
import { Form } from 'antd';

import { Panels } from './constants';
import { ActionPlanList } from '@/core/domain/action-plan-list';
import { useQueryParams } from '@/hooks/v2/useQueryParams';
import { useApplicationContext } from '@/context/v1/Application/context';
import type { Context, Column } from './types';

const { useForm } = Form;

interface ActionPlanProviderProps {
	children: ReactNode;
}

const ActionPlanContext = createContext<Context>({} as Context);

export function ActionPlanProvider({ children }: Readonly<ActionPlanProviderProps>) {
	const [rows, setRows] = useState<ActionPlanList[]>([]);
	const [columns, setColumns] = useState<Column[]>([]);
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

	const [form] = useForm();
	const query = useQueryParams();
	const { organization } = useApplicationContext();

	const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);

	const currentPanel: string = query.get('panel') || Panels.BOARD;
	const sort = query.get('sort');

	const actionPlanName = query.get('action_plan_name');
	const companyId = query.get('company_id');
	const sectorId = query.get('sector_id');
	const lineId = query.get('line_id');
	const workstationId = query.get('workstation_id');
	const activityId = query.get('activity_id');
	const responsibleId = query.get('responsible_id');
	const creationDate = query.get('creation_date');
	const dueDate = query.get('due_date');
	const investmentRange = query.get('investment_range');
	const priorityUrl = query.get('priority');
	const status = query.get('status');
	const origin = query.get('origin');

	const creation_date = formatDatesToField(creationDate);
	const due_date = formatDatesToField(dueDate);
	const priority = priorityUrl ? Number(priorityUrl) : undefined;

	const filter = {
		action_plan_name: actionPlanName!,
		organization_id: organization?.id,
		company_id: companyId!,
		sector_id: sectorId!,
		line_id: lineId!,
		workstation_id: workstationId!,
		activity_id: activityId!,
		responsible_id: responsibleId!,
		creation_date,
		due_date,
		status: status!,
		priority,
		origin: origin!,
		investment_range: investmentRange ? Number(investmentRange) : undefined
	};

	function formatDatesToField(dates: string | null): Moment[] | undefined {
		if (!dates) return undefined;
		const splittedDates = dates.split(',');
		return [moment(splittedDates[0]), moment(splittedDates[1])];
	}

	function handleToggleFilter(): void {
		setIsFilterOpen(!isFilterOpen);
	}

	function handleToggleNotifications(): void {
		setIsNotificationOpen(!isNotificationOpen);
	}

	function handleSetRows(values: ActionPlanList[]): void {
		setRows(values);
	}

	function handleSetColumns(values: any): void {
		setColumns(values);
	}

	const contextValues: Context = {
		rows,
		columns,
		filter,
		currentPanel,
		isFilterOpen,
		isNotificationsOpen: isNotificationOpen,
		onToggleFilter: handleToggleFilter,
		onToggleNotifications: handleToggleNotifications,
		handleSetRows,
		handleSetColumns
	};

	const initialValues = {
		filter,
		sort,
		create_action_plan: {
			organization_id: organization?.id
		},
		rows
	};

	return (
		<ActionPlanContext.Provider value={contextValues}>
			<Form form={form} initialValues={initialValues}>
				{children}
			</Form>
		</ActionPlanContext.Provider>
	);
}

export function useActionPlanContext() {
	const context = useContext(ActionPlanContext);
	return context;
}
