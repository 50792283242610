import React from 'react';
import { Tooltip } from 'antd';
import { riskColors } from '@/utils/riskRange';
import * as S from '../styles';

interface Item {
	id: string;
	name: string;
	score: number;
}

interface MosaicComponentProps {
	item: Item;
	entity: string;
	onClick: (id: string, entity: string, name: string) => void;
}

const MAX_CHARACTER_NAME = 50;

export function MosaicComponent({ item, entity, onClick }: Readonly<MosaicComponentProps>) {
	function handleOnClick(): void {
		onClick(item.id, entity, item.name);
	}

	function handleOnFormatName(name: string): string {
		return name.length > MAX_CHARACTER_NAME ? `${name.substring(0, MAX_CHARACTER_NAME)}...` : name;
	}

	const displayName = handleOnFormatName(item.name);

	return (
		<S.MosaicCard key={item.id} onClick={handleOnClick} $color={riskColors[item.score]}>
			<Tooltip title={item.name}>
				<span>{displayName}</span>
			</Tooltip>
		</S.MosaicCard>
	);
}
