import React from 'react';
import { Image } from 'antd';
import { ModalPreviewImageWrapper } from './styles';

interface ImagePreviewProps {
	previewOpen: boolean;
	previewImage: string;
	handleCancel: () => void;
}

export function ImagePreview({ previewOpen, previewImage, handleCancel }: Readonly<ImagePreviewProps>) {
	return (
		<ModalPreviewImageWrapper open={previewOpen} footer={false} onCancel={handleCancel} centered>
			<Image alt="image" style={{ width: '100%' }} src={previewImage} preview={false} />
		</ModalPreviewImageWrapper>
	);
}
