import React, { useState } from 'react';
import { Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DownloadOutlined } from '@ant-design/icons';

import { ModalPdf } from './ModalPdf';
import * as S from './styles';

export function DownloadPdf() {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	function handleToggleModalPdf(): void {
		setIsModalOpen(!isModalOpen);
	}

	return (
		<Row>
			<S.CustomButton onClick={handleToggleModalPdf} icon={<DownloadOutlined />}>
				{I18n.get('Download pdf')}
			</S.CustomButton>
			<ModalPdf isOpen={isModalOpen} onClose={handleToggleModalPdf} />
		</Row>
	);
}
