import styled from 'styled-components';

export const Container = styled.div`
	background-color: rgba(248, 248, 248, 1);
	transition: border 0.3s ease-in-out;
	border-radius: 18px;
	text-align: center;
	cursor: pointer;
	padding: 20px;

	&:hover {
		box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.2);
		background-color: rgba(234, 238, 253, 1);
	}
`;

export const Icon = styled.div`
	color: rgba(47, 84, 235, 1);
	margin-bottom: 10px;
	font-size: 48px;
`;

export const Text = styled.p`
	color: rgba(38, 38, 38, 1);
	line-break: loose;
	font-size: 20px;
	margin: 0;
`;
