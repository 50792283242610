import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import type { DefaultOptionType } from 'antd/lib/select';

import * as S from './styles';
import type { FormFieldProps } from '../../types';

const { useFormInstance, useWatch } = Form;

interface SectorProps extends FormFieldProps {
	options?: DefaultOptionType[];
}

export function Sector({
	options = [],
	formName = [''],
	isLoading = false,
	isDisabled = false,
	isRequired = false
}: Readonly<SectorProps>) {
	const form = useFormInstance();
	const companyId = useWatch([...formName, 'company_id'], form);

	const title = I18n.get('Sector');
	const errorMessage = I18n.get('Select the sector');

	function handleClearHierarchyFields(): void {
		form.setFieldValue([...formName, 'line_id'], undefined);
		form.setFieldValue([...formName, 'workstation_id'], undefined);
		form.setFieldValue([...formName, 'origin'], undefined);
	}

	return (
		<S.CustomFormItem
			label={title}
			required={isRequired}
			labelCol={{ span: 24 }}
			name={[...formName, 'sector_id']}
			rules={[{ required: isRequired, message: errorMessage }]}
		>
			<S.CustomSelect
				allowClear
				options={options}
				loading={isLoading}
				placeholder={title}
				disabled={isDisabled || !companyId}
				onClear={handleClearHierarchyFields}
				onChange={handleClearHierarchyFields}
			/>
		</S.CustomFormItem>
	);
}
