import styled from 'styled-components';
import { Row, Col } from 'antd';
import Icon from '@ant-design/icons';
import { Line } from 'react-chartjs-2';

const GNR = '#17A93B';
const NOT_GNR = '#E80707';

export const colors: string[] = [GNR, NOT_GNR];

export const StyledCol = styled(Col)`
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
	justify-content: center;

	p {
		margin: 0;
		font-size: 1rem;
		span {
			font-weight: 600;
		}
	}
`;

export const StyledRow = styled(Row)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const IconWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CustomIcon = styled(Icon)`
	font-size: 2rem;
	margin-right: 0.5rem;
`;

export const IconTextWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const LineChartWrapper = styled(Line)`
	margin: 0 auto;
	max-width: 100%;
	max-height: 320px;
	width: 100% !important;
`;
