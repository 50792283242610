import { Mapper } from './base-mapper';
import { RebaExposureScore, RebaExposureScoreBodyPartAttributes } from '../domain/reba-exposure-score';
import { RebaExposureScoreDTO, RebaExposureScoreBodyPartDTO } from '../dto/reba-exposure-score';

export class RebaExposureScoreMapper extends Mapper<RebaExposureScore> {
  private readonly data: RebaExposureScoreDTO;

  constructor(data: RebaExposureScoreDTO) {
    super();
    this.data = data;
  }

  private mapBodyPart(bodyPart: RebaExposureScoreBodyPartDTO): RebaExposureScoreBodyPartAttributes {
    return {
      name: bodyPart.name,
      totalSeconds: bodyPart.total_seconds,
      data: bodyPart.data.map(item => ({
        risk: item.risk,
        percentage: item.percentage,
      })),
    };
  }

  toDomain(): RebaExposureScore {
    const bodyParts = this.data.map(this.mapBodyPart);
    return new RebaExposureScore(bodyParts);
  }
}
