import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '@/infra/services/user';
import { ResponsableDTO } from '@/core/dto/responsable';

export interface GetResponsableOptionsParams {
	organization_id: string;
	company_id: string;
}

const transformResponsibleOptions = (data: ResponsableDTO[]) => {
	return data.map((responsible) => ({ value: responsible.id, label: responsible.name }));
};

export const useGetResponsableOptions = (params: GetResponsableOptionsParams) => {
	return useQuery([QUERY_KEYS.GET_RESPONSIBLES_OPTIONS, params], () => Service.listOptions(params), {
		enabled: !!params.organization_id && !!params.company_id,
		select: transformResponsibleOptions
	});
};
