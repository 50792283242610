import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ScoreCard } from './ScoreCard';
import { Status } from '../../../Fields';
import { useEditActionPlanContext } from '../context';
import * as S from './styles';

interface InformationProps {
	formName: string[];
}

export function Informations({ formName }: Readonly<InformationProps>) {
	const { actionPlan } = useEditActionPlanContext();

	const originName = actionPlan.origin ? actionPlan.origin.name : 'N/A';

	return (
		<Row gutter={[0, 20]}>
			<Col span={24}>
				<Row gutter={[20, 0]}>
					<Col span={14}>
						<Status formName={formName} showLabel={false} />
					</Col>
					{!!actionPlan.score && (
						<Col span={10} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<ScoreCard score={actionPlan.score} />
						</Col>
					)}
				</Row>
			</Col>
			<S.Card span={24}>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<S.Label>{I18n.get('Creation date')}:</S.Label>
						<S.Value>{moment(actionPlan.created_at).format('L')}</S.Value>
					</Col>
					<Col span={24}>
						<S.Label>{I18n.get('Author')}:</S.Label>
						<S.Value>{actionPlan.author.name}</S.Value>
					</Col>
					<Col span={24}>
						<S.Label>{I18n.get('Origin')}:</S.Label>
						<S.Value>{originName}</S.Value>
					</Col>
				</Row>
			</S.Card>
			<S.Card span={24}>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<S.Label>{I18n.get('Company')}:</S.Label>
						<S.Value>{actionPlan.organization.name}</S.Value>
					</Col>
					<Col span={24}>
						<S.Label>{I18n.get('Industrial site')}:</S.Label>
						<S.Value>{actionPlan.company.name}</S.Value>
					</Col>
					<Col span={24}>
						<S.Label>{I18n.get('Sector')}:</S.Label>
						<S.Value>{actionPlan.sector.name}</S.Value>
					</Col>
					<Col span={24}>
						<S.Label>{I18n.get('Line')}:</S.Label>
						<S.Value>{actionPlan.line.name}</S.Value>
					</Col>
					<Col span={24}>
						<S.Label>{I18n.get('Workstation')}:</S.Label>
						<S.Value>{actionPlan.workstation.name}</S.Value>
					</Col>
				</Row>
			</S.Card>
		</Row>
	);
}
