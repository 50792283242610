import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Input, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from '../styles';
import { CommentFormProps } from './types';

export function CommentForm({ form, onFinish, onCancel, initialValues }: Readonly<CommentFormProps>) {
	return (
		<Col span={24}>
			<Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
				<Form.Item name="comment" rules={[{ required: true, message: 'Please enter a description!' }]}>
					<Input.TextArea rows={4} placeholder={I18n.get('Description of the file')} />
				</Form.Item>
				<Form.Item>
					<Row justify="center" gutter={[20, 20]}>
						<Col>
							<SecondaryButton onClick={onCancel}>{I18n.get('Cancel')}</SecondaryButton>
						</Col>
						<Col>
							<PrimaryButton type="primary" htmlType="submit">
								{I18n.get('Save')}
							</PrimaryButton>
						</Col>
					</Row>
				</Form.Item>
			</Form>
		</Col>
	);
}
