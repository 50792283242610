import React from 'react';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useDownloadPdfActionPlanEdit } from '@/hooks/v2/useDownloadPdfActionPlanEdit';
import { useEditActionPlanContext } from '../../context';
import { useApplicationContext } from '@/context/v1/Application/context';
import * as S from './styles';

interface ModalPdfProps {
	isOpen: boolean;
	onClose(): void;
}

const { useForm } = Form;

export function ModalPdf({ isOpen, onClose }: Readonly<ModalPdfProps>) {
	const [form] = useForm<FormData>();
	const browserLanguage = window.navigator.language ?? 'en-US';
	const { actionPlan } = useEditActionPlanContext();
	const { organization, company } = useApplicationContext();

	const { mutateAsync: downloadPdfEdit } = useDownloadPdfActionPlanEdit();

	async function handleOnSubmit(): Promise<void> {
		await form.validateFields();

		const parameters = {
			organization_id: organization?.id,
			company_id: company?.id,
			id: actionPlan.id,
			locale: browserLanguage,
			sections: form.getFieldValue('items')
		};

		downloadPdfEdit(parameters);
	}

	const items = [
		{ label: I18n.get('Responsible'), value: 'responsible', key: 0 },
		{ label: I18n.get('Due Date'), value: 'due_date', key: 1 },
		{ label: I18n.get('Priority'), value: 'priority', key: 2 },
		{ label: I18n.get('Investment'), value: 'investment', key: 3 },
		{ label: I18n.get('Attachments - Images'), value: 'image_attachments', key: 4 },
		{ label: I18n.get('Attachments - Other files'), value: 'other_attachments', key: 5 },
		{ label: I18n.get('Completed tasks'), value: 'completed_tasks', key: 6 },
		{ label: I18n.get('Tasks not completed'), value: 'uncompleted_tasks', key: 7 },
		{ label: I18n.get('Edit history'), value: 'history', key: 8 },
		{ label: I18n.get('Comments'), value: 'comments', key: 9 }
	];

	const initialValues = {
		items: items.map((item) => item.value)
	};

	const options = items.map((item) => ({
		label: item.label,
		value: item.value
	}));

	return (
		<S.ModalWrapper width={600} open={isOpen} onCancel={onClose} footer={null}>
			<Row gutter={[0, 15]}>
				<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
					<S.TitlePdf>PDF</S.TitlePdf>
				</Col>
				<Col span={24}>
					<S.SubTitlePdf>{I18n.get('Select the items you want to present in your document')}</S.SubTitlePdf>
				</Col>
			</Row>
			<Form
				form={form}
				layout="vertical"
				onFinish={handleOnSubmit}
				initialValues={initialValues}
				style={{ width: '100%' }}
			>
				<Row gutter={[0, 15]}>
					<Col span={24}>
						<Form.Item name="items">
							<S.CheckboxGroupWrapper options={options} />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Row justify="center" gutter={[16, 0]}>
							<Col>
								<S.SecondaryButton onClick={onClose}>{I18n.get('Cancel')}</S.SecondaryButton>
							</Col>
							<Col>
								<S.PrimaryButton type="primary" onClick={handleOnSubmit}>
									{I18n.get('Generate document')}
								</S.PrimaryButton>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</S.ModalWrapper>
	);
}
