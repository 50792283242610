import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Switch, Form, Row } from 'antd';

interface PredeterminedTimeProps {
	formName: string[];
}

export function PredeterminedTime({ formName }: Readonly<PredeterminedTimeProps>) {
	return (
		<Row align="middle">
			<Col>
				<Form.Item
					name={[...formName, 'predetermined_time_value']}
					valuePropName="checked"
					style={{ margin: 0 }}
				>
					<Switch />
				</Form.Item>
			</Col>
			<Col>
				<span style={{ marginLeft: '5px' }}>{I18n.get('Predetermined time frame')}</span>
			</Col>
		</Row>
	);
}
