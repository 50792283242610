import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Header } from './Header';
import { Content } from './Content';
import { Navigation } from './Navigation';
import { MosaicResultProvider } from './context';
import * as S from './styles';

export function Mosaic() {
	const history = useHistory();
	const location = useLocation();

	function handleOnClick(id: string, entity: string, name: string): void {
		if (!entity || !id || entity === 'task' || entity === 'file') {
			return;
		}

		const searchParams = new URLSearchParams(location.search);
		const existingValue = searchParams.get(entity);

		if (existingValue === id) {
			return;
		}

		searchParams.set(entity, id);
		entity !== 'custom_report_id' && searchParams.set(`${entity}_name`, name);
		const path = `${location.pathname}?${searchParams.toString()}`;
		history.push(path);
	}

	return (
		<MosaicResultProvider>
			<Navigation />
			<S.HeadertWrapper>
				<Header handleOnClick={handleOnClick} />
			</S.HeadertWrapper>
			<Content handleOnClick={handleOnClick} />
		</MosaicResultProvider>
	);
}
