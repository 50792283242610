import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';

import { unShortenUrl } from '../../utils';
import { Card } from '../../components/Card';
import { StyledDivPopOver } from '../../styles';
import { HierarchyItem } from './HierarchyItem';
import { useGetCustomReportByName } from '@/hooks';
import { Single } from '../../components/Filters/Single';
import { CUSTOM_REPORT_NAME } from '@/utils/customReport';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetHierarchyWorstScores } from '../../hooks/useGetHierarchyWorstScores';
import * as S from './styles';

const TOTAL_ITEMS = 10;
const { useFormInstance } = Form;

interface EwaD86ComparisonProps {
	onClear(fieldNames: string[]): void;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export function EwaD86Comparison({ onClear }: Readonly<EwaD86ComparisonProps>) {
	const { organization, company } = useApplicationContext();
	const query = useQuery();
	const form = useFormInstance();
	const location = useLocation();

	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');
	const workstation_id = query.get('workstation');

	const fieldName = `ewa_d86_comparison`;
	const urlParams = unShortenUrl(location.search.substring(1));
	const queryFilters = urlParams?.[fieldName] ?? urlParams?.general?.unique_period;

	const {
		data: customReport,
		isError: hasErrorCustomReport,
		isLoading: isGettingCustomReport
	} = useGetCustomReportByName({
		organization_id: organization?.id,
		company_id: company_id ?? company?.id,
		report_name: CUSTOM_REPORT_NAME.EWA_D86
	});

	const {
		isLoading: isGettingWorstScores,
		data: worstScores,
		isError: hasErrorWorstScores
	} = useGetHierarchyWorstScores({
		organization_id: organization?.id,
		company_id: company_id!,
		sector_id: sector_id!,
		line_id: line_id!,
		workstation_id: workstation_id!,
		custom_report_id: customReport?.id,
		start_date: queryFilters?.start_date,
		end_date: queryFilters?.end_date,
		limit: TOTAL_ITEMS
	});

	const listFirstPart = worstScores?.[0];
	const listSecondPart = worstScores?.[1];

	const isError = hasErrorCustomReport || hasErrorWorstScores;
	const isLoading = isGettingCustomReport || isGettingWorstScores;
	const isEmpty = listFirstPart?.length === 0 && listSecondPart?.length === 0;

	const subtitle = `${I18n.get('Risk comparison between')} ${I18n.get(getHierarchyName())}`;

	function getHierarchyName(): string {
		if (!!workstation_id) {
			return 'files';
		}

		if (!!line_id) {
			return 'workstations';
		}

		if (!!sector_id) {
			return 'lines';
		}

		if (!!company_id) {
			return 'sectors';
		}

		return 'industrial sites';
	}

	function handleOnSubmit(): void {
		form.submit();
	}

	function getDisplayDate(): { start: string; end: string } {
		return {
			start: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(queryFilters?.start_date),
			end: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(queryFilters?.end_date)
		};
	}

	return (
		<Card.Container size="medium" isLoading={isLoading} isError={isError}>
			<Card.Header
				hasIcon
				hasDisplayDate
				displayDate={getDisplayDate()}
				isTitleBold
				subtitle={subtitle}
				icon={<CalendarOutlined />}
				title={I18n.get('AEP (NR17 + JDS D86)')}
				popoverComponent={
					<StyledDivPopOver>
						<Single
							handleClear={onClear}
							hasGranularity={false}
							fieldName={[fieldName]}
							handleFormSubmit={handleOnSubmit}
						/>
					</StyledDivPopOver>
				}
			/>
			<Card.Content isEmpty={isEmpty}>
				<Row justify="space-between">
					<Col span={11}>
						<S.ListContainer align="top">
							{listFirstPart?.map(({ id, name, worst_score, rank }) => (
								<Col key={id} span={24}>
									<HierarchyItem title={name} score={worst_score} rank={rank} />
								</Col>
							))}
						</S.ListContainer>
					</Col>
					{listSecondPart && listSecondPart.length > 0 && (
						<Col span={1} style={{ display: 'flex', justifyContent: 'center' }}>
							<S.CustomDivider type="vertical" />
						</Col>
					)}
					<Col span={11}>
						<S.ListContainer align="top">
							{listSecondPart?.map(({ id, name, worst_score, rank }) => (
								<Col key={id} span={24}>
									<HierarchyItem title={name} score={worst_score} rank={rank} />
								</Col>
							))}
						</S.ListContainer>
					</Col>
				</Row>
			</Card.Content>
		</Card.Container>
	);
}
