export enum QUERY_KEYS {
	GET_ORGANIZATION = 'get-organization',
	GET_CUSTOM_REPORTS = 'get-custom-reports',
	GET_MOSAIC = 'get-mosaic',
	GET_STRAIN_INDEX_RSI = 'get-strain-index-rsi',
	GET_CHECKLIST_REPORTS = 'get-checklist-reports',
	GET_DELAYED_ACTION_PLANS = 'get-delayed-action-plans',
	GET_PERCENTILE_BY_GENDER = 'percentile-by-gender',
	GET_STATUS_ACTION_PLANS = 'get-status-action-plans',
	GET_CUSTOM_REPORT_RISKS_COUNT = 'get-custom-report-risks-count',
	GET_BERA_COMPARISON = 'get-bera-comparison',
	GET_SERA_COMPARISON = 'get-sera-comparison',
	GET_BERA_SIX_TOO = 'get-bera-six-too',
	GET_SERA_MAIN_RISK = 'get-sera-main-risk',
	GET_SERA_SUM_RPN = 'get-sera-sum-rpn',
	GET_BERA_SUM_RPN = 'get-bera-sum-rpn',
	GET_REBA_EXPOSURE_SCORE = 'get-reba-exposure-score',
	GET_REBA_EXPOSURE_BODY = 'get-reba-exposure-body',
	GET_NIOSH_RISKS_COUNT = 'get-niosh-risks-count',
	GET_KIM_MHO_RISKS_COUNT = 'get-kim-mho-risks-count',
	GET_KIM_PP_RISKS_COUNT = 'get-kim-pp-risks-count',
	GET_BERA_GNS = 'get-bera-gns',
	GET_ACTION_PLAN_LIST = 'get-action-plan-list',
	GET_COMPANIES = 'get-companies-options',
	GET_LINES = 'get-lines-options',
	GET_SECTORS = 'get-sectors-options',
	GET_WORKSTATIONS_OPTIONS = 'get-workstations-options',
	GET_ACTIVITIES_OPTIONS = 'get-activities-options',
	GET_RESPONSIBLES_OPTIONS = 'get-responsibles-options',
	GET_CUSTOM_REPORT_OPTIONS = 'get-custom-report-options',
	GET_ATTACHMENTS = 'get-attachments',
	GET_RELATED_REPORTS = 'get-related-reports',
	GET_ACTION_PLAN_CARD_LIST = 'get-action-plan-card-list',
	GET_ACTION_PLAN_ORIGINS = 'get-action-plan-origins',
	GET_ACTION_PLAN_USER_PREFERENCE_COLUMNS = 'get-action-plan-user-preference-columns',
	GET_ACTION_PLAN_HISTORY = 'get-action-plan-history',
	GET_ACTION_PLAN_COMMENTS = 'get-action-plan-comments',
	GET_ACTION_PLAN_NOTIFICATIONS = 'get-action-plan-notifications',
	CREATE_ACTION_PLAN_COMMENTS = 'create-action-plan-comments',
	UPDATE_ACTION_PLAN_COMMENTS = 'update-action-plan-comments',
	DELETE_ACTION_PLAN_COMMENTS = 'delete-action-plan-comments',
	GET_ACTION_PLAN = 'get-action-plan',
	GET_ACTION_PLAN_TASKS = 'get-action-plan-tasks',
	GET_ACTION_PLANS_COUNT = 'get-action-plans-count',
	GET_EVIDENCES = 'get-evidences',
	GET_ATTACHMENT_DOWNLOAD_URL = 'get-attachment-download-url',
	GET_TASK_ATTACHMENT_DOWNLOAD_URL = 'get-task-attachment-download-url',
	GET_ACTION_PLAN_FROM_REPORT = 'get-action-plan-from-report',
	GET_ACTION_PLANS_BY_REPORT = 'get-action-plans-by-report'
}
