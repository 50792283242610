import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/custom-report';

export interface GetMosaicParams {
	custom_report_id?: string;
	organization_id: string;
	workstation_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	limit: number;
	offset: number;
}

export const useGetMosaic = (params: GetMosaicParams) => {
	return useQuery([QUERY_KEYS.GET_MOSAIC, params], () => Service.getMosaic(params), {
		enabled: !!params.custom_report_id && !!params.organization_id,
		retry: 1
	});
};
