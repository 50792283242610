import { useMutation } from '@tanstack/react-query';
import { Service } from '@/infra/services/action-plan';
import { AxiosError } from 'axios';
import { message } from 'antd';
import { I18n } from '@aws-amplify/core';

interface GetPdfEditParams {
	organization_id: string;
	company_id: string;
	id: string;
	locale: string;
	sections: string[];
}

export const downloadPdfEdit = (params: GetPdfEditParams) => {
	return Service.downloadPdfEdit(params);
};

export const useDownloadPdfActionPlanEdit = () => {
	return useMutation<string, AxiosError<Response>, GetPdfEditParams>((params) => Service.downloadPdfEdit(params), {
		onError: (err: any) => {
			message.error(I18n.get(err?.response?.data?.message ?? err?.message));
		},
		onSuccess: (data) => {
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		}
	});
};
