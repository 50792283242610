import React, { useEffect } from 'react';
import { Row, Col, Form, Skeleton } from 'antd';

import { GetCustomReportResultStepKeyDefaultDataResponse } from '@/hooks/useGetCustomReportResultStepKeyDefaultData';
import { Vulnerabilities, Exposures, Severities, RiskCategories, RiskDescriptions, RiskDamages } from './Inputs';
import { useApplicationContext } from '@/context/v1/Application/context';
import {
	useCalculateStepKeyRpn,
	useGetCustomReportStepKeysDefaultRisks,
	useGetCustomReportSubStepKeysDefaultRisks
} from '@/hooks';
import { useStepKeysContext } from './context';
import { Title } from '@/components/Typography';
import { RiskLevelCard } from './RiskLevelCard';

const { useWatch, useFormInstance } = Form;

interface DefaultSelectsProps {
	stepKeyId: string;
	subStepKeyId?: string;
	formFieldName: string[];
	disableExposure?: boolean;
	children?: React.ReactNode;
	customReportDefaultData?: GetCustomReportResultStepKeyDefaultDataResponse | null;
	gettingCustomReportDefaultData?: boolean;
}

export const DefaultSelects: React.FC<DefaultSelectsProps> = ({
	children,
	stepKeyId,
	subStepKeyId,
	formFieldName,
	disableExposure = false
}) => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { file_id } = useStepKeysContext();
	const exposureId: string = useWatch([...formFieldName, 'exposure_id'], form);
	const vulnerabilityId: string = useWatch([...formFieldName, 'vulnerability_id'], form);
	const severityId: string = useWatch([...formFieldName, 'severity_id'], form);
	const { data: stepKeyDefaultRisks, isFetching: gettingStepKeyDefaultRisks } =
		useGetCustomReportStepKeysDefaultRisks({
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_step_key_id: stepKeyId
		});

	const { data: subStepKeyDefaultRisks, isFetching: gettingSubStepKeyDefaultRisks } =
		useGetCustomReportSubStepKeysDefaultRisks({
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_sub_step_key_id: subStepKeyId
		});

	const { data: rpnScore, isFetching: gettingRpnScore } = useCalculateStepKeyRpn({
		exposure_id: exposureId,
		company_id: company?.id,
		severity_id: severityId,
		vulnerability_id: vulnerabilityId,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKeyId
	});

	useEffect(() => {
		if (
			(!gettingStepKeyDefaultRisks && stepKeyDefaultRisks) ||
			(!gettingSubStepKeyDefaultRisks && subStepKeyDefaultRisks)
		) {
			const riskCategoryId: string = form.getFieldValue([...formFieldName, 'risk_category_id']);
			if (!riskCategoryId) {
				form.setFieldValue(
					[...formFieldName, 'risk_category_id'],
					subStepKeyDefaultRisks.risk_category_id ?? stepKeyDefaultRisks.risk_category_id
				);
			}

			const riskDescriptionId: string = form.getFieldValue([...formFieldName, 'risk_description_id']);
			if (!riskDescriptionId) {
				form.setFieldValue(
					[...formFieldName, 'risk_description_id'],
					subStepKeyDefaultRisks.risk_description_id ?? stepKeyDefaultRisks.risk_description_id
				);
			}

			const riskDamageId: string = form.getFieldValue([...formFieldName, 'risk_damage_id']);
			if (!riskDamageId) {
				form.setFieldValue(
					[...formFieldName, 'risk_damage_id'],
					subStepKeyDefaultRisks.risk_damage_id ?? stepKeyDefaultRisks.risk_damage_id
				);
			}
		}
	}, [gettingStepKeyDefaultRisks, gettingSubStepKeyDefaultRisks]);

	useEffect(() => {
		if (!gettingRpnScore && severityId) {
			form.setFieldValue([...formFieldName, 'result'], rpnScore.result);
			form.setFieldValue([...formFieldName, 'score'], rpnScore.score);
		}
	}, [gettingRpnScore]);

	return (
		<Row gutter={[0, 50]}>
			<Col sm={24} lg={12}>
				<Row justify="start">
					<Col sm={24} lg={18}>
						<RiskCategories formFieldName={formFieldName} />
						<RiskDescriptions formFieldName={formFieldName} />
						<RiskDamages formFieldName={formFieldName} />
					</Col>
				</Row>
			</Col>
			<Col sm={24} lg={12}>
				<Row justify="start">
					<Col sm={24} lg={18}>
						<Severities formFieldName={formFieldName} />
						<Vulnerabilities formFieldName={formFieldName} />
						<Exposures formFieldName={formFieldName} disabled={disableExposure} />
					</Col>
				</Row>
			</Col>
			<Col sm={24} lg={12}>
				{children}
			</Col>
			{gettingRpnScore ? (
				<Col sm={24} lg={12}>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Skeleton active />
						</Col>
					</Row>
				</Col>
			) : (
				<Col sm={24} lg={12}>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Title level={4}>Risk Index:</Title>
						</Col>
						<Col span={24}>
							<RiskLevelCard
								score={rpnScore.score}
								stepKeyId={stepKeyId}
								value={rpnScore.result}
								fileId={file_id}
								subStepKeyId={subStepKeyId}
							/>
						</Col>
					</Row>
				</Col>
			)}
		</Row>
	);
};
