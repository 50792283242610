import React from 'react';
import { Row, Col, Dropdown, Form } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import { UserAvatar } from '@/components/ui/UserAvatar';
import type { FormFieldProps, SelectOption } from './types';
import * as S from './styles';

const { useWatch, useFormInstance } = Form;

interface ResponsibleDropdownProps extends FormFieldProps {
	options?: SelectOption[];
}

export function ResponsibleDropdown({
	formName = [''],
	options = [],
	isRequired = false,
	isDisabled = false
}: Readonly<ResponsibleDropdownProps>) {
	const form = useFormInstance();

	const fieldName = [...formName, 'responsible_id'];
	const responsible = useWatch(fieldName);

	function handleOnSelectValue(id: string): void {
		form.setFieldValue(fieldName, id);
	}

	const userOptions = options?.map(({ value, label }) => {
		return {
			key: value,
			label: (
				<Row align="middle" gutter={[10, 0]}>
					<Col>
						<UserAvatar name={label} size="small" />
					</Col>
					<Col>
						<span>{label}</span>
					</Col>
				</Row>
			)
		};
	});

	return (
		<S.CustomFormItem required={isRequired} name={fieldName}>
			<Dropdown
				trigger={['click']}
				disabled={isDisabled}
				placement="bottomRight"
				overlayStyle={{ maxHeight: 200, overflow: 'auto', boxShadow: ' 2px 2px 10px 0px #00000040' }}
				menu={{ items: userOptions, selectedKeys: [responsible], onClick: (e) => handleOnSelectValue(e.key) }}
			>
				<S.IconButton $isSelected={!!responsible} $isDisabled={isDisabled}>
					<UserAddOutlined />
				</S.IconButton>
			</Dropdown>
		</S.CustomFormItem>
	);
}
