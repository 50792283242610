import Api from '@/services/api';
import type { AxiosInstance } from 'axios';

export type BasicInfo = {
	id: string;
	company_id: string;
	organization_id: string;
};

export interface IRead<T> {
	findOne(params: BasicInfo): Promise<T>;
	findAll(): Promise<T[]>;
}

export interface IWrite<T> {
	create(data: T): Promise<T>;
	udpate(data: T): Promise<T>;
	destroy(id: string): Promise<void>;
}

export abstract class BaseService<T> implements IRead<T>, IWrite<T> {
	constructor(private readonly api: AxiosInstance = Api) {}

	getInstance(): AxiosInstance {
		return this.api;
	}

	// @ts-ignore
	findOne(params: BasicInfo): Promise<T> {
		throw new Error('Method not implemented.');
	}

	findAll(): Promise<T[]> {
		throw new Error('Method not implemented.');
	}

	create(data: T): Promise<T> {
		throw new Error('Method not implemented.');
	}

	udpate(data: T): Promise<T> {
		throw new Error('Method not implemented.');
	}

	destroy(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
}
