import { Mapper } from './base-mapper';
import { CustomReportRiskCount } from '../domain/custom-report-risk-count';
import { CustomReportRiskCountDTO } from '../dto/custom-report-risk-count';

export class CustomReportRiskCountMapper extends Mapper<CustomReportRiskCount> {
	private readonly acceptable_risk: number;
	private readonly moderate_risk: number;
	private readonly high_risk: number;
	private readonly very_high_risk: number;
	private readonly serious_and_imminent_risk: number;

	constructor({
		acceptable_risk,
		moderate_risk,
		high_risk,
		very_high_risk,
		serious_and_imminent_risk
	}: CustomReportRiskCountDTO) {
		super();
		this.acceptable_risk = acceptable_risk;
		this.moderate_risk = moderate_risk;
		this.high_risk = high_risk;
		this.very_high_risk = very_high_risk;
		this.serious_and_imminent_risk = serious_and_imminent_risk;
	}

	toTransform(value: CustomReportRiskCountDTO): CustomReportRiskCount[] {
		return Object.keys(value).map((key) => ({
			risk: key,
			count: value[key as keyof CustomReportRiskCountDTO]
		}));
	}

	toDomain(): CustomReportRiskCount[] {
		return this.toTransform({
			acceptable_risk: this.acceptable_risk,
			moderate_risk: this.moderate_risk,
			high_risk: this.high_risk,
			very_high_risk: this.very_high_risk,
			serious_and_imminent_risk: this.serious_and_imminent_risk
		});
	}
}
