import styled from 'styled-components';
import { Row, Col, Divider } from 'antd';

const BLACK_COLOR = '#26262680';

export const CustomDivider = styled(Divider)`
	height: 100%;
	border-left: 1px solid ${BLACK_COLOR};
`;

export const ListContainer = styled(Row)`
	margin-bottom: 100%;
	> .ant-col {
		padding: 1rem 0;

		&:not(:last-child) {
			border-bottom: 1px solid ${BLACK_COLOR};
		}
	}
`;
export const DescriptionWrapper = styled(Col)`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const CustomText = styled.span`
	margin-right: 0.5rem;
	padding-right: 0.5rem;
	border-right: 1px solid #000000;
`;
