import { Mapper } from './base-mapper';
import { ActionPlanPriorityEnum } from '@/utils/enum';
import { ActionPlanItemDTO, ActionPlanListDTO } from '../dto/action-plan-list';
import { ActionPlanList, ActionPlanListDomain, Priority } from '../domain/action-plan-list';

export class ActionPlanListMapper implements Mapper<ActionPlanListDomain> {
	private readonly response: ActionPlanListDTO;

	constructor(response: ActionPlanListDTO) {
		this.response = {
			offset: response?.offset ?? 0,
			limit: response?.limit ?? 10,
			total: response?.total ?? 0,
			data: Array.isArray(response?.data) ? response.data : []
		};
	}

	private formatStatus(status: string): string {
		const statusMap: Record<string, string> = {
			'TO DO': 'Not started',
			DOING: 'Doing',
			DONE: 'Done'
		};

		return statusMap[status] || status;
	}

	private getPriority(value: number): Priority {
		return (ActionPlanPriorityEnum[value] || 'Unknown') as Priority;
	}

	private mapActionPlanItem(item: ActionPlanItemDTO): ActionPlanList {
		return new ActionPlanList({
			id: item.id,
			company_id: item.company_id,
			title: item.title,
			status: this.formatStatus(item.status),
			score: item.score ?? 0,
			priority: this.getPriority(item.priority),
			investment: item.investment,
			progress: 0,
			attachments: item.attachments,
			evidences: item.evidences,
			origin: item.origin_name,
			completedTasks: item.completed_tasks,
			totalTasks: item.total_tasks,
			responsible: {
				name: item.responsible.name,
				url_logo: item.responsible.url_logo ?? null
			},
			author: {
				name: item.author.name,
				url_logo: item.author.url_logo ?? null
			},
			dueDate: item.due_date ?? null,
			completedAt: item.completed_at ?? null,
			createdAt: item.created_at ?? null
		});
	}

	toDomain(): ActionPlanListDomain {
		return {
			offset: this.response.offset,
			limit: this.response.limit,
			total: this.response.total,
			data: this.response.data.map((item) => this.mapActionPlanItem(item))
		};
	}
}
