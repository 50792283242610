import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '@/infra/services/workstation';
import { WorkstationDTO } from '@/core/dto/workstation';

export interface GetWorkstationOptionsParams {
	company_id: string;
	organization_id: string;
	line_id: string;
}

const transformWorkstationsOptions = (data: WorkstationDTO[]) => {
	return data.map((workstation) => ({ value: workstation.id, label: workstation.name }));
};

export const useWorkstationOptions = (params: GetWorkstationOptionsParams) => {
	return useQuery([QUERY_KEYS.GET_WORKSTATIONS_OPTIONS, params], () => Service.listOptions(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.line_id,
		select: transformWorkstationsOptions
	});
};
