import styled from 'styled-components';

interface ToolCardProps {
	$isSelected?: boolean;
	$isDisabled?: boolean;
}

export const ToolCard = styled.div<ToolCardProps>`
	border: 2px solid ${(props) => (props.$isSelected ? '#2563EB' : 'rgba(230, 230, 230, 1)')};
	background-color: ${(props) => (props.$isSelected ? '#2563EB10' : '#fff')};
	border-radius: 12px;
	align-items: center;
	cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
	display: flex;
	padding: 10px;
	height: 100%;
	opacity: ${(props) => (props.$isDisabled ? 0.5 : 1)};
	transition: all 0.2s ease;

	:hover {
		${(props) =>
			!props.$isDisabled &&
			`
			background-color: rgba(248, 248, 248, 1);
			border-color: ${props.$isSelected ? '#2563EB' : '#2563EB80'};
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
		`}
	}

	:active {
		${(props) =>
			!props.$isDisabled &&
			`
			transform: scale(0.99);
		`}
	}
`;

export const ToolIcon = styled.div`
	font-size: 30px;
	margin-right: 15px;
	margin-left: 10px;
	width: 30px;
`;

export const ToolInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ToolName = styled.strong`
	font-size: 16px;
`;

export const ToolDescription = styled.p`
	margin: 0;
	color: #666;
`;
