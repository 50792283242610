import React, { useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import { useDropzone } from 'react-dropzone';
import { CloudUploadOutlined } from '@ant-design/icons';
import * as S from './styles';

interface DropProps {
	disabled?: boolean;
	onFileDrop: (file: File) => void;
}

export function Drop({ onFileDrop, disabled }: DropProps) {
	const handleOnDropFiles = useCallback(
		(acceptedFiles: File[]): void => {
			if (acceptedFiles.length === 0) return;
			onFileDrop(acceptedFiles[0]);
		},
		[onFileDrop]
	);

	const { isDragActive, getRootProps, getInputProps } = useDropzone({
		onDrop: handleOnDropFiles,
		disabled: disabled,
		maxFiles: 1,
		accept: {
			'text/*': ['text/x-comma-separated-values', 'text/comma-separated-values', 'text/csv'],
			'application/*': [
				'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'application/vnd.ms-excel',
				'application/x-csv',
				'application/csv',
				'application/pdf',
				'vnd.ms-excel',
				'msword'
			],
			'image/*': []
		}
	});

	const dragActiveMessage = isDragActive
		? 'Drop files here'
		: 'Drag or select the files you want to attach to your action plan';

	return (
		<S.Container {...getRootProps()}>
			<input {...getInputProps()} />
			<S.Icon>
				<CloudUploadOutlined />
			</S.Icon>
			<S.Text>{I18n.get(dragActiveMessage)}</S.Text>
		</S.Container>
	);
}
