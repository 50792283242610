import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/custom-report';

export interface GetCustomReportWorstScoresParams {
	organization_id: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	workstation_id?: string;
	start_date?: Date;
	end_date?: Date;
	custom_report_id: string;
	limit: number;
}

export const useGetHierarchyWorstScores = (params: GetCustomReportWorstScoresParams) => {
	return useQuery([QUERY_KEYS.GET_MOSAIC, params], () => Service.getHierarchyWorstScores(params), {
		enabled: !!params.organization_id && !!params.custom_report_id
	});
};
