import styled from 'styled-components';

export const ToolCard = styled.div`
	height: 40px;
	display: flex;
	padding: 0 12px;
	margin-top: 1rem;
	border-radius: 4px;
	margin-right: 1rem;
	width: fit-content;
	align-items: center;
	background-color: #f5f5f5;
	border: 1px solid #d9d9d9;
`;

export const ToolIcon = styled.span`
	width: 30px;
	display: flex;
	font-size: 30px;
	margin-left: 10px;
	margin-right: 15px;
	align-items: center;
`;

export const ToolTitle = styled.span`
	color: #262626;
	font-size: 14px;
	white-space: nowrap;
`;
