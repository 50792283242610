import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Service } from '@/infra/services/action-plan';
import { SetRelatedReportsResponseDTO } from '@/core/dto/action-plan-attachment';
import type { Response } from '@/types';

interface UpdateRelatedReportsParams {
	organization_id: string;
	company_id: string;
	action_plan_id: string;
	related_reports: {
		report_id: string;
		ergonomic_tool_id: string;
	}[];
}

export const useUpdateRelatedReports = () => {
	return useMutation<SetRelatedReportsResponseDTO, AxiosError<Response>, UpdateRelatedReportsParams>((params) =>
		Service.setRelatedReports(params)
	);
};
