import { BaseService } from './base-service';
import { KimPushPullRiskCount } from '@/core/domain/kim-pp-risk-count';
import { KimPushPullCountMapper } from '@/core/mapper/kim-pp-risk-count';
import { GetKimPushPullRiskCountRequestDTO } from '@/core/dto/kim-pp-risk-count';

class KimPushPull extends BaseService<any> {
	constructor(public readonly basePath: string = '/ergonomic-tool/kim-pp') {
		super();
	}

	async getCountByRisk(params: GetKimPushPullRiskCountRequestDTO): Promise<KimPushPullRiskCount[]> {
		const url = this.basePath + '/count-by-risk';
		const { data } = await this.getInstance().get<KimPushPullRiskCount[]>(url, { params });
		return new KimPushPullCountMapper(data).toDomain();
	}
}

const Service = Object.freeze(new KimPushPull());
export { Service };
