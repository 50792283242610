import React from 'react';

export function LibertyMutualIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
	return (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clipPath="url(#clip0_2416_3359)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.4554 5.00103C9.64876 5.01776 6.87529 6.49252 5.01054 9.29988C3.36113 11.7894 2.74348 14.8393 3.09372 17.7275H4.86618C4.67348 16.4206 4.72598 15.0799 5.02 13.7994C5.28977 12.6258 5.7536 11.5385 6.3949 10.5711C7.03858 9.60654 7.82187 8.81201 8.72348 8.22098C9.5967 7.64948 10.5433 7.28706 11.5395 7.14209C11.8448 7.09749 12.1525 7.07518 12.4577 7.0724V8.85382C12.4554 9.03503 12.6352 9.1354 12.7559 9.02388L15.7873 6.20538C15.8796 6.11617 15.882 5.9489 15.7873 5.86247L12.7583 3.04676C12.6399 2.93524 12.4601 3.03561 12.4601 3.21681L12.4554 5.00103ZM17.9695 12.3307H10.5196C9.6828 12.3307 9.00439 13.0306 9.00439 13.8939V17.8731C9.00439 18.7365 9.68279 19.4363 10.5196 19.4363H17.9695C18.8063 19.4363 19.4847 18.7365 19.4847 17.8731V13.8939C19.4847 13.0306 18.8063 12.3307 17.9695 12.3307ZM21 13.8939C21 12.1672 19.6432 10.7674 17.9695 10.7674H10.5196C8.84594 10.7674 7.48913 12.1672 7.48913 13.8939V17.8731C7.48913 19.5998 8.84594 20.9996 10.5196 20.9996H17.9695C19.6432 20.9996 21 19.5998 21 17.8731V13.8939Z"
					fill="#262626"
				/>
			</g>
		</svg>
	);
}
