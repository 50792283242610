import { UploadFileStatus } from 'antd/es/upload/interface';

interface UploadFileItemAttributes {
	uid: string;
	name: string;
	size: number;
	type: string;
	status: UploadFileStatus;
	url?: string;
	author: string;
	percent?: number;
	preview?: string;
	created_at: string;
	description?: string;
}

export class UploadFileItem {
	public readonly uid: string;
	public readonly name: string;
	public readonly size: number;
	public readonly type: string;
	public readonly status: UploadFileStatus;
	public readonly url?: string;
	public readonly author: string;
	public readonly percent?: number;
	public readonly preview?: string;
	public readonly created_at: string;
	public readonly description?: string;

	constructor(attributes: UploadFileItemAttributes) {
		this.uid = attributes.uid;
		this.name = attributes.name;
		this.size = attributes.size;
		this.type = attributes.type;
		this.status = attributes.status;
		this.author = attributes.author;
		this.percent = attributes.percent;
		this.preview = attributes.preview;
		this.created_at = attributes.created_at;
		this.description = attributes.description;
	}
}
