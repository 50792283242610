import { Mapper } from './base-mapper';
import { Mosaic, MosaicItem } from '@/core/domain/mosaic';
import { MosaicListDTO, MosaicEntity, MosaicItemDTO } from '@/core/dto/mosaic';

export class MosaicFindAllMapper extends Mapper<Mosaic> {
	private readonly rows: MosaicItemDTO[];
	private readonly entity: MosaicEntity;
	private readonly limit: number;
	private readonly offset: number;
	private readonly total: number;

	constructor({ rows, entity, limit, offset, total }: MosaicListDTO) {
		super();
		this.rows = rows;
		this.entity = entity;
		this.limit = limit;
		this.offset = offset;
		this.total = total;
	}

	toTransformItem(value: MosaicItemDTO): MosaicItem {
		return new MosaicItem({
			id: value.id,
			name: value.name,
			score: value.score
		});
	}

	toDomain(): Mosaic {
		return {
			rows: this.rows.map(this.toTransformItem.bind(this)),
			entity: this.entity,
			limit: this.limit,
			offset: this.offset,
			total: this.total
		};
	}
}
