const dictionary = {
	'Customer information not found': 'Informações do cliente não encontradas',
	'Account subscription expired, renew to continue.': 'A assinatura da conta expirou, renove para continuar.',
	'Account subscription cancelled, renew to continue.': 'Assinatura da conta cancelada, renove para continuar.',

	Warning: 'Atenção',
	Yes: 'Sim',
	No: 'Não',

	Companies: 'Empresas',
	'Corrupted file': 'Arquivo corrompido',
	'Extracted data': 'Dados extraídos',
	'Not processing': 'Não processado',
	Processing: 'Processando',
	Processed: 'Processado',
	'In queue': 'Em fila',

	// Upload
	'Send video': 'Enviar vídeo',
	'Add files or drag': 'Adicionar arquivos ou arraste',
	'Unsupported file': 'Arquivo não suportado',
	'Drop files here': 'Solte os arquivos aqui',
	'Unsupported video format': 'Formato de video não é suportado',
	and: 'e',
	'is larger than': 'É maior que',
	'Max size': 'Tamanho máximo',
	Blur: 'Desfoque',
	'Are you sure you want to exit? You have an uploads in process.':
		'Tem certeza que deseja sair ? Você tem um uploads em processo.',
	'User created with success': 'Usuário criado com sucesso',
	'File duplicated': 'Arquivo duplicado',
	'Files number exceeded': 'Número de arquivos excedido',

	// Select project
	'Select the desired company below': 'Selecione a empresa desejada abaixo',
	'Select the desired industrial site below': 'Selecione a planta industrial desejada abaixo',

	'Create a new company': 'Crie uma nova empresa',
	'Create a new industrial site': 'Crie uma nova planta industrial',

	'Enter a name for the company': 'Insira um nome para a empresa',
	'Enter a name for the industrial site': 'Insira um nome para a planta industrial',

	'Company created successfully': 'Empresa criada com sucesso',
	'Industrial site created successfully': 'Planta industrial criada com sucesso',

	'There are no registered companies': 'Não há empresas cadastradas',
	'There are no registered industrial sites': 'Não existe nenhuma planta industrial cadastrada.',

	'Failed to fetch information': 'Falha ao buscar informações',

	'Congratulations! Now create an industrial site that belongs to this company.':
		'Parabéns! Agora crie a planta industrial que pertence a essa empresa e comece a utilizar a plataforma',
	'Create industrial site': 'Criar planta industrial',

	'Welcome to Kinebot, create your first company': 'Bem vindo ao Kinebot, crie sua primeira empresa.',
	'Create company': 'Criar empresa',

	'Go back': 'Voltar',
	Back: 'Voltar',
	Create: 'Criar',

	'Ergonomic tools': 'Ferramentas ergonômicas',
	'Select the most suitable tool for your analysis': 'Selecione a ferramenta mais adequada para sua análise',
	'Biomechanical analysis': 'Análise biomecânica',
	'Load analysis': 'Análise de cargas',
	'Repeatability analysis': 'Análise de repetitividade',

	// Report/UploadList
	Workstation: 'Posto de trabalho',
	Description: 'Descrição',
	Size: 'Tamanho',
	'Upload date': 'Data de upload',
	Status: 'Status',
	Sector: 'Setor',
	Rating: 'Classificação',
	Actions: 'Ações',
	Action: 'Ações',
	'Not assigned': 'Não atribuido',
	'Heads up!': 'Atenção!',
	'Heads up! Want to delete this file?': 'Atenção! Deseja excluir este arquivo?',
	'Heads up! Want to delete this report?': 'Atenção! Deseja excluir este relatório?',
	'Heads up! Want to delete this action plan?': 'Atenção! Deseja excluir este plano de ação?',
	'Heads up! Want to delete this workstation?': 'Atenção! Deseja excluir este posto de trabalho?',
	'Heads up! Want to delete this line? All workstations related to it will be lost.':
		'Atenção! Deseja excluir esta linha? Todos postos de trabalho relacionados a ela serão perdidos',
	'Heads up! Want to delete this sector? All lines and workstations related to it will be lost.':
		'Atenção! Deseja excluir este setor? Todas as linhas e postos de trabalho relacionados a ele serão perdidos',
	'Heads up! Want to delete this industrial site? All sectors, lines and workstations related to it will be lost.':
		'Atenção! Deseja excluir esta planta industrial? Todos setores, linhas e postos de trabalho relacionados a ela serão perdidos',
	'Force/Charge movement data': 'Dados sobre movimento com Força/Carga',

	Repetitions: 'Repetições',
	'Repetitions per minute': 'Repetições por minuto',
	Legs: 'Pernas',
	Diagnostic: 'Diagnóstico',
	'Analysis results': 'Resultados da análise',

	'Not found': 'Não encontrado',
	Light: 'Leve',
	'Failed to fetch data': 'Falha ao buscar os dados.',
	//'Risk level of the job': 'Grau de risco do posto de trabalho',
	'Average exposure of the workplace': 'Média de exposição do posto de trabalho',
	'Unable to show results.': 'Não foi possível mostrar os resultados.',

	'Load less than 2 kg (intermittent)': 'Carga menor de 2 kg (intermitente)',
	'Load from 2 kg to 10 kg (intermittent)': 'Carga de 2 kg a 10 kg (intermitente)',
	'Load from 2 kg to 10 kg (static or repetitive)': 'Carga de 2 kg a 10 kg (estático ou repetitivo)',
	'Bigger load 10 kg of repetitive load or blows': 'Carga maior 10 kg de carga repetitivos ou pancadas',
	'Legs and feet supported and with equal load distribution':
		'As pernas e pés apoiados e com igual distribuição de carga',
	'Legs without support': 'Pernas sem apoio',

	Reports: 'Relatórios',
	'Select the data visualization model': 'Selecione o modelo de visualização dos dados',
	Angle: 'Ângulo',
	'Angle in degrees (°)': 'Ângulo em graus (°)',
	'Time in seconds (s)': 'Tempo em segundos (s)',
	Parameters: 'Parâmetros',
	'Manual lifting': 'Levantamento de cargas',
	'NIOSH / ISO 11228-1 - Manual lifting analysis': 'NIOSH / ISO 11228-1 - Análise de levantamento manual de cargas',
	'Push and pull': 'Empurrar e puxar',
	'KIM - Push and pull': 'KIM - Empurrar e puxar',
	'KIM - PP Report Results': 'KIM - EP Relatório de Resultados',
	meters: 'metros',
	'Temporal distribution': 'Distribuição temporal',
	Distance: 'Distância',
	'Working conditions': 'Condições de trabalho',
	'Vehicle properties': 'Propriedades veículo',
	Vehicle: 'Veículo',
	'Vehicle: ': 'Veículo: ',
	'Distance or duration': 'Distância ou duração',

	'Select one of the options below': 'Selecione uma das opções abaixo',
	'Short distances': 'Distâncias curtas',
	'Long distances': 'Distâncias longas',
	Time: 'Tempo',
	Mass: 'Massa',
	'Mass: ': 'Massa: ',
	Precision: 'Precisão',
	'Worker gender': 'Gênero do trabalhador',
	'Pushing / Pulling in pairs': 'Empurrando / Puxando em pares',
	'By distance': 'Pela distância',
	'By duration': 'Pela duração',
	'Distance per work day (in meters)': 'Distância por dia de trabalho (em metros)',
	'Duration per work day (in minutes)': 'Duração por dia de trabalho (em minutos)',
	'≤ 40': '≤ 40',
	'≤ 200': '≤ 200',
	'≤ 400': '≤ 400',
	'≤ 800': '≤ 800',
	'≤ 1,200': '≤ 1.200',
	'≤ 1,800': '≤ 1.800',
	'≤ 2,500': '≤ 2.500',
	'≤ 4,200': '≤ 4.200',
	'≤ 6,300': '≤ 6.300',
	'≤ 8,400': '≤ 8.400',
	'≤ 11,000': '≤ 11.000',
	'≤ 15,000': '≤ 15.000',
	'≤ 20,000': '≤ 20.000',
	'≤ 1': '≤ 1',
	'≤ 5': '≤ 5',
	'≤ 10': '≤ 10',
	'≤ 20': '≤ 20',
	'≤ 30': '≤ 30',
	'≤ 45': '≤ 45',
	'≤ 60': '≤ 60',
	'≤ 100': '≤ 100',
	'≤ 150': '≤ 150',
	'≤ 210': '≤ 210',
	'≤ 270': '≤ 270',
	'≤ 360': '≤ 360',
	'≤ 480': '≤ 480',
	'Select one of the vehicles': 'Selecione um dos veículos',
	Barrows: 'Carrinhos de mão',
	Carriages: 'Carros de transporte',
	'Overhead conveyors': 'Transportadores',
	'Overhead cranes': 'Guindastes',
	'Choose an option': 'Escolha uma opção',
	'Reference image: only swivel castors': 'Imagem de referência: apenas rodas móveis',
	'Reference image: with fixed castors or lockable swivel castors':
		'Imagem de referência: com rodas fixas ou que podem ser travadas',
	'Reference image: pedestrian-controlled': 'Imagem de referência: controlados por pedestre',
	'Driveway conditions': 'Condições da calçada',
	'Inclination or stairs': 'Inclinação ou escadas',
	'Select the mass to be transported (in kg)': 'Selecione a massa a ser transportada (em kg)',
	'Driveway completely level, smooth, solid, dry, without inclinations':
		'Calçada completamente nivelada, lisa, sólida, seca, sem inclinações',
	'Driveway mostly smooth and level, with small damaged spots/faults, without inclinations':
		'Calçada quase lisa e nivelada, com pequenas danificações em alguns pontos, sem inclinações',
	'Mixture of cobbles, concrete, asphalt, slight inclinations, dropped kerb':
		'Mistura de paralelepípedos, concreto, asfalto, inclinações leves, meio-fio rebaixado',
	'Mixture of roughly cobbled, hard sand, slight inclinations, small edges/sills':
		'Mistura rude de paralelepípedos, areia dura, inclinações leves e pequenas arestas/soleiras',
	'Earth or roughly cobbled driveway, potholes, heavy soiling, slight inclinations, landings, sills':
		'Mistura rude de terra ou paralelepípedos, buracos, sujeira pesada, pequenas inclinações, aterragem, soleiras',
	'Inclinations of 2 ≤ 4° (4 ≤ 8%)': 'Inclinações de 2 ≤ 4° (4 ≤ 8%)',
	'Inclinations of 5 ≤ 10° (9 ≤ 18%)': 'Inclinações de 5 ≤ 10° (9 ≤ 18%)',
	'Stairs, inclinations > 10° (18%)': 'Escadas, inclinações > 10° (18%)',
	'No significant inclination': 'Sem inclinação relevante',
	'≤ 50': '≤ 50',
	'≤ 300': '≤ 300',
	'≤ 600': '≤ 600',
	'≤ 1,000': '≤ 1.000',
	'≤ 1,300': '≤ 1.300',
	'> 1,300': '> 1.300',
	'Select the appropriate options': 'Selecione a opção apropriada',
	'Unfavorable working conditions - more than one can be selected':
		'Condições de trabalho desfavoráveis - mais de uma opção pode ser selecionada',
	'Unfavorable vehicle properties - more than one can be selected':
		'Propriedades desfavoráveis do veículo - mais de uma opção pode ser selecionada',
	'Unfavorable working conditions': 'Condições de trabalho desfavoráveis',
	'Unfavorable vehicle properties': 'Propriedades desfavoráveis do veículo',
	'Regularly significantly increased starting forces, because transport devices sink into the ground or get wedged':
		'Regularmente necessária uma força considerável para iniciar movimentação, devido ao veículo afundar no chão ou ficar travado',
	'Frequent stops with breaking': 'Paradas frequentes com freagem',
	'Frequent stops without breaking': 'Paradas frequentes sem freagem',
	'Many changes of direction or curves, frequent manouvering':
		'Muitas mudanças de direção ou curvas, manobramento frequente',
	'Load must be positioned precisely and stopped, driveway must be adhered to precisely':
		'Carga precisa ser posicionada precisamente e fixada, aderindo um lugar exato da calçada',
	'Increased movement speed (approx. 1.0 ≤ 1.3 m/s)': 'Velocidade de movimento elevada (aprox. 1.0 ≤ 1.3 m/s)',
	'None: there are no unfavorable working conditions': 'Nenhuma: não há condições de trabalho desfavoráveis',
	'No suitable handles or construction parts for applying force':
		'Não há alças ou partes adequadas para aplicação de força',
	'No brake when driving on inclinations > 2° (> 3%)': 'Sem freios quando manuseando ou inclinações > 2° (> 3%)',
	'Unadjusted castors (e.g. too small on soft or uneven floor)':
		'Rodas mal ajustadas (ex.: pequenas demais ou não condizem com o piso)',
	'Defective castors (worn-out, rubbing, stiff, air pressure too low)':
		'Rodas defeituosas (desgastadas, com fricção, travadas, pressão de ar muito baixa)',
	'None: there are no unfavorable properties of the transport devices':
		'Nenhuma: não há propriedades desfavoráveis do veículo',

	'Trunk upright or slightly inclined forward, no twisting / Force application height can be selected freely / No hindrance for the legs':
		'Tronco ereto ou levemente inclinado para frente, sem torção / Força de aplicação com altura selecionada livremente / Sem complicação para as pernas',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side / Fixed force application height ranging from 0.9 – 1.2 m / No or only slight hindrance for the legs / Predominantly pulling':
		'Corpo inclinado na direção do movimento ou levemente torcido puxando a carga de lado / Aplicação de força na altura fixa de 0.9 - 1.2m / Nenhuma ou pequena complicação para as pernas / Predominantemente puxando',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view / Significant hindrance for the legs / Constant twisting and/or lateral inclination of the trunk':
		'Posturas corporais estranhas causadas por força na altura fixa de < 0.9 ou > 1.2m, aplicação de força lateral em um lado ou com vista significantemente obstruída / Complicação considerável para as pernas / Torção constante e/ou inclinação lateral do tronco',

	'Trunk upright or slightly inclined forward, no twisting':
		'Tronco ereto ou levemente inclinado para frente, sem torção',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side':
		'Corpo inclinado na direção do movimento ou levemente torcido puxando a carga de lado',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view':
		'Posturas corporais estranhas causadas por força na altura fixa de < 0.9 ou > 1.2m, aplicação de força lateral em um lado ou com vista significantemente obstruída',

	'Force application height can be selected freely': 'Força de aplicação com altura selecionada livremente',
	'Fixed force application height ranging from 0.9 – 1.2 m': 'Aplicação de força na altura fixa de 0.9 - 1.2m',
	'Significant hindrance for the legs': 'Complicação considerável para as pernas',

	'No hindrance for the legs': 'Sem complicação para as pernas',
	'No or only slight hindrance for the legs': 'Nenhuma ou pequena complicação para as pernas',
	'Constant twisting and/or lateral inclination of the trunk': 'Torção constante e/ou inclinação lateral do tronco',

	'Predominantly pulling': 'Predominantemente puxando',

	Postures: 'Posturas',
	'Direction of force': 'Direção da força',
	Unfavorable: 'Desfavorável',
	'Score and risk assessment': 'Score e definição do risco',
	'Intensity of load: ': 'Intensidade da carga',
	'Probability of physical overload / Possible health consequences: ':
		'Probabilidade de sobrecarga física / Possíveis consequências de saúde: ',
	'Physical overload is unlikely / No health risk is to be expected.':
		'Sobrecarga física é improvável / Sem risco de saúde esperado.',
	'Physical overload is unlikely': 'Sobrecarga física é improvável',
	'No health risk is to be expected': 'Sem risco de saúde esperado',
	'Physical overload is possible for less resilient persons /  Fatigue, low-grade adaptation problems which can be compensated for during leisure time.':
		'Sobrecarga física é possível para pessoas menos resistentes / Fadiga, pequenos problemas de adaptação que podem ser compensados com tempo de descanso.',
	'Physical overload is possible for less resilient persons':
		'Sobrecarga física é possível para pessoas menos resistentes',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time':
		'Fadiga, pequenos problemas de adaptação que podem ser compensados com tempo de descanso.',
	'Physical overload is also possible for normally resilient persons / Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'Sobrecarga física é possível mesmo para pessoas com resistência normal / Distúrbios (dor), possibilidade de disfunções, reversível na maioria dos casos, sem manifestação morfológica',
	'Physical overload is also possible for normally resilient persons':
		'Sobrecarga física é possível mesmo para pessoas com resistência normal',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'Distúrbios (dor), possibilidade de disfunções, reversível na maioria dos casos, sem manifestação morfológica',
	'Physical overload is likely / More pronounced disorders and/or dysfunctions, structural damage with pathological significance':
		'Sobrecarga física é provável / Distúrbios acentuados e/ou disfunções, dano estrutural com significância patológica',
	'Physical overload is likely': 'Sobrecarga física é provável',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance':
		'Distúrbios acentuados e/ou disfunções, dano estrutural com significância patológica',
	'Measures: ': 'Medidas: ',
	' For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		' Para as pessoas menos resitentes, trocar o local de trabalho e outras medidas de prevenção podem ser de ajuda.',
	' Workplace redesign and other prevention measures should be considered.':
		' Trocar o local de trabalho e outras medidas de prevenção devem ser consideradas.',
	' Workplace redesign measures are necessary. Other prevention measures should be considered.':
		' Trocar o local de trabalho é necessário. Outras medidas de prevenção também devem ser consideradas.',
	None: 'Nenhuma',
	Results: 'Resultados',

	Information: 'Informações',
	'Body parts': 'Partes do corpo',
	Charts: 'Gráficos',

	'Industrial Site': 'Planta industrial',
	'Collection date': 'Data de coleta',
	'Risk range': 'Faixa de risco',
	'Risk Range': 'Faixa de risco',

	'Failed to fetch sectors': 'Falha ao buscar os setores',
	'Failed to create a new sector': 'Falha ao criar um novo setor',
	'Failed to fetch risk bands': 'Falha ao buscar as faixas de risco',
	'Sector created successfully': 'Setor criado com sucesso',
	'Line created successfully': 'Linha criada com sucesso',
	'Workstation created successfully': 'Posto de trabalho criado com sucesso',
	'TLID created successfully': 'TLID criada com sucesso',
	'Evaluator created successfully': 'Avaliador criado com sucesso',
	'Cycle created successfully': 'Ciclo criado com sucesso',

	'Select a sector': 'Selecione o setor',
	'Insert a workstation': 'Insira o posto de trabalho',
	'Insert the date of collection': 'Insira a data da coleta',
	'Select a risk band': 'Selecione uma faixa de risco',
	'Graphs of selected body parts': 'Gráficos das partes do corpo selecionadas',

	Summary: 'Resumo',
	'Range of Risk': 'Faixa de risco',
	'Job information': 'Informação de trabalho',
	'File data': 'Dados do arquivo',

	File: 'Arquivo',
	Duration: 'Duração',
	'Relevant points': 'Pontos relevantes',
	Frequency: 'Frequência',
	'Most critical move': 'Movimento mais crítico',
	'Include conclusion': 'Incluir conclusão',
	'Include signature': 'Incluir assinatura',
	'Insert conclusion': 'Inserir conclusão',
	'Risk track created successfully!': 'Faixa de risco criada com sucesso!',
	'Create custom risk bands based on your analysis':
		'Crie faixas de riscos personalizadas de acordo com as suas análises',

	//Dashboard
	'Industrial site status': 'Status da planta industrial',
	'Harmful postures': 'Posturas lesivas',
	'Inadequate postures': 'Posturas inadequadas',
	'Workstation with good postures': 'Postos com boas posturas',
	'Workers in real danger': 'Trabalhadores em risco real',
	'Total of videos': 'Total de vídeos',
	'Processed minutes': 'Minutos processados',
	'Critical sector': 'Setor crítico',
	'Registered sectors': 'Setores cadastrados',
	'Exported reports': 'Relatórios extraídos',
	'Sectors in alert': 'Setores em alerta',
	'Main statistics': 'Estatísticas principais',
	'No. of videos by risk level': 'Total de vídeos por grau de risco',
	Month: 'Mês',
	Year: 'Ano',
	Day: 'Dia',
	'Assessments completed': 'Avaliações completas',
	'Videos by sector': 'Vídeos por setores',
	'Percentage of videos by risk': 'Percentual de vídeos por risco',
	'Ergonomics Management': 'Gestão de Ergonomia',
	'Risk Quotation': 'Cotação de Risco',
	'Total workstations': 'Total de postos',
	'Total pending evaluations': 'Total de avaliações pendentes',
	'Total evaluations consolidated': 'Total de avaliações consolidadas',
	Evaluations: 'Avaliações',
	'Not analyzed': 'Não analisados',
	'Unit overview': 'Visão geral da unidade',
	'There is nothing to access.': 'Não há nada para acessar.',
	'There is no data': 'Não há dados',
	'of the workstation are': 'das estações de trabalho são',
	'There are no registered action plans': 'Não há planos de ação registrados',
	'Not applicable': 'Não aplicável',
	'Acceptable Risk': 'Risco aceitável',
	'Acceptable risk': 'Risco aceitável',
	'Moderate Risk': 'Risco moderado',
	'Moderate risk': 'Risco moderado',
	'High Risk': 'Risco elevado',
	'High risk': 'Risco elevado',
	'Very High Risk': 'Risco muito elevado',
	'Very high risk': 'Risco muito elevado',
	'Extreme and Imminent Risk': 'Risco grave e iminente',
	'Serious and Imminent Risk': 'Risco grave e iminente',
	'Serious and imminent risk': 'Risco grave e iminente',
	'Serious risk': 'Risco grave',
	'Serious and imminent': 'Grave e iminente',
	'Not GNS': 'Sem GNS',

	// Dashboard Filter
	Lines: 'Linhas',
	'Select the line': 'Selecione a linha',
	'Select the Workstation': 'Selecione o Posto de Trabalho',
	'Working Population': 'População trabalhadora',
	Others: 'Outros',
	'Stress Level': 'Nível de Estresse',
	'Risk Level': 'Nível de Risco',

	// Dashboard Tools Results
	Repetitiveness: 'Repetitividade',
	'Cargo Handling': 'Manuseio de Carga',
	'Work Organization': 'Organização do Trabalho',
	'Score by workstation': 'Índice por posto de trabalho',
	'Total evaluations': 'Total de avaliações',
	Low: 'Baixa',
	Average: 'Média',
	High: 'Alta',

	// Dashboard Movement by score
	'Movement by score': 'Percentual de Exposição',
	'Risk per Workstation': 'Risco por posto de trabalho',

	// Dashboard Workstations
	Workstations: 'Postos de trabalho',
	'Higher workstation risk score': 'Maior índice de risco do posto de trabalho',
	'Workstation score': 'Nota do posto',

	// Dashboard Action Plans
	'Action plans': 'Planos de ação',
	'Planned Actions': 'Ações previstas',
	Completed: 'Concluídas',
	Concluded: 'Concluído',
	'Order by deadline': 'Ordenar por prazo',
	'Order by worst score': 'Ordenar por score',
	Ascending: 'Crescente',
	Descending: 'Decrescente',
	'Deadline : ': 'Prazo : ',

	// Buttons
	Next: 'Avançar',
	Previous: 'Voltar',
	delete: 'excluir',
	Delete: 'Excluir',

	'Do you really want to delete these tracks?': 'Deseja realmente exlcuir essas faixas ?',
	'Heads up! the deletion is permanent': 'Atenção! a exclusão é permanente',
	'Risk ranges deleted successfully': 'Faixas de risco excluídas com sucesso',

	'Description (optional)': 'Descrição (opcional)',

	// Body parts
	Neck: 'Pescoço',
	Trunk: 'Tronco',
	UpperArm: 'Ombro',
	LowerArm: 'Cotovelo',

	'Customize bands of risk': 'Personalize faixas de risco',
	'Create custom risk strips according to your needs.':
		'Crie faixas de riscos personalizadas de acordo com as suas necessidades.',

	Name: 'Nome',
	'Industrial site name': 'Nome da planta industrial',
	'Enter a name': 'Insira um nome',
	'Please enter a valid name': 'Insira um nome válido',
	'Please enter a valid phone number': 'Insira um telefone válido',
	'Invalid phone number': 'Número de telefone inválido',
	'Insert short description': 'Insira uma breve descrição',
	'Add new band': 'Adicionar nova banda',
	'Create new band': 'Criar nova banda',
	'Minimum angle': 'Ângulo mínimo',
	'Maximum angle': 'Ângulo máximo',
	Coloring: 'Coloração',
	Remove: 'Remover',

	//	Breadcrumb
	Home: 'Início',
	'Manage users': 'Gerenciar usuários',
	Preferences: 'Preferências',
	'My informations': 'Minhas informações',
	'My plan': 'Meu plano',

	individual: 'Individual',
	team: 'Equipe',
	business: 'Empresarial',
	pilot: 'Piloto',

	yearly: 'anual',
	monthly: 'mensal',
	Yearly: 'Anual',
	Monthly: 'Mensal',

	User: 'Usuário',
	Supervisor: 'Supervisor',
	Master: 'Master',
	Admin: 'Administrador',

	'Choose a plan different from your current plan': 'Escolha um plano diferente do seu plano atual',
	'Plan successfully canceled': 'Plano cancelado com sucesso',
	'We received your order, we are processing your payment': 'Recebemos seu pedido, estamos processando seu pagamento',

	'Password changed successfully': 'Senha alterada com sucesso',

	'Customer plan not found': 'Plano do cliente não encontrado',
	'Customer data not found': 'Dados do cliente não encontrados',
	'User access not found': 'Permissão do usuário não encontrada',
	'File deleted successfully': 'Arquivo excluído com sucesso',

	'Check the parameters entered': 'Verifique os dados inseridos',
	'Number of document characters are invalid': 'Número de caracteres do documento são inválidos',
	'Invalid inserted CPF': 'CPF inserido inválido',
	'Invalid inserted CNPJ': 'CNPJ inserido inválido',

	'It is necessary to spend a day after contracting the service to change the plan':
		'É necessário passar um dia após a contratação do serviço para alterar o plano.',

	'Fake communication failure': 'Falha na comunicação',

	'Remember me': 'Lembrar de mim',
	'Forgot password': 'Esqueceu sua senha?',
	'Log in': 'Acessar',
	Password: 'Senha',
	'Please input your password!': 'Insira uma senha',
	'Invalid email or password': 'E-mail ou senha inválidos',
	'The input is not a valid e-mail!': 'Este e-mail não é válido!',
	'Please input your e-mail': 'Por favor, digite seu e-mail',
	'Inactive account, please activate': 'Conta invativa, por favor ativar',
	'Enter your email': 'Insira seu e-mail',
	Send: 'Enviar',
	'User not found': 'Usuário não encontrado',
	'Success, check your email box': 'Sucesso, veja sua caixa de e-mail',
	'Password recovery': 'Redefinição de senha',
	'Token invalid': 'Requisição inválida',
	'Token expired, generated a new one': 'Sessão expirada, tente novamente',
	'Please confirm your password!': 'Por favor confirme sua senha',
	'Confirm Password': 'Confirme sua senha',
	'The two passwords that you entered do not match!': 'As duas senhas não conferem, verifique',
	Confirm: 'Confirmar',
	Generate: 'Gerar',
	Validate: 'Validar',
	Continue: 'Continuar',
	'Reset token': 'Recuperar',
	Cancel: 'Cancelar',
	flexion: 'Flexão',
	turned: 'Virado',
	extension: 'Extensão',
	sideways: 'Lateralmente',
	crouched: 'Agachado',
	downed: 'Abaixado',
	arm_raised: 'Braço levantado',
	behind: 'Atrás',
	raised: 'Levantado',
	none: 'Nenhum',

	// Check-in Customer
	'Registered successfully!': 'Cadastro realizado com sucesso!',
	'Just a little longer, check your e-mail inbox': 'Agora falta pouco, verifique sua caixa de entrada no email',
	Access: 'Acessar',
	'Kinebot - Terms of use': 'Kinebot - Termos de uso',
	'terms of use': 'termos de uso',
	"Welcome! Let's start": 'Seja bem-vindo, vamos começar!',
	'Enter your phone number': 'Insira seu telefone',
	'Select your contry': 'Selecione seu país',
	State: 'Estado',
	'State:': 'Estado:',
	'Select your state': 'Selecione o seu estado',
	'Select your city': 'Selecione a sua cidade',
	'Zip code': 'CEP',
	'Enter your zipcode': 'Insira o seu código postal',
	'Enter your address': 'Insira o seu endereço',
	'Enter your street number': 'Insira o número da rua',
	'Coupon (optional)': 'Cupom (opcional)',
	Coupon: 'Cupom',

	// User Activation
	'There are problems on your operation.': 'Existem alguns problemas com a sua operação.',
	Leave: 'Sair',
	Data: 'Dados',
	'Final steps': 'Finalização',
	'Card number': 'Número do cartão',
	'Enter your credit card number': 'Insira o número do cartão',
	'Invalid credit card': 'Cartão inválido',
	'Card holder name': 'Nome do titular',
	'Enter the card holder name': 'Insira o nome do titular',
	'Due date': 'Validade',
	"Enter the card's due date": 'Insira a validade do cartão',
	'Invalid month': 'Mês inválido',
	'Invalid card date': 'Data do cartão inválida',
	'Enter the security code': 'Insira o código de segurança',
	'Invalid code': 'Código inválido',
	'Payment information': 'Dados de pagamento',

	// Maintenance
	"We are going through maintenance, we'll be back shortly.": 'Estamos em manutenção, voltamos em breve.',

	// Finalization
	'Personal data': 'Dados pessoais',
	'Document no.': 'CPF',
	'Payment details': 'Detalhes do pagamento',
	'You will only be charged after the free test period, it can be cancelled at anytime.':
		'O valor da cobrança só será efetuado após o período gratuito, podendo ser cancelado a qualquer momento.',
	'Payment method': 'Método de pagamento',
	'Payment options': 'Tipo de pagamento',
	'Full payment': 'À vista',
	Discounts: 'Descontos',
	'I have read and agree with the': 'Eu li e concordo com os',
	Close: 'Fechar',

	// User Activation - Information
	'Validating coupon': 'Validando cupom',
	"Couldn't find the coupon": 'Não foi possível buscar o cupom',
	Individual: 'Individual',
	Team: 'Equipe',
	Business: 'Empresarial',
	'Enter your document no.': 'Insira o CPF',
	'Enter a valid document': 'Insira um CPF válido',
	'Enter a phone number': 'Insira um telefone',
	'Enter the zip code': 'Insira o CEP',
	'Enter the city': 'Insira a cidade',
	'Enter the state': 'Insira o estado',
	'Street number': 'Número da rua',
	'Street number:': 'Número da rua:',

	// Credit Card
	'Under review': 'Cartão sob revisão',
	'Insufficient funds': 'Saldo insuficiente',
	'Not accepted': 'Transação inválida',
	'Customer already registered': 'Usuário já cadastrado',
	'Failed to perform transaction': 'Falha ao realizar a transação',

	'email must be unique': 'E-mail já registrado',

	'Error loading video': 'Erro ao carregar o vídeo',
	'Failed to fetch your plan information.': 'Falha ao obter as informações do seu plano',
	'Failed to fetch total customer videos.': 'Falha ao buscar o total de vídeos do cliente',
	'Maximum uploads reached.': 'Máximo de uploads atingido',
	'Maximum minutes reached.': 'Máximo de minutos atingido',
	'Corrupted video.': 'Vídeo corrompido',

	// Painel control
	'Maximum users reached': 'Máximo de usuários cadastrados',

	"The 'name' field is required.": 'O campo nome é obrigatório.',
	"The 'email' field is required.": 'O campo email é obrigatório.',
	"The 'country' field is required.": 'O campo país é obrigatório.',
	"The 'state' field is required.": 'O campo estado é obrigatório.',
	"The 'city' field is required.": 'O campo cidade é obrigatório.',
	"The 'address' field is required.": 'O campo endereço é obrigatório.',
	"The 'street_number' field is required.": 'O campo número é obrigatório.',
	"The 'zipcode' field is required.": 'O campo código postal é obrigatório.',

	// ********************************** //
	'Personal information': 'Informações pessoais',
	Localization: 'Localização',

	'Full name': 'Nome completo',
	'Full name:': 'Nome completo:',
	'Enter your full name': 'Insira seu nome completo',
	'Enter your name': 'Insira seu nome',
	'Insert a document': 'Insira um documento',
	'Insert a valid document': 'Insira um documento válido',
	Address: 'Endereço',
	'Address:': 'Endereço:',
	'Enter an address': 'Insira um endereço',
	Number: 'Número',
	'Enter the number': 'Insira o número',
	Zipcode: 'CEP',
	'Zipcode:': 'Código postal:',
	'Enter zip code': 'Insira o código postal',
	Country: 'País',
	'Country:': 'País:',
	'Select your country': 'Selecione o seu país',
	'Select state': 'Selecione o estado',
	City: 'Cidade',
	'City:': 'Cidade:',
	'Select city': 'Selecione a cidade',

	'Update performed': 'Operação realizada',
	'Customer information updated successfully': 'Informações atualizadas com sucesso',
	'Fill in your personal information': 'Complete suas informações pessoais',

	// Forgot password
	'Forgot your password?': 'Esqueceu sua senha?',
	'We will send you an email to retrieve your password, rest assured :)':
		'Iremos enviar um e-mail para você recuperar sua senha, fique tranquilo :)',
	'Back to login': 'Voltar para o login',
	'Please enter a valid e-mail address': 'Insira um e-mail válido',
	'Enter an e-mail': 'Insira um e-mail',

	'Oops... Something went wrong!': 'Ops... Algo deu errado!',
	'Operation performed successfully': 'Operação realizada com sucesso',
	'Check your email inbox': 'Verifique sua caixa e-mails',
	'Create new password': 'Crie uma nova senha',
	'Your new password must be different from previous used passwords':
		'Sua nova senha deve ser diferente das senhas usadas anteriormente',
	'Enter your new password': 'Insira sua nova senha',
	'Confirm new password': 'Confirme a nova senha',
	'The two passwords that you entered do not match': 'As duas senhas que você digitou não coincidem',
	Save: 'Salvar',
	'Click here to continue': 'Clique aqui para continuar',
	'Invalid Token': 'Token inválido',
	'Expired token': 'Token expirado',

	trunk: 'Tronco',
	neck: 'Pescoço',
	left_lower_arm: 'Cotovelo esquerdo',
	right_lower_arm: 'Cotovelo direito',
	left_upper_arm: 'Ombro esquerdo',
	right_upper_arm: 'Ombro direito',
	left_knee: 'Joelho esquerdo',
	right_knee: 'Joelho direito',
	left_ankle: 'Tornozelo esquerdo',
	right_ankle: 'Tornozelo direito',
	hip: 'Quadril',
	left_hand: 'Punho esq.',
	right_hand: 'Punho dir.',

	Knee: 'Joelho',
	Ankle: 'Tornozelo',
	Hip: 'Quadril',
	Hand: 'Punho',
	Qualification: 'Qualificação',
	'Select the body parts to display': 'Selecione as partes do corpo a serem exibidas',

	'Light risk': 'Risco leve',
	'Average risk': 'Risco médio',

	Level: 'Nível',
	'Limit of bands reached': 'Limite de bandas atingido',

	'Industrial site': 'Planta industrial',
	Period: 'Período',
	Search: 'Buscar',
	Clear: 'Limpar',

	'Select company': 'Selecionar empresa',

	//User management
	'Create new user': 'Criar novo usuário',
	Email: 'Email',
	Permissions: 'Permissões',
	'Companies - Industrial sites': 'Empresas - Plantas industriais',

	'Change password': 'Alterar senha',
	'Current password': 'Senha atual',
	'New password': 'Nova senha',
	'Confirm password': 'Confirmar senha',
	'Enter your current password': 'Insira sua senha atual',
	'Enter a new password': 'Insira uma nova senha',
	'Password must be at least 6 characters long': 'A senha deve ter no mínimo 6 caracteres',
	'Repeat your new password': 'Repita sua nova senha',
	"The two passwords don't match, check": 'As duas senhas não conferem, verifique',
	'Sorry time exceeded, try again.': 'Desculpe, o tempo foi excedido, tente novamente',
	'Invalid current password': 'Senha atual inválida',
	'Update information': 'Atualizar informações',

	'Phone number': 'Telefone',
	'Account type': 'Tipo da conta',
	'Created in': 'Criada em',
	'Postal code': 'CEP',
	'Sorry, something went wrong :(': 'Desculpe, algo deu errado :(',
	Retry: 'Tentar novamente',

	// Layout - Sidebar
	Settings: 'Configurações',
	'My account': 'Minha conta',
	Plan: 'Plano',
	'Subscription type': 'Tipo de assinatura',
	'Subscription type:': 'Tipo de assinatura:',
	Recurrence: 'Recorrência',
	'Recurrence:': 'Recorrência:',
	'Expiration date': 'Data de expiração',
	'Plan expiration date:': 'Data de expiração do plano:',
	'Active users': 'Usuários ativos',
	'Processed videos': 'Vídeos processados',
	'Processed videos:': 'Vídeos processados:',
	'Processing time': 'Tempo de processamento',
	'Processing time:': 'Tempo de processamento:',
	'Additional minutes': 'Minutos adicionais',
	'Add credits': 'Adicionar créditos',
	'Renew subscription': 'Renovar assinatura',
	'Change subscription': 'Alterar assinatura',
	'Cancel subscription': 'Cancelar assinatura',
	of: 'de',
	minutes: 'minutos',

	Test: 'Teste',

	Minutes: 'Minutos',
	Purchase: 'Comprar',
	Payments: 'Pagamentos',
	Payment: 'Pagamento',
	'Buy additional credits': 'Compre créditos adicionais',
	'Enter the amount of additional credits (minutes) below to continue using the platform':
		'Insira abaixo a quantidade de créditos adicionais (minutos) para continuar usando a plataforma',
	'Consolidate data': 'Consolidar dados',
	'Export report': 'Exportar relatório',
	'There is no post with screwdriver / screwdriver': 'Não tem posto com parafusadeira/apertadeira',
	'Data not found': 'Dados não encontrados',
	'Requires actions': 'Requer ações',
	'No actions required': 'Não requer ações',
	'High Risk and Requires Action': 'Alto Risco e Requer ações',
	'Moderate risk and no action required': 'Risco Moderado e Não requer ações',
	'Low Risk and No Action Required': 'Baixo Risco e Não requer ações',
	'Intensity of work': 'Intensidade do trabalho',
	'Work organization': 'Organização do trabalho',
	'Is spontaneous breaks allowed during the workday?':
		'É permitido pausas espontâneas durante a jornada de trabalho?',
	'Is there a diversification of tasks in the performance of your functions?':
		'No desempenho de suas funções há diversificação de tarefas?',
	'Materials are always available at the workstation, avoiding rush, delay or overload?':
		'Os materiais sempre estão disponíveis no posto de trabalho evitando correria, atraso ou sobrecarga?',
	Complexity: 'Complexidade',
	'Movements do not require high dexterity': 'Movimentos não exigem alta destreza',
	'Low level of attention requirement for long periods': 'Baixo nível de exigência de atenção por longos períodos',
	'Workstation is complex and high cognitive demand': 'Posto é complexo e de alta exigência cognitiva',
	'Workstation is simple and of low cognitive requirement': 'Posto é simples e de baixa exigência cognitiva',
	'Displacement ': 'Deslocamento ',
	'Squat ': 'Agachamento ',
	Cervical: 'Cervical',
	'Left Shoulder/Arm': 'Ombro/Braço Esq.',
	'Right Shoulder/Arm': 'Ombro/Braço Dir.',
	'Left wrist': 'Punho esq.',
	'Right wrist': 'Punho dir.',
	'No repeatability': 'Sem repetividade',
	'With repetition': 'Com repetividade',
	' x per hour': ' x por hora',
	'Without risk': 'Sem risco',
	'Action Level': 'Nível de ação',
	'Complementary resources': 'Fatores complementares',
	'Percussion tools ': 'Ferramentas de percussão ',
	'Hit against ': 'Batidas contra ',
	'Present Risk': 'Risco Presente',
	'Low risk': 'Baixo Risco',
	'Global Ranking': 'Classificação Global',
	'Operator report': 'Relato do operador',
	'Enter operator report below': 'Insira abaixo o relato do operador',
	'Easy to understand information': 'Informação de fácil compreensão',

	'≤ 10 steps per minute': '≤ 10 passos por minuto',
	'15 to 25 steps per minute': 'de 15 a 25 passos por minuto',
	'Above 25 steps per minute': 'Acima de 25 passos por minuto',

	'Inform below the displacement of the operator during the workday':
		'Informar abaixo o deslocamento do operador durante a jornada de trabalho',
	'Regulation notes': 'Notas de regulação',
	'Spontaneous breaks are allowed during the workday (e.g. drinking water, drinking coffee, going to the bathroom)':
		'É permitido pausas espontâneas durante a jornada de trabalho (ex.: tomar água, beber café, ir ao banheiro)',
	'In the performance of their functions, there is diversification of tasks':
		'No desempenho de suas funções há diversificação de tarefas',
	'Materials are always available at the workstation, avoiding rush, delay or overload':
		'Os materiais sempre estão disponíveis no posto de trabalho evitando correria, atraso ou sobrecarga',
	'Work intensity: give a score from 1 to 7 (1 being a little work and 7 being very intense work)':
		'Intensidade do trabalho: dê uma nota de 1 a 7 (sendo 1 pouco trabalho e 7 trabalho intensíssimo)',

	Wrist: 'Punho',
	'Hit against': 'Batidas',
	'Enter job information below': 'Insira abaixo informações sobre o posto de trabalho',
	'During the operation, the operator taps his hands to adjust parts or components.':
		'O operador realiza durante a operação batidas com as mãos para fazer ajuste de peças ou componentes.',

	'Add a companion video for wrist analysis': 'Adicione um vídeo complementar para análise do punho',
	'Do not include in the analysis': 'Não incluir na análise',
	'Include external video': 'Incluir vídeo externo',
	'Enter video name': 'Digite o nome do vídeo',

	'Sledgehammer and/or taps are hits from hard hits (except hit adjustments)':
		'Marreta e/ou tas so batidas de batidas fortes (exceto ajustes de batida)',
	'Light - Quiet': 'Leve - Tranquilo',
	'Medium - Some effort is perceived (defined)': 'Médio - Percebe-se algum esforço (definido)',
	'Heavy - Clear effort: no change in facial expression': 'Pesado - Esforço nitido: sem mudança de expressão facial',
	'Very heavy - Sharp effort: changes facial expression': 'Muito pesado - Esforço nitido: muda a expressão facial',
	'Next. maximum - Use of torso and limbs to generate greater force':
		'Próximo. máximo - Uso do tronco e membros para gerar força maior',

	// ------- //
	'Workstation Information': 'Informação de trabalho',

	'Enter operator difficulty control below': 'Insira abaixo o controle de dificuldade do operador',
	'Control Difficulty': 'Dificuldade de controle',
	'Select a cost center': 'Selecione o centro de custo',

	'Enter data about vibration in employee activity below':
		'Insira abaixo os dados sobre a vibração na atividade do funcionário, caso não tenha não é necessário preencher',

	"Enter below the data on the employee's reaction in the activity":
		'Insira abaixo os dados sobre a reação do colaborador na atividade',
	'Insert a new reaction': 'Insira uma nova reação',
	'Insert a new vibration': 'Insira uma nova vibração',

	"Enter below the data of the employee's effort in the activity":
		'Insira abaixo os dados sobre o esforço do colaborador na atividade',

	'Insert a new effort': 'Insira um novo esforço',
	'Cost center': 'Centro de custo',
	'Enter a workstation': 'Insira o posto de trabalho',

	'Enter the data related to the selected video below': 'Insira abaixo os dados relacionados ao vídeo selecionado',

	Staff: 'Quadro de colaboradores',
	'Enter the Company Activities': 'Insra o CNAE',
	'Insert a production line': 'Insira a linha de produção',
	'Add new range': 'Adicionar nova banda',
	'industrial site': 'planta industrial',
	'Select a industrial site': 'Selecione a planta industrial',
	'Select an industrial site': 'Selecione a planta industrial',
	'Production line': 'Linha de produção',
	'Select a production line': 'Selecione a linha de produção',

	Role: 'Cargo/Função',
	Code: 'Código',

	'Tax Code': 'CNPJ',
	'Enter a Tax Number': 'Insira o CNPJ',
	'Insert a valid Tax Number': 'Insira um CNPJ válido',

	'Select a role': 'Selecione um cargo/função',
	'User type': 'Tipo de usuário',
	'User type:': 'Tipo de usuário:',
	Document: 'Documento',
	'Document:': 'Documento:',
	'Current plan': 'Plano atual',
	'Not informed': 'Não informado',

	'Risk ranges': 'Bandas de risco',
	'Local application': 'Local de aplicação',
	'Units to be analyzed': 'Unidades a serem analisadas',
	'Select the units that will be included in the review': 'Selecione as unidades que serão incluídas na revisão',
	Factory: 'Fábrica',
	'Select the industrial sites to be analyzed': 'Selecione as plantas industriais a ser analizadas',
	'Production Volume': 'Volume de produção',
	Investments: 'Investimentos',
	'Enter production volumes below': 'Insira os volumes de produção abaixo',
	'Inform below the investments in ergonomics': 'Informe abaixo os investimentos em ergonomia',

	Date: 'Data',
	'Date of reference': 'Data de referência',
	'Date of the report': 'Data do relatório',

	'Ergonomic analysis of work': 'Análise ergonômica do trabalho',
	Presentation: 'Apresentação',
	'Plant Information': 'Informações da planta',
	'Insert a company name': 'Insira o nome da empresa',
	'Risk degree': 'Grau de risco',
	Activity: 'Atividade',
	District: 'Bairro',
	Administrative: 'Administrativo',
	'Work Shift': 'Turno de Trabalho',
	Operational: 'Operacional',
	'Direct hourly worker': 'Horista direto',
	'Indirect hourly worker': 'Horista indireto',
	'Monthly worker': 'Mensalista',
	'Normal working hours': 'Horário normal de trabalho',

	// AET
	'Include checklists related to this analysis below': 'Inclua abaixo as checklists relacionadas a essa análise',
	'Non-standard scale': 'Escala não padronizada',

	// Bodyparts
	'Lower members': 'Membros inferiores',
	'Left elbow': 'Cotovelo esquerdo',
	'Right elbow': 'Cotovelo direito',
	'Left shoulder': 'Ombro esquerdo',
	'Right shoulder': 'Ombro direito',
	'Left knee': 'Joelho esquerdo',
	'Right knee': 'Joelho direito',
	'Left ankle': 'Tornozelo esquerdo',
	'Right ankle': 'Tornozelo direito',

	/* Nomenclature v2 */
	'Left hand': 'Mão esquerda',
	'Right hand': 'Mão direita',
	'Video description': 'Descrição de vídeo',
	Below: 'Abaixo de',
	Above: 'Acima de',

	Checklist: 'Checklist',
	'Frequency and duration': 'Frequência e duração',

	'Date of collection': 'Data de coleta',

	'Load from 2 kg ≤ 10 kg (intermittent)': 'Carga de 2 kg a 10 kg (intermitente)',
	'Load from 2 kg ≤ 10 kg (static or repetitive)': 'Carga de 2 kg a 10 kg (estático ou repetitivo)',
	'Load more than 10 kg of repetitive load or blows': 'Carga maior 10 kg de carga repetitivos ou golpes',

	Medium: 'Médio',
	Invalid: 'Inválido',

	Safe: 'Aceitável',
	Info: 'Leve',
	Danger: 'Perigo',

	safe: 'aceitável',
	info: 'leve',
	warning: 'alto',
	danger: 'perigo',

	'Corrective action not required': 'Ação corretiva não necessária',
	'Corrective action as soon as possible': 'Ação corretiva o mais rápido possível',
	'High priority corrective action': 'Ação corretiva de alta prioridade',
	'Immediate corrective action': 'Ação corretiva imediata',
	'Unable to show results': 'Não foi possível mostrar os resultados',

	/* Nomenclature */
	Line: 'Linha',
	Effort: 'Esforço',
	Reaction: 'Reação',
	Vibration: 'Vibração',
	'Beats against': 'Batidas contras',
	Percussion: 'Percussão',
	Hands: 'Mãos',
	'Shoulders/Arms': 'Ombros/Braços',
	Left: 'Esquerda',
	Right: 'Direita',
	Exit: 'Sair',
	Filters: 'Filtros',

	/* Login - Page */
	// Placeholder's
	'E-mail': 'E-mail',
	'E-mail:': 'E-mail',

	// Validation's errors inputs
	'Enter a valid e-mail': 'Insira um e-mail válido',
	'Enter your password': 'Insira sua senha',
	Login: 'Login',

	// SideMenu
	Dashboard: 'Dashboard',
	Upload: 'Upload',
	PEA: 'AEP',
	EAW: 'AET',

	// Dashboard
	'Comparison over the last 6 months': 'Comparativo nos ultimos 6 meses',
	/*************/

	'Are you sure?': 'Tem certeza?',
	'You are being redirected': 'Você está sendo redirecionado',
	'Are you sure you want to quit? You have an upload in process.':
		'Tem certeza que deseja sair? Você tem um upload em processo',

	// Upload filter
	'Select a company': 'Selecione uma empresa',
	'Select a line': 'Selecione a linha',
	'Select the workstation': 'Selecione o posto de trabalho',
	'Select a workstation': 'Selecione o posto de trabalho',

	// Chart
	'No data available': 'Não há dados',
	'There is no registered data.': 'Não há dados cadastrados.',

	// Layout
	Logout: 'Sair',

	// Report
	'Add item': 'Adicionar item',
	"Approximated values, depends mainly on the filming methods. In case there is no data, the algorithm didn't find the points to determine the angle, please review the filming method used.":
		'Valores aproximados, dependentes da técnica de filmagem adotada. Caso não tenha dados o algoritmo não identificou os pontos para determinar o ângulo, observar técnicas de filmagem.',
	'The results obtained from each body part can be affected by the method and position of filming, the identification of the person in the analysis can suffer interference from objects present in the room. The angle data shown are approximate values.':
		'Os resultados obtidos em cada membro do corpo poderão ser impactados de acordo com a forma e posição de gravação, a identificação da pessoa que é analisada pode sofrer interferência de objetos presentes no local de operação. Os dados dos ângulos mostrados são valores aproximados.',
	Reference: 'Referência',
	'Investigate and change immediately': 'Investigar e mudar imediatamente',
	'Analysis of the movement by angle': 'Análise do movimento por ângulo',
	'Analysis of the movement by score': 'Análise do movimento com score',
	'Analysis of the movement & Body parts': 'Análise do movimento & Partes do corpo',
	'Report types': 'Tipos de relatórios',
	'Generate reports': 'Gerar relatórios',
	Finish: 'Finalizar',
	'Description already exists!': 'Descrição já existente!',
	'Enter a new sector': 'Insira um novo setor',
	'Do you wish to delete this sector?': 'Deseja excluir esse setor?',
	'This sector is used in other reports, do you really wish to delete it?':
		'Esse setor está sendo utilizado em outros relatórios, tem certeza que deseja excluir?',
	'Sector created successfully!': 'Setor criado com sucesso!',
	'Line created successfully!': 'Linha criada com sucesso!',
	'Workstation created successfully!': 'Posto de trabalho criado com sucesso!',
	"This operation couldn't be completed": 'Não foi possível realizar esta ação',
	Sectors: 'Setores',
	'Fill in the essential information below.': 'Preencha abaixo algumas informações essencias.',
	'Select the sector from the above analysis': 'Selecione o setor da análise acima',
	'Select the sector for the analysis': 'Selecione o setor da análise',
	Add: 'Adicionar',
	'Enter the workstation': 'Insira o posto de trabalho',
	'Enter the date the collection was made': 'Insira a data em que a coleta foi feita',
	'Select the tool for the analysis and the type of report you wish to generate.':
		'Selecione a ferramenta que deseja aplicar para a análise e o tipo de relatório que deseja gerar.',
	Tool: 'Ferramenta',
	'Pick the tool above': 'Selecione a ferramenta acima',
	'Select the type of tool': 'Selecione o tipo da ferramenta',
	Report: 'Relatório',
	'Select the type of report above': 'Selecione o tipo de relatório acima',
	'Select the type of report': 'Selecione o tipo de relatório',

	// BriefBest
	'Shoulder movement': 'Movimentos de ombros',
	'Flexion greater than 20° degrees': 'Flexão maior que 20° graus',
	'Executed with support': 'Execução com suporte',
	Rotation: 'Rotação',
	'Force greater or equal to 11.3kg': 'Força maior ou igual a 11.3kg',
	'Forearm movement': 'Movimentos de antebraço',
	'Greater or equal to 40°': 'Maior ou igual a 40°',
	'Full extension': 'Extensão completa',
	'Force greater or equal to 4.5kg': 'Força maior ou igual a 4.5kg',
	'Movement of hands and wrist': 'Movimentos das mãos e punhos',
	'Ulnar deviation': 'Desvio ulnar',
	'Radial deviation': 'Desvio radial',
	'[Force]: Pinch or pressure with the fingers greater or equal to 0.9kg, or palm pressure greater or equal to 4.5kg.':
		'[Força]: Pinça ou pressão com os dedos maior ou igual 0,9kg, ou pressão palmar maior ou igual 4,5kg.',
	'[Duration]: Greater or equal to 10 seconds.': '[Duração]: Maior ou igual a 10 segundos.',
	'[Frequency]: Greater or equal to 30 minutes.': '[Frequência]: Maior ou igual a 30 minutos.',
	'Leg movement': 'Movimentos das pernas',
	Squatting: 'Agachado',
	'With support': 'Com suporte',
	'Foot pedal greater or equal to 4.5kg': 'Pedal de pé maior ou igual á 4.5kg',
	'Neck movement': 'Movimentos de pescoço',
	'Flexion greater than 30°': 'Flexão maior que 30° graus',
	'Side movement': 'Movimento para os lados',
	Extended: 'Extendido',
	'Rotation greater than 20°': 'Rotação maior que 20° graus',
	'Force greater or equal to 0.9kg': 'Força maior ou igual a 0.9kg',
	'Arm behind the body': 'Braço atrás do corpo',
	'Shoulders tucked in': 'Ombros encolhidos',
	Elbows: 'Cotovelos',
	Ombros: 'Shoulders',
	'Back ': 'Costas', // Coloquei um espaço depois de "Back" para diferenciar de "Voltar"
	"Data couldn't be loaded.": 'Não foi possível carregar.',
	'Body parts - Critical:': 'Partes do corpo - Criticas:',
	Indicators: 'Indicadores',
	'Glove usage': 'Uso de luvas',
	'Impact exposure': 'Exposição a impacto',
	'Vibration exposure': 'Exposição a vibração',
	'Low temperature exposure': 'Exposição à baixas temperaturas',
	'Mechanical compression of muscular tissue': 'Compressão mecânica do tecido muscular',
	'More than 40 hours': 'Maior que 40 horas',
	'20 - 40 hours': '20 - 40 horas',
	'4 - 19 hours': '4 - 19 horas',
	'Less than 4 hours': 'Menor que 4 horas',
	Video: 'Vídeo',
	'Exposure time': 'Tempo de exposição',
	'Tool used': 'Ferramenta utilizada',
	'Physical stress factors': 'Fatores de estresse físico',
	"Data couldn't be loaded": 'Não foi possível buscar os dados',
	'Very high': 'Muito alto',
	'Final score:': 'Pontuação final:',
	'(Task risk x Exposure time factor)': '(Risco da tarefa x fator tempo de exposição)',
	'Priority:': 'Prioridade:',
	'Subtitle:': 'Legendas:',
	Graphs: 'Gráficos',
	'Not identified': 'Não identificado',
	Comments: 'Comentários',
	'Comments:': 'Comentários:',
	'Enter the analysis conclusion': 'Insira aqui a conclusão da análise',
	'Complementary factors': 'Fatores complementares',
	'Physical stress': 'Estresse físico:',
	'Inadequate use of gloves': 'Uso inadequado de luvas',
	'Weekly exposure time:': 'Tempo de exposição semanal:',
	'Physical factors': 'Fatores físicos',
	Filter: 'Filtrar',
	Company: 'Empresa',
	'Select the company': 'Selecione a empresa',
	'Select the industrial site': 'Selecione a planta industrial',
	Function: 'Função',
	'Enter the function': 'Insira a função',
	'Customized reports': 'Relatórios customizados',

	// Settings
	"We weren't able to change your password": 'Não foi possível alterar a sua senha',
	'Warning!': 'Atenção!',
	'Failed to fetch requested information': 'Falha ao buscar informações solicitadas',
	'Do you really wish to cancel your plan?': 'Deseja realmente cancelar seu plano?',
	'After cancelling your plan, when the expiration date comes, you will have no more access to the platform.':
		'Ao cancelar seu plano, após a data de expiração atual você não terá acesso a plataforma.',
	"We weren't able to cancel your plan": 'Não foi possível cancelar o plano',
	"Select a plan that's different from your current one": 'Selecione um plano diferente do seu plano atual',
	"We couldn't process the operation": 'Não foi possível realizar a operação',
	'Plan cancelled on:': 'Plano cancelado em:',
	'Active users:': 'Usuários ativos:',
	'Plan switch': 'Alteração de plano',
	'You have recently changed your plan': 'Recentemente você alterou sua assinatura',
	'Plan type:': 'Tipo de assinatura:',
	'New plan start date:': 'Data de início do novo plano:',
	'User limit:': 'Limite de usuários:',
	'Upload limit:': 'Limite de uploads:',
	'Processing time limit:': 'Limite de minutos de processamento:',
	'Cancel plan': 'Cancelar plano',
	'Error when trying to verify industrial sites from company.':
		'Erro ao verificar plantas industriais dentro da companhia.',
	'Error when trying to delete the company': 'Erro ao deletar empresa',
	'Enter a permission': 'Insira uma permissão',
	'Select a permission': 'Selecione uma permissão',
	'Edit user': 'Editar usuário',
	'User changed successfully': 'Usuário alterado com sucesso',
	Permission: 'Permissão',
	'Do you wish to delete this user?': 'Deseja excluir este usuário',
	Active: 'Ativo',
	Inactive: 'Inativo',
	All: 'Todos',
	Disabled: 'Desabilitado',

	// Manage companies
	'Manage companies': 'Gerenciar empresas',
	'Manage industrial sites': 'Gerenciar plantas industriais',
	'Heads up! This company is not empty': 'Atenção! Esta empresa não está vazia',
	'To continue, first remove industrial sites belonging to this company.':
		'Para continuar, remova as plantas industriais pertencentes a esta empresa.',
	'All industrial sites in this company will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'Todas as plantas industriais dessa empresa serão deletadas, em conjunto com seus uploads e relatórios. Tem certeza que deseja prosseguir?',
	'All uploads and reports from this industrial site will be deleted. This action cannot be undone. Do you wish to continue?':
		'Todos os uploads e relatórios dessa planta industrial serão deletados. Esta ação não pode ser desfeita. Tem certeza que deseja prosseguir?',
	'Failed to update information': 'Falha ao atualizar a informação',
	'Edit company': 'Editar empresa',
	Loading: 'Carregando',
	'Company name': 'Nome da empresa',
	'Industrial sites': 'Plantas industriais',
	'Total Industrial Sites': 'Total de plantas industriais',
	'Manage industrial site': 'Gerenciar planta industrial',
	'Update company name': 'Atualizar nome da empresa',
	'Delete company': 'Apagar empresa',
	'Organization created successfully': 'Empresa criada com sucesso.',
	'Organization deleted successfully': 'Empresa removida com sucesso.',
	'Updated information': 'Informação atualizada',
	'Error while changing the name of the industrial site': 'Erro ao editar o nome da planta industrial',

	// ui/Reports
	'1 - Acceptable posture if not repeated or kept for long periods':
		'1 - Postura aceitável se não for repetida ou mantida durante longos períodos',
	'2 - Investigate; possibility to request for change; it is convenient to introduce alterations':
		'2 - Investigar; possibilidade de requerer mudanças; é conveniente introduzir alterações',
	'3 - Investigate; make changes quickly': '3 - Investigar; realizar mudanças rapidamente',
	'4 - Immediate change': '4 - Mudanças imediatas',
	'Acceptable posture if not repeated or kept for long periods':
		'Postura aceitável se não for repetida ou mantida durante longos períodos',
	'Investigate; possibility to request for change; it is convenient to introduce alterations':
		'Investigar; possibilidade de requerer mudanças; é conveniente introduzir alterações',
	'Investigate; make changes quickly': 'Investigar; realizar mudanças rapidamente',
	'Immediate change': 'Mudanças imediatas',
	Ergonomist: 'Ergonomista',
	'Left lower arm': 'Antebraço esquerdo',
	'Right lower arm': 'Antebraço direito',
	'Left upper arm': 'Braço esquerdo',
	'Right upper arm': 'Braço direito',
	Type: 'Tipo',

	// views/Report/Niosh
	'Manual Lifting Report': 'Relatório - Levantamento Manual',
	'Enter the job characteristics below.': 'Entre com as características do trabalho abaixo.',
	'Gender of the worker': 'Genero do trabalhador',
	'Select the gender': 'Selecione o gênero',
	Age: 'Idade',
	'Select the age': 'Selecione a idade',
	'How many workers are lifting the object?': 'Quantos trabalhadores levantam o objeto?',
	'Which hands are used?': 'Quais mãos são utilizadas?',
	One: 'Um',
	Two: 'Dois',
	Three: 'Três',
	'Both hands': 'Duas mãos',
	'One hand': 'Uma mão',
	'Select the coupling quality': 'Selecione a qualidade da pega',
	'Select the lifting frequency': 'Selecione a frequência do levantamento',
	'Select the lifting duration': 'Selecione a duração do levantamento',
	'Lifting frequency (lifts per minute)': 'Frequência de levantamento (levant./min)',
	Poor: 'Pobre',
	Fair: 'Razoável',
	Good: 'Boa',
	'1 hour': '1 hora',
	'1 - 2 hours': '1 a 2 horas',
	'2 - 8 hours': '2 a 8 horas',
	'Enter the task variables below.': 'Insira abaixo as variáveis do trabalho.',
	'Please enter a value': 'Insira um valor',
	'Weight of the object': 'Peso do objeto',
	'Distance the object is moved vertically': 'Distância que o objeto é deslocado verticalmente',
	'Vertical distance of the object at the beginning of handling, relative to the ground':
		'Distância vertical do objeto no inicio do manuseio, relativa ao solo',
	'Horizontal distance of the object relative to the body': 'Distância horizontal do objeto, relativa ao corpo',
	'Rotation angle': 'Ângulo de rotação',
	'Recommended Weight Limit (RWL)': 'Limite de peso recomendado (LPR)',
	'Lifting Index (LI)': 'Índice de levantamento (IL)',
	'Coupling factor: ': 'Fator de pega: ',
	'Frequency factor: ': 'Fator frequência: ',
	'lifts / min': 'levant. / min',
	'Report date': 'Data do relatório',
	'Comment:': 'Comentário:',
	Details: 'Detalhes',
	Risk: 'Risco',
	'Very low risk': 'Risco muito baixo',
	Measurements: 'Medidas',
	'Lifted by ': 'Levantado por ',
	'1 worker': '1 pessoa',
	'2 workers': '2 pessoas',
	'3 workers': '3 pessoas',
	Using: 'Usando',
	'only one hand': 'apenas uma mão',
	'both hands': 'as duas mãos',
	'Reference weight': 'Peso de referência',
	'Enter the required values': 'Insira os valores obrigatórios',
	'Report created successfully': 'Relatório criado com sucesso',
	'Comment updated successfully': 'Comentário atualizado com sucesso',
	'Document created successfully': 'Documento criado com sucesso',

	// NIOSH - Conclusions
	Conclusions: 'Conclusões',
	Conclusion: 'Conclusão',
	'The weight to be lifted': 'O peso a ser levantado',
	'is greater than the recommended weight limit (RWL)': 'é maior do que o limite de peso recomendado (LPR)',
	'is less than the recommended weight limit (RWL)': 'é menor do que o limite de peso recomendado (LPR)',
	' The lifting index (LI)': ' O índice de levantamento (IL)',
	'is more than 3.': 'é maior do que 3.',
	'is more than 2 and less than 3.': 'está entre 2 e 3.',
	'is more than 1,5 and less than 2.': 'está entre 1,5 e 2.',
	'is more than 1 and less than 1,5.': 'está entre 1 e 1,5.',
	'is less than 1.': 'é menor do que 1.',
	' There is a very high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		' O risco é muito alto. Estes valores indicam que o levantamento seria perigoso para a maioria dos trabalhadores saudáveis.',
	' There is a high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		' O risco é alto. Estes valores indicam que o levantamento seria perigoso para a maioria dos trabalhadores saudáveis.',
	' There is a moderate risk. These values indicate that this job is somewhat stressful.':
		' O risco é moderado. Estes valores indicam que a tarefa é um pouco rigorosa.',
	' There is a low risk. These values indicate that this job is adequate for the majority of industrial workers, but some may have trouble.':
		' O risco é baixo. Estes valores indicam que a tarefa é adequada para a maioria dos trabalhadores, poucos poderiam encontrar problemas.',
	' There is a very low risk. These values indicate that this job is adequate for the majority of industrial workers.':
		' O risco é muito baixo. Estes valores indicam que a tarefa é adequada para a maioria dos trabalhadores.',
	'These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		'Estes valores indicam que o levantamento seria perigoso para a maioria dos trabalhadores saudáveis.',
	'These values indicate that this job is somewhat stressful.':
		'Estes valores indicam que a tarefa é um pouco rigorosa.',
	'These values indicate that this job is adequate for the majority of industrial workers, but some may have trouble.':
		'Estes valores indicam que a tarefa é adequada para a maioria dos trabalhadores, poucos poderiam encontrar problemas.',
	'These values indicate that this job is adequate for the majority of industrial workers.':
		'Estes valores indicam que a tarefa é adequada para a maioria dos trabalhadores.',
	Male: 'Masculino',
	Female: 'Feminino',
	Man: 'Homem',
	Woman: 'Mulher',
	'20 to 45 years old': '20 a 45 anos',
	'20 to 45': '20 a 45',
	'< 20 years old': '< 20 anos',
	'> 45 years old': '> 45 anos',
	'Download PDF': 'Baixar PDF',

	// NIOSH - Critical factors
	'The most critical variables are listed below with suggestions:':
		'As variáveis mais críticas estão listadas abaixo com sugestões de melhoria:',
	'Bring the load closer to the worker by removing any horizontal barriers or reducing the size of the object. Lifts near the floor should be avoided.':
		'Leve o peso para mais próximo do trabalhador removendo qualquer barreira horizontal ou reduzindo o tamanho do objeto. Levantamentos próximos ao piso devem ser evitados se possível.',
	'Raise/lower the origin/destination of the lift. Avoid lifting near the floor or above the shoulders.':
		'Eleve a origem ou desça o destino do levantamento. Evite levantamentos próximos ao piso ou acima dos ombros.',
	'Reduce the vertical distance between the origin and the destination of the lift.':
		'Reduza a distância vertical entre a origem e o destino do levantamento.',
	'Move the origin and destination closer together to reduce the angle of twist, or move them further apart to force the worker to turn the feet and step, rather than twist the body.':
		'Mova a origem e o destino mais pertos um do outro para reduzir o ângulo de torção, ou deixe-os mais afastados para que o trabalhador precise dar um passo sem torcer o corpo.',
	'Reduce the lifting frequency rate, reduce the lifting duration, or provide longer recovery periods (i.e., light work period).':
		'Reduza a frequência de levantamento, reduza a duração do levantamento ou proporcione períodos de descanso maiores (tarefas menos exigentes).',
	'Improve the hand-to-object coupling by providing optimal containers with handles or handhold cutouts, or improve the handholds for irregular objects.':
		'Melhore a facilidade de agarre providenciando alças ou recortes para as mãos. Para objetos irregulares, melhore os apoios para as mãos.',
	'Consider using both hands when lifting the weight.': 'Considere utilizar as duas mãos ao realizar o levantamento.',
	'One handed': 'Usando uma mão',
	'Distance H': 'Distância H',
	'Distance Vc': 'Distância Vc',
	'Distance Dc': 'Distância Dc',
	'Angle A': 'Ângulo A',
	'Frequency/Duration': 'Frequência/Duração',
	Coupling: 'Pega',
	Characteristics: 'Características',
	Variables: 'Variáveis',
	Result: 'Resultado',
	'Choose a gender': 'Escolha um gênero',
	Gender: 'Gênero',
	Pairs: 'Pares',

	// STRAIN INDEX REPORT
	'Report Results - Revised Strain Index': 'Relatório - Strain Index Revisado',
	'Intensity of exertion': 'Intensidade do esforço',
	'Efforts per minute': 'Esforços por minuto',
	'Number of exertions': 'Número de esforços',
	'Observation time': 'Tempo de observação',
	'Duration per exertion': 'Duração por esforço',
	'Hand/wrist posture': 'Postura da mão/punho',
	'Type of wrist posture': 'Tipo de postura do punho',
	'Hand/wrist angle': 'Ângulo da mão/punho',
	'Duration of task per day': 'Duração da tarefa por dia',
	'Revised Strain Index (RSI)': 'Strain Index Revisado (RSI)',
	'Risk factor': 'Fator de risco',
	Input: 'Entrada',
	'Job is probably safe': 'A tarefa é provavelmente segura',
	'Job is probably hazardous': 'A tarefa é provavelmente perigosa',
	Degrees: 'Graus',
	'Duration/day': 'Duração/dia',
	'Intensity of exertion (Borg Scale - BS)': 'Intensidade do esforço (Borg Scale - BS)',
	'Somewhat Hard': 'Pouco Difícil',
	Hard: 'Difícil',
	'Very Hard': 'Muito Difícil',
	'Near Maximal': 'Quase Máxima',
	'Risk factors 1': 'Fatores de risco 1',
	'Risk factors 2': 'Fatores de risco 2',
	'Barely noticeable or relaxed effort (BS: 0-2)': 'Quase imperceptivel ou esforço mínimo (BS: 0-2)',
	'Noticeable or definite effort (BS: 3)': 'Esforço perceptível (BS: 3)',
	'Obvious effort; Unchanged facial expression (BS: 4-5)':
		'Esforço óbvio; Sem mudanças na expressão facial (BS: 4-5)',
	'Substantial effort; Changes expression (BS: 6-7)': 'Esforço substancial; Mudanças na expressão facial (BS: 6-7)',
	'Uses shoulder or trunk for force (BS: 8-10)': 'Utiliza ombros ou tronco para força (BS: 8-10)',
	'Number of exertions observed': 'Núm. de esforços observados',
	'Total observation time': 'Tempo total da observação',
	'Average single exertion time': 'Tempo médio de cada esforço',
	'(total exertions * single exertion time) must be less than or equal to the total observation time':
		'(núm. de esforços * tempo médio por esforço) deve ser menor ou igual ao tempo da observação',
	seconds: 'segundos',
	hours: 'horas',
	Extension: 'Extensão',
	Flexion: 'Flexão',
	'Revised Strain Index (Moore and Garg)': 'Strain Index Revisado (Moore e Garg)',
	'Report Results': 'Relatório',
	'Revised Strain Index': 'Strain Index Revisado',
	'(Moore and Garg)': '(Moore e Garg)',

	// REBA
	'REBA Report Results': 'Relatório REBA',
	'REBA score of the workplace': 'Pontuação REBA do posto de trabalho',
	'Left leg': 'Perna esquerdo',
	'Right leg': 'Perna direita',
	'Load less than 5kg (intermittent)': 'Carga menor do que 5kg (intermitente)',
	'Load from 5kg to 20kg (intermittent)': 'Carga de 5kg a 20kg (intermitente)',
	'Load from 5kg to 20kg (static or repetitive)': 'Carga de 5kg a 20kg (estática ou repetitiva)',
	'Bigger load 20kg of repetitive load or blows': 'Carga maior do que 20kg de repetição ou de pancadas',
	Unacceptable: 'Inaceitável',
	Force: 'Força',
	Load: 'Carga',
	'Select the load': 'Selecione a carga',
	'Select the number of repetitions': 'Selecione o número de repetições',
	'Score left': 'Score esquerdo',
	'Score right': 'Score direito',
	Acceptable: 'Aceitável',
	Investigate: 'Investigar',
	'Investigate and change soon': 'Investigar e mudar logo',
	'Investigate and change now': 'Investigar e mudar agora',
	'REBA Score': 'Score REBA',
	'The 4 most critical body parts': 'As 4 partes mais críticas',
	'Viewing the selected body part': 'Visualizando a parte selecionada',
	minute: 'minuto',
	'Requested video data is old, please upload to continue':
		'Os dados do vídeo solicitado são antigos, faça o upload para continuar',
	'View score': 'Ver pontuação',
	'Unable to view': 'Não foi possivel a visualização',
	'Worst grade found in time': 'Pior score encontrado no tempo',
	'Worst score': 'Pior score',
	'Worst RPN': 'Pior RPN',

	// Angle time
	'Angle by time': 'Ângulo no Tempo',
	'Create risk range': 'Criar faixa de risco',
	'Report Results - Angle by Time': 'Relatório - Ângulo no Tempo',
	'Please fill all required fields': 'Favor preencher todos os campos necessários',

	// ACTION PLANS
	'Do you want to delete this action plan?': 'Gostaria de remover este plano de ação?',
	'Action plan created successfully': 'Plano de ação criado com sucesso',
	'Action plan updated successfully': 'Plano de ação atualizado com sucesso',
	'Create action plan': 'Criar plano de ação',
	'Update action plan': 'Atualizar plano de ação',
	'Please enter a valid description': 'Por favor, digite uma descrição válida',
	'Action plan description': 'Descrição do plano de ação',
	'Please enter a valid action plan description': 'Por favor, insira uma descrição válida do plano de ação',
	'Person responsible': 'Responsável',
	Responsible: 'Responsável',
	'Who is responsible for this action plan?': 'Quem é o responsável por este plano de ação?',
	'Describe the situation found and the preventive measures already implemented (if exist)':
		'Descreva a situação encontrada e as medidas preventivas já implementadas (caso existam)',
	Deadline: 'Data de vencimento',
	'Finish editing your other task first': 'Primeiro finalize a edição da outra tarefa',
	'Add a new task': 'Adicione uma nova tarefa',
	History: 'Histórico',
	'To Do': 'A fazer',
	Doing: 'Fazendo',
	Done: 'Feito',
	'TO DO': 'A FAZER',
	DOING: 'FAZENDO',
	DONE: 'FEITO',
	'Finish by: ': 'Concluir até: ',
	'On time': 'No prazo',
	Overdue: 'Atrasado',
	'Report type': 'Tipo do relatório',
	'Select a report type': 'Selecione o tipo do relatório',
	'Finishing in a week': 'Finalizando em uma semana',
	'Person responsible updated': 'Responśavel pelo plano de ação atualizado',
	'Deadline updated': 'Prazo do plano de ação atualizado',
	Tasks: 'Tarefas',
	'Task created': 'Tarefa criada',
	'Task deleted': 'Tarefa removida',
	'Task renamed': 'Tarefa renomeada',
	'Task type changed': 'Tipo da tarefa modificada',
	'Task and type changed': 'Tarefa e tipo alterado',
	'Task checked': 'Tarefa concluída',
	'Task unchecked': 'Tarefa desfeita',
	'Action plan created': 'Plano de ação criado',
	'Action plan renamed': 'Plano de ação renomeado',
	'Status changed': 'Status alterado',
	'Deadline changed': 'Prazo alterado',
	'by ': 'por ',
	'Successfully updated action plan data': 'Dados do plano de ação atualizados com sucesso',
	'Responsible user changed': 'Responsável alterado',
	'Action plan title': 'Título do plano de ação',
	Title: 'Título',
	'Select a file': 'Selecione um arquivo',
	'Select an option': 'Selecione uma opção',
	'Please enter a valid title': 'Por favor, digite um título válido',
	'Please enter a responsible': 'Por favor, insira um responsável',
	'Please enter a valid date': 'Por favor, insira uma data válida',
	'Please select a condition': 'Por favor, selecione uma condição',
	'Search company': 'Buscar empresa',
	'Search industrial site': 'Buscar planta industrial',
	'Enter a valid email': 'Insira um e-mail válido',
	'Enter a description for your action plan.': 'Insira uma descrição para seu plano de ação.',
	'Select a condition': 'Selecione uma condição',
	Eliminate: 'Eliminar',
	Control: 'Controlar',
	Compensate: 'Compensar',
	ELIMINATE: 'ELIMINAR',
	CONTROL: 'CONTROLAR',
	COMPENSATE: 'COMPENSAR',
	'Due Date': 'Prazo de vencimento',
	'Date of Completion': 'Data de finalização',
	Investment: 'Investimento',
	Attachments: 'Anexos',
	Progress: 'Progresso',
	Evidence: 'Evidência',
	Author: 'Autor',
	Origin: 'Origem',
	'Manage Columns': 'Administrar Colunas',
	'Priority editing': 'Edição de prioridade',
	'Due Date editing': 'Edição de prazo de vencimento',
	'Responsible editing': 'Edição de responsável',
	'Are you sure you want to delete the selected action plans?':
		'Pretende mesmo eliminar os planos de ação selecionados?',
	'New Task': 'Nova Tarefa',
	'Are you sure you want to delete the selected task?': 'Tem certeza de que deseja excluir a tarefa selecionada?',

	// KIM - MHO
	'Error, cannot get "': 'Erro, não é possível obter "',
	'" from "': '" de "',
	'Total duration of this sub-activity per working day': 'Duração total da atividade por dia trabalhado',
	'Total duration per day worked': 'Duração total por dia trabalhado',
	'Force exerted on hand': 'Força exercida na mão',
	'fingers (L|R)': 'dedos (E|D)',
	'gripping conditions': 'condições de aderência',
	'Power transfer': 'Transferência de energia',
	'Type of force exertion in the finger/hand area within a “standard minute”':
		'Força exercida na área da mão/dedos em um "minuto padrão"',
	'Force transfer / gripping conditions': 'Transferência de força / condições de pega',
	'Hand/arm position and movement': 'Posição e movimento da mão/braço',
	'Unfavourable working conditions': 'Condições de trabalhos desfavoráveis',
	'Work organization / temporal distribution': 'Organização de trabalho / distribuição temporal',
	'Body posture/movement': 'Postura e movimento do corpo',
	'Very low': 'Muito baixa',
	Moderate: 'Moderada',
	'Peak forces': 'Forças de pico',
	'Powerful hitting': 'Batidas fortes',
	'Up to 15% Fmax. Button actuation, shifting, ordering, material guidance, insertion of small parts.':
		'Até 15% de Fmax. Acionamento de botão, deslocamento, ordenamento, orientação de material, inserção de peças pequenas.',
	'Up to 30% Fmax. Gripping, joining small work pieces by hand or with small tools.':
		'Até 30% de Fmax. Agarrar ou unir peças pequenas com a mão ou com ferramentas pequenas.',
	'Up to 50% Fmax. Turning, winding, packaging, grasping, holding or joining parts, pressing in, cutting, working with small powered hand tools.':
		'Até 50% de Fmax. Tornear, enrolar, embalar, agarrar, segurar ou unir peças, pressionar, cortar, trabalhar com pequenas ferramentas manuais motorizadas.',
	'Up to 80% Fmax. Cutting involving major element of force, working with small staple guns, moving or holding parts or tools.':
		'Até 80% de Fmax. Corte envolvendo grande elemento de força, trabalhando com pequenas pistolas de grampo, movendo ou segurando peças ou ferramentas.',
	'More than 80% Fmax. Tightening, loosening bolts, separating, pressing in.':
		'Mais de 80% de Fmax. Apertar, desapertar parafusos, separar, pressionar.',
	'Hitting with the ball of the thumb, palm of the hand or fist.':
		'Golpear com a ponta do polegar, palma da mão ou punho.',
	'Optimum force transfer/application': 'Transferência/aplicação de força ideal',
	'Restricted force transfer/application': 'Transferência/aplicação de força restrita',
	'Force transfer/application considerably hindered':
		'Transferência/aplicação de força consideravelmente prejudicada',
	'Optimum force application': 'Aplicação de força ideal',
	'Restricted force application': 'Aplicação de força restrita',
	'Force application considerably hindered': 'Aplicação de força consideravelmente prejudicada',
	'Working objects are easy to grip (e.g. bar-shaped, gripping grooves) / good ergonomic gripping design (grips, buttons, tools).':
		'Objetos de trabalho são fáceis de segurar (por exemplo, em forma de barra, ranhuras de preensão) / bom design ergonômico de preensão (alças, botões, ferramentas).',
	'Greater holding forces required / no shaped grips.':
		'Maiores forças de pega necessárias / alças sem formato ergonômico.',
	'Working objects hardly possible to grip (slippery, soft, sharp edges) / no or only unsuitable grips.':
		'Objetos de trabalho dificeis de segurar (escorregadios, macios, bordas afiadas) / apenas alças inadequados ou nenhuma.',
	Restricted: 'Restrita',
	Unfavourable: 'Desfavorável',
	'Position or movements of joints in the middle (relaxed) range, only rare deviations, no continuous static arm posture, hand-arm rest possible as required.':
		'Posição ou movimentos das articulações no intervalo médio (relaxado), apenas desvios raros / sem postura estática contínua do braço / apoio mão-braço possível conforme necessário.',
	'Occasional positions or movements of the joints at the limit of the movement ranges, occasional long continuous static arm posture.':
		'Posições ou movimentos ocasionais das articulações no limite das amplitudes de movimento, postura de braço ocasionalmente estática, longa e contínua.',
	'Frequent positions or movements of the joints at the limit of the movement ranges, frequent long continuous static arm posture.':
		'Posições ou movimentos das articulações frequentemente no limite das amplitudes de movimento, postura de braço frequentemente estática, longa e contínua.',
	'Constant positions or movements of the joints at the limit of the movement ranges, constant long continuous static arm posture.':
		'Posições ou movimentos das articulações constantemente no limite das amplitudes de movimento, postura de braço constantemente estática, longa e contínua.',
	'Frequent variation of the physical workload situation due to other activities (including other types of physical workload) / without a tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'Variação frequente da situação de carga de trabalho física devido a outras atividades (incluindo outros tipos de carga de trabalho física) / sem uma sequência estreita de cargas de trabalho físicas mais altas dentro de um tipo de carga de trabalho física durante um único dia de trabalho.',
	'Rare variation of the physical workload situation due to other activities (including other types of physical workload) / occasional tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'Variação rara da situação de carga de trabalho física devido a outras atividades (incluindo outros tipos de carga de trabalho física) / sequência restrita ocasional de cargas de trabalho físicas mais altas dentro de um tipo de carga de trabalho física durante um único dia de trabalho.',
	'No/hardly any variation of the physical workload situation due to other activities (including other types of physical workload) / frequent tight sequence of higher physical workloads within one type of physical workload during a single working day with concurrent high load peaks.':
		'Nenhuma/quase nenhuma variação da situação de carga de trabalho física devido a outras atividades (incluindo outros tipos de carga de trabalho física)/sequência estreita frequente de cargas de trabalho físicas mais altas dentro de um tipo de carga de trabalho físico durante um único dia de trabalho com picos de carga elevados simultâneos.',
	'There are no unfavourable working conditions, i.e. reliable recognition of detail / no dazzle / good climatic conditions.':
		'Não há condições de trabalho desfavoráveis, ou seja, reconhecimento confiável de detalhes / sem encandeamento / boas condições climáticas.',
	'Occasionally impaired detail recognition due to dazzle or excessively small details difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'Reconhecimento de detalhes ocasionalmente prejudicado devido a ofuscamento ou detalhes excessivamente pequenos condições difíceis como corrente de ar, frio, umidade e/ou concentração perturbada devido ao ruído.',
	'Frequently impaired detail recognition due to dazzle or excessively small details frequently difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'Reconhecimento de detalhes frequentemente prejudicado devido a ofuscamento ou detalhes excessivamente pequenos condições frequentemente difíceis, como correntes de ar, frio, umidade e/ou concentração perturbada devido ao ruído.',
	'Alternation between sitting and standing, alternation between standing and walking, dynamic sitting possible':
		'Alternância entre sentado e em pé, alternância entre em pé e andando, assento dinâmico possível',
	'Trunk inclined forward only very slightly': 'Tronco inclinado para a frente apenas muito ligeiramente',
	'No twisting and/or lateral inclination of the trunk identifiable':
		'Sem torção e/ou inclinação lateral do tronco identificável',
	'Head posture: variable, head not inclined backward and/or severely inclined forward or constantly moving':
		'Postura da cabeça: variável, cabeça não inclinada para trás e/ou severamente inclinada para frente ou em constante movimento',
	'No gripping above shoulder height / no gripping at a distance from the body':
		'Sem preensão acima da altura do ombro/sem preensão à distância do corpo',
	'Predominantly sitting or standing with occasional walking':
		'Predominantemente sentado ou em pé com caminhada ocasional',
	'Trunk with slight inclination of the body towards the work area':
		'Tronco com leve inclinação do corpo em direção à área de trabalho',
	'Occasional twisting and/or lateral inclination of the trunk identifiable':
		'Torção ocasional e/ou inclinação lateral do tronco identificável',
	'Occasional deviations from good “neutral” head posture/movement':
		'Desvios ocasionais da boa postura/movimento “neutro” da cabeça',
	'Occasional gripping above shoulder height / occasional gripping at a distance from the body':
		'Apreensão ocasional acima da altura do ombro/apreensão ocasional à distância do corpo',
	'Exclusively standing or sitting without walking': 'Exclusivamente em pé ou sentado sem andar',
	'Trunk clearly inclined forward and/or frequent twisting and/or lateral inclination of the trunk identifiable':
		'Tronco claramente inclinado para frente e/ou torção frequente e/ou inclinação lateral do tronco identificável',
	'Frequent deviations from good “neutral” head posture/movement':
		'Desvios frequentes da boa postura/movimento “neutro” da cabeça',
	'Head posture hunched forward for detail recognition / restricted freedom of movement':
		'Postura da cabeça curvada para a frente para reconhecimento de detalhes/liberdade de movimento restrita',
	'Frequent gripping above shoulder height / frequent gripping at a distance from the body':
		'Preensão frequente acima da altura do ombro/preensão frequente à distância do corpo',
	'Trunk severely inclined forward / frequent or long-lasting bending':
		'Tronco fortemente inclinado para a frente/flexão frequente ou de longa duração',
	'Work being carried out in a kneeling, squatting, lying position':
		'Trabalho sendo realizado em posição ajoelhada, agachada e deitada',
	'Constant twisting and/or lateral inclination of the trunk identifiable':
		'Torção constante e/ou inclinação lateral do tronco identificável',
	'Body posture strictly fixed / visual check of action through magnifying glasses or microscopes':
		'Postura corporal estritamente fixa/verificação visual da ação através de lupas ou microscópios',
	'Constant deviations from good “neutral” head posture/movement':
		'Desvios constantes da boa postura/movimento “neutro” da cabeça',
	'Constant gripping above shoulder height / constant gripping at a distance from the body':
		'Pegada constante acima da altura do ombro/pegada constante a uma distância do corpo',
	'Manual Handling': 'Manuseio Manual',
	'Manual Handling Operations': 'Operações de Manuseio Manual',
	'KIM - Manual Handling Operations': 'KIM - Operações de Manuseio Manual',
	'Total duration per working day': 'Duração total por dia trabalhado',
	'Type of force exertion in the finger/hand (left | right)': 'Força exercida na área da mão/dedos (esq. | dir.)',
	'Probability of physical overload': 'Probabilidade de sobrecarga fisica',
	'Possible health consequences': 'Possíveis consequências à saúde',
	Measures: 'Medidas',
	'Physical overload is unlikely.': 'Sobrecarga física não é provável.',
	'No health risk is to be expected.': 'Nenhum risco à saúde é esperado.',
	'None.': 'Nenhum.',
	'Physical overload is possible for less resilient persons.':
		'Sobrecarga física é possível para pessoas menos resilientes.',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time.':
		'Fadiga, problemas de adaptação de baixo grau que podem ser compensados durante o tempo livre.',
	'For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		'Para pessoas menos resilientes, mudanças no local de trabalho e outras medidas preventivas podem ser de ajuda.',
	'Physical overload is possible for normally resilient persons.':
		'Sobrecarga física é possível para pessoas normalmente resilientes.',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation.':
		'Distúrbios (dor), possivelmente incluindo disfunções, reversíveis na maioria dos casos, sem manifestação morfológica.',
	'Workplace redesign and other prevention measures should be considered.':
		'Mudanças no local de trabalho e outras medidas preventivas devem ser consideradas.',
	'Physical overload is likely.': 'Sobrecarga física é provável.',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance.':
		'Distúrbios e/ou disfunções mais pronunciados, danos estruturais com significado patológico.',
	'Workplace redesign measures are necessary. Other prevention measures should be considered.':
		'Mudanças no local de trabalho são necessárias, outras medidas preventivas devem ser consideradas.',
	'Slightly increased': 'Ligeiramente elevada',
	'Substantially increased': 'Substancialmente elevada',
	'Type of force exertion in the finger/hand area': 'Tipo de esforço realizado na área da mão/dedos',
	'Intensity of force exertion in the finger/hand area within a “standard minute”':
		'Intensidade da força exercida na área da mão/dedos em um "minuto padrão"',
	Holding: 'Segurando',
	Moving: 'Movendo',
	'average (seconds holding / minute)': 'média (segundos segurando / min.)',
	'average (movements / minute)': 'média (movimentos / min.)',
	'Left arm': 'Braço esquerdo',
	'Right arm': 'Braço direito',

	'Time and force': 'Tempo e força',
	Conditions: 'Condições',
	Posture: 'Postura',

	'Select the body parts you are interested in': 'Selecione as partes do corpo que lhe interessam',
	'Select all': 'Selecionar todos',

	'Shoulder L': 'Ombro E',
	'Shoulder R': 'Ombro D',
	'Elbow L': 'Cotovelo E',
	'Elbow R': 'Cotovelo D',
	'Leg L': 'Perna E',
	'Leg R': 'Perna D',

	Cycle: 'Ciclo',
	'Select the cycle': 'Selecione o ciclo',
	'Select one of the vehicles or a wheel type:': 'Selecione um dos veículos ou um tipo de roda',
	Conveyors: 'Transportador',
	Cranes: 'Guindastes',
	'Driveway conditions:': 'Condições do piso:',
	'Select the mass to be transported (in kg):': 'Selecione a massa a ser transportada (em kg):',
	'Inclination or stairs:': 'Inclinação ou escadas:',
	'Conditions:': 'Condições:',

	'Increased movement speed (approx. 1.0 up to 1.3 m/s)': 'Maior velocidade de movimento (aprox. 1,0 até 1,3 m/s)s',

	//Preliminary Ergonomic Analysis

	//Error
	'length must be less than or equal to 50 characters long': 'comprimento deve ser menor ou igual a 50 caracteres',

	'Preliminary Ergonomic Analysis': 'Avaliação Ergonômica Preliminar',

	//Super Preliminary Ergonomic Analysis

	'Basic Information': 'Informações Básicas',
	'Select PEA': 'Seleção de AEP',

	"To generate this document, it's necessary that the EWA's must be consolidated, so the list below only shows the consolidated EWA's.":
		"Para gerar esse documento é necessário que as AEP's sejam consolidadas, por isso a lista abaixo apresenta apenas as AEPs consolidadas.",

	// Basic Informartion Step
	'Report Information': 'Dados do relatório',
	'Report name': 'Nome do relatório',
	'Enter report name': 'Digite o nome do relatório',
	'Select a Company': 'Selecione uma empresa',
	'Select the reference date': 'Selecione a data de referência',
	'Select the report date': 'Selecione a data do relatório',

	// Select PEA Step
	'File Name': 'Nome do arquivo',
	'File name': 'Nome do arquivo',
	'Created At': 'Criado em',
	'Uploaded date': 'Data de upload',
	'Date of creation': 'Data de criação',
	'Date of update': 'Data de atualização',
	'Analyst Name': 'Analista',
	Submit: 'Finalizar',

	//Signatures Step
	Signatures: 'Assinaturas',
	'Enter with a role': 'Digite o cargo/função',
	'Enter with a name of responsible': 'Digite o nome do responsável',
	'Add a new signature': 'Adicione uma assinatura',
	'Responsible name': 'Nome do Respontavel',

	// Conclusion Step
	'Check selected Files': 'Confira os Arquivos selecionados',
	'Files List': 'Lista de Arquivos',
	'Selected EWA': 'Análises Selecionadas.',
	'EWA created successfully': 'AEP criada com sucesso.',
	'Failed to upload PEA report.': 'Falha ao criar a AEP.',

	// Super PEA File History
	'Preliminary Ergonomic Analyzes': 'Avaliações Ergonômicas Preliminares',
	'Selected Files': 'Arquivos selecionados',
	'Worst Score': 'Pior Avaliação',
	'Open File List': 'Abrir Lista de Arquivos',
	'EWA deleted successfully': 'PEA removida com sucesso.',

	//Others words
	Men: 'Homens',
	Women: 'Mulheres',
	Likelihood: 'Probabilidade',
	Exposure: 'Exposição',
	'Possible Injuries/Harms': 'Possíveis lesões/danos',
	Job: 'Trabalho',
	'Assignment note': 'Nota da Tarefa',
	'Hours worked in the day': 'Horas trabalhadas no dia',
	'Weight Limit recommended (WLR)': 'Limite de peso recomendado (LPR)',
	'Lifting index (LI)': 'Índice de elevação (IL)',
	Comment: 'Comentário',
	Particularities: 'Particularidades',
	Production: 'Produção',
	'Working regime': 'Regime de trabalho',
	EWA: 'AEP',
	'New document': 'Novo documento',
	'File history': 'Histórico de arquivos',
	'Items per page': 'Itens por página',
	'New Report': 'Novo Relatório',
	Analysis: 'Análise',

	//Action plans cards
	'Fill in the details above': 'Preencha os dados acima',

	'Risk Acceptable': 'Risco Aceitável',

	'Immediate action for improvement and reassess': 'Ação Imediata de melhoria e reavaliar',

	'Improvement action': 'Ação de melhoria',

	'Risk Very High': 'Risco Muito Elevado',
	'Perform Ergonomic Work Analysis': 'Realizar Análise Ergonômica do Trabalho',

	'Risk Serious and Imminent': 'Risco Grave e Iminente',
	'Stop the activity and carry out an Ergonomic Work Analysis':
		'Parar a atividade e realizar análise ergonômica do trabalho',

	//Possible injuries
	'Possible injuries': 'Possíveis lesões/agravos',
	'Fatigue complaints': 'Queixas de fadiga',
	Absenteeism: 'Absenteismo',
	'Musculoskeletal complaints': 'Queixas osteomusculares',
	'Complaints of mental overload': 'Queixas de esgotamento mental',
	'Complaints of irritability': 'Queixas de irritabilidade',
	'Nonconforming products': 'Produtos não conforme',
	'Low back pain complaints': 'Queixas de lombalgia',
	'Elbow pain': 'Dor de cotovelo',
	'Shoulder pain': 'Dor no ombro',
	Headache: 'Dor de cabeça',
	'Unexcused absences': 'Faltas não justificadas',
	'Neck pain': 'Dor de pescoço',
	'Wrist pain': 'Dor no punho',

	//Consequence
	Consequence: 'Consequência',
	'Severe with fatalities and serious injuries': 'Severo com mortes e lesões graves',
	'Temporary disability': 'Incapacidade temporária',
	'Injuries with leave': 'Lesões/dano com afastamentos',
	'Severe with possibility of death or disability': 'Grave com possibilidade de morte ou incapacidade',
	'Superficial injuries': 'Lesões/dano superficiais',
	'Catastrophic with numerous deaths': 'Catastrófico com inúmeras mortes',

	//Probability
	Probability: 'Probabilidade',
	'Most likely and expected outcome': 'Resultado mais provável e esperado',
	'Extremely remote possibility': 'Extremamente remota a possibilidade',
	'Practically impossible, never happened': 'Praticamente impossível, nunca ocorreu',
	'Unlikely, but it has happened': 'Pouco provável, mas já ocorreu',
	'Possible outcome': 'Resultado possível',
	'Rare but possible': 'Raro, porém, possível ',

	//Exhibition
	'Remotely (never occurred)': 'Remotamente (nunca ocorreu)',
	'Rarely (occurs in short bursts without definition)': 'Raramente (ocorre em curtos espaços sem definição)',
	'Irregular (once a month to once a year)': 'Irregular (uma vez ao mês até uma vez ao ano)',
	'Occasionally (once a week to once a month)': 'Ocasionalmente (uma vez na semana até uma vez ao mês)',
	'Frequent (at least once a day)': 'Frequente (pelo menos uma vez ao dia)',
	'Continues (several times a day)': 'Contínua (várias vezes ao dia)',
	'Remotely (>90% green)': 'Remotamente (>90% verde)',
	'Rarely (80 to 89% green)': 'Raramente (80 a 89% verde)',
	'Irregular (50 to 79% green)': 'Irregular (50 a 79% verde)',
	'Occasionally (30 to 49% green)': 'Ocasionalmente (30 a 49% verde)',
	'Frequent (10 to 29% green)': 'Frequente (10 a 29% verde)',
	'Continues (<9% green)': 'Contínua (<9% verde)',

	//Steps erros
	'Select the injuries': 'Selecione uma ou muitas lesões/agravos',
	'Select the consequence': 'Selecione a Consequência',
	'Select the probability': 'Selecione a Probabilidade',
	'Select the exhibition': 'Selecione a Exposição',
	"Some required steps wasn't filled": 'Dados obrigatórios não preenchidos',

	//Step 1
	Score: 'Nota',
	Informations: 'Informações',
	'Select the sector': 'Selecione o setor',
	Analyst: 'Analista',
	'Insert the analyst': 'Insira um analista',
	Responsibility: 'Cargo/Função',
	'Insert the responsibility': 'Insira um cargo/função',
	'Insert the cell': 'Insira uma célula',
	'Insert the activity': 'Insira uma atividade',
	'Insert the workstation': 'Insira o posto de trabalho',
	'Enter the collection date': 'Insira a data de coleta',

	//Step 2
	'Work Conditions': 'Condições de trabalho',
	'Select the constraints perceived in the evaluated work condition':
		'Selecione as restrições percebidas na condição de trabalho avaliada',
	'Work schedule': 'Horário de trabalho',
	'Summary description of the place': 'Descrição sumária do local',
	'Simplified description of the expected task': 'Descrição simplificada da tarefa prevista',
	'Simplified description of the task performed': 'Descrição simplificada da tarefa realizada',

	//Step 3
	'Working population': 'População trabalhadora',
	'Working population percentages must sum up to 100%.':
		'As porcentagens da população trabalhadora devem somar 100%.',
	"Worker's verbalization": 'Verbalização dos trabalhadores',
	'Self-evaluation': 'Autoavaliação',
	'What is the level of stress perceived by the worker when performing the task?':
		'Qual é o nível de estresse percebido pelo trabalhador ao realizar a tarefa?',
	'Nothing stressful': 'Nada estressante',
	'Little stressful': 'Pouco estressante',
	'Working population must be greater than 0': 'A população de trabalhadores deve ser maior que 0',
	workers: 'trabalhadores',
	Stressful: 'Estressante',
	'Update the tool': 'Atualize a ferramenta',
	'Tool already set': 'Ferramenta já definida',
	'Tool not set yet': 'Ferramenta ainda não definida',
	'Very stressful': 'Muito estressante',
	'Extremely stressful': 'Extremamente estressante',

	//Step 4
	'Workday and concentration at work': 'Jornada e concentração no trabalho',
	'Working hours of more than 8 hours a day': 'Jornada de trabalho superior à 8h por dia',
	'Existence of long and frequent overtime hours of work':
		'Existência de longas e frequentes horas extras de trabalho',
	'Stays long time continuous operation': 'Permanece longo tempo de operação contínua',
	'Insufficient rest interval': 'Intervalo de descanso insuficiente',
	'Insufficient rest break': 'Intervalo de descanso insuficiente',
	'Insufficient rest days': 'Dias de descanso insuficientes',
	'Unbalanced concentrations of work in a day, week, month or year':
		'Concentrações desequilibradas de trabalho em um dia, semana, mês ou ano',
	'Unbalanced concentrations of work among workers': 'Concentrações desequilibradas de trabalho entre trabalhadores',
	'Insufficient rest between shifts (less than 11 hours)': 'Descanso insuficiente entre turnos (menos de 11 h)',
	'Remains long time continuous operation': 'Permanece longo tempo de operação contínua',

	//Step 5
	'Kind of work': 'Tipo de trabalho',
	'Lift and/or carry heavy objects': 'Levanta e/ou carrega objetos pesados',
	'Apply the tool': 'Aplique a ferramenta',
	'Load surveys': 'Carregar pesquisas',
	'Lifts and/or carries objects farther than 60 cm': 'Levanta e/ou carrega objetos mais distantes que 60 cm',
	'Performs tasks that require great strength': 'Realiza tarefas que requerem grande força',
	'Performs tasks that require pronounced pushing and pulling forces':
		'Realiza tarefas que exigem forças acentuadas para empurrar e puxar',
	'Performs repetitive work': 'Realiza trabalho repetitivo',
	'Performs work that requires frequent finger, hand, or arm movements':
		'Realiza trabalho que requer movimentos frequentes de dedo, mão ou braço',
	'Do intensive work with a keyboard or other input devices':
		'Realiza trabalho intensivo com um teclado ou outros dispositivos de entrada de dados',
	'Perform precision work': 'Realiza trabalho de precisão',
	'Performs activities with high visual requirements': 'Realiza atividades de requisitos visuais elevados',

	//Step 6
	'Postures and movements': 'Posturas e movimentos',
	'Stays in uncomfortable postures and movements': 'Permanece em posturas e movimentos desconfortáveis',
	'Performs continuous and/or highly frequent change in joint positions':
		'Realiza mudança contínua e/ou altamente frequente nas posições das articulações',
	'Stays in constrained posture for long duration': 'Permanece em postura restrita de longa duração',
	'Walk for long duration and/or long distance (on a horizontal surface as well as an inclined surface)':
		'Realiza caminhada de longa duração e/ou longa distância (em superfície horizontal bem como em uma superfície inclinada)',
	'Performs frequent stair climbing': 'Realiza subida de escada frequente',
	'Remains in a sedentary/standing position for a long time':
		'Permanece tempo prolongado em posição sedentária/de pé',
	'Postures requirements (Kinebot)': 'Exigências posturais (Kinebot)',

	//Step 7
	'Influence of workspace and task factors': 'Influência do espaço de trabalho e de fatores da tarefa',
	'Workspace is inadequate and forces an uncomfortable posture or limited movement':
		'O espaço de trabalho é inadequado e força uma postura desconfortável ou movimento limitado',
	'Workstation layout requires excessive movement or uncomfortable postures':
		'O leiaute da estação de trabalho exige movimento excessivo ou posturas desconfortáveis',
	'The heights, distances and dimensions of controls or work surfaces are inadequate':
		'As alturas, distâncias e dimensões dos comandos ou superfície de trabalho são inadequadas',
	'Anthropometry Table x Furniture (cm)': 'Tabela da Antropometria x Mobiliário (cm)',
	'Add information': 'Adicionar informações',
	'Found (cm)': 'Encontrado (cm)',
	'Foreseen (cm)': 'Previsto (cm)',
	'Enter a location': 'Qual a localização',
	'Enter what was found': 'Onde foi encontrado',
	'Insert predicted': 'Insira a previsão',
	'Performs handling of work objects above the shoulder or below the knee':
		'Realiza manuseio de objetos de trabalho acima do ombro ou abaixo do joelho',
	'The workspace forces the worker to maintain the same working posture':
		'O espaço de trabalho força o trabalhador a manter a mesma postura de trabalho',
	'Handles work objects that are difficult to handle and slippery':
		'Manuseia objetos de trabalho que sejam difíceis de manusear ou escorregadios',
	'Activity requires high-voltage contact or local pressure acting on the body':
		'Atividade exige alta-tensão de contato ou pressão local que age no corpo',

	//Step 8
	'Influence of psychosocial factors': 'Influência de fatores psicossociais',
	'Activity with mental overload or underload': 'Atividade com sobrecarga ou subcarga mental',
	'Work pace with time pressure and high demands': 'Ritmo de trabalho com pressão de tempo e altas demandas',
	'Presence of work-related stress': 'Presença estresse relacionado ao trabalho',
	'Reports of low job satisfaction': 'Relatos de baixa satisfação no trabalho',
	'Activity with lack of autonomy (low influence, low control)':
		'Atividade com falta de autonomia (baixa influência, controle baixo)',
	'Low social support': 'Baixo apoio social',

	//Step 9
	'Influence of environmental factors': 'Influência de fatores do meio ambiente',
	'Place with slippery and/or uneven floor': 'Local com piso escorregadio e/ou irregular',
	'There is exposure to whole body vibration (see ISO 2631)':
		'Existe exposição a vibração em todo o corpo (ver ISO 2631)',
	'Makes use of hand-arm vibration tools during work; (see ISO 5349)':
		'Faz uso de ferramentas de vibração mão-braço durante o trabalho) (ver ISO 5349)',
	'Performs work using vehicles that transmit vibration throughout the body':
		'Realiza trabalho usando veículos que transmitem vibração em todo o corpo',
	'Hot or cold working environment': 'Local ambiente de trabalho quente ou frio',
	'Location with background noise': 'Local com ruido de fundo ',

	//Step 10
	'Organizational influences': 'Influência organizacionais',
	'Production standards are not clear or understood by workers':
		'As normas de produção não são claras ou compreendidas pelos trabalhadores',
	'The operative mode descriptions are insufficient or wrong':
		'As descrições de modo operatório são insuficientes ou erradas',
	'The time to carry out the activities is inadequate': 'O tempo para realizar as atividades é inadequado',
	'The pace of work is accelerated': 'O ritmo de trabalho é acelerado',
	'The content of the tasks is different from the technical means available':
		'O conteúdo das tarefas é diferente dos meios técnicos disponíveis ',
	'Task with high cognitive demand': 'Tarefa com grande exigência cognitiva',

	// Results
	'Anthropometry Table': 'Tabela de antropometria',
	'Consolidate analysis': 'Consolidar análise',
	Deconsolidate: 'Desconsolidar',
	'Successfully consolidated analysis': 'Análise consolidada com sucesso',
	'Successfully deconsolidated analysis': 'Análise desconsolidada com sucesso',
	'Failed to generate the report': 'Falha ao gerar o relatório',
	'Failed to generate PDF': 'Falha ao gerar o PDF',

	'Cannot change a consolidated analysis': 'Não é possível alterar uma análise consolidada',
	'Comments successfully saved': 'Comentários salvos com sucesso',

	'Action plan deleted successfully': 'Plano de ação removido com sucesso',
	'Task created successfully': 'Tarefa criada com sucesso',
	'Task deleted successfully': 'Tarefa removida com sucesso',
	'Task updated successfully': 'Tarefa atualizada com sucesso',

	'Ops... something happened!': 'Ops... algo aconteceu!',
	'It was not possible to save your information': 'Não foi possível salvar suas informações',

	Hazardous: 'Perigoso',
	'Movement by score (Kinebot/REBA)': 'Movimento por score (Kinebot/REBA)',
	'Cargo Handling (NIOSH)': 'Manuseio de carga (NIOSH)',
	'Manual Handling (KIM)': 'Operações de manuseio manual (KIM MHO)',
	'Push and Pull (KIM)': 'Empurrar e puxar (KIM PP)',
	'No more action plans available': 'Não há mais planos de ação disponíveis',
	'No more workstations available': 'Não há mais postos de trabalho disponíveis',

	'No task available': 'Não há tarefas disponíveis',
	day: 'dia',
	days: 'dias',

	Location: 'Localização',
	Found: 'Encontrado',
	Foreseen: 'Previsto',
	Condition: 'Condição',

	'NOT OK': 'NÃO OK',
	"Score's sum": 'Somatório',

	// Do not change due to AEP key - Influences the translation of action plans created by AEP
	'Trunk posture from Kinebot': 'Postura do tronco da Kinebot',
	'Trunk posture from kinebot': 'Postura do tronco da kinebot',
	'Neck posture from Kinebot': 'Postura do pescoço da Kinebot',
	'Neck posture from kinebot': 'Postura do pescoço da kinebot',
	'Left elbow posture from Kinebot': 'Postura do cotovelo esquerdo da Kinebot',
	'Left elbow posture from kinebot': 'Postura do cotovelo esquerdo da kinebot',
	'Right elbow posture from Kinebot': 'Postura do cotovelo direito da Kinebot',
	'Right elbow posture from kinebot': 'Postura do cotovelo direito da kinebot',
	'Left shoulder posture from Kinebot': 'Postura do ombro esquerdo da Kinebot',
	'Left shoulder posture from kinebot': 'Postura do ombro esquerdo da kinebot',
	'Right shoulder posture from Kinebot': 'Postura do ombro direito da Kinebot',
	'Right shoulder posture from kinebot': 'Postura do ombro direito da kinebot',

	'Failed to upload the file': 'Falha ao realizar o upload do arquivo',

	// Two-factor authentication
	// API Errors
	'Two-factor authentication not found': 'Autenticação em dois fatores não encontrada',
	'Already created two-factor authentication': 'Autenticação em dois fatores já criada',
	'Invalid token': 'Token inválido',
	'Token already validated': 'Token já validado',
	'Token not yet validated': 'Token não validado ainda',
	'Token expired': 'Token expirado',
	'Too early to resend email': 'Muito cedo para reenviar o e-mail',
	'Something went wrong checking token': 'Algo deu errado ao verificar o token',
	'Authentication type same as default': 'Tipo de autenticação é o mesmo do atual',
	'Authentication type through app already enabled': 'Autenticação pelo aplicativo já habilitada',
	'Two-factor authentication not enabled': 'Autenticação em dois fatores não habilitada',
	'Too many tries!': 'Muitas tentativas!',
	'Too many calls! Wait a moment to try again.': 'Muitas chamadas. Espere um momento e tente novamente.',
	'You are not authorized to access this resource': 'Você não tem permissão para usar esse recurso',

	// Hooks success message
	'Two-factor authentication generated successfully': 'Autenticação em dois fatores gerada com sucesso',
	'Two-factor authentication validated successfully': 'Autenticação em dois fatores validada com sucesso',
	'Two-factor authentication method changed successfully':
		'Método de autenticação em dois fatores alterado com sucesso',
	'Token sent successfully': 'Token enviado com sucesso',
	'Token resent successfully': 'Token reenviado com sucesso',
	'Authenticator app enabled successfully': 'Aplicativo de autenticação habilitado com sucesso',
	'Two-factor authentication deleted successfully': 'Autenticação em dois fatores deletada com sucesso',
	'Two-factor authentication reset successfully': 'Autenticação em dois fatores recuperada com sucesso',
	'Forced two-factor authentication enabled successfully':
		'Forçar autenticação em dois fatores habilitado com sucesso',
	'Forced two-factor authentication disabled successfully':
		'Forçar autenticação em dois fatores desabilitado com sucesso',

	// My Account
	'Force two-factor authentication to all users': 'Forçar autenticação em dois fatores para todos os usuários',
	'When activated, all users will be forced to activate two-factor authentication on login.':
		'Quando ativado, todos os usuários serão obrigados a ativar a autenticação em dois fatores ao fazer login.',
	'Two-factor authentication': 'Dois fatores de autenticação',
	'When activated, you will have to type a validation code every time you login.':
		'Quando ativado, você terá que digitar um código de validação toda vez que fizer o login.',
	'You can choose to receive your code by email or connect your authentication app with a QRCode.':
		'Você pode optar por receber o código por email ou conectar seu aplicativo de autenticação com um código QR.',

	// Actions
	'Reset 2FA': 'Redefinir',
	'Deactivate 2FA': 'Desativar',
	'You are about to reset your authentication method.': 'Você está prestes a redefinir seu método de autenticação.',
	'Select a new desired authentication type and insert one of your recovery token to perform this operation.':
		'Selecione um novo método desejado e insira um dos tokens de recuperação para realizar essa ação.',
	'Authentication method': 'Método',
	'Recovery token': 'Token de recuperação',
	'Authenticator app': 'Aplicativo',
	'You are about to deactivate your 2FA.': 'Você está prestes a desativar sua autenticação em dois fatores.',
	'Confirming this action will delete your two-factor authentication. Are you sure?':
		'Confirmando essa ação, você irá desativar sua autenticação em dois fatores. Tem certeza disso?',
	'Heads up! Closing now will delete the token you generated. Want to continue?':
		'Atenção! Fechar agora implica em apagar o token que acabou de gerar. Quer continuar?',
	'Generate Two-factor authentication': 'Gerar autenticação em dois fatores',
	'Select a method to generate your two-factor authentication':
		'Selecione um método para sua autenticação em dois fatores',
	'With this method, you will scan a QRCode so you can use your favorite authenticator app (such as Google Authenticator or Microsoft Authenticator). Then, all you have to do is type the code shown in your app when you login. This code changes every few seconds.':
		'Com esse método, você irá escanear um código QR com seu aplicativo de autenticação preferido (como Google Authenticator ou Microsoft Authenticator). Então, tudo que tem a fazer é digitar o código que aparecer no seu aplicativo quando fizer o login. Esse código se altera a cada poucos segundos',
	'You are about to generate your 2FA with your authenticator app.':
		'Você está prestes a gerar sua autenticação em dois fatores pelo método de aplicativo.',
	'Be sure to have your mobile phone with you ready to scan your code.':
		'Certifique-se de ter seu telefone celular pronto para escanear seu código.',
	'Validation code': 'Código para validação',
	'Enter the 6 digit code.': 'Digite o código de 6 dígitos',
	'Recovery tokens': 'Códigos de recuperação',

	"Here are your recovery codes, which will be requested should you need to recover your account. Keep in mind that they will not be accessible again after this moment, so it's crucial that you save them securely.":
		'Aqui estão seus códigos de recuperação, que serão solicitados caso precise recuperar sua conta. Tenha em mente que eles não estarão mais acessíveis após este momento, então é crucial que os salve de maneira segura.',

	"Can't close yet. Read the instructions.": 'Não pode fechar essa janela ainda. Leia as instruções.',
	"Your company has asked for all users to keep two-factor authentication enabled for security reasons. You'll be asked to activate it again on your next login. Want to deactivate it anyway?":
		'Sua empresa solicitou que todos os usuários mantenham a autenticação de dois fatores habilitada por motivos de segurança. Você será solicitado a ativá-la novamente no próximo login. Deseja desativá-la mesmo assim?',

	//Login
	'For protection reasons, we need that you validate your 2FA':
		'Por razões de segurança, precisamos que faça sua validação em dois fatores',
	'Your company is requesting that all users must have two-factor authentication enabled. Continue to activate yours and login.':
		'Sua companhia está solicitando que todos os usuários tenham validação em dois fatores habilitado. Continue para ativar a sua e fazer o login',

	Change: 'Mudar',
	Security: 'Segurança',
	'Account security settings': 'Configurações de segurança da conta',
	'Account information summary': 'Resumo das informações da conta',
	'Password must be at least 8 characters long': 'A senha deve ter pelo menos 8 caracteres',
	'The password must have a maximum of 20 characters': 'A senha deve ter no máximo 20 caracteres',
	'The password field is required': 'O campo senha é obrigatório',
	'Password field cannot be empty': 'Campo de senha não pode estar vazio',
	'The password must contain capital letters': 'A senha deve conter letras maiúsculas',
	'The password must contain lowercase letters': 'A senha deve conter letras minúsculas',
	'Password must contain numbers': 'A senha deve conter números',
	'The password must contain at least one special character': 'A senha deve conter pelo menos um caractere especial',

	// Risks Manager
	Set: 'Definir',
	'Severity not set': 'Severidade não definida',
	'Manage risks': 'Gerenciar riscos',
	'Manage risk categories': 'Gerenciar categorias de risco',
	'Risk category': 'Categoria do risco',
	'Risk damage': 'Dano',
	'Risk category not found': 'Categoria não encontrada',
	'Risk description not found': 'Descrição não encontrada',
	'Risk damage not found': 'Dano não encontrado',
	'Select a risk damage': 'Selecionar dano',
	'Current severity': 'Severidade atual',
	'Severity set successfully': 'Severidade definida com sucesso',
	'Create new risk category': 'Criar nova categoria',
	'Update category name': 'Atualizar nome da categoria',
	'Delete category': 'Apagar categoria',
	'Category updated successfully': 'Categoria atualizada com sucesso',
	'Category deleted successfully': 'Categoria removida com sucesso',
	'Damage created successfully': 'Dano criado com sucesso',
	'Category created successfully': 'Categoria criada com sucesso',
	'Risk description created successfully': 'Descrição criada com sucesso',
	'Data created successfully': 'Dado criado com sucesso',
	'Data updated successfully': 'Dado atualizado com sucesso',
	'Data deleted successfully': 'Dado removido com sucesso',
	'Create new company': 'Cria nova empresa',
	'Company updated successfully': 'Empresa atualizada com sucesso',
	'Company deleted successfully': 'Empresa removida com sucesso',
	'All risk descriptions related to it will be deleted. Do you wish to proceed?':
		'Todas as descrições de riscos relacionadas a ela serão deletadas. Tem certeza que deseja prosseguir?',
	'Manage risk description': 'Gerenciar descrições de risco',
	'Select a risk description': 'Selecionar descrição do risco',
	'Want to delete this description?': 'Deseja excluir esta descrição?',
	'Want to delete this damage?': 'Deseja excluir este dano?',

	// B.E.R.A. Report
	Files: 'Arquivos',
	'Custom report': 'Relatório personalizado',
	'Custom reports': 'Relatórios customizados',
	'Custom reports files': 'Arquivos de relatórios personalizados',
	'Basic Ergonomics Risk Assessment (B.E.R.A.)': 'Avaliação Básica de Riscos Ergonômicos (B.E.R.A.)',
	'General informations': 'Informações gerais',
	'Insert below the informations for the cycle': 'Insira abaixo as informações para o ciclo',
	Organization: 'Organização',
	'Operator evaluated': 'Operador avaliado',
	'Enter a report name': 'Insira o nome do relatório',
	'Enter a evaluator': 'Insira o avaliador',
	'Work center': 'Centro de trabalho',
	Evaluator: 'Avaliador',
	'Select below the video files referring to the tasks that make up the cycle':
		'Selecione abaixo os arquivos de vídeo referentes às tarefas que compõem o ciclo',
	Task: 'Tarefa',
	'Insert below the informations related to the selected video':
		'Insira abaixo as informações relacionadas ao vídeo selecionado',
	'Task list ID (TLID)': 'ID da lista de tarefas (TLID)',
	'Task time': 'Tempo da tarefa',
	'Has known injury?': 'Existe algum histórico de lesão nessa tarefa?',
	'There is no presence of "Too High" movements': 'Não há presença de movimentos "Muito Alto"',
	'Too High': 'Muito Alto',
	'Stress level': 'Nível de estresse',
	'Job element': 'Elemento de trabalho',
	'Insert job element': 'Inserir elemento de trabalho',
	'Total task duration': 'Duração total da tarefa',
	'Select the task': 'Selecione a tarefa',
	'Insert a task duration': 'Insira a duração da tarefa',
	'Elbow to Shoulder - 1075 to 1425 mm (42.2 to 56 in)': 'Cotovelo ao Ombro - 1075 a 1425 mm (42,2 a 56 pol.)',
	'Shoulder to top of head  > 60 deg shoulder flex - 1425 to 1730 mm (56 to 68 in)':
		'Ombro até o topo da cabeça > flexão do ombro a 60 graus - 1425 a 1730 mm (56 a 68 pol.)',
	'Overhead work - >  1730 mm (> 68 in)': 'Trabalho acima da cabeça - > 1730 mm (> 68 pol.)',
	'1 per task': '1 por tarefa',
	'1 per minute': '1 por minuto',
	'2 per minute': '2 por minuto',
	'>2 per minute': '>2 por minuto',
	'<10% of Task': '<10% da tarefa',
	'<25% of Task': '<25% da tarefa',
	'<33% of Task': '<33% da tarefa',
	'<50% of Task': '<50% da tarefa',
	'>50% of Task': '>50% da tarefa',
	'Too Low': 'Muito Baixo',
	'There is no presence of "Too Low" movements': 'Não há presença de movimentos "Muito Baixo"',
	'Elbow to standing grip - 1075 to 800 mm (42.2 to 31.3 in)':
		'Cotovelo ao punho em pé - 1075 a 800 mm (42,2 a 31,3 pol.)',
	'Grip to knee - 800 to 550 mm (31.3 to 21.8 in)': 'Punho ao joelho - 800 a 550 mm (31,3 a 21,8 pol.)',
	'Below knee - < 550 mm (<21.8 in)': 'Abaixo do joelho - <550 mm (<21,8 pol.)',
	'Well Below Knee <255 mm (<10 in)': 'Bem abaixo do joelho <255 mm (<10 pol.)',
	'Too Far': 'Muito Longe',
	'There is no presence of "Too Far" movements': 'Não há presença de movimentos "Muito Longe"',
	'<300 mm (<12 inches) in front of body': '<300 mm (<12 polegadas) à frente do corpo',
	'300<460 mm (12<18 inches) in front of body': '300<460 mm (12<18 polegadas) à frente do corpo',
	'460<610 mm (18<24 inches) in front of body': '460<610 mm (18<24 polegadas) à frente do corpo',
	'610<915 mm (24<36 inches) in front of body': '610<915 mm (24<36 polegadas) à frente do corpo',
	'>915 mm (>36 inches) in front of body': '>915 mm (>36 polegadas) à frente do corpo',
	'Select the stress level': 'Selecionar o nível de estresse',
	'Select the frequency': 'Selecionar a frequência',
	'Select the total task duration': 'Selecionar a duração total da tarefa',
	'Too Many': 'Muitas Vezes',
	'There is no presence of "Too Many" movements': 'Não há presença de movimentos "Muitas vezes"',
	'Striking parts': 'Ajuste de peças',
	'Hand activities': 'Atividades manuais',
	'Small hammer (<.2 kg, <.3 lb)': 'Martelo pequeno (<0,2 kg, <0,3 lb)',
	'Mallet Use (< 1 kg, < 2.2 lb)': 'Uso do martelo de madeira (<1 kg, <2,2 lb)',
	'Using hand as a hammer': 'Usar a mão como martelo',
	'Hammer Use (< 1 kg, < 2.2 lb)': 'Uso do martelo (<1 kg, <2,2 lb)',
	'Sledge Hammer Use (> 2 kg, >4.5 lb)': 'Uso do martelo grande (>2 kg, >4,5 lb)',
	'Light tap 1/cycle': 'Toque leve 1/ciclo',
	'<10 per hour': '<10 por hora',
	'>10 per hour': '>10 por hora',
	'>20 per hour': '>20 por hora',
	'>30 per hour': '>30 por hora',
	'Populate using tool': 'Popular usando ferramenta',
	'Job Strain Index <3': 'Índice de Tensão do Trabalho <3',
	'Job Strain Index 3-5': 'Índice de Tensão do Trabalho 3-5',
	'Job Strain Index 5-7': 'Índice de Tensão do Trabalho 5-7',
	'Job Strain Index 7-12': 'Índice de Tensão do Trabalho 7-12',
	'Job Strain Index >12': 'Índice de Tensão do Trabalho >12',
	'Select at least one option.': 'Selecione pelo menos uma opção.',
	'Too Much': 'Muito Pesado',
	'There is no presence of "Too Much" movements': 'Não há presença de movimentos "Muito pesado"',
	Lifting: 'Levantamento',
	'Push/Pull One Hand': 'Empurrar/Puxar com uma mão',
	'Push/Pull Two Hand': 'Empurrar/Puxar com duas mãos',
	'Push/Pull Cart': 'Empurrar/Puxar um carrinho',
	'Tool Weight <2.3 kg (<5 lb)': 'Peso da ferramenta <2,3 kg (<5 lb)',
	'Tool Weight <4.5 kg (<10 lb)': 'Peso da ferramenta <4,5 kg (<10 lb)',
	'Tool Weight >4.5 kg (>10 lb)': 'Peso da ferramenta >4,5 kg (>10 lb)',
	'Tool Weight >7 kg (>15 lb)': 'Peso da ferramenta >7 kg (>15 lb)',
	'One hand force <3.6 kg (<8 lb)': 'Força com uma mão <3,6 kg (<8 lb)',
	'One hand force >3.6 kg (>8 lb)': 'Força com uma mão >3,6 kg (>8 lb)',
	'One hand force >6.8 kg (>15 lb)': 'Força com uma mão >6,8 kg (>15 lb)',
	'One hand force >9 kg (>20 lb)': 'Força com uma mão >9 kg (>20 lb)',
	'One hand force >11.3 kg (>25 lb)': 'Força com uma mão >11,3 kg (>25 lb)',
	'Two hand force <6 kg (<13 lb)': 'Força com duas mãos <6 kg (<13 lb)',
	'Two hand force >6 kg (>13 lb)': 'Força com duas mãos >6 kg (>13 lb)',
	'Two hand force >10 kg (>22 lb)': 'Força com duas mãos >10 kg (>22 lb)',
	'Two hand force >15 kg (>33 lb)': 'Força com duas mãos >15 kg (>33 lb)',
	'Two hand force >18 kg (>40 lb)': 'Força com duas mãos >18 kg (>40 lb)',
	'Cart Push/Pull Initial <23 kg (< 50lb)': 'Empurrar/Puxar carrinho Inicial <23 kg (< 50 lb)',
	'Cart Push/Pull Initial >23 kg (> 50lb)': 'Empurrar/Puxar carrinho Inicial >23 kg (> 50 lb)',
	'Cart Push/Pull Initial >29.5 kg (> 65lb)': 'Empurrar/Puxar carrinho Inicial >29,5 kg (> 65 lb)',
	'Cart Push/Pull Initial >34 kg (>75 lb)': 'Empurrar/Puxar carrinho Inicial >34 kg (>75 lb)',
	'No noticeable vibration': 'Sem vibração perceptível',
	'Slight hand vibration': 'Leve vibração nas mãos',
	'Noticeable vibration of hand': 'Vibração perceptível na mão',
	'Vibration in arm': 'Vibração no braço',
	'Too Long': 'Muito Longa',
	'There is no presence of "Too Long" movements': 'Não há presença de movimentos "Muito Longo"',
	'Inadequate Clearance': 'Espaço Insuficiente',
	'Contact Stress': 'Estresse de Contato',
	'Limited Hand Clearance': 'Espaço Limitado para as Mãos',
	'Limited Whole Body Clearance': 'Espaço Limitado para o Corpo Inteiro',
	'Limited Finger Clearance': 'Espaço Limitado para os Dedos',
	'Limited Arm Clearance': 'Espaço Limitado para os Braços',
	'Limited Hand Clearance with Part': 'Espaço Limitado para as Mãos com Peça',
	'Contact Stress <20 sec': 'Estresse de Contato <20 seg',
	'Contact Stress >20 sec': 'Estresse de Contato >20 seg',
	'Contact Stress >30 sec': 'Estresse de Contato >30 seg',
	Severity: 'Severidade',
	Vulnerability: 'Probabilidade',
	'Risk Priority Number (RPN)': 'Número de Prioridade de Risco (RPN)',
	'Collection Date': 'Data de Coleta',
	'Known injuries': 'Lesões conhecidas',
	'Has no injury history': 'Não possui histórico de lesões',
	'Has known injury': 'Possui lesão conhecida',
	'Last update': 'Última atualização',
	'Show reports': 'Mostrar relatórios',
	'Hide reports': 'Esconder',
	'Create B.E.R.A. report': 'Criar relatório B.E.R.A.',
	'Total evaluated time': 'Tempo total avaliado',
	'Weighted RSI': 'RSI Ponderado',
	'Weighted RPN': 'RPN Ponderado',
	'Tasks analyzed': 'Tarefas analisadas',
	'Cycle summary': 'Resumo do ciclo',
	'Risk Factors': 'Fatores de risco',
	'Highest RPN': 'Maior RPN',
	'RPN Score': 'RPN',
	Position: 'Posição',
	'Task RPN': 'RPN da Tarefa',
	'Greater than': 'Maior que',
	'Greater than or equal': 'Maior ou igual a',
	'Less than': 'Menor que',
	'Less than or equal': 'Menor ou igual a',
	Between: 'Entre',
	Maximum: 'Máximo',
	Minimum: 'Mínimo',
	'Search by file name...': 'Pesquisar pelo nome do arquivo...',
	'Select at least one file': 'Selecione um arquivo pelo menos',
	Seconds: 'Segundos',
	'Finish B.E.R.A.': 'Finalizar B.E.R.A.',
	'Consolidate report': 'Consolidar relatório',
	'Report updated successfully': 'Relatório atualizado com sucesso',
	'B.E.R.A. deleted successfully': 'B.E.R.A. removido com sucesso',
	'B.E.R.A. report deleted successfully': 'Relatório B.E.R.A. removido com sucesso',
	Uncertain: 'Incerto',
	'Some risk': 'Algum risco',
	Extreme: 'Extremo',

	// S.E.R.A. Report
	'Safety Ergonomic Risk Assessment (S.E.R.A.)': 'Avaliação de Riscos em Segurança e Ergonomia (S.E.R.A.)',
	'Ergonomic - Biomechanical': 'Ergonômicos - Biomecânicos',
	'Ergonomic - Furniture and equipment': 'Ergonômicos - Mobiliário e equipamentos',
	'Ergonomic - Organizational': 'Ergonômicos - Organizacionais',
	'Ergonomic - Environmental': 'Ergonômicos - Ambientais',
	'Ergonomic - Psychosocial and cognitive': 'Ergonômicos - Psicossociais e cognitivos',
	'Working in awkward or uncomfortable postures for long periods':
		'Trabalho em posturas incômodas ou pouco confortáveis por longos períodos',
	'Sitting posture for long periods': 'Postura sentada por longos períodos',
	'Standing posture for long periods': 'Postura de pé por longos períodos',
	'Frequent commuting on foot during the workday': 'Frequente deslocamento a pé durante a jornada de trabalho',
	'Work with intense physical exertion': 'Trabalho com esforço físico intenso',
	'Lifting and manual transport of loads or volumes': 'Levantamento e transporte manual de cargas ou volumes',
	'Frequent pulling/pushing of loads or packages': 'Frequente ação de puxar/empurrar cargas ou volumes',
	'Frequent execution of repetitive movements': 'Frequente execução de movimentos repetitivos',
	'Handling tools and/or heavy objects for long periods':
		'Manuseio de ferramentas e/ou objetos pesados por longos períodos',
	'Requiring frequent use of force, pressure, gripping, flexion, extension, or twisting of body segments':
		'Exigência de uso frequente de força, pressão, preensão, flexão, extensão ou torção dos segmentos corporais',
	'Compression of body parts by hard or cornered surfaces':
		'Compressão de partes do corpo por superfícies rígidas ou com quinas',
	'Requiring frequent spinal flexions': 'Exigência de flexões de coluna vertebral frequentes',
	'Frequent use of pedals': 'Uso intenso de pedais',
	'Frequent use of levers': 'Uso intenso de alavancas',
	'Demand for frequent lifting of upper limbs':
		'Exigência de elevação frequente de membros superiores acima do ombro',
	'Handling or moving loads and volumes without a handle or with a “poor handle”':
		'Manuseio ou movimentação de cargas e volumes sem pega ou com “pega pobre”',
	'Exposure to localized vibrations (hand-arm)': 'Exposição a vibrações localizadas (mão-braço)',
	'Frequent use of stairs': 'Uso frequente de escadas',
	'Intensive work with a keyboard or other input devices':
		'Trabalho intensivo com teclado ou outros dispositivos de entrada de dados',
	'Makeshift workstation': 'Posto de trabalho improvisado',
	'Furniture without regulation means of adjustment': 'Mobiliário sem meios de regulagem de ajuste',
	'Equipment and/or machines without regulation means or without conditions of use':
		'Equipamentos e/ou máquinas sem meios de regulagem de ajuste ou sem condições de uso',
	'Unplanned/Unadapted workstation for sitting position':
		'Posto de trabalho não planejado/adaptado para a posição sentada',
	'Inappropriate seat': 'Assento inadequado',
	'Improper or missing seat backrest': 'Encosto do assento inadequado ou ausente',
	'Furniture or equipment without space to move body segments':
		'Mobiliário ou equipamento sem espaço para movimentação de segmentos corporais',
	'Work with the need to reach objects, documents, controls or any point beyond the ideal reach zones for the anthropometric characteristics of the worker':
		'Trabalho com necessidade de alcançar objetos, documentos, controles ou qualquer ponto além das zonas de alcance ideais para as características antropométricas do trabalhador',
	"Equipment or furniture not adapted to the worker's anthropometry":
		'Equipamentos ou mobiliários não adaptados à antropometria do trabalhador',
	'Work performed without predefined rest breaks': 'Trabalho realizado sem pausas pré-definidas para descanso',
	'Need to maintain intense work rhythms': 'Necessidade de manter ritmos intensos de trabalho',
	'Work with need for shift variation': 'Trabalho com necessidade de variação de turnos',
	Monotony: 'Monotonia',
	'Night work': 'Trabalho noturno',
	'Insufficient training to perform the task': 'Insuficiência de capacitação para execução da tarefa',
	'Work with strict use of production targets': 'Trabalho com utilização rigorosa de metas de produção',
	'Work paid by production': 'Trabalho remunerado por produção',
	'Work cadence imposed by a piece of equipment': 'Cadência do trabalho imposta por um equipamento',
	'Imbalance between work time and rest time': 'Desequilíbrio entre tempo de trabalho e tempo de repouso',
	'Working conditions with sound pressure levels outside comfort parameters':
		'Condições de trabalho com níveis de pressão sonora fora dos parâmetros de conforto',
	'Working conditions with effective temperature index outside the comfort parameters':
		'Condições de trabalho com índice de temperatura efetiva fora dos parâmetros de conforto',
	'Working conditions with air speed outside comfort parameters':
		'Condições de trabalho com velocidade do ar fora dos parâmetros de conforto',
	'Working conditions with air humidity outside comfort parameters':
		'Condições de trabalho com umidade do ar fora dos parâmetros de conforto',
	'Working conditions with inadequate daytime lighting': 'Condições de trabalho com Iluminação diurna inadequada',
	'Working conditions with inadequate night lighting': 'Condições de trabalho com Iluminação noturna inadequada',
	'Presence of reflections on screens, panels, glass, monitors or any surface':
		'Presença de reflexos em telas, painéis, vidros, monitores ou qualquer superfície',
	'Slippery and/or uneven floor': 'Piso escorregadio e/ou irregular',
	'Excessive stressful situations': 'Excesso de situações de estresse',
	'Situations of mental work overload': 'Situações de sobrecarga de trabalho mental',
	'Requiring a high level of concentration, attention and memory':
		'Exigência de alto nível de concentração, atenção e memória',
	'Working in conditions of difficult communication': 'Trabalho em condições de difícil comunicação',
	'Excessive hierarchical conflicts at work': 'Excesso de conflitos hierárquicos no trabalho',
	'Excessive emotional/affective demands at work': 'Excesso de demandas emocionais/afetivas no trabalho',
	'Harassment of any nature at work': 'Assédio de qualquer natureza no trabalho',
	'Work with divergent demands (divergent orders, mutually incompatible goals, demand for quality X quantity, among others)':
		'Trabalho com demandas divergentes (ordens divergentes, metas incompatíveis entre si, exigência de qualidade X quantidade, entre outras)',
	'Requirement to perform multiple tasks, with high cognitive demand':
		'Exigência de realização de múltiplas tarefas, com alta demanda cognitiva',
	'Job dissatisfaction': 'Insatisfação no trabalho',
	'Lack of autonomy at work': 'Falta de autonomia no trabalho',
	'Accidents and physical discomforts': 'Acidentes e desconfortos físicos',
	'Low performance': 'Baixo desempenho',
	'Physical or mental exhaustion': 'Exaustão física ou mental',
	'Adoption of inappropriate postures and movements': 'Adoção de posturas e movimentos inadequados',
	'Alteration in the circadian cycle, sleep disorders': 'Alteração no ciclo circadiano, distúrbios do sono',
	'Low visibility': 'Baixa visibilidade',
	'Compression of structures (tissues, tendons, ligaments and nerves) causing musculoskeletal disorders':
		'Compressão de estruturas (tecidos, tendões, ligamentos e nervos) causando distúrbios osteomusculares',
	Discomfort: 'Desconforto',
	'Discomfort in lower limbs (legs and feet)': 'Desconforto em membros inferiores (pernas e pés)',
	'Discomfort in upper limbs (arms and hands)': 'Desconforto em membros superiores (braços e mãos)',
	'Discomfort in upper limbs and spine': 'Desconforto em membros superiores e coluna vertebral',
	'Discomfort in the spine': 'Desconforto na coluna vertebral',
	'Discomfort in the lower limbs and spine': 'Desconforto nos membros inferiores e na coluna vertebral',
	'Development of musculoskeletal disorders': 'Desenvolvimento de distúrbios osteomusculares',
	'Difficulty concentrating, irritability': 'Dificuldade de concentração, irritabilidade',
	'Balance disorders, headache': 'Distúrbios de equilíbrio, cefaleia',
	'Musculoskeletal disorders in the spine': 'Distúrbios osteomusculares na Coluna Vertebral',
	'Musculoskeletal disorders in the upper limbs (Repetitive Strain Injury)':
		'Distúrbios osteomusculares nos membros superiores (Lesão por Esforço Repetitivo)',
	'Musculoskeletal disorders': 'Distúrbios osteomusculares',
	'Mental exhaustion and psychiatric disorders': 'Exaustão mental e distúrbios psiquiátricos',
	'Fatigue in lower limbs': 'Fadiga em membros inferiores',
	'Muscle fatigue and development of musculoskeletal disorders':
		'Fadiga muscular e desenvolvimento de distúrbios osteomusculares',
	'Fatigue in the legs and development of vascular disorders':
		'Fadiga nas pernas e desenvolvimento de distúrbios vasculares',
	'Fatigue, risk of falls': 'Fadiga, risco de quedas',
	'Excessive caloric expenditure, development of musculoskeletal disorders':
		'Gasto calórico excessivo, desenvolvimento de distúrbios osteomusculares',
	'Irritation of structures (tendons, ligaments and nerves) causing musculoskeletal disorders':
		'Irritação de estruturas (tendões, ligamentos e nervos) causando distúrbios osteomusculares',
	'Low back pain': 'Lombalgias',
	'Visualization impairment': 'Prejuízo na visualização',
	'Reflections, glare': 'Reflexos, ofuscamentos',
	'Stumbles, falls': 'Tropeços, quedas',
	'Exposure to whole-body vibration below action level':
		'Exposição à vibração de corpo inteiro abaixo do nível de ação',
	'Exposure to whole-body vibration above the tolerance limit':
		'Exposição à vibração de corpo inteiro acima do limite de tolerância',
	'Exposure to localized vibrations (hand-arm) above the tolerance limit':
		'Exposição a vibrações localizadas (mão-braço) acima do limite de tolerância',
	'Exposure to whole-body vibration at action level': 'Exposição à vibração de corpo inteiro no nível de ação',
	'Exposure to localized vibrations (hand-arm) at action level':
		'Exposição a vibrações localizadas (mão-braço) no nível de ação',
	'Exposure to localized vibrations (hand-arm) below action level':
		'Exposição a vibrações localizadas (mão-braço) abaixo do nível de ação',
	'Musculoskeletal disorders in the upper limbs': 'Distúrbios osteomusculares nos membros superiores',
	'Physical discomforts': 'Desconfortos físicos',
	'Accidents, physical or mental exhaustion': 'Acidentes, exaustão física ou mental',
	'Accidents, low performance': 'Acidentes, baixo desempenho',
	'Mental exhaustion': 'Exaustão mental',
	'Discomfort in upper/lower limbs and spine': 'Desconforto nos membros inferiores/superiores e na coluna vertebral',
	'Mental/physical exhaustion and psychiatric disorders': 'Exaustão física/mental e distúrbios psiquiátricos',
	'Adoption of unnecessary and inappropriate postures and movements.':
		'Adoção de posturas e movimentos desnecessários e inadequados',
	'1% to 10% of the cycle': '1% a 10% do ciclo',
	'11% to 20% of the cycle': '11% a 20% do ciclo',
	'21% to 30% of the cycle': '21% a 30% do ciclo',
	'31% to 50% of the cycle': '31% a 50% do ciclo',
	'51% to 75% of the cycle': '51% a 75% do ciclo',
	'76% to 100% of the cycle': '76% a 100% do ciclo',
	'[1] 1% to 10% of the cycle': '[1] 1% a 10% do ciclo',
	'[2] 11% to 20% of the cycle': '[2] 11% a 20% do ciclo',
	'[3] 21% to 30% of the cycle': '[3] 21% a 30% do ciclo',
	'[4] 31% to 50% of the cycle': '[4] 31% a 50% do ciclo',
	'[5] 51% to 75% of the cycle': '[5] 51% a 75% do ciclo',
	'[6] 76% to 100% of the cycle': '[6] 76% a 100% do ciclo',
	'Muscle fatigue/Discomfort': 'Fadiga muscular/desconforto',
	'First aid': 'Primeiro socorro',
	'Recordable injury': 'Histórico de lesão',
	'Recordable with surgery or restrictions': 'Histórico com cirurgia ou restrições',
	'Lost time - Missed work due to injury and/or hospitalization':
		'Tempo perdido - Falta ao trabalho devido a lesão e/ou hospitalização',
	Fatality: 'Fatalidade',
	'[1] Muscle fatigue/Discomfort': '[1] Fadiga muscular/desconforto',
	'[2] First aid': '[2] Primeiro socorro',
	'[3] Recordable injury': '[3] Histórico de lesão',
	'[4] Recordable with surgery or restrictions': '[4] Histórico com cirurgia ou restrições',
	'[5] Lost time - Missed work due to injury and/or hospitalization':
		'[5] Tempo perdido - Falta ao trabalho devido a lesão e/ou hospitalização',
	'[6] Fatality': '[6] Fatalidade',
	'Unlikely (never happened and there is no possibility)': 'Improvável (nunca ocorreu e não existe possibilidade)',
	'Remote (there is a possibility of this occurring once during the useful life of the equipment)':
		'Remota (existe a possibilidade de ocorrer 1 vez durante a vida útil do equipamento)',
	'Occasional (there is a possibility of this occurring more than once during the useful life of the equipment)':
		'Ocasional (existe a possibilidade de ocorrer mais de 1 vez durante a vida útil do equipamento)',
	'Likely (there is already a record and there is the possibility of occurring 1 to 12 times a year)':
		'Provável (já há registro e existe a possibilidade de ocorrer 1 a 12 vezes no ano)',
	'Frequent (there is already a registration and there is the possibility of occurring more than once a month)':
		'Frequente (já há registro e existe a possibilidade de ocorrer mais de 1 vez ao mês)',
	'Certainly (there is already a record and there is the possibility of occurring daily)':
		'Certamente (já há registro e existe a possibilidade de ocorrer diariamente)',
	'[1] Unlikely (never happened and there is no possibility)':
		'[1] Improvável (nunca ocorreu e não existe possibilidade)',
	'[2] Remote (there is a possibility of this occurring once during the useful life of the equipment)':
		'[2] Remota (existe a possibilidade de ocorrer 1 vez durante a vida útil do equipamento)',
	'[3] Occasional (there is a possibility of this occurring more than once during the useful life of the equipment)':
		'[3] Ocasional (existe a possibilidade de ocorrer mais de 1 vez durante a vida útil do equipamento)',
	'[4] Likely (there is already a record and there is the possibility of occurring 1 to 12 times a year)':
		'[4] Provável (já há registro e existe a possibilidade de ocorrer 1 a 12 vezes no ano)',
	'[5] Frequent (there is already a registration and there is the possibility of occurring more than once a month)':
		'[5] Frequente (já há registro e existe a possibilidade de ocorrer mais de 1 vez ao mês)',
	'[6] Certainly (there is already a record and there is the possibility of occurring daily)':
		'[6] Certamente (já há registro e existe a possibilidade de ocorrer diariamente)',
	'[1] Unlikely': '[1] Improvável',
	'[2] Remote': '[2] Remota',
	'[3] Occasional': '[3] Ocasional',
	'[4] Likely': '[4] Provável',
	'[5] Frequent': '[5] Frequente',
	'[6] Certainly': '[6] Certamente',
	'Reports status': 'Status dos relatórios',
	Uncompleted: 'Incompleto',
	'Show only uncompleted reports': 'Mostrar apenas análises incompletas',
	'Show only completed reports': 'Mostrar apenas análises finalizadas',
	'Show only reviewed reports': 'Mostrar apenas análises revisadas',
	'Created date': 'Data de criação',
	'Reviewed date': 'Data de revisão',
	'Review each task below': 'Faça a análise de cada tarefa abaixo',
	'Add report': 'Adiciona relatório',
	Specifications: 'Especificações',
	Specification: 'Especificação',
	'Existing prevention measures': 'Medidas existentes para prevenção',
	Category: 'Categoria',
	'Select the category': 'Selecione a categoria',
	'Risk description': 'Descrição do perigo',
	'Total risk descriptions': 'Total de descrições de risco',
	'Select the description': 'Selecione o perigo',
	Damage: 'Dano',
	'Select the damage': 'Selecione o dano',
	'Select the evaluator': 'Selecione o avaliador',
	'Select the exposure': 'Selecione a exposição',
	'Select the vulnerability': 'Selecione a probabilidade',
	'Select the severity': 'Selecione a severidade',
	'Select a severity': 'Selecione a severidade',
	'How many times is the operator exposed to risk in the work cycle?':
		'Quantas vezes o operador está exposto ao risco no ciclo de trabalho?',
	'What is the probability of an incident happening?': 'Qual é a probabilidade de um incidente acontecer?',
	'How severe could the injury be?': 'Quão grave pode ser a lesão?',
	Edit: 'Editar',
	'Create review': 'Fazer revisão',
	'Cycle results': 'Resultados do ciclo',
	Version: 'Versão',
	Review: 'Revisão',
	'Result S.E.R.A.': 'Resultado S.E.R.A.',
	'Original version date': 'Data da versão original',
	'Last review': 'Última revisão',
	'Analyzed reports': 'Atividades avaliadas',
	'Reviewed reports': 'Atividades revisadas',
	'Sum of RPN': 'Soma de RPNs',
	'Original version sum of RPN': 'Soma de RPNs da versão original',
	'Average RPN': 'RPN médio',
	'Analyzed tasks': 'Atividades avaliadas',
	'Reviewed at': 'Avaliado em',
	'Review date': 'Data de revisão',
	'Task review': 'Revisão da tarefa',
	'Select tasks below to review': 'Selecione as tarefas abaixo para revisão',
	'RPN and Priority': 'RPN e prioridade',
	Priority: 'Prioridade',
	'Create S.E.R.A. report': 'Criar relatório S.E.R.A.',
	'Go back to files selection': 'Voltar para a seleção de arquivos',
	'Action plans list': 'Lista de planos de ação',
	'S.E.R.A. action plans': 'Plano de ação S.E.R.A.',
	'Add action plan': 'Criar plano de ação',
	'Action plan': 'Plano de ação',
	'General history': 'Histórico geral',
	'Tasks summary': 'Resumo das atividades',
	'Reviews history': 'Histórico de revisões',
	'Changed field': 'Campo alterado',
	'Original value': 'Valor original',
	'Reviewed value': 'Valor revisado',
	Reviewer: 'Revisor',
	Reviewed: 'Revisado',
	Hide: 'Esconder',
	Show: 'Mostrar',
	'Unlikely (never happened and there is no possibility) [1]':
		'Improvável (nunca ocorreu e não existe possibilidade) [1]',
	'Remote (there is a possibility of this occurring once during the useful life of the equipment) [2]':
		'Remota (existe a possibilidade de ocorrer 1 vez durante a vida útil do equipamento) [2]',
	'Occasional (there is a possibility of this occurring more than once during the useful life of the equipment) [3]':
		'Ocasional (existe a possibilidade de ocorrer mais de 1 vez durante a vida útil do equipamento) [3]',
	'Likely (there is already a record and there is the possibility of occurring 1 to 12 times a year) [4]':
		'Provável (já há registro e existe a possibilidade de ocorrer 1 a 12 vezes no ano) [4]',
	'Frequent (there is already a registration and there is the possibility of occurring more than once a month) [5]':
		'Frequente (já há registro e existe a possibilidade de ocorrer mais de 1 vez ao mês) [5]',
	'Certainly (there is already a record and there is the possibility of occurring daily) [6]':
		'Certamente (já há registro e existe a possibilidade de ocorrer diariamente) [6]',
	Choose: 'Escolher',
	'Next file': 'Próximo arquivo',
	'No fields were changed': 'Nenhum campo alterado',
	'S.E.R.A. deleted successfully': 'S.E.R.A. removido com sucesso',

	// Liberty Mutual
	'Go back to report list': 'Voltar para a lista de relatórios',
	'Material Handling': 'Manuseio de Material',
	'Material handling': 'Manuseio de material',
	'Invalid request data': 'Dados inválidos para requisição',
	'Liberty Mutual report created successfully!': 'Relatório Liberty Mutual criado com sucesso',
	'Liberty Mutual report updated successfully!': 'Relatório Liberty Mutual atualizado com sucesso',
	'Liberty Mutual task not found': 'Atividade do Liberty Mutual não encontrada',
	'Return to task list': 'Retornar a lista de atividades',
	'End hand height lower than start hand height. Should it be Lower task?':
		"Altura final da mão menor que altura inicial. Deveria ser a tarefa 'Abaixar'?",
	'End hand height higher than start hand height. Should it be Lift task?':
		"Altura final da mão maior que altura inicial. Deveria ser a tarefa 'Levantar'?",
	'System of units not found': 'Sistema de unidades não encontrado',
	'Cannot be lower than start height': 'Não pode ser menor que altura inicial',
	'Cannot be higher than start height': 'Não pode ser maior que altura inicial',
	Movements: 'Movimentos',
	'Individually evaluate the movements present in the task':
		'Avalie individualmente os movimentos presentes na tarefa',
	'Liberty mutual report created successfully!': 'Relatório Liberty mutual criado com sucesso',
	'Liberty mutual report updated successfully!': 'Relatório Liberty mutual atualizado com sucesso',
	'Liberty mutual task not found': 'Atividade do Liberty mutual não encontrada',
	Lift: 'Levantar',
	Lifts: 'Levantamentos',
	lift: 'levantamento',
	lifts: 'levantamentos',
	Lower: 'Abaixar',
	Lowers: 'Abaixamentos',
	lowers: 'abaixamentos',
	lower: 'abaixamento',
	Push: 'Empurrar',
	Pushes: 'Empurrações',
	pushes: 'empurrações',
	push: 'empurração',
	Pull: 'Puxar',
	Pulls: 'Puxadas',
	pulls: 'puxadas',
	pull: 'puxada',
	Carry: 'Carregar',
	Carries: 'Carregamentos',
	carries: 'carregamentos',
	carry: 'carregamento',
	'System of unit': 'Sistema de unidades',
	'Select the system of unit': 'Selecione um sistema de unidades',
	'Select a system of unit': 'Selecione um sistema de unidades',
	'Select the movement of the task being evaluated': 'Selecione o movimento da tarefa sendo avaliada',
	'Insert the fields data for the selected task': 'Insira os dados dos campos para a atividade selecionada',
	'Start hand height': 'Altura inicial da mão',
	'End hand height': 'Altura final da mão',
	'Start hand distance': 'Distância inicial da mão',
	'End hand distance': 'Distância final da mão',
	'Initial force': 'Força inicial',
	'Sustained force': 'Força contínua',
	'Horizontal distance': 'Distância horizontal',
	'Vertical hand height': 'Altura vertical da mão',
	'Hand coupling': 'Pega',
	'Object weight': 'Peso do objeto',
	Weight: 'Peso',
	'Select an hand coupling': 'Selecione uma qualidade de pega',
	'Insert a start height': 'Insira uma altura inicial',
	'Insert an end height': 'Insira uma altura final',
	'Insert a start distance': 'Insira uma distância inicial',
	'Insert an end distance': 'Insira uma distância final',
	'Insert an object weight': 'Insira o peso do objeto',
	'Insert a frequency value': 'Insira a frequência',
	'Select a frequency interval': 'Insira o intervalo da frequência',
	'Insert an initial force': 'Insira uma força inicial',
	'Insert a sustained force': 'Insira uma força contínua',
	Initial: 'Inicial',
	Sustain: 'Contínua',
	'Insert an horizontal distance': 'Insira uma distância horizontal',
	'Insert a vertical hand height': 'Insira uma altura vertical da mão',
	'Select a task': 'Selecione uma atividade',
	'per hour': 'por hora',
	hour: 'hora',
	'per minute': 'por minuto',
	per: 'por',
	'Video duration': 'Duração',
	'Assessment date': 'Data de avaliação',
	'Activity is safe for': 'Atividade é segura para',
	'Initial state': 'Estado inicial',
	'End state': 'Estado final',
	Displacement: 'Deslocamento',
	Metric: 'Métrico',
	Imperial: 'Imperial',
	'Data entered for Start hand height and End hand height indicates that the task type is Lift, not Lower':
		'Valores inseridos para alturas inicial e final da mão indicam que a atividade é do tipo Levantar e não Abaixar',
	'Data entered for Start hand height and End hand height indicates that the task type is Lower, not Lift':
		'Valores inseridos para alturas inicial e final da mão indicam que a atividade é do tipo Abaixar e não Levantar',

	// API error messages
	'Internal Server Error': 'Erro interno do servidor',
	'You are unauthorized to access the requested resource': 'Você não está autorizado a acessar o recurso solicitado',
	'Check the header parameters entered': 'Verifique os parâmetros de cabeçalho inseridos',
	'Range risk not found': 'Faixa de risco não encontrada',
	'Failed to fetch risk tracks': 'Falha ao buscar faixas de risco',
	'Task not found': 'Tarefa não encontrada',
	'Task already exists': 'A tarefa já existe',
	'Cycle not found': 'Ciclo não encontrado',
	'Cycle already exists': 'O ciclo já existe',
	'Stress level not found': 'Nível de estresse não encontrado',
	'Frequency not found': 'Frequência não encontrada',
	'Total task duration not found': 'Duração total da tarefa não encontrada',
	'Evaluator not found': 'Avaliador não encontrado',
	'Exposure not found': 'Exposição não encontrada',
	'Vulnerability not found': 'Vulnerabilidade não encontrada',
	'Severity not found': 'Severidade não encontrada',
	'Severity already set to this risk damage': 'A severidade já foi definida para esse dano',
	'Custom report step key not found': 'Chave da etapa do relatório personalizado não encontrada',
	'Custom report step not found': 'Etapa do relatório personalizado não encontrada',
	'Custom report not found': 'Relatório personalizado não encontrado',
	'Bera step key result not found': 'Resultado da chave da etapa de Bera não encontrado',
	'Bera step key result already exists': 'O resultado da chave de etapa do Bera já existe',
	'Bera weighted average not found': 'Média ponderada do Bera não encontrada',
	'Bera weighted average already exists': 'A média ponderada do Bera já existe',
	'Bera report not found': 'Relatório Bera não encontrado',
	'Bera report already exists': 'O relatório Bera já existe',
	'Bera job summary not found': 'Bera job summary não encontrado',
	'Bera job summary result not found': 'Resultado do resumo do trabalho de Bera não encontrado',
	'Bera job summary already exists': 'Bera job summary already exists',
	'Sera report not found': 'Relatório Sera não encontrado',
	'Sera report already exists': 'O relatório do Sera já existe',
	'Sera summary not found': 'Sera summary não encontrado',
	'Sera summary already exists': 'O resumo do Sera já existe',
	'Sera summary review not found': 'Sera summary review não encontrado',
	'Sera summary review already exists': 'Sera summary review already exists',
	'Sector not found': 'Setor não encontrado',
	'Report data not found': 'Dados do relatório não encontrados',
	'File not found': 'Arquivo não encontrado',
	'Failed to read file': 'Falha ao ler o arquivo',
	'Failed to save file': 'Falha ao salvar o arquivo',
	'Master user not found': 'Usuário mestre não encontrado',
	'Potential customer not found': 'Cliente em potencial não encontrado',
	'Voucher not found': 'Voucher não encontrado',
	'Invalid voucher': 'Voucher inválido',
	'Invalid recurrence': 'Recorrência inválida',
	'Plan not found': 'Plano não encontrado',
	'Plan already canceled': 'Plano já cancelado',
	'Invalid subscription type': 'Tipo de assinatura inválido',
	'It is not possible to downgrade the annual plan': 'Não é possível fazer downgrade do plano anual',
	'Failed to decode payload': 'Falha ao decodificar a carga útil',
	'Plan expiration date not found': 'Data de expiração do plano não encontrada',
	'Document already registered': 'Documento já registrado',
	'Failed to create user plan': 'Falha ao criar plano de usuário',
	'Maximum registered users': 'Máximo de usuários registrados',
	'Failed to create user': 'Falha ao criar usuário',
	'User already registered': 'Usuário já registrado',
	'Organization not found': 'Empresa não encontrada',
	'Company not found': 'Planta industrial não encontrada',
	'Failed to create company': 'Falha ao criar a empresa',
	'Failed to create a user permission': 'Falha ao criar uma permissão de usuário',
	'Failed to send email': 'Falha ao enviar e-mail',
	'Failed to create storage bucket': 'Falha ao criar um bucket de armazenamento',
	'Failed to upload file to storage': 'Falha ao fazer upload de arquivo para o armazenamento',
	'Failed to download data': 'Falha ao fazer download de dados',
	'Failed to download file': 'Falha ao fazer dowload do arquivo',
	'Failed to delete file': 'Falha ao excluir arquivo',
	'Fail get information storage': 'Falha ao obter informações de armazenamento',
	'Failed to store data': 'Falha ao armazenar dados',
	'File sent for download': 'Arquivo enviado para download',
	'Reply queue communication failure': 'Falha na comunicação da fila de resposta',
	'Selected report not found': 'Relatório selecionado não encontrado',
	'Unauthorized requisition': 'Requisição não autorizada',
	'Authorization header is missing': 'O cabeçalho de autorização está faltando',
	'Failed to query the database': 'Falha ao consultar o banco de dados',
	'Calculation per second not found': 'Cálculo por segundo não encontrado',
	'Business information not found': 'Informações comerciais não encontradas',
	'Worker self evaluation not found': 'Autoavaliação do trabalhador não encontrada',
	'Action plan not found': 'Plano de ação não encontrado',
	'Failed to create an history': 'Falha ao criar um histórico',
	'Already created action plan': 'Plano de ação já criado',
	'Action plan task not found': 'Tarefa do plano de ação não encontrada',
	'Action plan history not found': 'Histórico do plano de ação não encontrado',
	'Preliminary analysis not found': 'Análise preliminar não encontrada',
	'Default step not found': 'Etapa padrão não encontrada',
	'Analysis step not found': 'Etapa de análise não encontrada',
	'Probabilty analysis not found': 'Análise de probabilidade não encontrada',
	'Consequence analysis not found': 'Análise de consequências não encontrada',
	'Exhibition analysis not found': 'Análise de exposição não encontrada',
	'Organization still has registered companies': 'A organização ainda tem empresas registradas',
	'Failed to create an organization': 'Falha ao criar uma empresa',
	'Workstation not found': 'Posto de trabalho não encontrado',
	'Custom report result not found': 'Resultado de relatório personalizado não encontrado',
	'Already created custom report result': 'Resultado de relatório personalizado já criado',
	'Custom report step key result not found': 'Resultado da chave da etapa do relatório personalizado não encontrado',
	'Already created custom report step key result': 'Resultado da chave da etapa do relatório personalizado já criado',
	'Failed to create custom report step key result':
		'Falha ao criar o resultado da chave da etapa do relatório personalizado',
	'Failed to update custom report step key result':
		'Falha ao atualizar o resultado da chave da etapa do relatório personalizado',
	'Custom report step key additional item result not found':
		'Resultado do item adicional da chave da etapa do relatório personalizado não encontrado',
	'Failed to create custom report step key additional item result':
		'Falha ao criar o resultado do item adicional da chave da etapa do relatório personalizado',
	'Failed to update custom report step key additional item result':
		'Falha ao atualizar o resultado do item adicional da chave da etapa do relatório personalizado',
	'Work condition result not found': 'Resultado da condição de trabalho não encontrado',
	'Already created work condition result': 'Resultado da condição de trabalho já criado',
	'Characteristic result not found': 'Resultado da característica não encontrado',
	'Already created characteristic result': 'Resultado da característica já criado',
	'Custom report sub step key not found': 'Chave da subetapa do relatório personalizado não encontrada',
	'Corrupted video': 'Vídeo corrompido',
	'Failed to read sent file': 'Falha ao ler o arquivo enviado',
	'Failed to create download url': 'Falha ao criar url de download',
	'File already queued': 'Arquivo já está em fila',
	'File already processed': 'Arquivo já processado',
	'Already registered user': 'Usuário já registrado',
	'Failed to create customer plan': 'Falha ao criar o plano do cliente',
	'Failed to create customer transaction': 'Falha ao criar a transação do cliente',
	'Customer transaction not found': 'Transação do cliente não encontrada',
	'Customer transaction already completed': 'Transação do cliente já concluída',
	'Product not found': 'Produto não encontrado',
	'Failed to create customer credits': 'Falha ao criar créditos de cliente',
	'Processed minutes limit reached': 'Limite de minutos processados atingido',
	'Invalid event': 'Evento inválido',
	'Not authorized': 'Não autorizado',
	'Customer not found': 'Cliente não encontrado',
	'Unregistered customer': 'Cliente não registrado',
	'Already registered customer': 'Cliente já registrado',
	'Failed to create customer': 'Falha ao criar o cliente',
	'Failed to update customer': 'Falha ao atualizar o cliente',
	'Report not found': 'Relatório não encontrado',
	'Already created report': 'Relatório já criado',
	'Failed to create report': 'Falha ao criar o relatório',
	'Failed to create report pdf': 'Falha ao criar relatório em pdf',
	'Failed to update report': 'Falha ao atualizar o relatório',
	'Custom report additional item option not found':
		'Opção de item adicional dO relatório personalizado não encontrada',
	'Error calculating custom report additional item result':
		'Erro ao calcular o resultado do item adicional do relatório personalizado',
	'Line not found': 'Linha não encontrada',
	'Failed to create file signature': 'Falha ao criar assinatura de arquivo',

	// Force Two-Factor
	'For security reasons, we need you to enable your two-factor validation':
		'Por razões de segurança, precisamos que ative sua validação em dois fatores',
	'Your company is requesting that all users have two-factor authentication enabled. Proceed to activate yours and login.':
		'Sua companhia está solicitando que todos os usuários tenham a autenticação em dois fatores habilitada. Continue para ativar a sua e fazer login.',
	Return: 'Voltar',

	// Integrations
	'Continue with Microsoft SSO': 'Continue com Microsoft SSO',
	Integrations: 'Integrações',
	'Built-in user groups': 'Grupos de usuários integrados',
	'Connect your Microsoft Azure AD users': 'Conecte seus usuários do Microsoft Azure AD',
	'Invitation sent': 'Convite enviado',
	'Microsoft Azure Active Directory - Users': 'Microsoft Azure Active Directory - Usuários',
	'Failed to load user groups': 'Falha ao carregar grupos de usuários',
	'User removed successfully!': 'Usuário removido com sucesso!',
	'Failed to remove user': 'Falha ao remover usuário',
	'Do you want to remove the user': 'Você deseja remover o usuário',
	'Failed to update user': 'Falha ao atualizar usuário',
	'Failed to load information': 'Falha ao carregar as informações',
	'Investigate and implement change': 'Investigar e implementar mudanças',
	'Implement changes': 'Implementar mudanças',
	Carriage: 'Carrinho de transporte',
	Barrow: 'Carrinho de mão',
	Conveyor: 'Transportador',
	Crane: 'Guindaste',

	//EWA-JDS-D86
	'Ergonomic workplace analysis': 'Avaliação Ergonômica Preliminar',
	'Select the right model for your analysis': 'Selecione o modelo adequado para sua avaliação',
	'Brazilian standard': 'Norma brasileira',
	'Brazilian standard and John Deere guidelines': 'Norma brasileira e diretrizes John Deere',
	Unit: 'Unidade',
	'Evaluator name': 'Avaliador',
	Interviewer: 'Entrevistador',
	'Interviewee name': 'Entrevistado',
	'Select the restrictions perceived in the evaluated working condition':
		'Selecione as restrições percebidas na condição de trabalho avaliada',
	'Summary description of the site': 'Descrição sumária do local',
	'Simplified description of the planned task': 'Descrição simplificada da tarefa prevista',
	'Particularities / Production / Working regime': 'Particularidades / Produção / Regime de trabalho',
	'Select the Work schedule hours': 'Selecione o horário de trabalho',
	'Select the Sector': 'Selecione o setor',
	'Select the Unit': 'Selecione a unidade',
	'Select the Line': 'Selecione a linha',
	'Select the Collection date': 'Selecione a data de coleta',
	'Select the Evaluator name': 'Selecione o nome do avaliador',
	'Workday and concentration': 'Jornada e concentração',
	'Risk Index:': 'Índice de Risco:',
	'Check the tool for conducting risk assessments': 'Consulte a ferramenta para avaliação do risco',
	'Wrench length': 'Comprimento da chave',
	'Sum of RPNs': 'Soma dos RPNs',
	'Higher RPNs': 'Maiores RPNs',
	'Found at:': 'Encontrado em:',
	'Interviewer name': 'Entrevistador',
	'The movement is considered safe for': 'O movimento é considerado seguro para ',
	'of man': 'dos homens',
	'of woman': 'das mulheres',
	Movement: 'Movimento',
	'Movement by score (Kinebot/JDS)': 'Movimento por Score (Kinebot/JDS)',
	'Tool type': 'Tipo de ferramenta',
	'Movement type': 'Tipo de movimento',
	Dimension: 'Dimensão',
	'Range zone': 'Zona de alcance',
	'Command range': 'Alcance dos comandos',
	'Target condition': 'Condição-alvo',
	'Conditionally acceptable': 'Aceitável condicionalmente',
	'Actions must be implemented': 'Ações devem ser implementadas',
	'Impact frequency': 'Frequência do impacto',
	'Evaluation necessary': 'Avaliação necessária',
	'Evaluation not necessary': 'Avaliação não necessária',
	'Select the items you want to present in your document':
		'Selecione os itens que deseja apresentar em seu documento',
	'Criteria not evaluated': 'Critérios não avaliados',
	'Generate document': 'Gerar documento',
	'Analysis not consolidated!': 'Avaliação não consolidada!',
	'If you continue, the filled-in data will be saved but not taken into account in your dashboard':
		'Caso prossiga, os dados preenchidos serão salvos, mas não considerados em sua dashboard',
	Proceed: 'Prosseguir',
	'Material Handling (Liberty Mutual)': 'Manuseio de material (Liberty Mutual)',
	Tools: 'Ferramentas',
	Workday: 'Jornada e concentração',
	'Influence of workspace': 'Influência do espaço',
	'Influence of environment': 'Fatores do meio ambiente',
	"The risk assessment recommendation is based on the indicators in the JDS-92 guidelines. The color pattern follows the same pattern present in the document's tables.":
		'A recomendação de avaliação de risco é baseada nos indicadores presentes nas diretrizes da JDS-92. O padrão de cores segue o mesmo padrão presente em tabelas do documento.',
	"The risk assessment recommendation is based on the indicators in the JDS-86 guidelines. The color pattern follows the same pattern present in the document's tables.":
		'A recomendação de avaliação de risco é baseada nos indicadores presentes nas diretrizes da JDS-86. O padrão de cores segue o mesmo padrão presente em tabelas do documento.',
	'Not evaluated': 'Não avaliado',
	'Use of one-handed manual wrench': 'Uso de chave manual com uma mão',
	'Use of one-handed manual wrench with backup wrench': 'Uso de chave manual com uma mão com uso de chave auxiliar',
	'Use of two-handed manual wrench': 'Uso de chave manual com duas mãos',
	'Using power tools': 'Uso de ferramentas elétricas',
	'Performs work that requires frequent finger, hand or arm movements':
		'Realiza trabalho que requer movimentos frequentes de dedo, mão e braço',
	'Using an impact device': 'Uso de dispositivo de impacto',
	'Viewing area': 'Área de visualização',
	'Postural demands (JDS-D86)': 'Exigências posturais (JDS-D86)',
	'Workstation involves seated work (fixed height)': 'A estação de trabalho envolve trabalhar sentado (altura fixa)',
	'Workstation involves seated work (adjustable station)':
		'A estação de trabalho envolve trabalhar sentado (estação ajustável)',
	'Distances from controls or work surface are inadequate':
		'As distâncias dos comandos ou superfície de trabalho são inadequadas',
	'Workspace dimensions are too narrow': 'As dimensões dos espaços de trabalho são muito estreitas',
	'The dimensions and clearances of the controls or work surface with the hands are inadequate':
		'As dimensões e folgas dos comandos ou superfície de trabalho com as mãos são inadequadas',
	'Grip and hand strength': 'Força de aperto com as mãos',
	'Standing arm strength forces': 'Força de mão e braços',
	'Whole body vibration exposure': 'Exposição a vibração em todo o corpo',
	'Hand-arm vibration exposure for 8 hours': 'Exposição por 8 horas a vibração na mão e braço',
	'Hand-arm vibration exposure': 'Exposição à vibração na mão e braço',
	'Contact with very hot or very cold surfaces': 'Contato com superfície muito quente ou muito fria',
	'Exposure to hot or cold surfaces': 'Exposição a superficie quente ou fria',
	'Hammer Use (<.25 kg, <.5 lb)': 'Uso de martelo (<0,25 kg, <0.5 lb)',
	'Heavy mallet or hammer use (>1 kg, >2.2 lb)': 'Uso de martelo (>1 kg, >2.2 lb)',
	'Using hand for striking part': 'Uso da mão para bater na peça',
	'Visual tasks >=10º above the horizontal line of the eye': 'Tarefas visuais ≥10º acima da linha horizontal do olho',
	'Visual tasks 0º to 10º above the horizontal line of the eye':
		'Tarefas visuais 0º a 10º acima da linha horizontal do olho',
	'Visual tasks 0º to 45º below the horizontal line of the eye':
		'Tarefas visuais 0º a 45º abaixo da linha horizontal do olho',
	'Visual tasks 45º to 60º below the horizontal line of the eye':
		'Tarefas visuais 45º a 60º abaixo da linha horizontal do olho',
	'Visual tasks >=60º below the horizontal line of the eye':
		'Tarefas visuais ≥60º abaixo da linha horizontal do olho',
	'Workstation height': 'Altura da estação de trabalho',
	'Precision task height': 'Altura da tarefa de precisão',
	'Writing and light assembly task height': 'Altura para escrever e tarefa de montagem leve',
	'Horizontal knee (depth) clearance': 'Folga horizontal para joelhos (profundidade)',
	'Horizontal knee width clearance': 'Folga horizontal para largura do joelho',
	Horizontally: 'Horizontalmente',
	'Vertically for standing workstations': 'Verticalmente em pé',
	'Vertically for seated workstations': 'Verticalmente sentado',
	'Whole Body': 'Corpo inteiro',
	'Head (least favorable position)': 'Cabeça (posição menos favorável)',
	Leg: 'Perna',
	Foot: 'Pé',
	Arm: 'Braço',
	'Hand, Wrist, Fist': 'Mão, pulso, punho',
	Finger: 'Dedo',
	'One finger': 'Um dedo',
	'Two fingers': 'Dois dedos',
	'Empty hand, flat': 'Mão vazia e plana',
	'Empty hand, thumb tucked': 'Mão vazia, polegar dobrado',
	'Clenched hand, fist': 'Mão fechada, punho',
	'Hand plus 25 mm (1 in) object': 'Mão mais objeto de 25 mm (1 in)',
	'Hand plus X mm (in) object to wrist': 'Mão mais objeto de X mm (in) até o pulso',
	'Arm to elbow': 'Braço até o cotovelo',
	'Arm to Shoulder': 'Braço até o ombro',
	'Both Arms to Shoulder': 'Ambos os braços até o ombro',
	'Power grip force': 'Segurar com força',
	'Power grip press': 'Pressionar com força',
	'Pinch grip': 'Aperto como pinça',
	'Lateral pinch (key grip)': 'Pinça lateral (encaixe principal)',
	'Tip pinch grip': 'Aperto como pinça na ponta',
	'Hook grip': 'Aperto em gancho',
	'Push with index finger': 'Empurrar com dedo indicador',
	'Push with thumb': 'Empurrar com o dedão',
	'Wrist flexion or extension torque': 'Flexão do pulso ou torque de extensão',
	'Ulnar/Radial deviation': 'Desvio ulnar/radial',
	Screwdriving: 'Aparafusamento',
	'Push-Pull 1 hand (knee to shoulder)': 'Empurrar-puxar com 1 mão (joelho ao ombro)',
	'Push-Pull 1 hand (below knee or above shoulder)': 'Empurrar-puxar com 1 mão (abaixo do joelho ou acima do ombro)',
	'Up-Down 1 hand (knee to shoulder)': 'Para cima-para baixo com 1 mão (joelho ao ombro)',
	'Up-Down 1 hand (below knee or above shoulder)':
		'Para cima-para baixo com 1 mão (abaixo do joelho ou acima do ombro)',
	'Push-Pull 2 hands (knee to shoulder)': 'Empurrar-puxar com 2 mãos (joelho ao ombro)',
	'Push-Pull 2 hands (below knee or above shoulder)':
		'Empurrar-puxar com 2 mãos (abaixo do joelho ou acima do ombro)',
	'Up-Down 2 hands (knee to shoulder)': 'Para cima-para baixo com 2 mãos (joelho ao ombro)',
	'Up-Down 2 hands (below knee or above shoulder)':
		'Para cima-para baixo com 2 mãos (abaixo do joelho ou acima do ombro)',
	'Lateral force 2 hands (between knee and shoulder)': 'Força lateral com as 2 mãos (entre o joelho e o ombro)',
	'Lateral force 2 hands (above shoulder or below knee)':
		'Força lateral com as 2 mãos (acima do ombro ou abaixo do joelho)',
	'Vertical movement': 'Movimento vertical',
	'Lateral and longitudinal movement': 'Movimento lateral e longitudinal',
	'Hand-arm Vibration A(eq) for 8-hr exposure': 'Vibração de mão-braço A(eq) por 8 horas de exposição',
	'Without gloves': 'Sem uso de luvas',
	'Assembly gloves': 'Luvas de montagem',
	'Welding gloves': 'Luvas de soldagem',
	'Viewing area in relation to the horizontal line at eye level':
		'Área de visualização em relação a linha horizontal da altura do olho',
	Height: 'Altura',
	Width: 'Largura',
	Diameter: 'Diâmetro',
	'Object dimension': 'Dimensão do objeto',
	'Reach zone': 'Zona de alcance',
	'Body part': 'Parte do corpo',
	'Minimum gap': 'Vão mínimo',
	Criterion: 'Critério',
	"Some required step wasn't filled": 'Alguma etapa obrigatória não foi preenchida',
	Unlikely: 'Improvável',
	Remote: 'Remota',
	Occasional: 'Ocasional',
	Likely: 'Provável',
	Frequent: 'Frequente',
	Certainly: 'Certamente',
	'Muscle fatigue/discomfort or near-miss accident': 'Fadiga muscular/desconforto ou quase acidente',
	'No absence': 'Sem ausência',
	'No absence with surgery or restriction': 'Nenhuma ausência com cirurgia ou restrição',
	'Absence - loss of work capacity and/or hospitalization':
		'Ausência - perda da capacidade de trabalho e/ou hospitalização',
	'>20 strikes per hour': '>20 batidas por hora',
	'<20 strikes per hour': '<20 batidas por hora',
	'>40 strikes per hour': '>40 batidas por hora',
	'1 strike per minute': '1 batida por minuto',
	'<10 strikes per hour': '<10 batidas por hora',
	'2 strikes per minute': '2 batidas por minuto',
	'< 2 light taps for alignment': '<2 batidas leves para alinhar',
	'The action of pulling/pushing loads or volumes with great effort':
		'Realiza tarefas que exigem forças acentuadas para empurrar e puxar',
	'Vision-guided tasks outside the primary viewing area (0° to 45°)':
		'Tarefas orientadas pela visão fora da área de visão primária (0° a 45°)',
	'Standing for long periods': 'Postura de pé por longos períodos',
	'Sitting for long periods (fixed height)': 'A estação de trabalho envolve trabalhar sentado (altura fixa)',
	'Sitting for long periods (adjustable station)':
		'A estação de trabalho envolve trabalhar sentado (estação ajustável)',
	'Exposure to whole-body vibration': 'Exposição à vibração de corpo inteiro',
	'Musculoskeletal strain on wrists and elbows': 'Tensão musculoesquelética nos pulsos e cotovelos',
	'Development of musculoskeletal disorders and/or eye fatigue':
		'Desenvolvimento de distúrbios musculoesqueléticos e/ou fadiga ocular',

	// JDS D86
	'Ergonomic evaluation': 'Avaliação ergonômica',
	'Frequent motions': 'Movimentos frequentes',
	'Excessive reaches': 'Alcances excessivos',
	'Lifting of heavy components and tools': 'Levantamento de componentes e ferramentas pesados',
	'Handling of bulky parts': 'Manuseio de peças volumosas',
	'Heavy exertions to install parts': 'Grande esforço para instalar as peças',
	'High forces to pull or push carts, baskets and containers':
		'Força extrema para puxar ou empurrar carrinhos, cestas e recipientes',
	'Contact stress': 'Tensão por contato',
	'Sharp edges are present': 'Há presença de cantos vivos',
	'The noise level is very high': 'O nível de ruído é muito alto',
	'Poor access or inadequate clearances': 'Difícil acesso ou folgas inadequadas',
	'Awkward or static postures': 'Posturas inadequadas',
	'Staying in a static posture for a long time': 'Permanece tempo prolongado em postura estática',
	'Video selection': 'Seleção de video',
	'Search for a file': 'Procure um arquivo',
	Revaluations: 'Reavaliações',
	'Last revaluation': 'Ultima reavalição',
	'New revaluation': 'Nova reavalição',
	Revaluation: 'Reavaliação',
	'General History': 'Histórico Geral',
	Crouching: 'Agachado',
	Kneeling: 'Ajoelhado',
	Standing: 'Em pé',
	'Time per episode': 'Tempo por episódio',
	'Frequency per hour': 'Frequência por hora',
	'Total time (in one day)': 'Tempo total (em um dia)',
	'Are you sure you want to delete the last revaluation': 'Tem certeza que deseja excluir a última reavalição',
	'Are you sure you want to delete the review': 'Tem certeza que deseja excluir a análise',
	'Delete report': 'Excluir relatório',
	'Delete the last revaluation': 'Excluir a última reavaliação',
	'JDS D86 last revaluation deleted successfully': 'JDS D86 reavalição excluída com sucesso',
	'Delete review': 'Excluir revisão',
	'You can delete the entire report or just the last revaluation':
		'Você pode excluir o relatório inteiro ou apenas a reavalição mais recente',
	'JDS D86 deleted successfully': 'JDS D86 excluído com sucesso',
	'Select the video file below for the task you want to analyze':
		'Selecione abaixo o arquivo de vídeo referente à tarefas que deseja analisar',
	Evaluation: 'Avaliação',
	'Generating a document': 'Gerando um documento',
	'The created document is being generated and will be downloaded':
		'O documento criado está sendo gerado e será baixado',
	'Noise level': 'Nível de ruído',
	'Select the Report name': 'Selecione o nome do relatório',

	//D92
	'Lifting tasks': 'Levantamento',
	'Push, pull and carry': 'Empurrar, puxar e carregar',
	'Handles work objects above the shoulder or below the knee':
		'Realiza manuseio de objetos de trabalho acima do ombro ou abaixo do joelho',
	'Shoulder moment calculator': 'Calculadora de momento para ombro',
	'Manual lifting (NIOSH)': 'Levantamento de cargas (NIOSH)',
	'Manual handling (KIM MHO)': 'Manuseio manual (KIM MHO)',
	'Lever Arm': 'Braço de alavanca',
	'Type of Task': 'Tipo de tarefa',
	'Material handling (Liberty Mutual)': 'Manuseio de material (Liberty Mutual)',
	'Use of manually operated carts': 'Uso de carrinhos movimentados manualmente',
	'Is the manual force to lift the car out of inertia less than 22kg?':
		'A força manual para retirar o carro da inércia é menor do que 22kg?',
	'Is the sustained hand force less than 11.5kg?': 'A força manual sustentada é menor do que 11,5kg?',
	'Is the diameter to fit the fingers larger than 30mm and smaller than 50mm?':
		'O diâmetro para encaixe dos dedos é maior do que 30mm e menor do que 50mm?',
	'Is the vertical handle between 900mm and 1225mm?': 'A alça vertical está entre 900mm e 1225mm?',
	'Is the width from one handle to the other larger than 400mm and smaller than 600mm?':
		'A largura de uma pega à outra é maior do que 400mm e menor do que 600mm?',
	'Is the caster in good condition?': 'O rodízio está em boa condição?',
	'Is the road in good condition?': 'O trajeto percorrido está em boa condição?',
	'Is the layout in good condition for moving the car, with enough space for maneuvering?':
		'O layout está em boa condição para movimentação do carro com espaços suficientes para manobras?',
	'Is the floor in good condition?': 'O piso está em boa condição?',
	'Handling load': 'Manuseio de carga',
	'Horizontal push or pull': 'Empurrar ou puxar horizontal',
	'Push or pull downward': 'Empurrar ou puxar para baixo',
	'Distance from the acromion to the center of the load (greatest distance)':
		'Distância do acrômio ao centro da carga (maior distância)',
	'Repetitions in a working day': 'Repetições em um dia de trabalho',
	'Apply when moving a part with a mass greater than 4.5 kg with one hand':
		'Aplicar quando há movimentação  de uma peça de massa maior que 4,5kg com uma mão',
	'Apply when moving a piece with a mass greater than 9kg with two hands in up to two steps':
		'Aplicar quando há movimentação  de uma peça de massa maior que 9kg com duas mãos em até dois passos',
	'Apply when there is movement in a critical scenario (e.g. bottom of a container)':
		'Aplicar quando há movimentação  em um cenário crítico (ex: fundo de um container)',
	'per work day': 'por dia',
	'Probability of Shoulder Outcome': 'Probabilidade de consequência para ombro',
	'The task should serve 75% of the female population': 'A tarefa deve atender 75% da população feminina',

	//  Back compressive force estimation
	'Back compressive force estimation': 'Estimativa da força de compressão lombar',
	"Person's weight": 'Peso da pessoa',
	'Person weight': 'Peso da pessoa',
	'Insert the person weight': 'Insira o peso da pessoa',
	"Person's height": 'Altura da pessoa',
	'Person height': 'Altura da pessoa',
	'Insert the person height': 'Insira a altura da pessoa',
	'Trunk angle to Horizontal': 'Ângulo do tronco em relação a horizontal',
	'Trunk angle to horizontal': 'Ângulo do tronco em relação a horizontal',
	'Insert a trunk angle': 'Insira o ângulo do tronco',
	'Load in hands': 'Carga sendo manuseada',
	'Insert the load in hands': 'Insira a carga sendo manuseada',
	'Horizontal distance from hands to low back': 'Distância horizontal das mãos à região lombar',
	'Insert a horizontal distance': 'Insira a distância horizontal',
	'Total compressive force': 'Força Compressiva Total',
	'Back compressive force estimation report updated successfully':
		'Estimativa de força de compressão foi atualizada com sucesso',
	'Creation date': 'Data de criação',
	"Enter the operator's activity-related data below": 'Insira abaixo os dados relacionados à atividade do operador',
	Dimensions: 'Dimensões',
	'Condition found': 'Condição encontrada',
	'Select the video files for the operations that make up the workstation below':
		'Selecione abaixo o arquivo de vídeo referente à tarefas que deseja analisar',
	'Are you sure you want to delete the evaluation': 'Tem certeza que deseja excluir a avalição',
	'Document generated!': 'Documento gerado!',
	'Work conditions': 'Condições de trabalho',
	'Influence of organizational factors': 'Influência organizacionais',
	'Activity created successfully': 'Atividade criada com sucesso',
	'Select EWA': 'Seleção de AEP',
	'Unable to load data': 'Não foi possível carregar os dados',

	// New Dashboard
	'No data available.': 'Não há dados.',
	'This page was not found.': 'Essa página não foi encontrada.',
	'Try Again': 'Tentar novamente',
	'Start date must be before end date': 'Data inicial deve ser antes da final',
	'End date must be after start date': 'Data final deve ser após a inicial',
	'Number of reports': 'Número de relatórios',
	'Unable to load custom reports': 'Não foi possível carregar os relatórios personalizados',
	'Actions created and resolved': 'Ações criadas e resolvidas',
	'Actions created': 'Ações criadas',
	'Delayed actions': 'Ações atrasadas',
	Late: 'Atrasados',
	'Not started': 'Não iniciadas',
	Total: 'Total',
	'Number of actions': 'Quantidade de ações',
	Variation: 'Variação',
	'Risk evolution': 'Evolução do risco',
	'Main risk category': 'Principal categoria de risco',
	"Sum of RPN of factors '6 too'": "Soma de RPN dos fatores '6 too'",
	'Too Many Times': 'Muitas Vezes',
	'Too Heavy': 'Muito Pesado',
	'Risk comparison between': 'Comparativo de risco entre',
	files: 'arquivos',
	workstations: 'postos de trabalho',
	lines: 'linhas',
	sectors: 'setores',
	'industrial sites': 'plantas industriais',
	'Comparison of': 'Comparação de',
	'with variation': 'com variação',
	'Percentile by gender': 'Percentil por gênero',
	'Male percentile': 'Percentil masculino',
	'Female percentile': 'Percentil feminino',
	'Reports generated': 'Relatórios gerados',
	'General overview': 'Visão geral',
	'Ergonomic Risk Management': 'Gestão de Riscos Ergonômicos',
	'Ergonomics Assessments Tools Status': 'Status das Ferramentas de Avaliação Ergonômica',
	'John Deere Enterprise Ergonomics Assessments Tools Status':
		'Status das Ferramentas de Avaliação Ergonômica da John Deere',
	'Checklist reports generated': 'Relatórios de checklist gerados',
	'Action plan status': 'Status dos planos de ações',
	'Start date': 'Data inicial',
	'End date': 'Data final',
	'Predetermined time frame': 'Espaço de tempo pré-determinado',
	Week: 'Semana',
	Months: 'Meses',
	'Highest incidence': 'Categoria de maior',
	category: 'incidência',
	'Granularity of information': 'Granularidade das informações',
	'Change date for all': 'Alterar data para todos',
	'Single period': 'Período único',
	'Compound period (comparative)': 'Período composto (comparativo)',
	'Period 1': 'Período 1',
	'Period 2': 'Período 2',
	Biomechanical: 'Biomecânico',
	quarter: 'trimestre',
	semester: 'semestre',
	Feb: 'Fev',
	Apr: 'Abr',
	May: 'Maio',
	Aug: 'Ago',
	Sep: 'Set',
	Oct: 'Out',
	Dec: 'Dez',
	to: 'até',
	'Slightly high': 'Ligeiramente elevado',
	'Substantially high': 'Substancialmente elevado',
	Torso: 'Tronco',
	'Exposure score of all body parts': 'Score de exposição de todos os seguimentos do corpo',
	'Exposure by body side': 'Exposição por lado do corpo',
	'Left side': 'Lado esquerdo',
	'Right side': 'Lado direito',
	'Investigate and implement changes': 'Investigar e implementar mudanças',
	'PEA (NR17)': 'AEP (NR17)',
	'PEA (NR17 + JDS D86)': 'AEP (NR17 + JDS D86)',
	Today: 'Hoje',
	'This week': 'Esta semana',
	'This month': 'Este mês',
	'This quarter': 'Este trimestre',
	'This semester': 'Este semestre',
	'This year': 'Este ano',
	'Last 7 days': 'Últimos 7 dias',
	'Last 30 days': 'Últimos 30 dias',
	'Last 3 months': 'Últimos 3 meses',
	'Last 6 months': 'Últimos 6 meses',
	'Last 12 months': 'Últimos 12 meses',
	'From the beginning': 'Desde o início',
	'Fiscal year (FY)': 'Ano fiscal (FY)',

	'Evidence removed': 'Evidência removida',
	'Attachment removed': 'Anexo removido',
	'Evidence updated successfully': 'Evidência atualizada com sucesso',
	'Attachment updated successfully': 'Anexo atualizado com sucesso',
	'Action plan attachment not found': 'Anexo do plano de ação não encontrado',
	'Action plan evidence not found': 'Evidência do plano de ação não encontrada',
	'Task removed successfully': 'Tarefa removida com sucesso',
	'Action plan updated': 'Plano de ação atualizado',
	'Selected action plans deleted successfully': 'Planos de ações selecionados excluídos com sucesso',
	'Failed to deleted selected action plans': 'Erro ao excluir planos de ações selecionados',
	'Drag or select the files you want to attach to your action plan':
		'Arraste ou selecione os arquivos que você deseja anexar ao seu plano de ação',
	'Are you sure you want to remove the attachment': 'Tem certeza que deseja remover o anexo',
	'Add description': 'Adicionar descrição',
	'File description': 'Descrição do arquivo',
	'Upload author': 'Autor de upload',
	'Attach file': 'Anexar arquivo',
	'Attach evidences': 'Anexar evidencias',
	'New action plan': 'Novo plano de ação',
	Sort: 'Ordenação',
	Board: 'Quadro',
	Table: 'Tabela',
	Notifications: 'Notificações',
	Responsable: 'Responsável',
	'Select the responsible': 'Selecione o responsável',
	'Create an action plan': 'Criar um plano de ação',
	'Action plan name': 'Nome do plano de ação',
	'Alphabetical A-Z': 'Alfabética A-Z',
	'Alphabetical Z-A': 'Alfabética Z-A',
	'Most recent': 'Mais recente',
	'Least recent': 'Mais antigo',
	'Nearest due date': 'Vencimento próximo',
	'Furthest due date': 'Vencimento distante',
	'Highest priority': 'Maior prioridade',
	'Lowest priority': 'Menor prioridade',
	'Highest investment': 'Maior investimento',
	'Lowest investiment': 'Menor investimento',
	'Attach new file': 'Anexar novo arquivo',
	'Action plan deleted': 'Plano de ação deletado',
	'Description changed': 'Descrição alterada',
	'Title changed': 'Título alterado',
	'Responsible changed': 'Responsável alterado',
	'Due date changed': 'Data de vencimento alterada',
	'Priority changed': 'Prioridade alterada',
	'Investment changed': 'Investimento alterado',
	'Attachment added': 'Anexo adicionado',
	'Evidence added': 'Evidência adicionada',
	'Task changed': 'Tarefa alterada',
	'Task removed': 'Tarefa removida',
	'Task added': 'Tarefa adicionada',
	'Task completed': 'Tarefa concluída',
	'Task uncompleted': 'Tarefa desconcluída',
	'Linked tool': 'Ferramenta vinculada',
	'Unlinked tool': 'Ferramenta desvinculada',
	'Comment added': 'Comentário adicionado',
	'Comment removed': 'Comentário removido',
	'Comment changed': 'Comentário alterado',
	'Error fetching responsible options': 'Erro ao buscar opções de responsável',
	'Are you sure you want to delete the selected items?': 'Tem certeza que deseja excluir os itens selecionados?',
	'Please select a priority': 'Selecione uma prioridade',
	'Please select a date': 'Selecione uma data',
	'Please select a responsible': 'Selecione um responsável',
	Evidences: 'Evidências',
	'Notification Control': 'Controle de notificações',
	'Follow up action plans created by me': 'Acompanhar planos de ação criados por mim',
	'Receive an e-mail when the status of the action plan changes':
		'Receber e-mail quando o status do plano de ação for alterado',
	'Receive e-mail when tasks are completed': 'Receber e-mail quando tarefas forem concluídas',
	'Follow up on action plans assigned to me': 'Acompanhar planos de ação atribuídos a mim',
	'Receive an e-mail when a new action plan is assigned to me':
		'Receber e-mail quando um novo plano de ação for atribuído a mim',
	'Receive an e-mail when the due date is near': 'Receber e-mail quando a data de vencimento estiver próxima',
	Unknown: 'Desconhecido',
	'Select the due date': 'Selecione a data de vencimento',
	By: 'Por',
	'Investment value': 'Valor de investimento',
	'EWA JDS-D86': 'AEP + JDS-D86'
};

export default dictionary;
