import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';

interface GetCommentsParams {
	organization_id: string;
	company_id: string;
	action_plan_id: string;
	limit: number;
	offset: number;
}

export const getComments = (params: GetCommentsParams) => {
	return Service.getComments(params);
};

export const useGetCommentsActionPlan = (params: GetCommentsParams) => {
	return useInfiniteQuery(
		[QUERY_KEYS.GET_ACTION_PLAN_COMMENTS, params],
		async ({ pageParam = 0 }) => {
			const queryParams = {
				...params,
				offset: pageParam
			};
			const response = await Service.getComments(queryParams);
			return response;
		},
		{
			enabled: !!params.organization_id && !!params.company_id,
			getNextPageParam: (lastPage, allPages) => {
				const currentOffset = allPages.length * params.limit;
				return currentOffset < lastPage.total ? currentOffset : undefined;
			}
		}
	);
};
