import { message } from 'antd';
import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Response } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';

type TaskStatus = {
	id: string;
	is_completed: boolean;
};

interface UpdateTaskRequest {
	organization_id: string;
	action_plan_task_id: string;
	company_id: string;
}

interface UpdateTaskResponse {
	status: string;
	message: string;
	data?: TaskStatus;
}

export const useUpdateActionPlanTaskStatus = () => {
	const queryClient = useQueryClient();

	return useMutation<UpdateTaskResponse, AxiosError<Response>, UpdateTaskRequest>(
		(params) => Service.updateTaskStatus(params),
		{
			onError: (err) => {
				message.error(I18n.get(err?.response?.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_TASKS]);
			}
		}
	);
};
