import styled from 'styled-components';

interface RiskLevelIndicatorProps {
	$color: string;
}

export const RiskLevelIndicator = styled.div<RiskLevelIndicatorProps>`
	width: 1rem;
	height: 1rem;
	border-radius: 6px;
	border: 1px solid black;
	background-color: ${({ $color }) => $color};
	background-image: ${({ $color }) =>
		$color.toLowerCase() === '#ffffff' || $color.toLowerCase() === 'white'
			? `repeating-linear-gradient(
          45deg,
          transparent,
          transparent 2px,
          gray 2px,
          gray 4px
        )`
			: 'none'};
`;
