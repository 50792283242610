import { Mapper } from './base-mapper';
import { ActionPlanUpdateComments, ActionPlanUpdateCommentsDomain } from '../domain/action-plan-comments-update';
import { ActionPlanUpdateCommentsDTO, ActionPlanUpdateCommentsItemDTO } from '../dto/action-plan-comments.update';

export class ActionPlanUpdateCommentsMapper implements Mapper<ActionPlanUpdateCommentsDomain> {
	private readonly response: ActionPlanUpdateCommentsDTO;

	constructor(response: ActionPlanUpdateCommentsDTO) {
		this.response = {
			status: response.status,
			message: response.message,
			data: Array.isArray(response.data) ? response.data : []
		};
	}

	private mapActionPlanItem(item: ActionPlanUpdateCommentsItemDTO): ActionPlanUpdateComments {
		return new ActionPlanUpdateComments({
			id: item.id
		});
	}

	toDomain(): ActionPlanUpdateCommentsDomain {
		return {
			status: this.response.message,
			message: this.response.message,
			data: this.response.data.map((item) => this.mapActionPlanItem(item))
		};
	}
}
