import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
	.ant-modal-content {
		border-radius: 30px;
		.title-modal {
			font-size: 35px;
			text-align: center;
		}
	}
`;
