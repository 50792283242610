import { Mapper } from './base-mapper';
import { UploadFileItem } from '../domain/attachments';
import { UploadedFileDTO } from '../dto/action-plan-attachment';

export class ActionPlanAttachmentsMapper extends Mapper<UploadFileItem> {
	private readonly data: UploadedFileDTO[];

	constructor(data: UploadedFileDTO[]) {
		super();
		this.data = data;
	}

	toTransform(value: UploadedFileDTO): UploadFileItem {
		return new UploadFileItem({
			uid: value.id,
			url: value.url,
			status: 'done',
			size: value.size,
			preview: value.url,
			name: value.file_name,
			type:
				value.file_name.endsWith('.png') ||
				value.file_name.endsWith('.jpg') ||
				value.file_name.endsWith('.jpeg')
					? 'image'
					: 'file',
			author: value.user.name,
			created_at: value.created_at,
			description: value.description
		});
	}

	toDomain(): UploadFileItem[] {
		return this.data.map(this.toTransform.bind(this));
	}
}
