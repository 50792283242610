import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '@/infra/services/company';
import { CompanyDTO } from '@/core/dto/company';

export interface GetCompanyOptionsParams {
	organization_id: string;
	company_id: string;
	searching_organization_id?: string;
}

const transformCompanyOptions = (data: CompanyDTO[]) => {
	return data.map((company) => ({ value: company.id, label: company.name }));
};

export const useGetCompanyOptions = (params: GetCompanyOptionsParams) => {
	return useQuery([QUERY_KEYS.GET_COMPANIES, params], () => Service.listOptions(params), {
		enabled: !!params.organization_id,
		select: transformCompanyOptions
	});
};
