import React from 'react';
import { Row, Col, Switch, SwitchProps } from 'antd';
import styled from 'styled-components';

type SwitchWrapperProps = {
	label?: string;
	labelBold?: boolean;
} & SwitchProps;

interface LabelProps {
	$size?: 'default' | 'small';
	$labelBold?: boolean;
}

export const Label = styled.p<LabelProps>`
	margin: 0;
	text-decoration-skip-ink: none;
	text-underline-position: from-font;
	${(props) => props.$labelBold && `font-weight: 500;`}
	font-size: ${({ $size }) => ($size === 'small' ? '1rem' : '1.1rem')};
`;

export function SwitchWrapper({ label = '', labelBold = false, size, ...props }: Readonly<SwitchWrapperProps>) {
	return (
		<Row justify="start" gutter={[10, 0]}>
			<Col span={3} style={{ textAlign: 'center' }}>
				<Switch {...props} />
			</Col>
			<Col span={21}>
				<Label $size={size} $labelBold={labelBold}>
					{label}
				</Label>
			</Col>
		</Row>
	);
}
