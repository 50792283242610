import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Title } from '@/components/Typography';
import * as S from './styles';

export function Header() {
	return (
		<Row style={{ padding: '0 0.5rem' }}>
			<Col span={4} offset={12}>
				<Row>
					<S.CenteredCol span={24}>
						<Title level={5} style={{ margin: 0 }}>
							RPN
						</Title>
					</S.CenteredCol>
					<S.CenteredCol span={24}>
						<span>1° {I18n.get('Period')}</span>
					</S.CenteredCol>
				</Row>
			</Col>
			<Col span={4}>
				<Row>
					<S.CenteredCol span={24}>
						<Title level={5} style={{ margin: 0 }}>
							RPN
						</Title>
					</S.CenteredCol>
					<S.CenteredCol span={24}>
						<span>2° {I18n.get('Period')}</span>
					</S.CenteredCol>
				</Row>
			</Col>
			<S.CenteredCol span={4}>
				<Title level={5} style={{ margin: 0 }}>
					Variation
				</Title>
			</S.CenteredCol>
		</Row>
	);
}
