import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/sera';
import { SeraMainRisk } from '@/core/domain/sera-main-risk';

interface GetSeraMainRiskParams {
	organization_id: string;
	workstation_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	start_date?: Date;
	end_date?: Date;
}

export const useGetSeraMainRisk = (params: GetSeraMainRiskParams) => {
	return useQuery<SeraMainRisk, Error>(
		[QUERY_KEYS.GET_SERA_MAIN_RISK, params],
		() => Service.getIncidenceCategories(params),
		{
			enabled: !!params.organization_id
		}
	);
};
