interface BeraGenderNeutralStationAttributes {
	report: {
		total: number;
		label: string;
		data: number[];
	};
	labels: string[][];
}

export class BeraGenderNeutralStation {
	public readonly labels: string[][];
	public readonly report: {
		total: number;
		label: string;
		data: number[];
	};

	constructor({ report, labels }: BeraGenderNeutralStationAttributes) {
		this.report = report;
		this.labels = labels;
	}
}
