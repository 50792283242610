import React from 'react';
import { Row, Col } from 'antd';
import { Rsi } from '../Charts/Rsi';
import { RebaExposureScore } from '../Charts/RebaExposureScore';
import { RebaExposureBody } from '../Charts/RebaExposureBody';
import { PercentileByGender } from '../Charts/LibertyMutual/PercentileByGender';
import { RiskEvolutionKimPP } from '../Charts/RiskEvolutionKimPP';
import { RiskEvolutionNiosh } from '../Charts/RiskEvolutionNiosh';
import { RiskEvolutionKimMHO } from '../Charts/RiskEvolutionKimMHO';

interface ErgonomicToolsProps {
	handleClear(fieldNames: string[]): void;
}

export function ErgonomicTools({ handleClear }: ErgonomicToolsProps) {
	return (
		<Row gutter={[30, 30]}>
			<Col span={24}>
				<RebaExposureScore handleClear={handleClear} />
			</Col>
			<Col xs={24} xxl={16}>
				<Row gutter={[30, 30]}>
					<Col span={24}>
						<RiskEvolutionNiosh onClear={handleClear} />
					</Col>
					<Col span={24}>
						<RiskEvolutionKimMHO onClear={handleClear} />
					</Col>
					<Col span={24}>
						<RiskEvolutionKimPP onClear={handleClear} />
					</Col>
				</Row>
			</Col>
			<Col xs={24} xxl={8}>
				<Row gutter={[30, 30]}>
					<Col xs={24} md={12} xxl={24}>
						<Row gutter={[30, 30]}>
							<Col span={24}>
								<RebaExposureBody handleClear={handleClear} />
							</Col>
							<Col span={24}>
								<PercentileByGender handleClear={handleClear} />
							</Col>
						</Row>
					</Col>
					<Col xs={24} md={12} xxl={24}>
						<Rsi handleClear={handleClear} />
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
