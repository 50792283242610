import React from 'react';
import { Row, Col } from 'antd';
import { BeraGns } from '@/views/Dashboard/Charts/BeraGns';
import { BeraSixToo } from '@/views/Dashboard/Charts/BeraSixToo';
import { BeraSumRPN } from '@/views/Dashboard/Charts/BeraSumRpn';
import { SeraSumRPN } from '@/views/Dashboard/Charts/SeraSumRpn';
import { SeraMainRisk } from '@/views/Dashboard/Charts/SeraMainRisk';
import { BeraComparison } from '@/views/Dashboard/Charts/BeraComparison';
import { SeraComparison } from '@/views/Dashboard/Charts/SeraComparison';

interface ErgonomicToolsProps {
	handleClear(fieldNames: string[]): void;
}

export function JDErgonomicTools({ handleClear }: Readonly<ErgonomicToolsProps>) {
	return (
		<Row gutter={[30, 30]}>
			<Col xs={24} md={24} xxl={24}>
				<BeraGns handleClear={handleClear} />
			</Col>
			<Col xs={12} md={12} xxl={12}>
				<SeraSumRPN handleClear={handleClear} />
			</Col>
			<Col xs={12} md={12} xxl={12}>
				<BeraSumRPN handleClear={handleClear} />
			</Col>
			<Col xs={12} md={12} xxl={12}>
				<SeraComparison handleClear={handleClear} />
			</Col>
			<Col xs={12} md={12} xxl={12}>
				<BeraComparison handleClear={handleClear} />
			</Col>
			<Col xs={24} md={24} xxl={12}>
				<SeraMainRisk handleClear={handleClear} />
			</Col>
			<Col xs={24} md={24} xxl={12}>
				<BeraSixToo handleClear={handleClear} />
			</Col>
		</Row>
	);
}
