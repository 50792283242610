import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';

interface StatusParams {
	to_do: number;
	doing: number;
	done: number;
}

export interface StatusActionPlans {
	total: StatusParams;
	delayed: StatusParams;
}

interface GetStatusActionPlansParams {
	organization_id: string;
	workstation_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	start_date?: Date;
	end_date?: Date;
}

export const useGetStatusActionPlans = (params: GetStatusActionPlansParams) => {
	return useQuery([QUERY_KEYS.GET_STATUS_ACTION_PLANS, params], () => Service.countByStatus(params), {
		enabled: !!params.organization_id
	});
};
