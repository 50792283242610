import React from 'react';

export function RsiIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
	return (
		<svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M0.374902 19.875H3.35538C3.56157 19.875 3.73028 19.7063 3.73028 19.5V7.53282H28.502V10.9359C28.502 11.025 28.5301 11.1094 28.5863 11.1797C28.6179 11.2202 28.6571 11.254 28.7019 11.2793C28.7466 11.3045 28.7958 11.3206 28.8468 11.3267C28.8978 11.3328 28.9494 11.3288 28.9988 11.3148C29.0482 11.3008 29.0944 11.2772 29.1346 11.2453L35.85 5.97189C36.0515 5.73751 36.0187 5.48907 35.85 5.35314L29.1346 0.0843876C29.0655 0.0290946 28.9794 -0.000700982 28.8909 1.25229e-05C28.6753 1.25229e-05 28.4973 0.178137 28.4973 0.393762V3.79689H3.36006C1.50898 3.79689 0 5.30626 0 7.16251V19.5C0 19.7063 0.168706 19.875 0.374902 19.875ZM35.6157 16.125H32.6353C32.4291 16.125 32.2603 16.2938 32.2603 16.5V28.4672H7.48867V25.0641C7.48867 24.975 7.46056 24.8906 7.40432 24.8203C7.37275 24.7798 7.33348 24.746 7.28877 24.7208C7.24407 24.6955 7.19481 24.6794 7.14384 24.6733C7.09287 24.6672 7.04119 24.6712 6.9918 24.6852C6.9424 24.6992 6.89626 24.7228 6.85603 24.7547L0.140588 30.0281C-0.0609216 30.2625 -0.0281177 30.5109 0.140588 30.6469L6.85603 35.9156C6.92632 35.9719 7.01067 36 7.09971 36C7.31528 36 7.49336 35.8219 7.49336 35.6063V32.2031H32.6399C34.491 32.2031 36 30.6938 36 28.8375V16.5C35.9906 16.2938 35.8219 16.125 35.6157 16.125Z"
				fill="#262626"
			/>
		</svg>
	);
}
