import React from 'react';
import { Row, Col } from 'antd';

import { TaskItem } from './TaskItem';
import { Creation } from './Creation';
import { Text } from '@/components/Typography';
import { useCreateActionPlanContext } from '../context';

export function Tasks() {
	const { tasks } = useCreateActionPlanContext();

	return (
		<Row gutter={[0, 30]}>
			<Col span={24}>
				<Row gutter={[0, 12]}>
					<Col span={24}>
						<Text style={{ fontSize: '1.1rem' }}>Tasks</Text>
					</Col>
					<Col span={24}>
						<Creation />
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row gutter={[0, 15]}>
					{tasks?.map((task) => {
						return (
							<Col key={task.id} span={24}>
								<TaskItem data={task} />
							</Col>
						);
					})}
				</Row>
			</Col>
		</Row>
	);
}
