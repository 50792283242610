import styled from 'styled-components';
import { Row } from 'antd';
import { riskColors } from '@/utils/riskRange';

interface ContainerProps {
	$result: number;
}

export const Container = styled(Row)<ContainerProps>`
	width: 100%;
	color: #ffffff;
	padding: 0.25rem;
	font-size: 1.2rem;
	border-radius: 6px;
	text-align: center;
	background-color: ${({ $result }) => riskColors[$result] ?? '#e6e6e6'};
`;
