import React from 'react';
import { Content } from './Content';
import { ModalWrapper } from '@/views/ActionPlans/styles';

export type ActionPlanParams = {
	id: string;
	company_id: string;
};

interface ModalEditProps {
	data: ActionPlanParams;
	isOpen: boolean;
	onClose(): void;
}

export function ModalEdit({ data, isOpen, onClose }: Readonly<ModalEditProps>) {
	return (
		<ModalWrapper width={1260} open={isOpen} onCancel={onClose} footer={null}>
			<Content data={data} onClose={onClose} />
		</ModalWrapper>
	);
}
