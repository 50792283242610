import { useMutation } from '@tanstack/react-query';
import { Service } from '@/infra/services/action-plan';
import { AxiosError } from 'axios';
import { ActionPlanCreateUserPreferenceColumnsDomain } from '@/core/domain/action-plan-user-preference-columns';

interface createPreferenceColumnsParams {
	organization_id: string;
	company_id: string | null;
	columns: string[];
}

export const createPreferenceColumns = (params: createPreferenceColumnsParams) => {
	return Service.createPreferenceColumns(params);
};

export const useCreatePreferenceColumnsActionPlan = () => {
	return useMutation<
		ActionPlanCreateUserPreferenceColumnsDomain,
		AxiosError<Response>,
		createPreferenceColumnsParams
	>((params) => Service.createPreferenceColumns(params));
};
