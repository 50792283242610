import React from 'react';
import { RsiIcon } from '@/assets/icons/ergonomic-tools/rsi';
import { NioshIcon } from '@/assets/icons/ergonomic-tools/niosh';
import { KimppIcon } from '@/assets/icons/ergonomic-tools/kimpp';
import { KimmhoIcon } from '@/assets/icons/ergonomic-tools/kimmho';
import { LibertyMutualIcon } from '@/assets/icons/ergonomic-tools/liberty-mutual';
import { EstimativeCompressiveForceIcon } from '@/assets/icons/ergonomic-tools/compressive-force';
import { LineChartOutlined, PieChartOutlined } from '@ant-design/icons';

const ergonomicTools = [
	{
		name: 'rula',
		icon: <PieChartOutlined />
	},
	{
		name: 'reba',
		icon: <LineChartOutlined />
	},
	{
		name: 'niosh',
		icon: <NioshIcon />
	},
	{
		name: 'kim_mho',
		icon: <KimmhoIcon />
	},
	{
		name: 'kim_pp',
		icon: <KimppIcon />
	},
	{
		name: 'liberty_mutual',
		icon: <LibertyMutualIcon width={40} height={40} style={{ marginLeft: '-0.5rem' }} />
	},
	{
		name: 'strain_index',
		icon: <RsiIcon />
	},
	{
		name: 'back_compressive_force_estimation',
		icon: <EstimativeCompressiveForceIcon />
	}
];

export function getToolIcon(name: string) {
	const tool = ergonomicTools.find((tool) => tool.name === name);
	return tool?.icon;
}

export const TOOL_ORDER: Record<string, number> = {
	rula: 0,
	reba: 1,
	niosh: 2,
	kim_mho: 3,
	kim_pp: 4,
	liberty_mutual: 5,
	strain_index: 6,
	back_compressive_force_estimation: 7
};
