import { Button, Checkbox, Modal } from 'antd';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
	.ant-modal-content {
		border-radius: 30px !important;
	}
	.ant-modal-body {
		padding: 2rem 3rem;
	}
`;

export const TitlePdf = styled.p`
	margin: 0;
	font-size: 30px;
	font-weight: 600;
	line-height: 22px;
	text-align: center;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
`;

export const SubTitlePdf = styled.p`
	font-size: 18px;
	font-weight: 400;
	line-height: 23.94px;
	text-align: center;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
`;

export const CheckboxGroupWrapper = styled(Checkbox.Group)`
	display: flex;
	flex-direction: column;

	.ant-checkbox-wrapper {
		margin-bottom: 1rem;
		font-size: 1rem;
	}
`;

export const PrimaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	background-color: #2f54eb;
	box-shadow: 0px 2px 0px 0px #0000000b;
`;

export const SecondaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	box-shadow: 0px 1px 0px 0px #0000000b;
`;
