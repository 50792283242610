import styled from 'styled-components';
import { Col, Button } from 'antd';

export const RefetchButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 42px;
	font-size: 1rem;
	border-radius: 5px;
	padding: 0.5rem 1.1rem;
	box-shadow: 0px 1px 0px 0px #0000000b;
`;

export const CenteredCol = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;
`;
