import { Mapper } from './base-mapper';
import { BeraComparison } from '@/core/domain/bera-comparison';
import { BeraComparisonDTO, BeraComparisonItemDTO } from '@/core/dto/bera-comparison';

export class BeraComparisonMapper extends Mapper<BeraComparison> {
	private readonly data: BeraComparisonDTO;

	constructor(data: BeraComparisonDTO) {
		super();
		this.data = data;
	}

	toTransform(value: BeraComparisonItemDTO): BeraComparison {
		return new BeraComparison({
			id: value.id,
			name: value.name,
			total_rpn: parseFloat(value.total_rpn)
		});
	}

	toDomain(): BeraComparison[] {
		return this.data.map(this.toTransform.bind(this));
	}
}
