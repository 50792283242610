import moment from 'moment';
import imageCompression from 'browser-image-compression';

const TIME_OUT = 10000;
const MAX_FILE_SIZE = 10;
const MAX_FILE_DIMENSION = 600;

export async function compressImage(file: File): Promise<File> {
	const options = {
		useWebWorker: true,
		maxSizeMB: MAX_FILE_SIZE,
		maxWidthOrHeight: MAX_FILE_DIMENSION
	};

	const compressedImage = await imageCompression(file, options);
	return compressedImage;
}

export function getHeaders(file: File) {
	return {
		onUploadProgress: () => {},
		timeout: TIME_OUT,
		headers: {
			'Content-Type': file.type
		}
	};
}

export function truncateFileName(fileName: string, maxLength: number = 12): string {
	if (fileName.length <= maxLength) return fileName;

	const extension = fileName.split('.').pop();
	const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
	const truncatedName = nameWithoutExtension.substring(0, maxLength - 3);

	return `${truncatedName}...${extension ? `.${extension}` : ''}`;
}

export function formatFileSize(bytes: number): string {
	const megabytes = Math.ceil((bytes / (1024 * 1024)) * 10) / 10;
	return megabytes.toFixed(1);
}

export function formatDate(dateString: string): string {
	const browserLocale = navigator.language.toLowerCase();
	moment.locale(browserLocale);

	return moment(dateString).format('L');
}
