import styled from 'styled-components';
import { Row, Col } from 'antd';

export const ItemContainer = styled(Row)`
	padding: 0 0.5rem;
	font-size: 1.1rem;
	border-left: 1px solid #26262680;
`;

export const RiskList = styled(Row)`
	margin-bottom: 100%;
	> .ant-col {
		padding: 0.8rem 0;

		&:not(:last-child) {
			border-bottom: 1px solid #26262680;
		}
	}
`;

export const CenteredCol = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;
`;
