import styled from 'styled-components';
import { Row, Col, Button } from 'antd';

const BLUE_COLOR = '#2F54EB';

interface PanelButtonProps {
	$selected: boolean;
}

export const PanelButton = styled(Button)<PanelButtonProps>`
	display: flex;
	font-size: 1rem;
	padding: 0 1rem;
	font-weight: 600;
	min-height: 2.5rem;
	border-radius: 7px;
	align-items: center;
	justify-content: center;
	color: ${({ $selected }) => ($selected ? BLUE_COLOR : '#262626E5')};
	background-color: ${({ $selected }) => ($selected ? '#EAEEFD' : '#ffffff')};
	border: ${({ $selected }) => ($selected ? `1px solid ${BLUE_COLOR}` : '1px solid #e9e9e9')};

	.anticon {
		line-height: 0;
	}

	span {
		margin: 0 !important;
	}

	svg {
		width: 1.2rem;
		font-size: 1.1rem;
		margin-right: 0.6rem;
	}
`;

export const IconButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	padding: 0 0.8rem;
	font-weight: 600;
	height: 2.5rem;
	border-radius: 7px;

	.anticon {
		line-height: 0;
	}

	span {
		margin: 0 !important;
	}

	svg {
		width: 1.2rem;
		font-size: 1.1rem;

		@media (min-width: 1600px) {
			margin-right: 0.6rem;
		}
	}
`;

export const StatusWrapper = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const PrimaryButton = styled(Button)`
	display: flex;
	align-items: center;
	height: 2.4rem;
	font-size: 1rem;
	padding: 0 0.8rem;
	border-radius: 5px;
	background-color: #2f54eb;
	border: 1px solid #2f54eb;
	box-shadow: 0px 2px 0px 0px #0000000b;

	.anticon {
		line-height: 0;
	}

	span {
		margin: 0 !important;
	}

	svg {
		font-size: 1.3rem;
		margin-right: 0.6rem;
	}
`;

export const ContainerContent = styled(Row)`
	padding: 1.8rem;
	border-radius: 0.8rem;
	background-color: #ffffff;
`;
