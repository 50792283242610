interface SeraSumRPNAttributes {
	sum_rpn: number;
}

export class SeraSumRPN {
	public readonly sum_rpn: number;

	constructor({ sum_rpn }: SeraSumRPNAttributes) {
		this.sum_rpn = sum_rpn;
	}
}
