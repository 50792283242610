import React from 'react';
import * as S from './styles';
import { Skeleton } from 'antd';
import { UpdateActionPlan } from './UpdateActionPlan';
import { CreateActionPlan } from './CreateActionPlan';
import { useGetActionPlanFromReport } from '@/hooks/v2/useGetActionPlanFromReport';

const MODAL_WIDTH = 700;

interface ActionPlanModalProps {
	onClose(): void;
	score?: number;
	isOpen: boolean;
	id?: string;
	fileId: string;
	companyId: string;
	organizationId: string;
	seraSummaryReview?: string;
	customReportStepKey?: string;
	customReportSubStepKey?: string;
}

export function ActionPlanModal({
	id,
	isOpen,
	fileId,
	onClose,
	companyId,
	organizationId,
	seraSummaryReview,
	customReportStepKey,
	customReportSubStepKey,
	score = 0
}: Readonly<ActionPlanModalProps>) {
	const hierarchy = {
		file_id: fileId,
		company_id: companyId,
		organization_id: organizationId,
		sera_summary_review: seraSummaryReview,
		custom_report_step_key: customReportStepKey,
		custom_report_sub_step_key: customReportSubStepKey
	};

	const { isFetching, data: actionPlan } = useGetActionPlanFromReport({
		id,
		file_id: hierarchy.file_id,
		company_id: hierarchy.company_id,
		organization_id: hierarchy.organization_id,
		sera_summary_review_id: hierarchy.sera_summary_review,
		custom_report_step_key_id: hierarchy.custom_report_step_key,
		custom_report_sub_step_key_id: hierarchy.custom_report_sub_step_key
	});

	if (isFetching) {
		return (
			<S.ModalWrapper width={MODAL_WIDTH} open={isOpen} onCancel={onClose} footer={null}>
				<Skeleton active />
			</S.ModalWrapper>
		);
	}

	return (
		<S.ModalWrapper width={MODAL_WIDTH} open={isOpen} onCancel={onClose} footer={null}>
			{actionPlan ? (
				<UpdateActionPlan actionPlan={actionPlan} score={score} onClose={onClose} />
			) : (
				<CreateActionPlan hierarchy={hierarchy} score={score} onClose={onClose} />
			)}
		</S.ModalWrapper>
	);
}
