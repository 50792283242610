import React, { memo } from 'react';
import { I18n } from '@aws-amplify/core';
import * as S from './styles';

interface ExportProgressProps {
	progress: number;
}

export const ExportProgress = memo(function ExportProgress({ progress }: ExportProgressProps) {
	return (
		<S.ProgressContainer>
			<p>
				{I18n.get('Exporting')} ({progress}%)
			</p>
			<S.ProgressBar>
				<S.ProgressFill progress={progress} />
			</S.ProgressBar>
		</S.ProgressContainer>
	);
});
