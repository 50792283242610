import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '@/infra/services/action-plan';

type Origin = {
	file_id: string | null;
	report_id: string | null;
	name: string | null;
};

type Workstation = {
	id: string;
	name: string;
};

type Line = {
	id: string;
	name: string;
};

type Sector = {
	id: string;
	name: string;
};

type Company = {
	id: string;
	name: string;
};

type Organization = {
	id: string;
	name: string;
};

type Author = {
	name: string;
	url_logo: string | null;
};

export interface GetActionPlanRequest {
	id: string;
	organization_id: string;
	company_id: string;
}

export interface GetActionPlanResponse {
	id: string;
	title: string;
	status: string;
	score: number | null;
	priority: number;
	investment_range: number;
	investment_value: number | null;
	description: string | null;
	due_date: Date;
	lexo_rank: string;
	file_id: string | null;
	activity_id: string | null;
	workstation_id: string;
	user_id: string;
	responsible_user_id: string;
	action_plan_origin_id: string;
	completed_at: Date | null;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	author: Author;
	organization: Organization;
	company: Company;
	sector: Sector;
	line: Line;
	origin?: Origin;
	workstation: Workstation;
}

export const useGetActionPlan = (params: GetActionPlanRequest) => {
	return useQuery([QUERY_KEYS.GET_ACTION_PLAN, params], () => Service.findOne(params), {
		enabled: !!params.company_id && !!params.id,
		retry: 0
	});
};
