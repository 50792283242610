import React, { useState } from 'react';
import { Row, Col, Grid } from 'antd';
import { I18n } from '@aws-amplify/core';
import { BellOutlined, PlusCircleFilled } from '@ant-design/icons';

import { Filter } from './Filter';
import { Painel } from './Painel';
import { Sort } from './components/Fields';
import { useActionPlanContext } from './context';
import { DrawerNotifications } from './Notifications';
import { StatusCard } from './components/StatusCard';
import { FilterIcon } from '@/assets/icons/filter-icon';
import { ActionPlansStatus, Panels } from './constants';
import { ModalCreate } from './components/Modals/ModalCreate';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetActionPlansCount } from '@/hooks/v2/useGetActionPlanCount';
import * as S from './styles';

const { useBreakpoint } = Grid;

export function Content() {
	const { xxl } = useBreakpoint();
	const { organization } = useApplicationContext();
	const { currentPanel, onToggleFilter, onToggleNotifications, filter } = useActionPlanContext();
	const [isOpenModalCreate, setIsOpenModalCreate] = useState<boolean>(false);

	const {
		isLoading: isLoadingToDo,
		data: actionPlansToDo,
		isError: isErrorToDo
	} = useGetActionPlansCount({
		...filter,
		organization_id: organization.id,
		title: filter.action_plan_name,
		origin_name: filter.origin,
		status: 'to_do',
		start_date: filter.creation_date?.[0].toDate(),
		end_date: filter.creation_date?.[1].toDate(),
		due_date_start: filter.due_date?.[0].toDate(),
		due_date_end: filter.due_date?.[1].toDate()
	});

	const {
		isLoading: isLoadingDoing,
		data: actionPlansDoing,
		isError: isErrorDoing
	} = useGetActionPlansCount({
		...filter,
		organization_id: organization.id,
		title: filter.action_plan_name,
		origin_name: filter.origin,
		status: 'doing',
		start_date: filter.creation_date?.[0].toDate(),
		end_date: filter.creation_date?.[1].toDate(),
		due_date_start: filter.due_date?.[0].toDate(),
		due_date_end: filter.due_date?.[1].toDate()
	});

	const {
		isLoading: isLoadingDone,
		data: actionPlansDone,
		isError: isErrorDone
	} = useGetActionPlansCount({
		...filter,
		organization_id: organization.id,
		title: filter.action_plan_name,
		origin_name: filter.origin,
		status: 'done',
		start_date: filter.creation_date?.[0].toDate(),
		end_date: filter.creation_date?.[1].toDate(),
		due_date_start: filter.due_date?.[0].toDate(),
		due_date_end: filter.due_date?.[1].toDate()
	});

	const total = [actionPlansToDo?.total, actionPlansDone?.total, actionPlansDoing?.total].reduce(
		(acc = 0, current = 0) => acc + current,
		0
	);

	function handleToggleCreate(): void {
		setIsOpenModalCreate(!isOpenModalCreate);
	}

	const isLoading = isLoadingDoing || isLoadingDone || isLoadingToDo;

	if (isErrorToDo || isErrorDoing || isErrorDone) {
		return <span>{I18n.get('Oops... Something went wrong!')}</span>;
	}

	return (
		<S.ContainerContent gutter={[0, 12]}>
			<Col span={24}>
				<Row justify="space-between" align="middle">
					<Col lg={12} xxl={12}>
						<Row gutter={[12, 0]}>
							<Col>
								<S.IconButton onClick={onToggleFilter}>
									<FilterIcon />
									{!!xxl && 'Filters'}
								</S.IconButton>
							</Col>
							<Col lg={6} xxl={6}>
								<Sort />
							</Col>
							<Col lg={6} xxl={6}>
								<S.IconButton onClick={onToggleNotifications}>
									<BellOutlined />
									{!!xxl && I18n.get('Notifications')}
								</S.IconButton>
							</Col>
						</Row>
					</Col>
					<Col>
						<Row justify="end" gutter={[12, 0]}>
							<Col>
								<StatusCard isLoading={isLoading} value={total} />
							</Col>
							{currentPanel === Panels.TABLE && (
								<>
									<Col>
										<StatusCard
											isLoading={isLoadingToDo}
											status={ActionPlansStatus.TO_DO}
											value={actionPlansToDo?.total}
										/>
									</Col>
									<Col>
										<StatusCard
											isLoading={isLoadingDoing}
											status={ActionPlansStatus.DOING}
											value={actionPlansDoing?.total}
										/>
									</Col>
									<Col>
										<StatusCard
											isLoading={isLoadingDone}
											status={ActionPlansStatus.DONE}
											value={actionPlansDone?.total}
										/>
									</Col>
								</>
							)}
							<Col>
								<S.PrimaryIconButton
									type="primary"
									icon={<PlusCircleFilled />}
									onClick={handleToggleCreate}
								>
									{I18n.get('New action plan')}
								</S.PrimaryIconButton>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Painel />
			</Col>
			<Filter />
			<DrawerNotifications />
			<ModalCreate open={isOpenModalCreate} onClose={handleToggleCreate} />
		</S.ContainerContent>
	);
}
