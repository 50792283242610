import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { CustomFormItem, CustomTextInput } from '../../../Fields/styles';
import * as S from './styles';

const { useWatch } = Form;

interface NameProps {
	formName: string[];
}

export function Name({ formName }: Readonly<NameProps>) {
	const title = useWatch([...formName, 'action_plan_name']);
	const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);

	function handleToggleInputTitle(): void {
		setIsEditingTitle((prev) => !prev);
	}

	return (
		<Row style={{ alignItems: 'baseline' }}>
			<Col span={24} style={{ display: isEditingTitle ? 'block' : 'none' }}>
				<Row align="middle" gutter={[10, 0]}>
					<Col span={14}>
						<CustomFormItem labelCol={{ span: 24 }} name={[...formName, 'action_plan_name']}>
							<CustomTextInput maxLength={150} />
						</CustomFormItem>
					</Col>
					<Col>
						<Button
							size="small"
							type="primary"
							shape="circle"
							icon={<CheckOutlined />}
							onClick={handleToggleInputTitle}
							style={{ display: isEditingTitle ? 'block' : 'none' }}
						/>
					</Col>
				</Row>
			</Col>
			<Col span={20} style={{ display: isEditingTitle ? 'none' : 'block' }}>
				<Row wrap={false} align="middle" gutter={[10, 0]}>
					<Col>
						<S.CustomTitle level={3}>{title}</S.CustomTitle>
					</Col>
					<Col>
						<Button
							type="text"
							size="middle"
							onClick={handleToggleInputTitle}
							style={{ display: isEditingTitle ? 'none' : 'inline' }}
							icon={<EditOutlined style={{ color: '#2F54EB', fontSize: 20 }} />}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
