import React from 'react';
import { Col } from 'antd';
import { Text } from '@/components/Typography';
import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import * as S from './styles';

interface ToastProps {
	message: string;
	type?: 'error' | 'warning';
}

export function Toast({ type, message }: Readonly<ToastProps>) {
	return (
		<S.Container>
			<S.Wrapper type={type} align="middle" gutter={[10, 0]}>
				<Col>
					{type === 'error' && <ExclamationCircleOutlined />}
					{type === 'warning' && <WarningOutlined />}
				</Col>
				<Col>
					<Text>{message}</Text>
				</Col>
			</S.Wrapper>
		</S.Container>
	);
}
