import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Service } from '@/infra/services/action-plan';
import { AddEvidenceResponseDTO } from '@/core/dto/action-plan-evidences';
import type { Response } from '@/types';

interface AddEvidenceParams {
	size: number;
	file_name: string;
	company_id: string;
	content_type: string;
	organization_id: string;
	action_plan_task_id: string;
}

export const useAddEvidence = () => {
	return useMutation<AddEvidenceResponseDTO, AxiosError<Response>, AddEvidenceParams>((params) =>
		Service.addEvidence(params)
	);
};
