import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';

import { DoughnutChart } from './DoughnutChart';
import { unShortenUrl } from '@/views/Dashboard/utils';
import { Card } from '@/views/Dashboard/components/Card';
import { Single } from '@/views/Dashboard/components/Filters/Single';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetSeraMainRisk } from '@/views/Dashboard/hooks/useGetSeraMainRisk';
import { StyledDivPopOver } from '@/views/Dashboard/styles';

interface ChecklistReportsProps {
	handleClear(fieldNames: string[]): void;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const { useFormInstance } = Form;

export function SeraMainRisk({ handleClear }: Readonly<ChecklistReportsProps>) {
	const { organization } = useApplicationContext();
	const query = useQuery();
	const location = useLocation();
	const form = useFormInstance();

	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');

	const unshortened_url = unShortenUrl(location.search.substring(1));
	const seraMainRiskParams = unshortened_url?.sera_mrc ?? unshortened_url?.general?.unique_period;

	const filter_params = {
		organization_id: organization?.id!,
		company_id: company_id ?? undefined,
		sector_id: sector_id ?? undefined,
		line_id: line_id ?? undefined,
		workstation_id: workstation_id ?? undefined,
		end_date: seraMainRiskParams?.end_date ?? undefined,
		start_date: seraMainRiskParams?.start_date ?? undefined
	};

	const { isLoading, data, isError, refetch } = useGetSeraMainRisk(filter_params);

	function handleOnSubmit(): void {
		form.submit();
	}

	const chartData = data ? data.items.map((item) => item.percentage) : [];
	const chartLabels = data ? data.items.map((item) => I18n.get(item.description)) : [];

	function getDisplayDate() {
		return {
			start: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(seraMainRiskParams?.start_date),
			end: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(seraMainRiskParams?.end_date)
		};
	}

	return (
		<Card.Container size="medium" isLoading={isLoading} isError={isError} onRefetch={refetch}>
			<Card.Header
				hasIcon
				isTitleBold
				hasDisplayDate
				displayDate={getDisplayDate()}
				title={I18n.get('SERA')}
				subtitle={I18n.get('Main risk category')}
				icon={<CalendarOutlined />}
				popoverComponent={
					<StyledDivPopOver>
						<Single
							hasGranularity={false}
							fieldName={['sera_mrc']}
							handleClear={handleClear}
							handleFormSubmit={handleOnSubmit}
						/>
					</StyledDivPopOver>
				}
			/>
			<Card.Content isEmpty={!data?.items.length}>
				<div style={{ height: '280px', width: '100%', position: 'relative' }}>
					{data && <DoughnutChart data={chartData} labels={chartLabels} />}
				</div>
			</Card.Content>
		</Card.Container>
	);
}
