import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import { RiskTag } from './RiskTag';
import * as S from './styles';

interface HierarchyItemProps {
	rank: number;
	title: string;
	score: number;
}

export function HierarchyItem({ title, rank, score }: Readonly<HierarchyItemProps>) {
	return (
		<Row justify="space-between" align="middle" style={{ margin: '0 0.5rem', fontSize: '1rem' }}>
			<S.DescriptionWrapper lg={14} xxl={16}>
				<S.CustomText>{rank}°</S.CustomText>
				<Tooltip title={title}>
					<span>{title}</span>
				</Tooltip>
			</S.DescriptionWrapper>
			<Col>
				<RiskTag worstScore={score} />
			</Col>
		</Row>
	);
}
