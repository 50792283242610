import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Service } from '@/infra/services/action-plan';
import { AxiosError } from 'axios';
import { ActionPlanDeleteCommentsDTO } from '@/core/dto/action-plan-comments-delete';
import { message } from 'antd';
import { I18n } from '@aws-amplify/core';

interface DeleteCommentsParams {
	organization_id: string;
	company_id: string;
	action_plan_comment_id: string | undefined;
}

export const deleteComments = (params: DeleteCommentsParams) => {
	return Service.deleteComments(params);
};

export const useDeleteCommentsActionPlan = () => {
	const queryClient = useQueryClient();

	return useMutation<ActionPlanDeleteCommentsDTO, AxiosError<Response>, DeleteCommentsParams>(
		(params) => Service.deleteComments(params),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['get-action-plan-comments']);
				queryClient.invalidateQueries(['get-action-plan-history']);
			}
		}
	);
};
