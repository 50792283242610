import styled from 'styled-components';
import { Col } from 'antd';

export const StyledCol = styled(Col)`
	display: flex;
	justify-content: center;

	h1 {
		font-size: 4.5rem;
		margin-bottom: 0;
	}
`;
