import React from 'react';

export function BarsIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
	return (
		<svg viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M1 1V13.4444H6.44444V1H1Z"
				stroke="currentColor"
				strokeOpacity={0.9}
				strokeWidth={1.5}
				strokeLinejoin="round"
			/>
			<path
				d="M8.77734 1V16.5556H14.2218V1H8.77734Z"
				stroke="currentColor"
				strokeOpacity={0.9}
				strokeWidth={1.5}
				strokeLinejoin="round"
			/>
			<path
				d="M16.5547 1V8H21.9991V1H16.5547Z"
				stroke="currentColor"
				strokeOpacity={0.9}
				strokeWidth={1.5}
				strokeLinejoin="round"
			/>
		</svg>
	);
}
