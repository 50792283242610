import moment from 'moment';

const FISCAL_YEAR_START_MONTH = 10;
const FISCAL_YEAR_END_MONTH = 9;
const SEMESTER_START_MONTH = 4;
const SEMESTER_END_MONTH = 9;
const QUARTER_MONTHS = [
	{ start: 10, end: 0 },
	{ start: 1, end: 3 },
	{ start: 4, end: 6 },
	{ start: 7, end: 9 }
];
const TOTAL_MONTHS_YEAR = 12;
const QUARTER = 3;
const SEMESTER = 6;

interface DatePeriod {
	start_date: moment.Moment;
	end_date: moment.Moment;
}

export function handleDatePeriod(fiscal_year: boolean, predetermined: string): DatePeriod {
	let start_date = moment();
	let end_date = moment();

	const dateOptions: Record<string, () => void> = {
		today: () => {
			start_date = moment().startOf('day');
			end_date = moment().endOf('day');
		},
		week: () => {
			start_date = moment().startOf('week');
			end_date = moment().endOf('week');
		},
		month: () => {
			start_date = moment().startOf('month');
			end_date = moment().endOf('month');
		},
		quarter: () => {
			if (fiscal_year) {
				const currentMonth = moment().month();
				const fiscalQuarterIndex = Math.floor(
					((currentMonth + TOTAL_MONTHS_YEAR - FISCAL_YEAR_START_MONTH) % TOTAL_MONTHS_YEAR) / QUARTER
				);
				const quarter = QUARTER_MONTHS[fiscalQuarterIndex];
				start_date = moment().month(quarter.start).startOf('month');
				end_date = moment().month(quarter.end).endOf('month');
			} else {
				start_date = moment().startOf('quarter');
				end_date = moment().endOf('quarter');
			}
		},
		semester: () => {
			if (fiscal_year) {
				const currentMonth = moment().month();
				if (currentMonth >= SEMESTER_START_MONTH && currentMonth <= SEMESTER_END_MONTH) {
					start_date = moment().month(SEMESTER_START_MONTH).startOf('month');
					end_date = moment().month(SEMESTER_END_MONTH).endOf('month');
				} else if (currentMonth < SEMESTER_START_MONTH) {
					start_date = moment().subtract(1, 'year').month(SEMESTER_START_MONTH).startOf('month');
					end_date = moment().month(SEMESTER_END_MONTH).endOf('month');
				} else {
					start_date = moment().month(SEMESTER_START_MONTH).startOf('month');
					end_date = moment().month(SEMESTER_END_MONTH).endOf('month');
				}
			} else {
				const month = moment().month();
				if (month < SEMESTER) {
					start_date = moment().startOf('year');
					end_date = moment().month(5).endOf('month');
				} else {
					start_date = moment().month(6).startOf('month');
					end_date = moment().endOf('year');
				}
			}
		},
		year: () => {
			if (fiscal_year) {
				const currentMonth = moment().month();
				if (currentMonth >= FISCAL_YEAR_START_MONTH) {
					start_date = moment().month(FISCAL_YEAR_START_MONTH).startOf('month');
					end_date = moment().add(1, 'year').month(FISCAL_YEAR_END_MONTH).endOf('month');
				} else {
					start_date = moment().subtract(1, 'year').month(FISCAL_YEAR_START_MONTH).startOf('month');
					end_date = moment().month(FISCAL_YEAR_END_MONTH).endOf('month');
				}
			} else {
				const currentMonth = moment().month();
				if (currentMonth >= FISCAL_YEAR_START_MONTH) {
					start_date = moment().month(FISCAL_YEAR_START_MONTH).startOf('month');
					end_date = moment().add(1, 'year').month(FISCAL_YEAR_END_MONTH).endOf('month');
				} else {
					start_date = moment().month(0).startOf('year');
					end_date = moment().endOf('year');
				}
			}
		},
		last_seven_days: () => {
			start_date = moment().subtract(6, 'days').startOf('day');
			end_date = moment().endOf('day');
		},
		last_thirty_days: () => {
			start_date = moment().subtract(29, 'days').startOf('day');
			end_date = moment().endOf('day');
		},
		last_three_months: () => {
			start_date = moment().subtract(3, 'months').startOf('month');
			end_date = moment().endOf('month');
		},
		last_six_months: () => {
			start_date = moment().subtract(6, 'months').startOf('month');
			end_date = moment().endOf('month');
		},
		last_twelve_months: () => {
			start_date = moment().subtract(12, 'months').startOf('month');
			end_date = moment().endOf('month');
		},
		from_beginning: () => {
			start_date = moment('2020-01-01');
			end_date = moment().endOf('day');
		}
	};

	if (dateOptions[predetermined]) {
		dateOptions[predetermined]();
	}

	return { start_date, end_date };
}

export function getFiscalYearDates(): DatePeriod {
	const currentMonth = moment().month();
	let start_date: moment.Moment;
	let end_date: moment.Moment;

	if (currentMonth >= FISCAL_YEAR_START_MONTH) {
		start_date = moment().month(FISCAL_YEAR_START_MONTH).startOf('month');
		end_date = moment().add(1, 'year').month(FISCAL_YEAR_END_MONTH).endOf('month');
	} else {
		start_date = moment().subtract(1, 'year').month(FISCAL_YEAR_START_MONTH).startOf('month');
		end_date = moment().month(FISCAL_YEAR_END_MONTH).endOf('month');
	}

	return { start_date, end_date };
}
