import styled from 'styled-components';
import { Row, Col } from 'antd';
import { RisksEnum } from './enum';
import { RISK_COLORS } from '@/views/Dashboard/constants';

const RISK_COLOR_MAPPER: Record<string, string> = {
	[RisksEnum.ACCEPTABLE_RISK]: RISK_COLORS.ACCEPTABLE,
	[RisksEnum.MODERATE_RISK]: RISK_COLORS.MODERATE,
	[RisksEnum.HIGH_RISK]: RISK_COLORS.HIGH,
	[RisksEnum.VERY_HIGH_RISK]: RISK_COLORS.VERY_HIGH,
	[RisksEnum.SERIOUS_AND_IMMINENT_RISK]: RISK_COLORS.SERIOUS_IMMINENT
};

interface ScoreTagProps {
	$riskName: string;
}

export const Container = styled(Row)`
	padding: 0 0.5rem;
	font-size: 1.1rem;
	border-left: 1px solid #26262680;
`;

export const ScoreTag = styled.div<ScoreTagProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	width: 3.5rem;
	font-size: 1.1rem;
	font-weight: 600;
	color: #ffffff;
	background-color: ${({ $riskName }) => RISK_COLOR_MAPPER[$riskName]};
`;

export const IconWrapper = styled(Col)`
	height: 100%;
	display: flex;
	align-items: center;
	margin-right: 0.3rem;

	svg {
		width: 0.7rem;
	}
`;
