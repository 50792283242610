import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Moment } from 'moment';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation, useHistory } from 'react-router-dom';

import { Tabs } from './constants';
import { Mosaic } from './components/Mosaic';
import { Title } from '@/components/Typography';
import { ErgonomicRisk } from './Tabs/ErgonomicRisk';
import { ErgonomicTools } from './Tabs/ErgonomicTools';
import { ButtonSectionRef, TabContent } from './types';
import { FilterIcon } from '@/assets/icons/filter-icon';
import { JDErgonomicTools } from './Tabs/JDErgonomicTools';
import { GeneralFilter } from './components/Filters/General';
import { formatLocationSearch, shortenUrl, unShortenUrl } from './utils';
import * as S from './styles';
import { getFiscalYearDates } from './components/Filters/Fields/predetermined-helper';

const { useForm } = Form;

function useQuery(): URLSearchParams {
	return new URLSearchParams(useLocation().search);
}

const sections = [
	{
		id: 1,
		name: Tabs.ERGNOMIC_RISK,
		description: 'Ergonomic Risk Management'
	},
	{
		id: 2,
		name: Tabs.ERGNOMIC_TOOLS,
		description: 'Ergonomics Assessments Tools Status'
	},
	{
		id: 3,
		name: Tabs.JOHN_DEERE_ERGNOMIC_TOOLS,
		description: 'John Deere Enterprise Ergonomics Assessments Tools Status'
	}
];

export default function Dashboard() {
	const [form] = useForm();
	const query = useQuery();
	const history = useHistory();
	const location = useLocation();
	const buttonRefs = useRef<ButtonSectionRef>({});
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const currentTab: string = query.get('tab') || 'ergonomic_risk';

	const scrollToSelectedSection = useCallback(() => {
		if (buttonRefs.current[currentTab]) {
			buttonRefs.current[currentTab]?.scrollIntoView({
				block: 'nearest',
				inline: 'center'
			});
		}
	}, [currentTab]);

	const handleOnSubmit = useCallback((): void => {
		const values = form.getFieldsValue(true);
		const shortenedUrl = shortenUrl(values);

		const nonCompressedParams = getNonCompressedParams(new URLSearchParams(location.search));
		const newUrl = `${location.pathname}?${shortenedUrl}${nonCompressedParams ? `&${nonCompressedParams}` : ''}`;
		history.replace(newUrl);
	}, [form, location.pathname, location.search, history]);

	const setUniquePeriodDates = useCallback(
		(startDate: Moment, endDate: Moment): void => {
			form.setFieldValue(['general', 'unique_period', 'start_date'], startDate);
			form.setFieldValue(['general', 'unique_period', 'end_date'], endDate);
		},
		[form]
	);

	const setCompoundPeriodDates = useCallback(
		(startDate: Moment, endDate: Moment): void => {
			const periods = ['first_period', 'second_period'];
			periods.forEach((period) => {
				form.setFieldValue(['general', 'compound_period', period, 'start_date'], startDate);
				form.setFieldValue(['general', 'compound_period', period, 'end_date'], endDate);
			});
		},
		[form]
	);

	const setDefaultDates = useCallback(() => {
		const { start_date, end_date } = getFiscalYearDates();
		setUniquePeriodDates(start_date, end_date);
		setCompoundPeriodDates(start_date, end_date);
		handleOnSubmit();
	}, [setUniquePeriodDates, setCompoundPeriodDates, handleOnSubmit]);

	useEffect(() => {
		scrollToSelectedSection();

		const unshortenUrl = unShortenUrl(location.search.substring(1));
		const hasGeneral = unshortenUrl?.general;

		if (!hasGeneral) {
			setDefaultDates();
		}
	}, [location.search, scrollToSelectedSection, setDefaultDates]);

	function getNonCompressedParams(params: URLSearchParams): string {
		const keys = [
			'tab',
			'custom_report_id',
			'company',
			'company_name',
			'sector',
			'sector_name',
			'line',
			'line_name',
			'workstation',
			'workstation_name',
			'file',
			'file_name'
		];

		return keys
			.map((key) => (params.get(key) ? `${key}=${params.get(key)}` : ''))
			.filter(Boolean)
			.join('&');
	}

	function handleChangeTab(name: string): void {
		const search_params = new URLSearchParams(location.search);
		search_params.set('tab', name);

		const newUrl = `${location.pathname}?${search_params.toString()}`;
		history.replace(newUrl);
	}

	function isTabSelected(tabName: string): boolean {
		return currentTab === tabName;
	}

	function handleOpenModal(): void {
		setIsOpen(!isOpen);
	}

	function handleOnClearDynamic(fieldNames: string[]): void {
		form.resetFields(fieldNames);
		const currentParams = unShortenUrl(location.search.substring(1));

		if (typeof currentParams === 'object') {
			fieldNames.forEach((fieldName) => {
				delete currentParams[fieldName];
			});

			const shortened_url = shortenUrl(currentParams);
			const nonCompressedParams = getNonCompressedParams(new URLSearchParams(location.search));
			const newUrl = `${location.pathname}?${shortened_url}${
				nonCompressedParams ? `&${nonCompressedParams}` : ''
			}`;
			history.replace(newUrl);
		}
	}

	const tabsProps = {
		handleClear: handleOnClearDynamic
	};

	const tab: TabContent = {
		ergonomic_risk: <ErgonomicRisk {...tabsProps} />,
		ergonomic_tools: <ErgonomicTools {...tabsProps} />,
		jd_ergonomic_tools: <JDErgonomicTools {...tabsProps} />
	};

	const initialValues = formatLocationSearch(location.search, query);

	return (
		<Row gutter={[0, 15]}>
			<Col span={24}>
				<Title level={3}>General overview</Title>
			</Col>
			<Col span={24}>
				<Row gutter={[0, 30]}>
					<Col span={24}>
						<Mosaic />
					</Col>
					<Col span={24}>
						<Row wrap={false} justify="space-between" style={{ maxWidth: '100%' }}>
							<Col style={{ marginRight: 35 }}>
								<S.FilterButton onClick={handleOpenModal} icon={<FilterIcon width={20} />}>
									{I18n.get('Filters')}
								</S.FilterButton>
							</Col>
							<S.SectionsContainer>
								{sections.map(({ id, name, description }) => (
									<S.SectionButton
										key={id}
										$isSelected={isTabSelected(name)}
										onClick={() => handleChangeTab(name)}
										ref={(el) => (buttonRefs.current[name] = el)}
									>
										{I18n.get(description)}
									</S.SectionButton>
								))}
							</S.SectionsContainer>
						</Row>
					</Col>
					<Col span={24}>
						<Form form={form} initialValues={initialValues} onFinish={handleOnSubmit}>
							{tab[currentTab as keyof TabContent]}
							<GeneralFilter isOpen={isOpen} onClose={handleOpenModal} onClear={handleOnClearDynamic} />
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
