import React from 'react';
import { Col } from 'antd';
import { Variation } from './Variation';
import { Text } from '@/components/Typography';
import { CenteredCol } from '../styles';
import * as S from './styles';

interface ItemProps {
	name?: string;
	firstValue?: number;
	secondValue?: number;
}

export function Item({ name = '', firstValue = 0, secondValue = 0 }: Readonly<ItemProps>) {
	function getVariation(firstPeriodValue: number, secondPeriodValue: number): number {
		const difference = secondPeriodValue - firstPeriodValue;

		if (!difference) {
			return 0;
		}

		if (!firstPeriodValue) {
			return difference;
		}

		return (difference * 100) / firstPeriodValue;
	}

	const variation = getVariation(firstValue, secondValue);

	return (
		<S.Container align="middle">
			<Col span={12}>
				<Text>{name}</Text>
			</Col>
			<CenteredCol span={4}>
				<S.ScoreTag>{firstValue.toLocaleString(navigator.language)}</S.ScoreTag>
			</CenteredCol>
			<CenteredCol span={4}>
				<S.ScoreTag>{secondValue.toLocaleString(navigator.language)}</S.ScoreTag>
			</CenteredCol>
			<CenteredCol span={4}>
				<Variation>{variation}</Variation>
			</CenteredCol>
		</S.Container>
	);
}
