import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions, ChartData, Plugin, TooltipItem } from 'chart.js';
import { ChartContainer, COLORS } from './styles';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface DoughnutChartProps {
	labels?: string[];
	data?: number[];
	totalReports?: number;
}

export function DoughnutChart({ labels = [], data = [], totalReports = 0 }: Readonly<DoughnutChartProps>) {
	const chartData: ChartData<'doughnut'> = {
		labels: labels,
		datasets: [
			{
				data: data,
				backgroundColor: COLORS,
				borderColor: '#FFFFFF',
				borderWidth: 1,
				borderRadius: 3,
				hoverBorderWidth: 3
			}
		]
	};

	const options: ChartOptions<'doughnut'> = {
		responsive: true,
		animation: false,
		maintainAspectRatio: false,
		cutout: '65%',
		locale: navigator.language,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: function (context: TooltipItem<'doughnut'>) {
						const label = context.label || '';
						const percentage = (context.dataset.data[context.dataIndex] ?? 0) as number;

						return [I18n.get(label), `${percentage}%`];
					}
				}
			},
			datalabels: {
				color: '#FFFFFF',
				font: {
					weight: 'bold' as const,
					size: 16
				},
				display: (context) => {
					const percentage = (context.dataset.data[context.dataIndex] ?? 0) as number;
					return percentage >= 5;
				},
				formatter: (value: number, context: any) => {
					const percentage = (context.dataset.data[context.dataIndex] ?? 0) as number;
					const formattedPercentage = percentage ? `${Math.trunc(percentage)}%` : '';
					return value > 7 ? formattedPercentage : '';
				}
			}
		}
	};

	const centerTextPlugin: Plugin<'doughnut'> = {
		id: 'centerText',
		afterDraw: (chart) => {
			const {
				ctx,
				chartArea: { left, top, right, bottom }
			} = chart;
			const centerX = (left + right) / 2;
			const centerY = (top + bottom) / 2;

			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';

			ctx.font = 'bold 3rem Arial';
			ctx.fillStyle = '#000000';
			ctx.fillText(totalReports.toLocaleString(navigator.language), centerX, centerY - 10);

			ctx.font = '1.2rem Arial';
			ctx.fillText(I18n.get('Reports generated'), centerX, centerY + 20);
		}
	};

	return (
		<Row gutter={[16, 16]} align="middle">
			<Col span={24}>
				<ChartContainer>
					<Doughnut data={chartData} options={options} plugins={[centerTextPlugin]} />
				</ChartContainer>
			</Col>
		</Row>
	);
}
