import React from 'react';

export function KimmhoIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
	return (
		<svg viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M19.4767 1.1745C20.0156 1.0125 20.5725 0.931504 21.1383 0.931504C22.4092 0.931504 23.6308 1.35 24.6277 2.106C25.1666 1.944 25.7235 1.863 26.2893 1.863C27.5602 1.863 28.7817 2.2815 29.7787 3.0375C30.3176 2.8755 30.8745 2.7945 31.4403 2.7945C33.7576 2.7945 35.8458 4.176 36.7575 6.318C36.9194 6.69451 37.002 7.1005 37 7.5105L37 34.56C37 35.3565 36.3578 36 35.5629 36L19.2163 36C18.4214 36 17.7792 35.3565 17.7792 34.56L17.7792 28.7415L3.82169 24.8805C1.57179 24.2595 -6.03676e-07 22.1895 -7.05961e-07 19.8495C-7.64381e-07 18.513 0.529918 17.2665 1.49993 16.344C2.46546 15.4215 3.73188 14.949 5.06565 15.0165L10.5849 15.2865L10.5849 4.491C10.5849 3.9465 10.7286 3.41551 11.0025 2.9475C12.0579 1.1295 13.971 1.00663e-06 15.9874 9.18492e-07C17.2583 8.62939e-07 18.4798 0.4185 19.4767 1.1745ZM21.0171 32.76L33.7711 32.76L33.7711 29.115L21.0171 29.115L21.0171 32.76ZM13.8138 4.536L13.8138 18.684L4.90399 18.252C4.36957 18.225 3.8666 18.4635 3.53428 18.909C3.33219 19.1835 3.2289 19.521 3.2334 19.8585C3.23757 20.2897 3.38121 20.7079 3.64276 21.0503C3.90432 21.3927 4.26965 21.6409 4.68393 21.7575L20.8734 26.235L33.7711 26.235L33.7711 7.542C33.5699 7.0922 33.2433 6.71021 32.8306 6.44198C32.4179 6.17374 31.9367 6.03068 31.4448 6.03C31.0092 6.03 30.596 6.1335 30.2188 6.3405L29.0781 6.966L28.2249 5.9805C27.9851 5.70312 27.6885 5.48075 27.3553 5.32855C27.0221 5.17635 26.66 5.09789 26.2938 5.0985C25.8582 5.0985 25.4451 5.202 25.0678 5.409L23.9271 6.0345L23.0739 5.049C22.8341 4.77161 22.5375 4.54925 22.2043 4.39705C21.8711 4.24485 21.509 4.16639 21.1428 4.167C20.7072 4.167 20.2941 4.2705 19.9168 4.4775L18.7717 5.1075L17.9184 4.122C17.6787 3.84462 17.3821 3.62225 17.0488 3.47006C16.7156 3.31785 16.3536 3.23939 15.9874 3.24C15.1296 3.24 14.3033 3.735 13.8138 4.536Z"
				fill="#262626"
			/>
		</svg>
	);
}
