import React from 'react';
import styled from 'styled-components';
import { Text } from '@/components/Typography';

interface TextWrapperProps {
	$isBold: boolean;
}

export const TextWrapper = styled(Text)<TextWrapperProps>`
	font-size: 18px;
	margin-right: 0.3rem;
	font-weight: ${({ $isBold }) => ($isBold ? '600' : '400')};

	@media (min-width: 1600px) {
		font-size: 22px;
	}
`;

interface TitleProps {
	bold?: boolean;
	children: string;
}

export function Title({ bold = false, children }: TitleProps) {
	return <TextWrapper $isBold={bold}>{children}</TextWrapper>;
}
