import { BaseService } from './base-service';
import { LineDTO, GetLineOptionsParamsDTO } from '@/core/dto/line';

class LineService extends BaseService<any> {
	constructor(public readonly basePath: string = '/line') {
		super();
	}

	async listOptions(params: GetLineOptionsParamsDTO): Promise<LineDTO[]> {
		const url = this.basePath + `/${params.sector_id}`;
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}
}

const Service = Object.freeze(new LineService());
export { Service };
