import * as React from 'react';

interface AEPJDSChecklistProps {
	width?: string;
	height?: string;
}

export const AEPJDSChecklist = ({ width = '1rem', height = '1rem' }: Readonly<AEPJDSChecklistProps>) => {
	return (
		<svg
			fill="none"
			width={width}
			height={height}
			viewBox="0 0 36 36"
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMid meet"
		>
			<g filter="url(#filter0_d_6997_1716)">
				<path d="M17.5 4L30 16.5L17.5 29L5 16.5L17.5 4Z" fill="#4F7CD2" />
				<path
					strokeWidth="2"
					stroke="#4F7CD2"
					d="M6.41421 16.5L17.5 5.41421L28.5858 16.5L17.5 27.5858L6.41421 16.5Z"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_6997_1716"
					x="0"
					y="0"
					width="36"
					height="36"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						type="matrix"
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset dy="1" />
					<feGaussianBlur stdDeviation="2.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6997_1716" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6997_1716" result="shape" />
				</filter>
			</defs>
		</svg>
	);
};
