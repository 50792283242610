import styled from 'styled-components';
import { Col } from 'antd';

const COLOR_DEFAULT = '#26262640';

export const IconWrapper = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 5.5rem;
		fill: ${COLOR_DEFAULT};
	}
`;

export const TextWrapper = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		font-size: 1.1rem;
		color: ${COLOR_DEFAULT};
	}
`;
