import React, { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import {
	DndContext,
	closestCorners,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
	DragOverlay,
	DragStartEvent,
	DragOverEvent,
	DragEndEvent,
	PointerSensor,
	MeasuringStrategy
} from '@dnd-kit/core';

import { Card } from './Card';
import { Column } from './Column';
import { LexoRank } from './utils';
import { Actions } from '../Actions';

import type { Columns, Item } from '@/views/ActionPlans/types';
import { useUpdateCardRank } from '@/hooks/v2/useUpdateCardRank';
import { useApplicationContext } from '@/context/v1/Application/context';
import { I18n } from '@aws-amplify/core';
import { ActionPlanParams, ModalEdit } from '../../components/Modals/ModalEdit';
import { useQueryClient } from '@tanstack/react-query';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const initialColumns: Columns = {
	to_do: {
		name: 'Not started',
		items: [],
		color_primary: 'rgba(47, 84, 235, 1)',
		color_secondary: 'rgba(234, 238, 253, 1)'
	},
	doing: {
		name: 'Doing',
		items: [],
		color_secondary: 'rgba(254, 247, 233, 1)',
		color_primary: 'rgba(240, 174, 0, 1)'
	},
	done: {
		name: 'Done',
		items: [],
		color_secondary: 'rgba(23, 169, 59, 0.1)',
		color_primary: 'rgba(23, 169, 59, 1)'
	}
};

export function Board() {
	const query = useQuery();
	const lexoRank = new LexoRank();
	const queryClient = useQueryClient();
	const company_id = query.get('company');
	const { organization, company } = useApplicationContext();
	const [activeId, setActiveId] = useState<string | null>(null);
	const [columns, setColumns] = useState<Columns>(initialColumns);
	const [selectedRows, setSelectedRows] = useState<React.Key[]>();
	const hasSelectedRows = useMemo(() => !!selectedRows && selectedRows.length > 0, [selectedRows]);

	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
	const [selectedActionPlan, setSelectedActionPlan] = useState<ActionPlanParams>({} as ActionPlanParams);
	const [selectedCompanyIds, setSelectedCompanyIds] = useState<string[]>([]);

	const { mutateAsync: updateCardRank } = useUpdateCardRank();

	const isSorted = !!query.get('sort');
	const isFiltered = !!query.get('filter');
	const previousSortRef = React.useRef(isSorted);

	useEffect(() => {
		if (previousSortRef.current !== isSorted) {
			setColumns(initialColumns);
			Object.keys(columns).forEach((status) => {
				queryClient.invalidateQueries(['action-plan-cards', status]);
			});
			previousSortRef.current = isSorted;
		}
	}, [isSorted]);

	// useEffect(() => {
	// 	const handleFilterChange = async () => {
	// 		setColumns(initialColumns);
	// 		setSelectedRows([]);
	// 		queryClient.clear();
	// 	};

	// 	handleFilterChange();
	// }, [isFiltered]);

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 5
			}
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 50,
				tolerance: 5
			}
		}),
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 5
			}
		})
	);

	function handleColumnsChange(newColumns: Columns) {
		setColumns((prev) => {
			const updatedColumns = { ...newColumns };
			Object.keys(updatedColumns).forEach((key) => {
				if (!updatedColumns[key].items?.length && prev[key].items?.length) {
					updatedColumns[key].items = prev[key].items;
				}
			});
			return updatedColumns;
		});
	}

	const handleOnDragStart = (e: DragStartEvent) => {
		setActiveId(e.active.id as string);
	};

	const handleOnDragOver = ({ active, over }: DragOverEvent) => {
		if (!over) return;

		const activeContainer = active.data.current?.containerId;
		const overContainer = over.data.current?.containerId || over.id;

		if (activeContainer === overContainer) return;

		setColumns(moveItemBetweenColumns(activeContainer, overContainer, active.id as string));
	};

	const handleOnDragEnd = async ({ active, over }: DragEndEvent) => {
		setActiveId(null);
		if (!over) return;

		const activeContainer = active.data.current?.containerId;
		const overContainer = over.data.current?.containerId || over.id;

		setColumns((prev) => {
			const newColumns = moveItemWithinOrBetweenColumns(
				prev,
				activeContainer,
				overContainer,
				active.id as string,
				over.id as string
			);

			const movedItem = findMovedItem(newColumns, overContainer, active.id as string);

			if (movedItem) {
				const newRank = calculateNewRankForMovedItem(newColumns[overContainer].items, movedItem.id);
				movedItem.lexo_rank = newRank;
				updateCard(movedItem.id, newRank, movedItem.status);
			}

			return newColumns;
		});
	};

	const moveItemBetweenColumns = (activeContainer: string, overContainer: string, activeId: string) => {
		return (prev: Columns) => {
			if (!prev[activeContainer as keyof Columns] || !prev[overContainer as keyof Columns]) {
				return prev;
			}

			if (!prev[activeContainer as keyof Columns].items.length) {
				return prev;
			}

			const activeItems = prev[activeContainer as keyof Columns].items;
			const overItems = prev[overContainer as keyof Columns].items.filter(
				(item): item is Item => item !== undefined
			);
			const [movedItem] = activeItems.filter((item) => item?.id === activeId);

			if (!movedItem) {
				return prev;
			}

			movedItem.status = overContainer;
			movedItem.lexo_rank = calculateNewRank(overItems, overItems.length);

			const itemAlreadyExists = overItems.some((item) => item?.id === activeId);
			if (itemAlreadyExists) {
				return prev;
			}

			return {
				...prev,
				[activeContainer]: {
					...prev[activeContainer as keyof Columns],
					items: activeItems
						.filter((item) => item?.id !== activeId)
						.filter((item): item is Item => item !== undefined)
				},
				[overContainer]: {
					...prev[overContainer as keyof Columns],
					items: [...overItems, movedItem]
				}
			};
		};
	};

	const moveItemWithinOrBetweenColumns = (
		prev: Columns,
		activeContainer: string,
		overContainer: string,
		activeId: string,
		overId: string
	) => {
		if (!prev[activeContainer as keyof Columns] || !prev[overContainer as keyof Columns]) {
			return prev;
		}

		if (!prev[activeContainer as keyof Columns].items.length) {
			return prev;
		}

		const activeItems = [...prev[activeContainer as keyof Columns].items];
		const overItems =
			activeContainer === overContainer ? activeItems : [...prev[overContainer as keyof Columns].items];
		const activeIndex = activeItems.findIndex((item) => item?.id === activeId);
		const overIndex = overItems.findIndex((item) => item?.id === overId);
		const [movedItem] = activeItems.splice(activeIndex, 1);

		if (!movedItem) return prev;

		movedItem.status = overContainer;

		if (activeContainer === overContainer) {
			activeItems.splice(overIndex, 0, movedItem);
		} else {
			overItems.splice(overIndex, 0, movedItem);
		}

		return {
			...prev,
			[activeContainer]: { ...prev[activeContainer as keyof Columns], items: activeItems },
			[overContainer]: { ...prev[overContainer as keyof Columns], items: overItems }
		};
	};

	const calculateNewRank = (items: Item[], index: number) => {
		const prevRank = items[index - 1]?.lexo_rank || '';
		const nextRank = items[index + 1]?.lexo_rank || '';
		const newRank = lexoRank.generate(prevRank, nextRank);
		return newRank;
	};

	const calculateNewRankForMovedItem = (items: Item[], movedItemId: string): string => {
		const index = items.findIndex((item) => item.id === movedItemId);
		const prevRank = index > 0 ? items[index - 1].lexo_rank : '';
		const nextRank = index < items.length - 1 ? items[index + 1].lexo_rank : '';

		const newRank = lexoRank.generate(prevRank, nextRank);
		return newRank;
	};

	const findMovedItem = (columns: Columns, containerId: string, itemId: string) => {
		return columns[containerId as keyof Columns].items.find((item) => item?.id === itemId);
	};

	const getActiveItem = (): Item | null => {
		for (const column of Object.values(columns)) {
			const activeItem = column.items.find((item) => item?.id === activeId);
			if (activeItem) return activeItem;
		}
		return null;
	};

	async function updateCard(cardId: string, newRank: string, newStatus: string) {
		try {
			const item = { id: cardId, status: newStatus, lexo_rank: newRank, is_ordered: isSorted || isFiltered };
			const payload = { organization_id: organization.id, company_id: company.id, item };
			await updateCardRank(payload);
		} catch (error: any) {
			const errorMessage = error?.response?.data.message || error.message;
			message.error(I18n.get(errorMessage));
		}
	}

	const handleCardSelect = (cardId: string, checked: boolean, companyId: string) => {
		setSelectedRows((prev = []) => {
			if (checked) {
				setSelectedCompanyIds((prevIds) => [...prevIds, companyId]);
				return [...prev, cardId];
			}
			setSelectedCompanyIds((prevIds) => prevIds.filter((id) => id !== companyId));
			return prev.filter((id) => id !== cardId);
		});
	};

	function canChangeCardResponsible(): boolean {
		if (!selectedCompanyIds.length) {
			return false;
		}
		const firstCompanyId = selectedCompanyIds[0];
		return selectedCompanyIds.every((companyId) => companyId === firstCompanyId);
	}

	function handleToggleEdit(): void {
		setIsEditModalOpen(!isEditModalOpen);
	}

	function handleOnClickCard(data: ActionPlanParams): void {
		handleToggleEdit();
		setSelectedActionPlan(data);
	}

	return (
		<>
			<DndContext
				sensors={sensors}
				onDragEnd={handleOnDragEnd}
				onDragOver={handleOnDragOver}
				onDragStart={handleOnDragStart}
				collisionDetection={closestCorners}
				measuring={{
					droppable: {
						strategy: MeasuringStrategy.Always
					}
				}}
			>
				<Row gutter={[12, 0]}>
					{Object.entries(columns).map(([columnId, _]) => (
						<Col key={columnId} lg={8}>
							<Column
								status={columnId}
								columns={columns}
								activeId={activeId}
								onCardClick={handleOnClickCard}
								onColumnChange={handleColumnsChange}
								onCardSelect={handleCardSelect}
								selectedRows={selectedRows}
							/>
						</Col>
					))}
				</Row>
				<DragOverlay>
					{activeId ? <Card id={activeId} item={getActiveItem()!} onClick={handleOnClickCard} /> : null}
				</DragOverlay>
			</DndContext>
			<ModalEdit isOpen={isEditModalOpen} onClose={handleToggleEdit} data={selectedActionPlan} />
			{hasSelectedRows && (
				<Actions
					id={selectedRows || []}
					organizationId={organization.id}
					companyId={company_id ?? company.id}
					canChangeResponsible={canChangeCardResponsible()}
				/>
			)}
		</>
	);
}
