import React, { useEffect } from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useQueryClient } from '@tanstack/react-query';

import { CommentProps } from './types';
import { CommentForm } from './CommentForm';
import { FilePreview } from './FilePreview';
import { ModalCommentWrapper, StyledRowCenter } from '../styles';

import { QUERY_KEYS } from '@/utils/query-keys';
import { Title } from '@/components/Typography';
import { useUpdateAttachment } from '@/hooks/v2/useUpdateAttachment';

function useCommentForm({
	companyId,
	attachmentId,
	organizationId,
	handleCommentCancel,
	initialComment
}: Omit<CommentProps, 'previewImage' | 'previewType' | 'commentModalOpen'>) {
	const [form] = Form.useForm();
	const { mutateAsync: updateAttachment } = useUpdateAttachment();
	const queryClient = useQueryClient();

	useEffect(() => {
		form.setFieldsValue({ comment: initialComment });
	}, [initialComment, form]);

	async function handleFinish(values: { comment: string }) {
		await updateAttachment({
			company_id: companyId,
			description: values.comment,
			organization_id: organizationId,
			action_plan_attachment_id: attachmentId || ''
		});

		await queryClient.invalidateQueries({
			queryKey: [QUERY_KEYS.GET_ATTACHMENTS]
		});

		form.resetFields();
		handleCommentCancel();
	}

	return {
		form,
		handleFinish
	};
}

export function Comment({
	companyId,
	previewType,
	attachmentId,
	previewImage,
	organizationId,
	commentModalOpen,
	initialComment = '',
	handleCommentCancel
}: Readonly<CommentProps>) {
	const { form, handleFinish } = useCommentForm({
		companyId,
		attachmentId,
		organizationId,
		initialComment,
		handleCommentCancel
	});

	return (
		<ModalCommentWrapper
			centered
			footer={false}
			destroyOnClose
			open={commentModalOpen}
			onCancel={handleCommentCancel}
		>
			<StyledRowCenter gutter={[0, 20]}>
				<Col span={24}>
					<Title level={3}>{I18n.get('Add description')}</Title>
				</Col>
				<FilePreview previewType={previewType} previewImage={previewImage} />
				<CommentForm form={form} onFinish={handleFinish} onCancel={handleCommentCancel} />
			</StyledRowCenter>
		</ModalCommentWrapper>
	);
}
