import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { DateText, HistoryContainer, SubtitleHistory, TitleHistory } from '../styles';
import { I18n } from '@aws-amplify/core';

interface HistoryItemProps {
	description: string;
	type: string;
	author: string;
	createdAt: string | null;
}

export function HistoryItem({ description, type, author, createdAt }: Readonly<HistoryItemProps>) {
	return (
		<HistoryContainer>
			<Row justify="space-between" align="middle">
				<Col span={24}>
					<TitleHistory>
						{I18n.get(type)}: {I18n.get(description)}
					</TitleHistory>
				</Col>
				<Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
					<SubtitleHistory>
						{I18n.get('By')} {author}
					</SubtitleHistory>
					<DateText>{moment(createdAt).format('L')}</DateText>
				</Col>
			</Row>
		</HistoryContainer>
	);
}
