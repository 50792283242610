import React, { createContext, useContext, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Toast } from './Toast';
import { useApplicationContext } from '@/context/v1/Application/context';
import type { GetMosaicParams } from '@/views/Dashboard/hooks/useGetMosaic';
import type { Context, Methods, States } from './types';

const MAX_MOSAIC_LIMIT = 36;

interface MosaicProviderProps {
	children: ReactNode;
}

const MosaicDashboardContext = createContext<Context>({} as Context);

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export function MosaicResultProvider({ children }: Readonly<MosaicProviderProps>) {
	const query = useQuery();
	const custom_report_id = query.get('custom_report_id');
	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');
	const offset = query.get('offset');

	const { organization, company } = useApplicationContext();

	const [error, setErrorMessage] = useState<string>();
	const [warning, setWarningMessage] = useState<string>();

	const params: GetMosaicParams = {
		custom_report_id: custom_report_id ?? '',
		organization_id: organization?.id ?? '',
		workstation_id: workstation_id ?? undefined,
		company_id: company_id ?? undefined,
		sector_id: sector_id ?? undefined,
		line_id: line_id ?? undefined,
		offset: offset ? Number(offset) : 1,
		limit: MAX_MOSAIC_LIMIT
	};

	function handleSetErrorWarning(message: string, type_of: string): void {
		type_of === 'error' && setErrorMessage(message);
		type_of === 'warning' && setWarningMessage(message);

		setTimeout(() => {
			setErrorMessage(undefined);
			setWarningMessage(undefined);
		}, 1000);
	}

	const methods: Methods = {
		handleSetErrorWarning
	};

	const states: States = {
		organization_id: params?.organization_id,
		company_id: company?.id,
		params
	};

	const value: Context = {
		...states,
		...methods
	};

	return (
		<MosaicDashboardContext.Provider value={value}>
			{children}
			{!!error && <Toast message={error} type="error" />}
			{!!warning && <Toast message={warning} type="warning" />}
		</MosaicDashboardContext.Provider>
	);
}

export function useMosaicDashboardContext() {
	const context = useContext(MosaicDashboardContext);
	return context;
}
