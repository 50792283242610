import React, { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { I18n } from '@aws-amplify/core';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';

import AEPIcon from '@/assets/icons/dashboard/aep-icon-chart.png';
import AEPJDSIcon from '@/assets/icons/dashboard/aep-jds-icon-chart.png';
import JDSD86Icon from '@/assets/icons/dashboard/jds-d86-icon-chart.png';
import JDSD92Icon from '@/assets/icons/dashboard/jds-d92-icon-chart.png';
import { colors } from './styles';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

type Report = {
	label: string;
	data: number[];
};

type Result = {
	labels: string[];
	reports: Report[];
};

interface LineChartProps {
	result: Result;
}

export function LineChart({ result }: LineChartProps) {
	const [icons, setIcons] = useState<HTMLImageElement[] | null>(null);

	function getImages(src: string): Promise<HTMLImageElement> {
		return new Promise<HTMLImageElement>((resolve, reject) => {
			const img = new Image(20, 20);
			img.src = src;
			img.onload = () => resolve(img);
			img.onerror = reject;
		});
	}

	const getIcons = useCallback(async () => {
		try {
			const icons = [AEPIcon, AEPJDSIcon, JDSD86Icon, JDSD92Icon];
			const result = await Promise.all(icons.map(getImages));
			setIcons(result);
		} catch (error) {
			setIcons([]);
		}
	}, []);

	useEffect(() => {
		getIcons();
	}, [getIcons]);

	function createDatasets(reports: Report[], icons: HTMLImageElement[], colors: string[]) {
		return reports.map((report, index) => ({
			fill: false,
			pointRadius: 8,
			data: report.data,
			label: report.label,
			pointHoverRadius: 10,
			pointStyle: icons[index % icons.length],
			borderColor: colors[index % colors.length]
		}));
	}

	function createChartOptions() {
		return {
			scales: {
				x: {
					offset: true
				},
				y: {
					beginAtZero: true,
					title: {
						display: true,
						text: `${I18n.get('Number of reports')}`,
						font: {
							size: 17
						},
						color: '#000'
					}
				}
			},
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					enabled: true,
					callbacks: {
						label: (tooltipItem: any) => {
							const datasetLabel = tooltipItem.dataset.label || '';
							const value = tooltipItem.formattedValue;
							return `${I18n.get(datasetLabel)}: ${value}`;
						}
					}
				},
				datalabels: {
					display: false
				}
			},
			elements: {
				point: {
					hoverBorderWidth: 0,
					hoverBorderColor: 'transparent'
				}
			}
		};
	}

	if (icons === null) {
		return null;
	}

	const datasets = createDatasets(result.reports, icons, colors);

	const data = {
		datasets,
		labels: result.labels
	};

	const options = createChartOptions();

	return <Line data={data} options={options} />;
}
