interface CustomReportAttributes {
	id: string;
	name: string;
	description: string;
	acronym: string;
}

export class CustomReport {
	public readonly id: string;
	public readonly name: string;
	public readonly description: string;
	public readonly acronym: string;

	constructor({ id, name, acronym, description }: CustomReportAttributes) {
		this.id = id;
		this.name = name;
		this.acronym = acronym;
		this.description = description;
	}
}
