export const DEFAULT_COLUMNS = [
	{ dataIndex: 'title', title: 'Title' },
	{ dataIndex: 'score', title: 'Score' },
	{ dataIndex: 'tasks', title: 'Tasks' },
	{ dataIndex: 'author', title: 'Author' },
	{ dataIndex: 'origin', title: 'Origin' },
	{ dataIndex: 'status', title: 'Status' },
	{ dataIndex: 'dueDate', title: 'Due Date' },
	{ dataIndex: 'progress', title: 'Progress' },
	{ dataIndex: 'priority', title: 'Priority' },
	{ dataIndex: 'evidences', title: 'Evidences' },
	{ dataIndex: 'investment', title: 'Investment' },
	{ dataIndex: 'attachments', title: 'Attachments' },
	{ dataIndex: 'createdAt', title: 'Creation date' },
	{ dataIndex: 'responsible', title: 'Responsible' },
	{ dataIndex: 'completedAt', title: 'Date of Completion' }
];
