import React from 'react';
import { Moment } from 'moment';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomSelect } from '../styles';
import { handleDatePeriod } from './predetermined-helper';

const { useFormInstance } = Form;

interface TimeFrameProps {
	formName: string[];
}

type InputDatePeriod = { fiscal_year: boolean; predetermined: string };
type InputDate = { start_date: Moment; end_date: Moment };

export function TimeFrame({ formName }: Readonly<TimeFrameProps>) {
	const form = useFormInstance();

	function handleGetInputDatePeriod(): InputDatePeriod {
		const fiscal_year = form.getFieldValue([...formName, 'fiscal_year']);
		const predetermined = form.getFieldValue([...formName, 'time_frame']);
		return { fiscal_year, predetermined };
	}

	function handleSetPeriodDate({ start_date, end_date }: InputDate): void {
		form.setFieldValue([...formName, 'start_date'], start_date);
		form.setFieldValue([...formName, 'end_date'], end_date);
	}

	function handleOnChange(): void {
		const { fiscal_year, predetermined } = handleGetInputDatePeriod();
		const { start_date, end_date } = handleDatePeriod(fiscal_year, predetermined);
		handleSetPeriodDate({ start_date, end_date });
	}

	const options = [
		{ label: I18n.get('Today'), value: 'today' },
		{ label: I18n.get('This week'), value: 'week' },
		{ label: I18n.get('This month'), value: 'month' },
		{ label: I18n.get('This quarter'), value: 'quarter' },
		{ label: I18n.get('This semester'), value: 'semester' },
		{ label: I18n.get('This year'), value: 'year' },
		{ label: I18n.get('Last 7 days'), value: 'last_seven_days' },
		{ label: I18n.get('Last 30 days'), value: 'last_thirty_days' },
		{ label: I18n.get('Last 3 months'), value: 'last_three_months' },
		{ label: I18n.get('Last 6 months'), value: 'last_six_months' },
		{ label: I18n.get('Last 12 months'), value: 'last_twelve_months' },
		{ label: I18n.get('From the beginning'), value: 'from_beginning' }
	];

	return (
		<Row>
			<Col span={24}>
				<Form.Item name={[...formName, 'time_frame']} style={{ margin: 0 }}>
					<CustomSelect options={options} placeholder={I18n.get('Period')} onChange={handleOnChange} />
				</Form.Item>
			</Col>
		</Row>
	);
}
