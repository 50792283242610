import React from 'react';
import { I18n } from '@aws-amplify/core';
import type { FormFieldProps } from '../../types';
import * as S from './styles';

interface DueDateProps extends FormFieldProps {
	type?: 'date' | 'range';
}

export function DueDate({
	type = 'date',
	formName = [''],
	isDisabled = false,
	isRequired = false
}: Readonly<DueDateProps>) {
	const title = I18n.get('Deadline');
	const errorMessage = I18n.get('Select the due date');

	return (
		<S.CustomFormItem
			label={title}
			required={isRequired}
			labelCol={{ span: 24 }}
			name={[...formName, 'due_date']}
			rules={[{ required: isRequired, message: errorMessage }]}
		>
			{type === 'date' ? (
				<S.CustomDatePicker allowClear placeholder={title} format="L" disabled={isDisabled} />
			) : (
				<S.CustomRangePicker
					allowClear
					format="L"
					disabled={isDisabled}
					placeholder={[I18n.get('Start date'), I18n.get('End date')]}
				/>
			)}
		</S.CustomFormItem>
	);
}
