interface LibertyMutualPercentileByGenderAttributes {
	man: number;
	woman: number;
}

export class LibertyMutualPercentileByGender {
	public readonly man: number;
	public readonly woman: number;

	constructor({ man, woman }: LibertyMutualPercentileByGenderAttributes) {
		this.man = man;
		this.woman = woman;
	}
}
