import { Location } from 'history';
import { I18n } from '@aws-amplify/core';
import { getEntitiesFromSearchParams } from '../Navigation/entity-helpers';

const entityDisplayNames: { [key: string]: string } = {
	workstation: I18n.get('Workstation'),
	company: I18n.get('Company'),
	sector: I18n.get('Sector'),
	line: I18n.get('Line'),
	task: I18n.get('Task'),
	file: I18n.get('File')
};

export function getEntityNotFoundMessage(location: Location): string {
	const searchParams = new URLSearchParams(location.search);
	const entities = getEntitiesFromSearchParams(searchParams);
	const lastEntity = entities[entities.length - 1];

	const entityDisplayName = lastEntity
		? entityDisplayNames[lastEntity.entity] || I18n.get('Entity')
		: I18n.get('Entity');

	const message = I18n.get('{entity} not found').replace('{entity}', entityDisplayName);
	return message;
}
