import { I18n } from '@aws-amplify/core';
import { Mapper } from './base-mapper';
import { StrainIndexRSIDTO } from '@/core/dto/strain-index-rsi';
import { StrainIndexRSI, StrainIndexRSIItem } from '@/core/domain/strain-index-rsi';

export class StrainIndexRSIMapper implements Mapper<StrainIndexRSI> {
	private readonly less_or_equal_than_ten: number;
	private readonly greater_than_ten: number;

	constructor({ less_or_equal_than_ten, greater_than_ten }: StrainIndexRSIDTO) {
		this.less_or_equal_than_ten = less_or_equal_than_ten;
		this.greater_than_ten = greater_than_ten;
	}

	private calculateTotal(value: StrainIndexRSIDTO): number {
		return value.less_or_equal_than_ten + value.greater_than_ten;
	}

	private calculatePercentage(value: number, total: number): number {
		const percentage = (value / total) * 100;
		return Number(percentage.toFixed(2));
	}

	toTransform(value: StrainIndexRSIDTO): StrainIndexRSIItem[] {
		const total = this.calculateTotal(value);
		const i18n_label = I18n.get('Revised Strain Index');
		return [
			{
				description: `${i18n_label} ≤ 10`,
				percentage: this.calculatePercentage(value.less_or_equal_than_ten, total)
			},
			{
				description: `${i18n_label} > 10`,
				percentage: this.calculatePercentage(value.greater_than_ten, total)
			}
		];
	}

	toDomain(): StrainIndexRSI {
		return new StrainIndexRSI({
			items: this.toTransform({
				less_or_equal_than_ten: this.less_or_equal_than_ten,
				greater_than_ten: this.greater_than_ten
			}),
			total: this.less_or_equal_than_ten + this.greater_than_ten
		});
	}
}
