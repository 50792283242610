import { Mapper } from './base-mapper';
import {
	ActionPlanCreateUserPreferenceColumns,
	ActionPlanCreateUserPreferenceColumnsDomain
} from '../domain/action-plan-user-preference-columns';
import {
	ActionPlanCreateUserPreferenceColumnsDTO,
	ActionPlanCreateUserPreferenceColumnsItemDTO
} from '../dto/action-plan-user-preference-columns';

export class ActionPlanCreateUserPreferenceColumnsMapper
	implements Mapper<ActionPlanCreateUserPreferenceColumnsDomain>
{
	private readonly response: ActionPlanCreateUserPreferenceColumnsDTO;

	constructor(response: ActionPlanCreateUserPreferenceColumnsDTO) {
		this.response = {
			status: response.status,
			message: response.message,
			data: Array.isArray(response.data) ? response.data : []
		};
	}

	private mapActionPlanItem(
		item: ActionPlanCreateUserPreferenceColumnsItemDTO
	): ActionPlanCreateUserPreferenceColumns {
		return new ActionPlanCreateUserPreferenceColumns({
			organization_id: item.organization_id,
			company_id: item.company_id,
			columns: item.columns.map((column) => {
				return {
					score: column.score ?? '',
					responsible_user_id: column.responsible_user_id ?? '',
					due_date: column.due_date ?? '',
					completed_at: column.completed_at ?? '',
					priority: column.priority ?? 0,
					investment: column.investment ?? 0,
					attachments: column.attachments ?? 0,
					tasks: column.tasks ?? 0,
					progress: column.progress ?? 0,
					evidence: column.evidence ?? 0,
					author: column.author ?? '',
					origin: column.origin ?? '',
					created_at: column.created_at ?? ''
				};
			})
		});
	}

	toDomain(): ActionPlanCreateUserPreferenceColumnsDomain {
		return {
			status: this.response.status,
			message: this.response.message,
			data: this.response.data.map((item) => this.mapActionPlanItem(item))
		};
	}
}
