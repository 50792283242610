import { Mapper } from './base-mapper';
import { ActionPlanCountStatus } from '../domain/action-plan-count-status';
import { ActionPlanCountStatusDTO, ActionPlanStatusParamsDTO } from '../dto/action-plan-count-status';

export class ActionPlanCountStatusMapper implements Mapper<ActionPlanCountStatus> {
	private readonly total: ActionPlanStatusParamsDTO;
	private readonly delayed: ActionPlanStatusParamsDTO;

	constructor({ total, delayed }: ActionPlanCountStatusDTO) {
		this.total = total;
		this.delayed = delayed;
	}

	toDomain(): ActionPlanCountStatus {
		return new ActionPlanCountStatus({
			total: this.total,
			delayed: this.delayed
		});
	}
}
