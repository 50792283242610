export class LexoRank {
	private readonly VALID_CHARS: string = '0123456789abcdefghijklmnopqrstuvwxyz';
	private readonly MIN_LENGTH: number = 8;
	private readonly DEFAULT_RANK: string = 'm00000000';

	generate(prevRank: string = '', nextRank: string = ''): string {
		if (!prevRank && !nextRank) {
			return this.DEFAULT_RANK;
		}

		if (this.isInvalidRank(prevRank) && this.isInvalidRank(nextRank)) {
			return this.DEFAULT_RANK;
		}

		if (this.isInvalidRank(prevRank)) {
			return this.decrementRank(this.ensureMinLength(nextRank));
		}

		if (this.isInvalidRank(nextRank)) {
			return this.incrementRank(this.ensureMinLength(prevRank));
		}

		prevRank = this.ensureMinLength(prevRank);
		nextRank = this.ensureMinLength(nextRank);

		const newRank = this.middleRank(prevRank, nextRank);
		return newRank;
	}

	private isInvalidRank(rank: string): boolean {
		if (!rank) return true;
		const cleanRank = rank.replace(/[^0-9a-z]/g, '');
		return cleanRank.length === 0;
	}

	private ensureMinLength(rank: string): string {
		const cleanRank = rank.replace(/[^0-9a-z]/g, '');
		return cleanRank.padEnd(this.MIN_LENGTH, '0');
	}

	private middleRank(prevRank: string, nextRank: string): string {
		const prevCodes = this.rankToCodes(prevRank);
		const nextCodes = this.rankToCodes(nextRank);
		const newCodes: number[] = [];

		let i = 0;
		while (true) {
			const prevCode = prevCodes[i] !== undefined ? prevCodes[i] : 0;
			const nextCode = nextCodes[i] !== undefined ? nextCodes[i] : this.VALID_CHARS.length - 1;

			if (prevCode === nextCode) {
				newCodes.push(prevCode);
				i++;
				continue;
			}

			const midCode = Math.floor((prevCode + nextCode) / 2);
			if (midCode > prevCode) {
				newCodes.push(midCode);
				break;
			} else {
				newCodes.push(prevCode);
				i++;
			}
		}

		const newRank = this.codesToRank(newCodes);
		return this.ensureMinLength(newRank);
	}

	private decrementRank(rank: string): string {
		const codes = this.rankToCodes(rank);
		let borrow = true;

		for (let i = codes.length - 1; i >= 0 && borrow; i--) {
			if (codes[i] > 0) {
				codes[i]--;
				borrow = false;
			} else {
				codes[i] = this.VALID_CHARS.length - 1;
			}
		}

		return this.codesToRank(codes);
	}

	private incrementRank(rank: string): string {
		const codes = this.rankToCodes(rank);
		let carry = true;

		for (let i = codes.length - 1; i >= 0 && carry; i--) {
			if (codes[i] < this.VALID_CHARS.length - 1) {
				codes[i]++;
				carry = false;
			} else {
				codes[i] = 0;
			}
		}

		if (carry) {
			codes.unshift(1);
		}

		return this.codesToRank(codes);
	}

	private rankToCodes(rank: string): number[] {
		return rank
			.replace(/[^0-9a-z]/g, '')
			.split('')
			.map(char => this.VALID_CHARS.indexOf(char));
	}

	private codesToRank(codes: number[]): string {
		return codes
			.map(code => this.VALID_CHARS[Math.max(0, Math.min(this.VALID_CHARS.length - 1, code))])
			.join('');
	}
}

