import React from 'react';
import { Row, Col, Skeleton } from 'antd';

export function GridSkeleton() {
	return (
		<Row gutter={[16, 16]}>
			{Array.from({ length: 8 }).map((_, index) => (
				<Col key={index} span={24}>
					<Skeleton.Button active block />
				</Col>
			))}
		</Row>
	);
}
