import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CUSTOM_REPORT_NAME } from '@/utils/customReport';
import { EwaD86Comparison } from '../Charts/EwaD86Comparison';
import { ChecklistReports } from '../Charts/ChecklistReports';
import { ActionPlanStatus } from '../Charts/ActionPlans/Status';
import { RiskEvolutionTable } from '../Charts/RiskEvolutionTable';
import { CreatedResolved } from '../Charts/ActionPlans/CreatedResolved';

interface ErgonomicRiskProps {
	handleClear(fieldNames: string[]): void;
}

export function ErgonomicRisk({ handleClear }: Readonly<ErgonomicRiskProps>) {
	return (
		<Row gutter={[30, 30]}>
			<Col xs={24} xxl={8}>
				<Row gutter={[30, 30]}>
					<Col xs={8} xxl={24}>
						<CreatedResolved handleClear={handleClear} />
					</Col>
					<Col xs={16} xxl={24}>
						<ActionPlanStatus handleClear={handleClear} />
					</Col>
				</Row>
			</Col>
			<Col xs={24} xxl={16}>
				<ChecklistReports handleClear={handleClear} />
			</Col>
			<Col span={24}>
				<EwaD86Comparison onClear={handleClear} />
			</Col>
			<Col xs={24} xxl={12}>
				<RiskEvolutionTable
					onClear={handleClear}
					name={CUSTOM_REPORT_NAME.EWA}
					title={I18n.get('PEA (NR17)')}
				/>
			</Col>
			<Col xs={24} xxl={12}>
				<RiskEvolutionTable
					onClear={handleClear}
					name={CUSTOM_REPORT_NAME.EWA_D86}
					title={I18n.get('PEA (NR17 + JDS D86)')}
				/>
			</Col>
			<Col xs={24} xxl={12}>
				<RiskEvolutionTable title="JDS-D86" onClear={handleClear} name={CUSTOM_REPORT_NAME.JDS_D86} />
			</Col>
			<Col xs={24} xxl={12}>
				<RiskEvolutionTable title="JDS-D92" onClear={handleClear} name={CUSTOM_REPORT_NAME.JDS_D92} />
			</Col>
		</Row>
	);
}
