import styled from 'styled-components';
import { Col, Button } from 'antd';

const BLUE_COLOR = '#2f54eb';

interface SectionButtonProps {
	$isSelected?: boolean;
}

export const FilterButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	padding: 1.2rem 0.6rem;
	font-size: 1.1rem;
	color: #ffffff;
	border: 2px solid ${BLUE_COLOR};
	box-shadow: 2px 4px 10px 0px #00000026;
	background-color: ${BLUE_COLOR} !important;

	&:hover,
	&:focus {
		color: #ffffff;
	}

	span {
		margin-left: 0.8rem;
	}
`;

export const SectionButton = styled(Button)<SectionButtonProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 9px;
	padding: 1.2rem;
	font-size: 1.1rem;
	font-weight: 600;
	color: ${({ $isSelected }) => ($isSelected ? BLUE_COLOR : '#262626')};
	border: 2px solid ${({ $isSelected }) => ($isSelected ? BLUE_COLOR : '#E6E6E6')};
	background-color: ${({ $isSelected }) => ($isSelected ? '#EAEEFD' : '#ffffff')};

	@media (min-width: 1800px) {
		flex: 1;
	}
`;

export const StyledDivPopOver = styled.div`
	max-width: 31rem;
`;

export const SectionsContainer = styled(Col)`
	gap: 20px;
	display: flex;
	overflow-x: auto;
	flex-wrap: nowrap;
	flex-direction: row;
	padding-bottom: 0.6rem;

	@media (min-width: 1800px) {
		width: 100%;
	}

	::-webkit-scrollbar {
		height: 4px;
	}

	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	::-webkit-scrollbar-thumb {
		background-color: #b3b3b3;
		border-radius: 5px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;
