import React from 'react';
import { Col } from 'antd';
import { Text } from '@/components/Typography';
import { getResultRPN } from '@/utils/riskRange';
import * as S from './styles';

export const riskTitleMapper: Record<number, string> = {
	1: 'Acceptable risk',
	2: 'Moderate risk',
	3: 'High risk',
	4: 'Very high risk',
	5: 'Severe risk'
};

interface RiskTagProps {
	worstScore: number;
}

export function RiskTag({ worstScore }: Readonly<RiskTagProps>) {
	const result = getResultRPN(worstScore);
	const title = riskTitleMapper[result];

	return (
		<S.Container $score={result}>
			<Col span={24} style={{ textAlign: 'center' }}>
				<Text>{title}</Text>
			</Col>
		</S.Container>
	);
}
