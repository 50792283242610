import * as React from 'react';

interface AepChecklistProps {
	width?: string;
	height?: string;
}

export const AEPChecklist = ({ width = '1rem', height = '1rem' }: Readonly<AepChecklistProps>) => {
	return (
		<svg
			fill="none"
			width={width}
			height={height}
			viewBox="0 0 36 36"
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMid meet"
		>
			<g filter="url(#filter0_d_6997_1713)">
				<path
					fill="#9CB5FA"
					d="M18 4L27.1924 7.80761L31 17L27.1924 26.1924L18 30L8.80761 26.1924L5 17L8.80761 7.80761L18 4Z"
				/>
				<path
					strokeWidth="2"
					stroke="#9CB5FA"
					d="M9.57298 8.57298L18 5.08239L26.427 8.57298L29.9176 17L26.427 25.427L18 28.9176L9.57298 25.427L6.08239 17L9.57298 8.57298Z"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_6997_1713"
					x="0"
					y="0"
					width="36"
					height="36"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						type="matrix"
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset dy="1" />
					<feGaussianBlur stdDeviation="2.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6997_1713" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6997_1713" result="shape" />
				</filter>
			</defs>
		</svg>
	);
};
