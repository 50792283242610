import React from 'react';
import { Row, Form } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';

import { Card } from '../../components/Card';
import { DonutChart } from '../../components/DonutChart';
import { Single } from '@/views/Dashboard/components/Filters/Single';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetRebaExposureBody } from '../../hooks/useGetRebaExposureBody';
import { unShortenUrl } from '@/views/Dashboard/utils';
import { labels, bodyPartNameMapper } from './enum';
import { StyledDivPopOver } from '../../styles';
import * as S from './styles';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const { useFormInstance } = Form;

interface ChecklistReportsProps {
	handleClear(fieldNames: string[]): void;
}

export function RebaExposureBody({ handleClear }: Readonly<ChecklistReportsProps>) {
	const { organization } = useApplicationContext();
	const query = useQuery();
	const location = useLocation();
	const form = useFormInstance();

	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');

	const unshortenedUrl = unShortenUrl(location.search.substring(1));
	const rebaExposureBodyParams = unshortenedUrl?.reba_body ?? unshortenedUrl?.general?.unique_period;

	const filter_params = {
		organization_id: organization?.id!,
		company_id: company_id ?? undefined,
		sector_id: sector_id ?? undefined,
		line_id: line_id ?? undefined,
		workstation_id: workstation_id ?? undefined,
		end_date: rebaExposureBodyParams?.end_date ?? undefined,
		start_date: rebaExposureBodyParams?.start_date ?? undefined
	};

	const { isLoading, data, isError, refetch } = useGetRebaExposureBody(filter_params);

	function handleOnSubmit(): void {
		form.submit();
	}

	return (
		<Card.Container size="medium" isLoading={isLoading} isError={isError} onRefetch={refetch}>
			<Card.Header
				hasIcon
				isTitleBold
				title={I18n.get('REBA')}
				subtitle={I18n.get('Exposure by body side')}
				icon={<CalendarOutlined />}
				popoverComponent={
					<StyledDivPopOver>
						<Single
							hasGranularity={false}
							fieldName={['reba_body']}
							handleClear={handleClear}
							handleFormSubmit={handleOnSubmit}
						/>
					</StyledDivPopOver>
				}
			/>
			<Card.Content>
				<Row style={{ marginTop: '1rem' }} gutter={[0, 30]} justify="center">
					{data &&
						data?.bodySides.map((bodySide: { name: string | number; data: any[] }) => (
							<S.StyledCol span={12} key={bodySide.name}>
								<S.DonutChartWrapper>
									<DonutChart
										data={{
											labels: labels,
											values: bodySide.data.map((item) => item.percentage),
											colors: bodySide.data.map((item) => S.colors[item.risk ?? 0])
										}}
										bodyPart={
											bodyPartNameMapper[bodySide.name as keyof typeof bodyPartNameMapper] ||
											String(bodySide.name)
										}
									/>
								</S.DonutChartWrapper>
							</S.StyledCol>
						))}
				</Row>
			</Card.Content>
		</Card.Container>
	);
}
