import { Mapper } from './base-mapper';
import { CustomReportWorstScores } from '@/core/domain/custom-report-worst-scores';
import { CustomReportWorstScoresDTO } from '@/core/dto/custom-report-worst-scores';

export class CustomReportWorstScoresMapper extends Mapper<CustomReportWorstScores[]> {
	private readonly items: CustomReportWorstScoresDTO[];

	constructor(items: CustomReportWorstScoresDTO[]) {
		super();
		this.items = items;
	}

	splitArray(array: CustomReportWorstScores[]) {
		const half = 5;

		if (array.length <= half) {
			return [array, []];
		}

		const firstPart = array.slice(0, half);
		const secondPart = array.slice(half);

		return [firstPart, secondPart];
	}

	toTransform(items: CustomReportWorstScoresDTO[]): CustomReportWorstScores[][] {
		const worstScoresRank = items.map(({ id, name, worst_score }, index) => {
			const rank = index + 1;

			return {
				id,
				name,
				rank,
				worst_score
			};
		});

		return this.splitArray(worstScoresRank);
	}

	toDomain(): CustomReportWorstScores[][] {
		return this.toTransform(this.items);
	}
}
