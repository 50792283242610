import { BaseService } from './base-service';
import { WorkstationDTO, GetWorkstationOptionsParamsDTO } from '@/core/dto/workstation';

class WorkstationService extends BaseService<WorkstationDTO> {
	constructor(public readonly basePath: string = '/workstation') {
		super();
	}

	async listOptions(params: GetWorkstationOptionsParamsDTO): Promise<WorkstationDTO[]> {
		const url = this.basePath + `/${params.line_id}`;
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}
}

const Service = Object.freeze(new WorkstationService());
export { Service };
