export enum Panels {
	BOARD = 'board',
	TABLE = 'table'
}

export enum ActionPlansStatus {
	TO_DO = 'TO DO',
	DOING = 'DOING',
	DONE = 'DONE'
}

export const TO_DO_PRIMARY_COLOR = '#2F54EB';
export const DOING_PRIMARY_COLOR = '#F0AE00';
export const DONE_PRIMARY_COLOR = '#2CC852';

export const TO_DO_SECONDARY_COLOR = '#EAEEFD';
export const DOING_SECONDARY_COLOR = '#FEF7E9';
export const DONE_SECONDARY_COLOR = '#E8F7EC';
