import * as React from 'react';

interface CheckedProps {
	width?: string;
	height?: string;
}

export const Checked = ({ width = '1rem', height = '1rem' }: Readonly<CheckedProps>) => {
	return (
		<svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.5 8.8125L7.78571 11L11.5 6"
				stroke="#262626"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect x="1" y="1" width="15" height="15" rx="1" stroke="#262626" strokeWidth="2" />
		</svg>
	);
};
