import React from 'react';
import { Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CUSTOM_REPORT_RISKS } from '@/utils/enum';
import {
	MAX_HIGH_RISK_RPN,
	MAX_SERIOUS_RISK_RPN,
	MAX_MODERATE_RISK_RPN,
	MAX_VERY_HIGH_RISK_RPN,
	MAX_ACCEPTABLE_RISK_RPN,
	MIN_ACCEPTABLE_RISK_RPN
} from '@/utils/constants';
import * as S from './styles';

const NEUTRAL_RISK = 6;

interface ScoreCardProps {
	score?: number;
}

export function ScoreCard({ score = 0 }: Readonly<ScoreCardProps>) {
	function getResultRPN(value: number): number {
		if (value > MAX_SERIOUS_RISK_RPN) {
			return CUSTOM_REPORT_RISKS.DEFAULT;
		}

		if (value > MAX_VERY_HIGH_RISK_RPN) {
			return CUSTOM_REPORT_RISKS.SERIOUS_IMMINENT;
		}

		if (value > MAX_HIGH_RISK_RPN) {
			return CUSTOM_REPORT_RISKS.VERY_HIGH;
		}

		if (value > MAX_MODERATE_RISK_RPN) {
			return CUSTOM_REPORT_RISKS.HIGH;
		}

		if (value > MAX_ACCEPTABLE_RISK_RPN) {
			return CUSTOM_REPORT_RISKS.MODERATE;
		}

		if (value > MIN_ACCEPTABLE_RISK_RPN) {
			return CUSTOM_REPORT_RISKS.ACCEPTABLE;
		}

		return NEUTRAL_RISK;
	}

	const result = getResultRPN(score);

	return (
		<S.Container justify="center" align="middle" gutter={[5, 0]} $result={result}>
			<Col>
				<span style={{ fontWeight: 600 }}>{I18n.get('RPN')}:</span>
			</Col>
			<Col>
				<span>{score}</span>
			</Col>
		</S.Container>
	);
}
