import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Service } from '@/infra/services/action-plan';
import { CreateActionPlanResponseDTO } from '@/core/dto/action-plan-creation';
import type { Response } from '@/types';

type ActionPlanStatus = 'TO DO' | 'DOING' | 'DONE';

type ActionPlanOrigin = {
	origin_name: string;
	table_name: string;
	column_id: string;
};

interface CreateActionPlanParams {
	organization_id: string;
	company_id: string;
	workstation_id: string;
	title: string;
	due_date: Date;
	responsible_user_id: string;
	investment_range: number;
	priority: number;
	status: ActionPlanStatus;
	origin: ActionPlanOrigin;
}

export const useCreateActionPlan = () => {
	return useMutation<CreateActionPlanResponseDTO, AxiosError<Response>, CreateActionPlanParams>((params) =>
		Service.create(params)
	);
};
