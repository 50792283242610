import styled, { css } from 'styled-components';
import type { Transform } from '@dnd-kit/utilities';

interface CardProps {
	transform?: Transform | null;
	isDragging?: boolean;
	transition?: string;
	isSelected?: boolean;
}

export const Card = styled.div<CardProps>`
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
	padding: 20px;
	margin-bottom: 12px;
	cursor: grab;
	user-select: none;
	width: 100%;
	height: 200px;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	-webkit-font-smoothing: subpixel-antialiased;

	@media (max-width: 1366px) {
		.ant-typography {
			font-size: 18px;
		}
	}

	${({ isDragging }) =>
		isDragging &&
		css`
			box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
			cursor: grabbing;
		`}

	${({ isSelected }) =>
		isSelected &&
		css`
			border: 2px solid #2f54eb;
		`}
`;

export const ClickableArea = styled.div`
	cursor: pointer;
`;

export const Score = styled.span<{ isDark?: boolean; color: string }>`
	color: ${({ isDark }) => (isDark ? '#fff' : '#000')};
	background-color: ${({ color }) => color};
	border-radius: 6px;
	padding: 5px 10px;
	font-weight: 400;
	font-size: 14px;
	width: 100%;
`;

export const Paragraph = styled.p<{ isExpired?: boolean }>`
	align-items: center;
	margin-bottom: 2px;
	font-weight: 500;
	font-size: 15px;
	display: flex;
	color: ${({ isExpired }) => (isExpired ? 'red' : 'inherit')};

	.anticon {
		color: ${({ isExpired }) => (isExpired ? 'red' : 'inherit')};
		justify-content: center;
		align-items: center;
		padding-top: 15px;
		margin-left: 5px;
		font-size: 30px;
		display: flex;
		height: 30px;
		width: 30px;
	}
`;

export const TitleWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  margin-bottom: 8px;
`;
