import styled from 'styled-components';
import { Button, Checkbox, Col, Modal } from 'antd';
import { Text } from '@/components/Typography';

interface CustomCheckBoxProps {
	$selected?: boolean;
}

export const ModalWrapper = styled(Modal)`
	.ant-modal-content {
		border-radius: 30px !important;
	}

	.ant-modal-body {
		padding: 2rem 3rem;
	}
`;

export const CustomCheckBox = styled(Checkbox)<CustomCheckBoxProps>`
	font-size: 1rem;
	text-decoration: ${({ $selected }) => ($selected ? 'line-through' : 'none')};
`;

export const Label = styled(Text)`
	font-size: 1.1rem;
`;

export const PrimaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	background-color: #2f54eb;
	box-shadow: 0px 2px 0px 0px #0000000b;
`;

export const SecondaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	box-shadow: 0px 1px 0px 0px #0000000b;
`;

export const CustomColumn = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: solid 1px #d9d9d9;
`;
