import React from 'react';
import { Row, Col } from 'antd';
import { Header } from './Header';
import { Content } from './Content';

export function Container() {
	return (
		<Row gutter={[0, 12]}>
			<Col span={24}>
				<Header />
			</Col>
			<Col span={24}>
				<Content />
			</Col>
		</Row>
	);
}
