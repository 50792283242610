import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import * as S from './styles';

interface FooterProps {
	onSubmit(): void;
	onCancel(): void;
	loading: boolean;
}

export function Footer({ loading, onSubmit, onCancel }: Readonly<FooterProps>) {
	return (
		<Row justify="center" gutter={[20, 0]}>
			<Col>
				<S.SecondaryButton onClick={onCancel}>{I18n.get('Back')}</S.SecondaryButton>
			</Col>
			<Col>
				<S.PrimaryButton type="primary" onClick={onSubmit} loading={loading}>
					{I18n.get('Save')}
				</S.PrimaryButton>
			</Col>
		</Row>
	);
}
