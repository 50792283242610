import React from 'react';
import { I18n } from '@aws-amplify/core';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import { colors, DoughnutChartWrapper } from './styles';

interface DoughnutChartProps {
	labels?: string[];
	data?: number[];
}

export function BeraDoughnut({ labels = [], data = [] }: Readonly<DoughnutChartProps>) {
	const chartData: ChartData<'doughnut'> = {
		labels: labels,
		datasets: [
			{
				data: data,
				borderWidth: 1,
				borderRadius: 3,
				hoverBorderWidth: 3,
				borderColor: colors,
				backgroundColor: colors
			}
		]
	};

	const options: ChartOptions<'doughnut'> = {
		responsive: true,
		animation: false,
		maintainAspectRatio: false,
		cutout: '60%',
		plugins: {
			legend: {
				display: false,
				position: 'bottom'
			},
			tooltip: {
				enabled: false
			},
			datalabels: {
				color: '#FFFFFF',
				font: {
					size: 12,
					weight: 'bold' as const
				},
				formatter: (value: number) => {
					return value > 0 ? `${value}%` : '';
				}
			}
		}
	};

	const centerTextPlugin: Plugin<'doughnut'> = {
		id: 'centerText',
		afterDraw: (chart) => {
			const {
				ctx,
				chartArea: { left, top, right, bottom }
			} = chart;
			const centerX = (left + right) / 2;
			const centerY = (top + bottom) / 2;

			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.font = 'bold 36px Arial';
			ctx.fillStyle = '#000000';
			ctx.fillText(`${data[0] && data[0] > 0 ? data[0] : 0}%`, centerX, centerY - 10);
			ctx.font = '10px Arial';

			ctx.fillText(I18n.get('of the workstation are'), centerX, centerY + 10);
			ctx.fillText('GNS', centerX, centerY + 25);
		}
	};

	return <DoughnutChartWrapper data={chartData} options={options} plugins={[centerTextPlugin]} />;
}
