import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Service } from '@/infra/services/action-plan';
import { AddAttachmentResponseDTO } from '@/core/dto/action-plan-attachment';
import type { Response } from '@/types';

interface AddAttachmentParams {
	organization_id: string;
	company_id: string;
	action_plan_id: string;
	file_name: string;
	size: number;
	content_type: string;
}

export const useAddAttachment = () => {
	return useMutation<AddAttachmentResponseDTO, AxiosError<Response>, AddAttachmentParams>((params) =>
		Service.attachment(params)
	);
};
