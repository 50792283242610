export type MosaicEntity = 'organization' | 'company' | 'sector' | 'line';

interface MosaicItemAttributes {
	id: string;
	name: string;
	score: number;
}

interface MosaicAttributes {
	rows: MosaicItem[];
	entity: MosaicEntity;
	offset: number;
	total: number;
	limit: number;
}

export class MosaicItem {
	public readonly id: string;
	public readonly name: string;
	public readonly score: number;

	constructor({ id, name, score }: MosaicItemAttributes) {
		this.id = id;
		this.name = name;
		this.score = score;
	}
}

export class Mosaic {
	public readonly rows: MosaicItem[];
	public readonly entity: MosaicEntity;
	public readonly offset: number;
	public readonly total: number;
	public readonly limit: number;

	constructor({ rows, entity, offset, total, limit }: MosaicAttributes) {
		this.rows = rows;
		this.entity = entity;
		this.offset = offset;
		this.total = total;
		this.limit = limit;
	}
}
