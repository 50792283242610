interface ActionPlanDelayedAttributes {
	total: number;
	delayed: number;
}

export class ActionPlanDelayed {
	public readonly total: number;
	public readonly delayed: number;

	constructor({ total, delayed }: ActionPlanDelayedAttributes) {
		this.total = total;
		this.delayed = delayed;
	}
}
