import React from 'react';
import { Row, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { useQueries } from '@tanstack/react-query';
import { CalendarOutlined } from '@ant-design/icons';

import { Labels } from './Labels';
import { Totalizer } from './Totalizer';
import { LineChart } from './LineChart';
import { Card } from '@/views/Dashboard/components/Card';
import { Single } from '@/views/Dashboard/components/Filters/Single';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetCustomReports } from '@/views/Dashboard/hooks/useGetCustomReports';
import { getChecklistReports } from '@/views/Dashboard/hooks/useGetChecklistGenerated';

import {
	DEFAULT_GRANULARITY,
	QUERY_STATUS_SUCCESS,
	QUERY_STATUS_LOADING,
	CUSTOM_REPORT_FILTER_BERA,
	CUSTOM_REPORT_FILTER_SERA
} from './enums';

import { unShortenUrl } from '../../utils';
import { StyledDivPopOver } from '../../styles';
import { QUERY_KEYS } from '@/utils/query-keys';
import * as S from './styles';

const { useFormInstance } = Form;

interface ChecklistReportsProps {
	handleClear(fieldNames: string[]): void;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export function ChecklistReports({ handleClear }: ChecklistReportsProps) {
	const { organization, company } = useApplicationContext();
	const query = useQuery();
	const location = useLocation();
	const form = useFormInstance();

	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');

	const unshortenUrl = unShortenUrl(location.search.substring(1));
	const checklistParams = unshortenUrl?.checklist ?? unshortenUrl?.general?.unique_period;

	const filterParams = {
		organization_id: organization?.id!,
		line_id: line_id ?? undefined,
		sector_id: sector_id ?? undefined,
		company_id: company_id ?? undefined,
		workstation_id: workstation_id ?? undefined,
		granularity: checklistParams?.granularity ?? DEFAULT_GRANULARITY,
		fiscal_year: checklistParams?.fiscal_year ?? true,
		created_at_end: checklistParams?.end_date ?? undefined,
		created_at_start: checklistParams?.start_date ?? undefined
	};

	function handleOnSubmit(): void {
		form.submit();
	}

	const {
		refetch,
		isError: isErrorReports,
		isLoading: isLoadingReports,
		data: unfiltered_custom_reports
	} = useGetCustomReports({
		organization_id: organization?.id,
		company_id: company_id ?? company?.id
	});

	const custom_reports = unfiltered_custom_reports?.filter(
		(custom_report) =>
			custom_report.name !== CUSTOM_REPORT_FILTER_BERA && custom_report.name !== CUSTOM_REPORT_FILTER_SERA
	);

	const reports_queries = useQueries({
		queries: (custom_reports || []).map((custom_report) => ({
			queryKey: [QUERY_KEYS.GET_CHECKLIST_REPORTS, custom_report.id],
			queryFn: () =>
				getChecklistReports({
					...filterParams,
					custom_report_id: custom_report.id
				}),
			enabled: !!custom_report.id
		}))
	});

	const isReportsLoading = reports_queries.some((query) => query.status === QUERY_STATUS_LOADING);
	const isAllSuccess = reports_queries.every((query) => query.status === QUERY_STATUS_SUCCESS);
	const reportsData = reports_queries.map((query) => query.data as any);

	function translateLabels() {
		const labels = reportsData?.[0]?.labels;
		const { granularity } = filterParams;

		if (granularity === 'year') {
			return labels;
		}

		const granularityMapper: Record<string, (inputs: string[][]) => string[][]> = {
			day: translateDayLabels,
			week: translateWeekLabels,
			month: translateMonthLabels,
			quarter: translateQuarterLabels,
			semester: translateSemesterLabels
		};

		const translations = granularityMapper[granularity](labels);
		return translations || labels;
	}

	function translateDayLabels(inputs: string[][]): string[][] {
		const dayTranslations = inputs?.map(([date, year]) => {
			const [day, month] = date.split(' ');
			return [`${day + ' ' + I18n.get(month)}`, year];
		});
		return dayTranslations;
	}

	function translateWeekLabels(inputs: string[][]): string[][] {
		const weekTranslations = inputs?.map(([date, year]: string[]) => {
			const [start_date, , end_date, , month] = date.split(' ');
			return [`${start_date}  ${I18n.get('to')} ${end_date} - ${I18n.get(month)}`, year];
		});
		return weekTranslations;
	}

	function translateMonthLabels(inputs: string[][]): string[][] {
		const monthTranslations = inputs?.map(([month, year]: string[]) => {
			return [`${I18n.get(month)}`, year];
		});
		return monthTranslations;
	}

	function translateQuarterLabels(inputs: string[][]): string[][] {
		const quarterTranslations = inputs?.map(([date, year]: string[]) => {
			const quarter = date.split(' ')[0];
			return [`${quarter} ${I18n.get('quarter')}`, year];
		});
		return quarterTranslations;
	}

	function translateSemesterLabels(inputs: string[][]): string[][] {
		const semesterTranslations = inputs?.map(([date, year]: string[]) => {
			const semester = date.split(' ')[0];
			return [`${semester} ${I18n.get('semester')}`, year];
		});
		return semesterTranslations;
	}

	const result = {
		labels: translateLabels() ?? [],
		reports: reportsData?.map((reportData, index) => ({
			label: custom_reports?.[index]?.acronym ?? '',
			data: reportData?.report?.data ?? []
		}))
	};

	const totals = reportsData?.map((reportData) => reportData?.report?.total);
	const overallTotal = totals.reduce((sum, total) => sum + total, 0);

	const totalizerData = {
		overallTotal,
		reportTotals: reportsData?.map((reportData, index) => ({
			label: custom_reports?.[index]?.name ?? '',
			total: reportData?.report.total
		}))
	};

	function getDisplayDate(): { start: string; end: string } {
		return {
			start: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(checklistParams?.start_date),
			end: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(checklistParams?.end_date)
		};
	}

	return (
		<Card.Container
			size="large"
			isLoading={isLoadingReports || isReportsLoading}
			isError={isErrorReports || !isAllSuccess}
			onRefetch={refetch}
		>
			<Card.Header
				hasIcon
				hasDisplayDate
				displayDate={getDisplayDate()}
				icon={<CalendarOutlined />}
				title="Checklist reports generated"
				popoverComponent={
					<StyledDivPopOver>
						<Single fieldName={['checklist']} handleClear={handleClear} handleFormSubmit={handleOnSubmit} />
					</StyledDivPopOver>
				}
			/>
			<Card.Content>
				<Row>
					<S.StyledCol span={20}>
						<LineChart result={result} />
					</S.StyledCol>
					<S.StyledCol span={4}>
						<Totalizer totalizerData={totalizerData} />
					</S.StyledCol>
				</Row>
				<Labels />
			</Card.Content>
		</Card.Container>
	);
}
