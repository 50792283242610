import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Service } from '@/infra/services/action-plan';
import { AxiosError } from 'axios';
import { ActionPlanCreateCommentsDomain } from '@/core/domain/action-plan-comments-create';
import { message } from 'antd';
import { I18n } from '@aws-amplify/core';

interface CreateCommentsParams {
	organization_id: string;
	company_id: string;
	description: string;
	action_plan_id: string;
}

export const createComments = (params: CreateCommentsParams) => {
	return Service.createComments(params);
};

export const useCreateCommentsActionPlan = () => {
	const queryClient = useQueryClient();

	return useMutation<ActionPlanCreateCommentsDomain, AxiosError<Response>, CreateCommentsParams>(
		(params) => Service.createComments(params),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['get-action-plan-comments']);
				queryClient.invalidateQueries(['get-action-plan-history']);
			}
		}
	);
};
