import styled from 'styled-components';

export const COLORS = ['#2CC852', '#E74150', '#B3B3B3'];

export const ChartContainer = styled.div`
	height: 350px;
	width: 100%;
	position: relative;
`;

export const LegendContainer = styled.div`
	margin-top: 2.8rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 20px;
`;

export const LegendItem = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
`;

export const LegendColor = styled.div<{ color: string }>`
	width: 25px;
	height: 25px;
	background-color: ${(props) => props.color};
	margin-right: 8px;
	border-radius: 6px;
`;

export const LegendLabel = styled.span`
	font-size: 19px;
`;
