import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/bera';
import { BeraSumRPN } from '@/core/domain/bera-sum-rpn';

interface GetBeraSumRPNParams {
	organization_id: string;
	workstation_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	start_date?: Date;
	end_date?: Date;
}

export const useGetBeraSumRPN = (params: GetBeraSumRPNParams) => {
	return useQuery<BeraSumRPN, Error>([QUERY_KEYS.GET_BERA_SUM_RPN, params], () => Service.getSumRPN(params), {
		enabled: !!params.organization_id
	});
};
