import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/sera';

interface GetSeraComparisonParams {
	organization_id: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	workstation_id?: string;
	start_date: Date;
	end_date: Date;
}

export const useGetSeraComparison = (params: GetSeraComparisonParams) => {
	return useQuery([QUERY_KEYS.GET_SERA_COMPARISON, params], () => Service.getHierarchySumRpn(params), {
		enabled: !!params.organization_id
	});
};
