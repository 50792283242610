import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Radio } from 'antd';
import { StyledRadioGroup } from '../styles';

interface GranularityProps {
	formName: string[];
}

export function Granularity({ formName }: Readonly<GranularityProps>) {
	return (
		<Row>
			<Col span={24}>
				<Form.Item
					style={{ margin: 0 }}
					labelCol={{ span: 24 }}
					name={[...formName, 'granularity']}
					label={I18n.get('Granularity of information')}
				>
					<StyledRadioGroup>
						<Radio.Button value="day">{I18n.get('Day')}</Radio.Button>
						<Radio.Button value="week">{I18n.get('Week')}</Radio.Button>
						<Radio.Button value="month">{I18n.get('Month')}</Radio.Button>
						<Radio.Button value="quarter">
							<>3 {I18n.get('Months')}</>
						</Radio.Button>
						<Radio.Button value="semester">
							<>6 {I18n.get('Months')}</>
						</Radio.Button>
						<Radio.Button value="year">{I18n.get('Year')}</Radio.Button>
					</StyledRadioGroup>
				</Form.Item>
			</Col>
		</Row>
	);
}
