import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, UploadFile } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { formatDate } from '../../utils';
import { StyledInfoOverlay, StyledFileInfosOverlay, StyledCloseButton } from './styles';

interface UploadFileItem extends UploadFile {
	created_at: string;
	author: string;
}

interface InformationsProps {
	visibleInfoUid: string | null;
	uploadFileItem: UploadFileItem;
	setVisibleInfoUid: (uid: string | null) => void;
}

export function Informations({ visibleInfoUid, uploadFileItem, setVisibleInfoUid }: Readonly<InformationsProps>) {
	const handleClose = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setVisibleInfoUid(null);
	};

	const handleOverlayClick = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<StyledInfoOverlay visible={visibleInfoUid === uploadFileItem.uid} onClick={handleOverlayClick}>
			<Row className="custom-upload-infos-row">
				<Col span={24}>
					<StyledFileInfosOverlay>
						<p>{I18n.get('Upload date')}:</p>
						<p>{formatDate(uploadFileItem.created_at)}</p>
					</StyledFileInfosOverlay>
				</Col>
				<Col span={24}>
					<StyledFileInfosOverlay>
						<p>{I18n.get('Upload author')}:</p>
						<p>{uploadFileItem.author}</p>
					</StyledFileInfosOverlay>
				</Col>
			</Row>
			<StyledCloseButton onClick={handleClose}>
				<CloseOutlined />
			</StyledCloseButton>
		</StyledInfoOverlay>
	);
}
