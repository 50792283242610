export interface RebaExposureScoreItemAttributes {
  risk: number | null;
  percentage: number;
}

export class RebaExposureScoreItem {
  public readonly risk: number | null;
  public readonly percentage: number;

  constructor({ risk, percentage }: RebaExposureScoreItemAttributes) {
    this.risk = risk;
    this.percentage = percentage;
  }
}

export interface RebaExposureScoreBodyPartAttributes {
  name: string;
  totalSeconds: number;
  data: RebaExposureScoreItemAttributes[];
}

export class RebaExposureScoreBodyPart {
  public readonly name: string;
  public readonly totalSeconds: number;
  public readonly data: RebaExposureScoreItem[];

  constructor({ name, totalSeconds, data }: RebaExposureScoreBodyPartAttributes) {
    this.name = name;
    this.totalSeconds = totalSeconds;
    this.data = data.map(item => new RebaExposureScoreItem(item));
  }
}

export class RebaExposureScore {
  public readonly bodyParts: RebaExposureScoreBodyPart[];

  constructor(bodyParts: RebaExposureScoreBodyPartAttributes[]) {
    this.bodyParts = bodyParts.map(bodyPart => new RebaExposureScoreBodyPart(bodyPart));
  }
}
