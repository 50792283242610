import styled from 'styled-components';
import type { StatusType } from '.';

type StatusProps = {
	$bordered?: boolean;
	$type?: string;
} & typeof defaultProps;

const defaultProps = { $bordered: false, $type: '' };

const NOT_STARTED_COLOR_BACKGROUND = '#eaeefd';
const DOING_COLOR_BACKGROUND = '#fef7e9';
const DONE_COLOR_BACKGROUND = '#e8f7ec';
const COLOR_BACKGROUND_DEFAULT = '#fff';

function handleGetBackgroundColorStatus(value: StatusType): string {
	const statusOptions: Record<StatusType, string> = {
		Done: DONE_COLOR_BACKGROUND,
		Doing: DOING_COLOR_BACKGROUND,
		'Not started': NOT_STARTED_COLOR_BACKGROUND
	};
	return statusOptions[value] || COLOR_BACKGROUND_DEFAULT;
}

export const Status = styled.span<StatusProps>`
	width: 100px;
	display: grid;
	padding: 0.3rem;
	text-align: center;
	border-radius: 6px;
	background: ${({ $type }) => handleGetBackgroundColorStatus($type)};
	border: ${({ $bordered }) => ($bordered ? '2px solid white' : 'none')};
`;
