import React from 'react';

export function MessageOutlined(props: Readonly<React.SVGProps<SVGSVGElement>>) {
	return (
		<svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M5.06641 2.80273H22.7117C24.3686 2.80273 25.7117 4.14588 25.7117 5.80273V18.8405C25.7117 20.4974 24.3686 21.8405 22.7117 21.8405H20.5888C19.2769 21.8405 17.9914 22.2092 16.8788 22.9045L13.8891 24.7731L10.8993 22.9045C9.78678 22.2092 8.50125 21.8405 7.18931 21.8405H5.06641C3.40955 21.8405 2.06641 20.4974 2.06641 18.8405V5.80273C2.06641 4.14588 3.40955 2.80273 5.06641 2.80273ZM0.0664062 5.80273C0.0664062 3.04131 2.30498 0.802734 5.06641 0.802734H22.7117C25.4732 0.802734 27.7117 3.04131 27.7117 5.80273V18.8405C27.7117 21.6019 25.4732 23.8405 22.7117 23.8405H20.5888C19.6517 23.8405 18.7335 24.1038 17.9388 24.6005L14.4191 26.8004C14.0948 27.003 13.6833 27.003 13.3591 26.8004L9.8393 24.6005C9.04465 24.1038 8.12641 23.8405 7.18931 23.8405H5.06641C2.30498 23.8405 0.0664062 21.6019 0.0664062 18.8405V5.80273ZM5.33203 16.2578C4.77975 16.2578 4.33203 16.7055 4.33203 17.2578C4.33203 17.8101 4.77975 18.2578 5.33203 18.2578H22.4458C22.9981 18.2578 23.4458 17.8101 23.4458 17.2578C23.4458 16.7055 22.9981 16.2578 22.4458 16.2578H5.33203ZM4.33203 11.9922C4.33203 11.4399 4.77975 10.9922 5.33203 10.9922H22.4458C22.9981 10.9922 23.4458 11.4399 23.4458 11.9922C23.4458 12.5445 22.9981 12.9922 22.4458 12.9922H5.33203C4.77975 12.9922 4.33203 12.5445 4.33203 11.9922ZM5.33203 5.72656C4.77975 5.72656 4.33203 6.17428 4.33203 6.72656C4.33203 7.27885 4.77975 7.72656 5.33203 7.72656H22.4458C22.9981 7.72656 23.4458 7.27885 23.4458 6.72656C23.4458 6.17428 22.9981 5.72656 22.4458 5.72656H5.33203Z"
				fill="#2F54EB"
			/>
		</svg>
	);
}
