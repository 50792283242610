interface ColumnsProps {
	score?: string;
	responsible_user_id?: string;
	due_date?: string;
	completed_at?: string;
	priority?: number;
	investment?: number;
	attachments?: number;
	tasks?: number;
	progress?: number;
	evidence?: number;
	author?: string;
	origin?: string;
	created_at?: string;
}

interface ActionPlanCreateUserPreferenceColumnsItemAttributes {
	organization_id: string;
	company_id: string;
	columns: ColumnsProps[];
}

export class ActionPlanCreateUserPreferenceColumns {
	public readonly organization_id: string;
	public readonly company_id: string;
	public readonly columns: ColumnsProps[];

	constructor({ organization_id, company_id, columns }: ActionPlanCreateUserPreferenceColumnsItemAttributes) {
		this.organization_id = organization_id;
		this.company_id = company_id;
		this.columns = columns;
	}
}

export interface ActionPlanCreateUserPreferenceColumnsDomain {
	status: string;
	message: string;
	data: ActionPlanCreateUserPreferenceColumns[];
}
