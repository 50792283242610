import { BaseService } from './base-service';
import { RebaExposureScore } from '@/core/domain/reba-exposure-score';
import { RebaExposureScoreMapper } from '@/core/mapper/reba-exposure-score';
import { RebaExposureScoreDTO, RebaExposureScoreRequestDTO } from '@/core/dto/reba-exposure-score';

import { RebaBodySideExposureScore } from '@/core/domain/reba-body-side-exposure-score';
import { RebaBodySideExposureScoreMapper } from '@/core/mapper/reba-body-side-exposure-score';
import {
	RebaBodySideExposureScoreDTO,
	RebaBodySideExposureScoreRequestDTO
} from '@/core/dto/reba-body-side-exposure-score';

class RebaService extends BaseService<RebaExposureScoreDTO> {
	constructor(public readonly basePath: string = '/ergonomic-tool/reba') {
		super();
	}

	async getExposureScore(params: RebaExposureScoreRequestDTO): Promise<RebaExposureScore> {
		const url = this.basePath + '/dashboard/exposure-score';
		const { data } = await this.getInstance().get<RebaExposureScoreDTO>(url, { params });
		return new RebaExposureScoreMapper(data).toDomain();
	}

	async getBodySideExposureScore(params: RebaBodySideExposureScoreRequestDTO): Promise<RebaBodySideExposureScore> {
		const url = this.basePath + '/dashboard/body-side-exposure-score';
		const { data } = await this.getInstance().get<RebaBodySideExposureScoreDTO>(url, { params });
		return new RebaBodySideExposureScoreMapper(data).toDomain();
	}
}

const Service = Object.freeze(new RebaService());
export { Service };
