import styled, { keyframes } from 'styled-components';
import { Row } from 'antd';

interface NavItemProps {
	type?: 'error' | 'warning';
}

const bounceAnimation = keyframes`
	0% {
		top: -30%;
	}
	6% {
		top: -4%;
	}
	9% {
		top: -10%;
	}
	15% {
		top: -7%;
	}
	80% {
		top: -7%;
	}
	100% {
		top: -30%;
	}
`;

export const Container = styled.div`
	left: 0;
	top: -30%;
	width: 100%;
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	animation: ${bounceAnimation} 5s;
`;

export const Wrapper = styled(Row)<NavItemProps>`
	padding: 0.5rem;
	border-radius: 7px;
	backdrop-filter: blur(15px);
	background: linear-gradient(89deg, rgba(231, 65, 80, 0.85) 6.33%, rgba(217, 17, 35, 0.9) 96.55%);

	background: ${({ type }) =>
		type === 'error'
			? 'linear-gradient(89deg, rgba(231, 65, 80, 0.85) 6.33%, rgba(217, 17, 35, 0.9) 96.55%)'
			: 'linear-gradient(89deg, rgba(255, 223, 0, 0.85) 6.33%, rgba(255, 191, 0, 0.9) 96.55%);'};

	.ant-col {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	span {
		color: #ffffff;
		font-weight: 600;
		font-size: 1.1rem;

		@media (max-width: 1280px) {
			font-size: 0.9rem;
		}
	}

	svg {
		font-size: 1.5rem;
	}
`;
