import React from 'react';
import { Col } from 'antd';
import { RisksEnum } from './enum';
import { Variation } from './Variation';
import { Text } from '@/components/Typography';
import { CenteredCol } from '../styles';
import * as S from './styles';

const RISK_TITLE_MAPPER = {
	[RisksEnum.ACCEPTABLE_RISK]: 'Acceptable risk',
	[RisksEnum.MODERATE_RISK]: 'Moderate risk',
	[RisksEnum.HIGH_RISK]: 'High risk',
	[RisksEnum.VERY_HIGH_RISK]: 'Very high risk',
	[RisksEnum.SERIOUS_AND_IMMINENT_RISK]: 'Serious and imminent risk'
};

interface ItemProps {
	riskName: string;
	firstValue: number;
	secondValue: number;
}

export function Item({ riskName, firstValue, secondValue }: Readonly<ItemProps>) {
	const variation = calculateVariation();

	function calculateVariation(): number {
		const difference = secondValue - firstValue;

		if (!difference) {
			return 0;
		}

		if (!firstValue) {
			return difference;
		}

		return (difference * 100) / firstValue;
	}

	const title = RISK_TITLE_MAPPER[riskName as RisksEnum];

	return (
		<S.Container align="middle">
			<Col span={12}>
				<Text>{title}</Text>
			</Col>
			<CenteredCol span={4}>
				<S.ScoreTag $riskName={riskName}>{firstValue.toLocaleString(navigator.language)}</S.ScoreTag>
			</CenteredCol>
			<CenteredCol span={4}>
				<S.ScoreTag $riskName={riskName}>{secondValue.toLocaleString(navigator.language)}</S.ScoreTag>
			</CenteredCol>
			<CenteredCol span={4}>
				<Variation>{variation}</Variation>
			</CenteredCol>
		</S.Container>
	);
}
