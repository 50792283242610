import React from 'react';
import { Row, Col, Form } from 'antd';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';

import { Item } from './Item';
import { Header } from './Header';
import { RiskList } from './styles';
import { unShortenUrl } from '../../utils';
import { Card } from '../../components/Card';
import { StyledDivPopOver } from '../../styles';
import { useGetCustomReportByName } from '@/hooks';
import { Compound } from '../../components/Filters/Compound';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetCustomReportRisksCount } from '../../hooks/useGetCustomReportRisksCount';

const { useFormInstance } = Form;

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

interface RiskEvolutionTableProps {
	title: string;
	name: string;
	onClear(fieldNames: string[]): void;
}

export function RiskEvolutionTable({ title, name, onClear }: Readonly<RiskEvolutionTableProps>) {
	const query = useQuery();
	const form = useFormInstance();
	const location = useLocation();
	const { organization, company } = useApplicationContext();

	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const line_id = query.get('line');
	const sector_id = query.get('sector');

	const fieldName = `${name}_risk_evolution`;

	const urlParams = unShortenUrl(location.search.substring(1));
	const queryFilters = urlParams?.[fieldName] ?? urlParams?.general?.compound_period;

	const { data: customReport } = useGetCustomReportByName({
		report_name: name,
		organization_id: organization?.id,
		company_id: company_id ?? company?.id
	});

	const defaultParams = {
		organization_id: organization?.id,
		company_id: company_id ?? undefined,
		sector_id: sector_id ?? undefined,
		line_id: line_id ?? undefined,
		workstation_id: workstation_id ?? undefined,
		custom_report_id: customReport.id
	};

	const {
		isLoading: isGettingFirstRisks,
		data: firstPeriodRisks,
		isError: isErrorGettingFirstRisks,
		refetch: refetchFirstRisk,
		isRefetching: isRefetchingFirst
	} = useGetCustomReportRisksCount({
		...defaultParams,
		start_date: queryFilters?.first_period?.start_date!,
		end_date: queryFilters?.first_period?.end_date!
	});

	const {
		isLoading: isGettingSecondRisks,
		data: secondPeriodRisks,
		isError: isErrorGettingSecondRisks,
		refetch: refetchSecondRisk,
		isRefetching: isRefetchingSecond
	} = useGetCustomReportRisksCount({
		...defaultParams,
		start_date: queryFilters?.second_period?.start_date!,
		end_date: queryFilters?.second_period?.end_date!
	});

	const isLoading = isGettingFirstRisks || isGettingSecondRisks || isRefetchingFirst || isRefetchingSecond;
	const isError = isErrorGettingFirstRisks || isErrorGettingSecondRisks;
	const isEmpty = !firstPeriodRisks || !secondPeriodRisks;

	function handleRefetch(): void {
		refetchFirstRisk();
		refetchSecondRisk();
	}

	function handleOnSubmit(): void {
		form.submit();
	}

	return (
		<Card.Container size="medium" isLoading={isLoading} isError={isError} onRefetch={handleRefetch}>
			<Card.Header
				hasIcon
				isTitleBold
				title={title}
				subtitle="Risk evolution"
				icon={<CalendarOutlined />}
				popoverComponent={
					<StyledDivPopOver>
						<Compound handleClear={onClear} fieldName={[fieldName]} handleFormSubmit={handleOnSubmit} />
					</StyledDivPopOver>
				}
			/>
			<Card.Content isEmpty={isEmpty}>
				<Row>
					<Col span={24}>
						<Header />
					</Col>
					<Col span={24}>
						<RiskList>
							{secondPeriodRisks &&
								firstPeriodRisks?.map(({ risk }, index) => (
									<Col key={index} span={24}>
										<Item
											riskName={risk}
											firstValue={firstPeriodRisks[index].count}
											secondValue={secondPeriodRisks[index].count}
										/>
									</Col>
								))}
						</RiskList>
					</Col>
				</Row>
			</Card.Content>
		</Card.Container>
	);
}
