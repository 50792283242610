import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem, StyledDatePicker } from '../styles';

interface StartDateProps {
	formName: string[];
	disabled?: boolean;
}

export function StartDate({ formName, disabled = false }: Readonly<StartDateProps>) {
	return (
		<Row>
			<Col span={24}>
				<CustomFormItem
					style={{ margin: 0 }}
					labelCol={{ span: 24 }}
					label={I18n.get('Start date')}
					name={[...formName, 'start_date']}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value: moment.Moment) {
								const endDate = getFieldValue([...formName, 'end_date']);

								if (value && endDate && value.isAfter(endDate)) {
									return Promise.reject(I18n.get('Start date must be before end date'));
								}

								return Promise.resolve();
							}
						})
					]}
				>
					<StyledDatePicker
						disabled={disabled}
						disabledDate={(current) => current && current.valueOf() >= Date.now()}
						format={(value) => {
							if (value) {
								const date = value.toDate();
								return new Intl.DateTimeFormat(navigator.language, {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit'
								}).format(date);
							}
							return '';
						}}
					/>
				</CustomFormItem>
			</Col>
		</Row>
	);
}
