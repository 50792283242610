import React from 'react';
import { I18n } from '@aws-amplify/core';
import type { FormFieldProps } from '../../types';
import * as S from './styles';

export function CreationDate({ formName = [''], isDisabled = false, isRequired = false }: Readonly<FormFieldProps>) {
	const title = I18n.get('Creation date');

	return (
		<S.CustomFormItem
			label={title}
			required={isRequired}
			labelCol={{ span: 24 }}
			name={[...formName, 'creation_date']}
		>
			<S.CustomRangePicker
				allowClear
				disabled={isDisabled}
				placeholder={[I18n.get('Start date'), I18n.get('End date')]}
			/>
		</S.CustomFormItem>
	);
}
