import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import type { DefaultOptionType } from 'antd/lib/select';
import type { FormFieldProps } from '../../types';
import * as S from './styles';

const { useFormInstance, useWatch } = Form;

interface WorkstationProps extends FormFieldProps {
	options?: DefaultOptionType[];
}

export function Workstation({
	options = [],
	formName = [''],
	isLoading = false,
	isDisabled = false,
	isRequired = false
}: Readonly<WorkstationProps>) {
	const form = useFormInstance();
	const lineId = useWatch([...formName, 'line_id'], form);

	const title = I18n.get('Workstation');
	const errorMessage = I18n.get('Select the workstation');

	function handleClearHierarchyFields(): void {
		form.setFieldValue([...formName, 'origin'], undefined);
	}

	return (
		<S.CustomFormItem
			label={title}
			required={isRequired}
			labelCol={{ span: 24 }}
			name={[...formName, 'workstation_id']}
			rules={[{ required: isRequired, message: errorMessage }]}
		>
			<S.CustomSelect
				allowClear
				options={options}
				loading={isLoading}
				placeholder={title}
				disabled={isDisabled || !lineId}
				onChange={handleClearHierarchyFields}
			/>
		</S.CustomFormItem>
	);
}
