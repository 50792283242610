import { BaseService } from './base-service';
import { KimManualHandlingRiskCount } from '@/core/domain/kim-mho-risk-count';
import { KimManualHandlingCountMapper } from '@/core/mapper/kim-mho-risk-count';
import { GetKimManualHandlingRiskCountRequestDTO } from '@/core/dto/kim-mho-risk-count';

class KimManualHandling extends BaseService<any> {
	constructor(public readonly basePath: string = '/ergonomic-tool/kim-mho') {
		super();
	}

	async getCountByRisk(params: GetKimManualHandlingRiskCountRequestDTO): Promise<KimManualHandlingRiskCount[]> {
		const url = this.basePath + '/count-by-risk';
		const { data } = await this.getInstance().get<KimManualHandlingRiskCount[]>(url, { params });
		return new KimManualHandlingCountMapper(data).toDomain();
	}
}

const Service = Object.freeze(new KimManualHandling());
export { Service };
