import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '@/infra/services/action-plan';

type UserTask = {
	name: string;
};

export interface GetActionPlanTasksRequest {
	organization_id: string;
	company_id: string;
	action_plan_id: string;
}

export interface GetActionPlanTasksResponse {
	id: string;
	is_completed: boolean;
	action_plan_id: string;
	responsible_user: UserTask;
	responsible_user_id: string;
	title: string;
	user: UserTask;
	user_id: string;
	due_date: Date;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
}

interface TasksResponse {
	data: GetActionPlanTasksResponse[];
	total_completed: number;
}

function countCompletedTasks(tasks: GetActionPlanTasksResponse[]): TasksResponse {
	const totalCompleted = tasks.filter((task) => task.is_completed);

	return {
		data: tasks,
		total_completed: totalCompleted.length
	};
}

export const useGetActionPlanTasks = (params: GetActionPlanTasksRequest) => {
	return useQuery([QUERY_KEYS.GET_ACTION_PLAN_TASKS, params], () => Service.getTasks(params), {
		enabled: !!params.company_id && !!params.action_plan_id,
		select: countCompletedTasks
	});
};
