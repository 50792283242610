import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/reba';
import { RebaExposureScore } from '@/core/domain/reba-exposure-score';

interface GetRebaExposureScoreParams {
	organization_id: string;
	workstation_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	start_date?: Date;
	end_date?: Date;
}

export const useGetRebaExposureScore = (params: GetRebaExposureScoreParams) => {
	return useQuery<RebaExposureScore, Error>(
		[QUERY_KEYS.GET_REBA_EXPOSURE_SCORE, params],
		() => Service.getExposureScore(params),
		{
			enabled: !!params.organization_id
		}
	);
};
