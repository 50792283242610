import React from 'react';

export function DollarSignIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
	return (
		<svg viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M7.83081 9.09728L6.85927 8.8677V3.64202C8.31275 3.84436 9.21161 4.77043 9.36461 5.90661C9.38373 6.06226 9.51378 6.1751 9.66678 6.1751H11.3842C11.564 6.1751 11.7055 6.01556 11.6902 5.83268C11.4569 3.40856 9.49466 1.85214 6.87457 1.58366V0.311284C6.87457 0.140078 6.73687 0 6.56857 0H5.49376C5.32546 0 5.18777 0.140078 5.18777 0.311284V1.59533C2.4797 1.86381 0.360679 3.38521 0.360679 6.22568C0.360679 8.85603 2.2655 10.1245 4.26595 10.6109L5.21072 10.856V16.4086C3.52009 16.179 2.5715 15.2607 2.37643 14.0233C2.35348 13.8755 2.22343 13.7665 2.07426 13.7665H0.307129C0.127357 13.7665 -0.0141661 13.9222 0.00113366 14.1051C0.173256 16.2451 1.76826 18.214 5.17247 18.4669V19.6887C5.17247 19.8599 5.31016 20 5.47846 20H6.56475C6.73304 20 6.87074 19.8599 6.87074 19.6848L6.86309 18.4514C9.85803 18.1829 12 16.5525 12 13.6265C11.9962 10.9261 10.3094 9.71984 7.83081 9.09728ZM5.20689 8.46693C4.99269 8.40467 4.81292 8.3463 4.63315 8.27237C3.34032 7.79767 2.7398 7.03113 2.7398 6.0428C2.7398 4.63035 3.79166 3.8249 5.20689 3.64202V8.46693ZM6.85927 16.4202V11.2179C6.97784 11.2529 7.08494 11.2802 7.19586 11.3035C9.00506 11.8638 9.61323 12.642 9.61323 13.8366C9.61323 15.358 8.4887 16.2724 6.85927 16.4202Z"
				fill="currentColor"
			/>
		</svg>
	);
}
