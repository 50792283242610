import { AxiosError } from 'axios';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type Tasks = {
	title: string;
	due_date: Date;
	is_completed: boolean;
	responsible_user_id: string;
};

interface CreateActionPlanParams {
	company_id: string;
	organization_id: string;
	file_id: string;
	title: string;
	due_date: Date;
	responsible_user_id: string;
	investment_range: number;
	priority: number;
	tasks: Tasks[];
	sera_summary_review_id?: string;
	custom_report_step_key_id?: string;
	custom_report_sub_step_key_id?: string;
}

export const useCreateActionPlanFromReport = () => {
	const queryClient = useQueryClient();

	return useMutation<any, AxiosError<Response>, CreateActionPlanParams>(
		(params) => Service.createFromReport(params),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_FROM_REPORT]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLANS_BY_REPORT]);
			}
		}
	);
};
