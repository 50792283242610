import React from 'react';
import { Container } from './Container';
import { ActionPlanProvider } from './context';

export default function ActionPlans() {
	return (
		<ActionPlanProvider>
			<Container />
		</ActionPlanProvider>
	);
}
