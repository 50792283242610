import styled from 'styled-components';
import { Text } from '@/components/Typography';

export const TransparentButton = styled.button`
	border: none;
	background: transparent;

	&:hover {
		cursor: pointer;
	}
`;

export const Description = styled(Text)`
	font-size: 1rem;
`;

export const Action = styled.div`
	font-size: 1rem;

	.edit {
		color: blue;
	}
`;
