import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '@/infra/services/sector';
import { SectorDTO } from '@/core/dto/sector';

export interface GetSectorOptionsParams {
	organization_id: string;
	company_id: string;
}

const transformSectorOptions = (data: SectorDTO[]) => {
	return data.map((sector) => ({ value: sector.id, label: sector.name }));
};

export const useGetSectorOptions = (params: GetSectorOptionsParams) => {
	return useQuery([QUERY_KEYS.GET_SECTORS, params], () => Service.listOptions(params), {
		enabled: !!params.organization_id && !!params.company_id,
		select: transformSectorOptions
	});
};
