import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/strain-index';

export interface GetStrainIndexRsiParams {
	organization_id: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	workstation_id?: string;
	start_date?: Date;
	end_date?: Date;
}

export const useGetStrainIndexRsi = (params: GetStrainIndexRsiParams) => {
	return useQuery([QUERY_KEYS.GET_STRAIN_INDEX_RSI, params], () => Service.getRSIRisk(params), {
		enabled: !!params.organization_id
	});
};
