import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, TooltipItem, Plugin, ChartOptions, ChartData } from 'chart.js';
import { I18n } from '@aws-amplify/core';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DonutChartProps {
	data: {
		labels: string[];
		values: number[];
		colors: string[];
	};
	bodyPart: string;
}

export function DonutChart({ data, bodyPart }: DonutChartProps) {
	const totalValue = data.values.reduce((a, b) => a + b, 0);
	const percentageData = data.values.map((value) => ((value / totalValue) * 100).toFixed(1) + '%');

	const chartData: ChartData<'doughnut'> = {
		labels: data.labels,
		datasets: [
			{
				data: data.values,
				label: bodyPart,
				backgroundColor: data.colors,
				borderColor: '#FFFFFF',
				borderWidth: 1,
				borderRadius: 3,
				hoverBorderWidth: 3
			}
		]
	};

	const options: ChartOptions<'doughnut'> = {
		responsive: true,
		animation: false,
		maintainAspectRatio: false,
		locale: navigator.language,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: function (context: TooltipItem<'doughnut'>) {
						const label = context.label || '';
						const percentage = percentageData[context.dataIndex];

						return [I18n.get(label), percentage];
					}
				}
			},
			datalabels: {
				color: '#FFFFFF',
				font: {
					weight: 'bold' as const,
					size: 15
				},
				display: (context) => {
					const percentage = (context.dataset.data[context.dataIndex] ?? 0) as number;
					return percentage >= 7;
				},
				formatter: (value: number, context: any) => {
					const percentage = percentageData[context.dataIndex];
					const formattedPercentage = percentage ? `${Math.trunc(parseFloat(percentage))}%` : '';
					return value > 7 ? formattedPercentage : '';
				}
			}
		},
		cutout: '60%' as const
	};

	const centerTextPlugin: Plugin<'doughnut'> = {
		id: 'centerText',
		beforeDraw: (chart) => {
			const ctx = chart.ctx;
			const xCoor = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
			const yCoor = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
			ctx.fillStyle = '#262626';
			ctx.font =
				"1.7rem bold -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'";
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			const texts = I18n.get(chart?.data?.datasets?.[0]?.label ?? '').split(' ');
			const lineHeight = 26;
			const margin = (texts.length - 1) * lineHeight * -0.5;
			texts.forEach((text: string, i: number) => {
				ctx.fillText(text, xCoor, yCoor + lineHeight * i + margin);
			});
			ctx.save();
		}
	};

	return (
		<div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
			<Doughnut data={chartData} options={options} plugins={[centerTextPlugin]} />
		</div>
	);
}
