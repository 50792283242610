import styled from 'styled-components';
import { Button, Drawer } from 'antd';

export const CustomDrawer = styled(Drawer)`
	.ant-drawer-mask {
		background: rgba(0, 0, 0, 0.2);
	}

	.ant-drawer-content-wrapper {
		box-shadow: -3px -2px 15px 1px #0000001a;
	}

	.ant-drawer-content {
		padding: 0;
	}

	.ant-drawer-header {
		padding: 2.4rem 2.4rem 0 2.4rem;
		border: none;

		.ant-drawer-header-title {
			flex-direction: row-reverse;

			.ant-drawer-close {
				margin: 0;
			}
		}
	}

	.ant-drawer-body {
		padding: 0 2.4rem;
		overflow-y: auto;
		margin-top: 1.5rem;
	}
`;

export const PrimaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	background-color: #2f54eb;
	box-shadow: 0px 2px 0px 0px #0000000b;
`;

export const SecondaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	box-shadow: 0px 1px 0px 0px #0000000b;
`;
