import React from 'react';
import { Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';
import { unShortenUrl } from '@/views/Dashboard/utils';
import { Card } from '@/views/Dashboard/components/Card';
import { Single } from '@/views/Dashboard/components/Filters/Single';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetCustomReportLibertyMutual } from '@/views/Dashboard/hooks/useGetCustomReportLibertyMutual';
import { StyledDivPopOver } from '@/views/Dashboard/styles';
import * as S from './styles';

interface ChecklistReportsProps {
	handleClear(fieldNames: string[]): void;
}
function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const { useFormInstance } = Form;

export function PercentileByGender({ handleClear }: ChecklistReportsProps) {
	const { organization } = useApplicationContext();
	const query = useQuery();
	const location = useLocation();
	const form = useFormInstance();

	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');
	const workstation_id = query.get('workstation');

	const unshortenUrl = unShortenUrl(location.search.substring(1));
	const checklistParams = unshortenUrl?.percentile ?? unshortenUrl?.general?.unique_period;

	const filterParams = {
		organization_id: organization?.id!,
		company_id: company_id ?? undefined,
		sector_id: sector_id ?? undefined,
		line_id: line_id ?? undefined,
		workstation_id: workstation_id ?? undefined,
		end_date: checklistParams?.end_date ?? undefined,
		start_date: checklistParams?.start_date ?? undefined
	};

	const { isLoading, data, isError, refetch } = useGetCustomReportLibertyMutual(filterParams);

	function handleOnSubmit(): void {
		form.submit();
	}

	const isEmpty =
		(data?.man === 0 || data?.man.toString() === '0') && (data?.woman === 0 || data?.woman.toString() === '0');

	return (
		<Card.Container size="small" isLoading={isLoading} isError={isError} onRefetch={refetch}>
			<Card.Header
				hasIcon
				isTitleBold
				icon={<CalendarOutlined />}
				title={I18n.get('Liberty Mutual')}
				subtitle={I18n.get('Percentile by gender')}
				popoverComponent={
					<StyledDivPopOver>
						<Single
							hasGranularity={false}
							handleClear={handleClear}
							fieldName={['percentile']}
							handleFormSubmit={handleOnSubmit}
						/>
					</StyledDivPopOver>
				}
			/>
			<Card.Content isEmpty={isEmpty}>
				<Row>
					<S.StyledCol span={12}>
						<Row>
							<S.StyledCol span={24}>
								<Title level={1}>{data?.man}</Title>
							</S.StyledCol>
							<S.StyledCol span={24}>
								<Title level={5}>Male percentile</Title>
							</S.StyledCol>
						</Row>
					</S.StyledCol>
					<S.StyledCol span={12}>
						<Row>
							<S.StyledCol span={24}>
								<Title level={1}>{data?.woman}</Title>
							</S.StyledCol>
							<S.StyledCol span={24}>
								<Title level={5}>Female percentile</Title>
							</S.StyledCol>
						</Row>
					</S.StyledCol>
				</Row>
			</Card.Content>
		</Card.Container>
	);
}
