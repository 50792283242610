import React from 'react';
import { Row } from 'antd';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';

import { DonutChart } from '@/views/Dashboard/components/DonutChart';
import { unShortenUrl } from '@/views/Dashboard/utils';
import { Card } from '@/views/Dashboard/components/Card';
import { Single } from '@/views/Dashboard/components/Filters/Single';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetRebaExposureScore } from '@/views/Dashboard/hooks/useGetRebaExposureScore';
import { StyledDivPopOver } from '@/views/Dashboard/styles';
import { labels, bodyPartNameMapper, orderedBodyParts } from './enums';

import { Title } from '@/components/Typography';

import * as S from './styles';

interface ChecklistReportsProps {
	handleClear(fieldNames: string[]): void;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const { useFormInstance } = Form;

export function RebaExposureScore({ handleClear }: Readonly<ChecklistReportsProps>) {
	const { organization } = useApplicationContext();
	const query = useQuery();
	const location = useLocation();
	const form = useFormInstance();

	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');

	const unshortenedUrl = unShortenUrl(location.search.substring(1));
	const rebaExposureScoreParams = unshortenedUrl?.reba_exposure ?? unshortenedUrl?.general?.unique_period;
	console.log('unshortenedUrl:', unshortenedUrl);

	const filter_params = {
		organization_id: organization?.id!,
		company_id: company_id ?? undefined,
		sector_id: sector_id ?? undefined,
		line_id: line_id ?? undefined,
		workstation_id: workstation_id ?? undefined,
		end_date: rebaExposureScoreParams?.end_date ?? undefined,
		start_date: rebaExposureScoreParams?.start_date ?? undefined
	};

	const { isLoading, data, isError, refetch } = useGetRebaExposureScore(filter_params);

	function handleOnSubmit(): void {
		form.submit();
	}

	function getDisplayDate() {
		return {
			start: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(rebaExposureScoreParams?.start_date),
			end: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(rebaExposureScoreParams?.end_date)
		};
	}

	return (
		<Card.Container size="xxlarge" isLoading={isLoading} isError={isError} onRefetch={refetch}>
			<Card.Header
				hasIcon
				isTitleBold
				hasDisplayDate
				displayDate={getDisplayDate()}
				title={I18n.get('REBA')}
				subtitle={I18n.get('Exposure score of all body parts')}
				icon={<CalendarOutlined />}
				popoverComponent={
					<StyledDivPopOver>
						<Single
							hasGranularity={false}
							fieldName={['reba_exposure']}
							handleClear={handleClear}
							handleFormSubmit={handleOnSubmit}
						/>
					</StyledDivPopOver>
				}
			/>
			<Card.Content isEmpty={!data?.bodyParts.length}>
				<Row gutter={[30, 30]} justify="center">
					{data &&
						orderedBodyParts.map((key) => {
							const bodyPart = data.bodyParts.find((bp) => bp.name === key);
							if (bodyPart) {
								return (
									<S.StyledCol xs={24} sm={12} md={8} lg={6} key={key}>
										<S.DonutChartWrapper>
											<DonutChart
												data={{
													labels: labels,
													values: bodyPart.data.map((item) => item.percentage),
													colors: bodyPart.data.map((item) => S.colors[item.risk ?? 0])
												}}
												bodyPart={bodyPartNameMapper[key] || key}
											/>
										</S.DonutChartWrapper>
									</S.StyledCol>
								);
							}
							return null;
						})}
				</Row>
				<Row style={{ marginTop: '4rem' }} gutter={[10, 10]} justify="center">
					{labels.map((label, index) => (
						<S.StyledColWithoutFixedSize
							xs={24}
							sm={9}
							md={9}
							lg={9}
							xl={9}
							xxl={index === 3 || index === 4 ? 5 : 3}
							key={label}
						>
							<S.LabelWrapper>
								<S.ColorBox color={S.colors[index]} />
								<Title style={{ marginTop: '0.4rem' }} level={5}>
									{label}
								</Title>
							</S.LabelWrapper>
						</S.StyledColWithoutFixedSize>
					))}
				</Row>
			</Card.Content>
		</Card.Container>
	);
}
