import { BaseService } from './base-service';
import { SectorDTO, GetSectorOptionsParamsDTO } from '@/core/dto/sector';

class SectorService extends BaseService<SectorDTO> {
	constructor(public readonly basePath: string = '/sector') {
		super();
	}

	async listOptions(params: GetSectorOptionsParamsDTO): Promise<SectorDTO[]> {
		const url = this.basePath + `/${params.organization_id}/${params.company_id}`;
		const { data } = await this.getInstance().get(url);
		return data;
	}
}

const Service = Object.freeze(new SectorService());
export { Service };
