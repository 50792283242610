import styled from 'styled-components';
import { Col } from 'antd';
import { Text } from '@/components/Typography';

export const Card = styled(Col)`
	padding: 1.4rem;
	border-radius: 12px;
	background-color: #f5f5f5;
`;

export const Label = styled(Text)`
	font-size: 1rem;
	font-weight: 600;
`;

export const Value = styled(Text)`
	font-size: 1rem;
	margin-left: 0.3rem;
`;
