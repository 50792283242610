import React, { useState } from 'react';
import { DeleteOutlined, ExclamationCircleOutlined, EyeTwoTone, PlusCircleFilled } from '@ant-design/icons';
import { Row, Col, Tooltip, List, Divider, Button, Modal } from 'antd';
import { Title } from '@/components/Typography';
import { Spinner } from '@/components/Spinner';
import { I18n } from '@aws-amplify/core';

import { useApplicationContext } from '@/context/v1/Application/context';
import { SeraActionPlanDTO } from '../../../hooks/types/response';
import { useSeraStepsContext } from '../../../context';
import { CustomList, CustomModal } from './styles';
import { ActionPlanModal } from '@/components/ui/Modals/ActionPlanModal';
import { useGetActionPlansByReport } from '@/hooks/v2/useGetActionPlansByReport';
import { useDeleteActionPlan } from '@/hooks/v2/useDeleteActionPlan';

interface CreateActionPlanModalProps {
	score?: number;
	file_id: string;
	visible: boolean;
	onCloseModal: () => void;
}

export const CreateActionPlanModal: React.FC<CreateActionPlanModalProps> = ({
	file_id,
	visible,
	score = 0,
	onCloseModal
}) => {
	const [selectedActionPlan, setSelectedActionPlan] = useState<any>({} as any);
	const [isActionPlanModalOpen, setIsActionPlanModalOpen] = useState<boolean>(false);

	const { seraSummary } = useSeraStepsContext();
	const { company, organization } = useApplicationContext();

	const { sera_summary_review_id } = seraSummary;

	const {
		data: actionPlans,
		isLoading,
		isFetching
	} = useGetActionPlansByReport({
		company_id: company?.id,
		organization_id: organization?.id,
		sera_summary_review_id,
		file_id
	});
	const { mutateAsync: deleteActionPlan } = useDeleteActionPlan();

	function handleOnClose(): void {
		setSelectedActionPlan({});
		onCloseModal();
	}

	function handleToggleActionPlanModal(): void {
		setIsActionPlanModalOpen(!isActionPlanModalOpen);
	}

	function handleOnClickActionPlan(actionPlan: any): void {
		setSelectedActionPlan(actionPlan);
		handleToggleActionPlanModal();
	}

	function handleOnNewActionPlan(): void {
		setSelectedActionPlan({});
		handleToggleActionPlanModal();
	}

	async function handleDeleteActionClick(id: string) {
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this action plan?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: async () => {
				await deleteActionPlan({ ids: [id], company_id: company?.id, organization_id: organization.id });
			}
		});
	}

	const modalBody = () => {
		const actionPlanList = (
			<Row justify="center">
				<CustomList xs={22}>
					{isLoading || isFetching ? (
						<Spinner size={24} />
					) : (
						<List
							size="small"
							dataSource={actionPlans}
							renderItem={(actionPlan: SeraActionPlanDTO) => (
								<List.Item style={{ marginBottom: '1rem' }}>
									<Col xs={24}>
										<Row justify="center">
											<Col xs={22}>
												<Row align="middle">
													<Col
														style={{
															margin: 0,
															padding: 0
														}}
														xs={20}
													>
														<Row>
															<Title
																style={{
																	margin: 0,
																	padding: 0
																}}
																level={5}
															>
																{actionPlan.title}
															</Title>
														</Row>
													</Col>
													<Col xs={4}>
														<Row align="middle" justify="space-between">
															<Col
																xs={1}
																style={{
																	height: '2rem',
																	margin: 0,
																	padding: 0
																}}
															>
																<Divider
																	type="vertical"
																	style={{
																		height: '100%',
																		margin: 0,
																		padding: 0
																	}}
																/>
															</Col>
															<Col xs={8}>
																<Button
																	onClick={() => handleOnClickActionPlan(actionPlan)}
																	type="link"
																	icon={
																		<EyeTwoTone
																			style={{
																				fontSize: '1.2rem'
																			}}
																		/>
																	}
																/>
															</Col>
															<Col xs={8}>
																<Button
																	onClick={() => {
																		handleDeleteActionClick(actionPlan.id);
																	}}
																	type="link"
																	icon={
																		<DeleteOutlined
																			style={{
																				fontSize: '1.2rem',
																				color: '#E74150'
																			}}
																		/>
																	}
																/>
															</Col>
														</Row>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</List.Item>
							)}
						/>
					)}
				</CustomList>
			</Row>
		);

		return (
			<Row>
				<Col xs={24}>
					<Row justify="center">
						<Title level={4}>S.E.R.A. action plans</Title>
					</Row>
					<Row justify="center">
						<Col xs={21}>
							<span>{I18n.get('Action plans list')}</span>
						</Col>
					</Row>
					<Row justify="center" style={{ marginTop: '1rem' }}>
						<Col xs={24}>{actionPlans?.length > 0 && actionPlanList}</Col>
						<Col span={2}>
							<Row justify="center">
								<Tooltip title={I18n.get('Add action plan')}>
									<PlusCircleFilled
										onClick={handleOnNewActionPlan}
										style={{
											fontSize: '32px',
											color: '#2F54EB',
											marginTop: '1rem'
										}}
									/>
								</Tooltip>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};

	return (
		<>
			<CustomModal footer={false} open={visible} onCancel={handleOnClose}>
				{modalBody()}
			</CustomModal>
			<ActionPlanModal
				score={score}
				fileId={file_id}
				id={selectedActionPlan.id}
				isOpen={isActionPlanModalOpen}
				onClose={handleToggleActionPlanModal}
				companyId={company?.id}
				seraSummaryReview={sera_summary_review_id}
				organizationId={organization?.id}
			/>
		</>
	);
};
