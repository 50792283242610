// useGetChecklistReports.ts
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/custom-report';

export interface ReportsGenerated {
	report: {
		total: number;
		label: string;
		data: number[];
	};
	labels: string[][];
}

interface GetReportParams {
	custom_report_id: string;
	organization_id: string;
	workstation_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	created_at_start?: Date;
	created_at_end?: Date;
	granularity: 'day' | 'week' | 'month' | 'quarter' | 'semester' | 'year';
	fiscal_year?: boolean;
}

export const getChecklistReports = (params: GetReportParams) => {
	return Service.getChecklist(params);
};

export const useGetChecklistReports = (params: GetReportParams) => {
	return useQuery([QUERY_KEYS.GET_CHECKLIST_REPORTS, params.custom_report_id], () => Service.getChecklist(params), {
		enabled: !!params.custom_report_id && !!params.organization_id
	});
};
