import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Doughnut } from 'react-chartjs-2';

export const colors = ['#17A93B', '#E80707'];

export const ChartContainer = styled.div`
	height: 280px;
	width: 100%;
`;

export const LegendRow = styled(Row)`
	margin-bottom: 1.5rem;
`;

export const ColorIndicator = styled.div<{ color: string }>`
	width: 15px;
	height: 25px;
	border-radius: 20px;
	background-color: ${(props) => props.color};
`;

export const LabelText = styled.span`
	font-size: 16px;
	white-space: nowrap;
`;

export const PercentageSpan = styled.span<{ color: string }>`
	background-color: ${(props) => props.color};
	color: white;
	padding: 5px 15px;
	border-radius: 8px;
	font-size: 15px;
	font-weight: bold;
	display: inline-block;
	width: 90%;
	text-align: center;
`;

export const LegendCol = styled(Col)`
	margin-top: 0.5rem;
`;

export const DoughnutChartWrapper = styled(Doughnut)`
	max-width: 100%;
	margin: 0.5rem 34px;
	height: 260px !important;
`;
