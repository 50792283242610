import { Mapper } from './base-mapper';
import { BeraSumRPN } from '@/core/domain/bera-sum-rpn';
import { BeraSumRPNDTO } from '@/core/dto/bera-sum-rpn';

export class BeraSumRPNMapper extends Mapper<BeraSumRPN> {
	private readonly sum_rpn: number;

	constructor({ sum_rpn }: BeraSumRPNDTO) {
		super();
		this.sum_rpn = sum_rpn;
	}

	toDomain(): BeraSumRPN {
		return new BeraSumRPN({
			sum_rpn: this.sum_rpn
		});
	}
}
