import React, { useCallback, useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';

import GNSIcon from '@/assets/icons/dashboard/gns-icon-chart.svg';
import NotGNSIcon from '@/assets/icons/dashboard/not-gns-icon-chart.svg';
import { colors, LineChartWrapper } from './styles';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

type Report = {
	label: string;
	data: number[];
};

interface LineChartProps {
	labels?: any[];
	reports?: Report[];
}

export function LineChart({ labels = [], reports = [] }: LineChartProps) {
	const [icons, setIcons] = useState<HTMLImageElement[] | null>(null);

	function getImages(src: string): Promise<HTMLImageElement> {
		return new Promise<HTMLImageElement>((resolve, reject) => {
			const img = new Image(20, 20);
			img.src = src;
			img.onload = () => resolve(img);
			img.onerror = reject;
		});
	}

	const getIcons = useCallback(async () => {
		try {
			const icons = [GNSIcon, NotGNSIcon];
			const result = await Promise.all(icons.map(getImages));
			setIcons(result);
		} catch (error) {
			setIcons([]);
		}
	}, []);

	useEffect(() => {
		getIcons();
	}, [getIcons]);

	function createDatasets(reports: Report[], icons: HTMLImageElement[]) {
		return reports.map((report, index) => ({
			fill: false,
			pointRadius: 8,
			data: report.data,
			label: report.label,
			pointHoverRadius: 10,
			borderColor: colors[index],
			backgroundColor: colors[index],
			pointStyle: icons[index % icons.length]
		}));
	}

	function createChartOptions() {
		return {
			scales: {
				x: {
					offset: true
				},
				y: {
					beginAtZero: true,
					title: {
						display: true,
						color: '#000',
						text: `${I18n.get('Workstations')}`,
						font: {
							size: 10
						}
					},
					ticks: {
						stepSize: 1
					}
				}
			},
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					enabled: true,
					callbacks: {
						label: (tooltipItem: any) => {
							const datasetLabel = tooltipItem.dataset.label || '';
							const value = tooltipItem.formattedValue;
							return `${I18n.get(datasetLabel)}: ${value}`;
						}
					}
				},
				datalabels: {
					display: false
				}
			},
			elements: {
				point: {
					hoverBorderWidth: 0,
					hoverBorderColor: 'transparent'
				}
			}
		};
	}

	if (icons === null) {
		return null;
	}

	const datasets = createDatasets(reports, icons);
	const options = createChartOptions();
	const data = { datasets, labels };

	return <LineChartWrapper data={data} options={options} />;
}
