import { Header } from './Header';
import { Content } from './Content';
import { Container } from './Container';

export const Card = {
	Container,
	Header,
	Content
};

// Reference: https://vinniciusgomes.medium.com/implementando-composition-pattern-em-aplicacoes-react-4e8dc92742ff
