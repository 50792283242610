import React from 'react';
import { Form } from 'antd';
import { Row, Col } from 'antd';
import { Footer } from './Footer';
import { ModalWrapper } from './styles';
import { Title } from '@/components/Typography';
import { EndDate, FiscalYear, Granularity, PredeterminedTime, StartDate, TimeFrame } from './Fields';

const { useWatch, useFormInstance } = Form;

interface GeneralFilterProps {
	isOpen?: boolean;
	onClose(): void;
	onClear(fieldNames: string[]): void;
}

export function GeneralFilter({ isOpen = false, onClose, onClear }: GeneralFilterProps) {
	const form = useFormInstance();
	const formNameUnique = ['general', 'unique_period'];
	const formNameCompound = ['general', 'compound_period'];
	const predeterminedTimeUnique = useWatch([...formNameUnique, 'predetermined_time_value']);
	const predeterminedFirst = useWatch([...formNameCompound, 'first_period', 'predetermined_time_value']);
	const predeterminedSecond = useWatch([...formNameCompound, 'second_period', 'predetermined_time_value']);

	function handleOnSubmit(): void {
		form.submit();
	}

	return (
		<ModalWrapper open={isOpen} onCancel={onClose} width={700} footer={false} centered>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Title level={2}>Change date for all</Title>
				</Col>
				<Col span={24}>
					<Row gutter={[0, 30]}>
						<Col span={24}>
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<Title level={4}>Single period</Title>
								</Col>
								<Col span={12}>
									<StartDate disabled={predeterminedTimeUnique} formName={formNameUnique} />
								</Col>
								<Col span={12}>
									<EndDate disabled={predeterminedTimeUnique} formName={formNameUnique} />
								</Col>
								<Col span={24}>
									<PredeterminedTime formName={formNameUnique} />
								</Col>
								{predeterminedTimeUnique && (
									<Col span={12}>
										<Row gutter={[20, 0]}>
											<Col span={13}>
												<TimeFrame formName={formNameUnique} />
											</Col>
											<Col>
												<FiscalYear formName={formNameUnique} />
											</Col>
										</Row>
									</Col>
								)}
								<Col span={24}>
									<Granularity formName={formNameUnique} />
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							<Row gutter={[0, 20]}>
								<Col span={24}>
									<Title level={4} style={{ margin: 0 }}>
										Compound period (comparative)
									</Title>
								</Col>
								<Col span={24}>
									<Row gutter={[10, 0]}>
										<Col span={12}>
											<Row gutter={[0, 10]}>
												<Col span={24}>
													<Title level={5} style={{ margin: 0 }}>
														Period 1
													</Title>
												</Col>
												<Col span={24}>
													<Row gutter={[0, 20]}>
														<Col span={24}>
															<StartDate
																disabled={predeterminedFirst}
																formName={[...formNameCompound, 'first_period']}
															/>
														</Col>
														<Col span={24}>
															<EndDate
																disabled={predeterminedFirst}
																formName={[...formNameCompound, 'first_period']}
															/>
														</Col>
														<Col span={24}>
															<PredeterminedTime
																formName={[...formNameCompound, 'first_period']}
															/>
														</Col>
														{predeterminedFirst && (
															<Col span={24}>
																<Row gutter={[20, 0]}>
																	<Col span={13}>
																		<TimeFrame
																			formName={[
																				...formNameCompound,
																				'first_period'
																			]}
																		/>
																	</Col>
																	<Col>
																		<FiscalYear
																			formName={[
																				...formNameCompound,
																				'first_period'
																			]}
																		/>
																	</Col>
																</Row>
															</Col>
														)}
													</Row>
												</Col>
											</Row>
										</Col>
										<Col span={12}>
											<Row gutter={[0, 10]}>
												<Col span={24}>
													<Title level={5} style={{ margin: 0 }}>
														Period 2
													</Title>
												</Col>
												<Col span={24}>
													<Row gutter={[0, 20]}>
														<Col span={24}>
															<StartDate
																disabled={predeterminedSecond}
																formName={[...formNameCompound, 'second_period']}
															/>
														</Col>
														<Col span={24}>
															<EndDate
																disabled={predeterminedSecond}
																formName={[...formNameCompound, 'second_period']}
															/>
														</Col>
														<Col span={24}>
															<PredeterminedTime
																formName={[...formNameCompound, 'second_period']}
															/>
														</Col>
														{predeterminedSecond && (
															<Col span={24}>
																<Row gutter={[20, 0]}>
																	<Col span={13}>
																		<TimeFrame
																			formName={[
																				...formNameCompound,
																				'second_period'
																			]}
																		/>
																	</Col>
																	<Col>
																		<FiscalYear
																			formName={[
																				...formNameCompound,
																				'second_period'
																			]}
																		/>
																	</Col>
																</Row>
															</Col>
														)}
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							<Footer onSubmit={handleOnSubmit} onClear={() => onClear(['general'])} />
						</Col>
					</Row>
				</Col>
			</Row>
		</ModalWrapper>
	);
}
