import React, { useEffect } from 'react';
import { Col, Form, Row, Skeleton } from 'antd';

import { Vulnerabilities, Exposures, Severities, RiskCategories, RiskDescriptions, RiskDamages } from './Inputs';
import { useEwaJdsD86Context } from '@/components/views/EwaJdsD86/context';
import { useApplicationContext } from '@/context/v1/Application/context';
import {
	useCalculateStepKeyRpn,
	useGetCustomReportStepKeysDefaultRisks,
	useGetCustomReportSubStepKeysDefaultRisks
} from '@/hooks';
import { Title } from '@/components/Typography';
import { RiskLevelCard } from './RiskLevelCard';
import { GetCustomReportResultStepKeyDefaultDataResponse } from '@/hooks/useGetCustomReportResultStepKeyDefaultData';

const { useWatch, useFormInstance } = Form;

interface DefaultSelectsProps {
	stepKeyId: string;
	subStepKeyId?: string;
	formFieldName: string[];
	disableExposure?: boolean;
	children?: React.ReactNode;
	customReportDefaultData?: GetCustomReportResultStepKeyDefaultDataResponse | null;
	gettingCustomReportDefaultData?: boolean;
}

export const DefaultSelects: React.FC<DefaultSelectsProps> = ({
	children,
	stepKeyId,
	subStepKeyId,
	formFieldName,
	disableExposure = false,
	customReportDefaultData,
	gettingCustomReportDefaultData
}) => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { ewaJdsD86 } = useEwaJdsD86Context();

	const exposureId: string = useWatch([...formFieldName, 'exposure_id'], form);
	const vulnerabilityId: string = useWatch([...formFieldName, 'vulnerability_id'], form);
	const severityId: string = useWatch([...formFieldName, 'severity_id'], form);

	const { data: stepKeyDefaultRisks, isFetching: gettingStepKeyDefaultRisks } =
		useGetCustomReportStepKeysDefaultRisks({
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_step_key_id: stepKeyId
		});

	const { data: subStepKeyDefaultRisks, isFetching: gettingSubStepKeyDefaultRisks } =
		useGetCustomReportSubStepKeysDefaultRisks({
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_sub_step_key_id: subStepKeyId
		});

	const { data: rpnScore, isFetching: gettingRpnScore } = useCalculateStepKeyRpn({
		exposure_id: exposureId,
		company_id: company?.id,
		severity_id: severityId,
		vulnerability_id: vulnerabilityId,
		organization_id: organization?.id,
		custom_report_step_key_id: stepKeyId
	});

	useEffect(() => {
		if (
			(!gettingCustomReportDefaultData && customReportDefaultData) ||
			(!gettingStepKeyDefaultRisks && !gettingCustomReportDefaultData && stepKeyDefaultRisks) ||
			(!gettingSubStepKeyDefaultRisks && !gettingCustomReportDefaultData && subStepKeyDefaultRisks)
		) {
			const defaultData = subStepKeyId
				? customReportDefaultData?.sub_step_key_results?.find((f) => f?.sub_step_key_id === subStepKeyId)
				: customReportDefaultData?.step_key_result;

			const riskCategoryId: string = form.getFieldValue([...formFieldName, 'risk_category_id']);
			if (!riskCategoryId) {
				form.setFieldValue(
					[...formFieldName, 'risk_category_id'],
					defaultData?.risk_category_id ??
						subStepKeyDefaultRisks.risk_category_id ??
						stepKeyDefaultRisks.risk_category_id
				);
			}

			const riskDescriptionId: string = form.getFieldValue([...formFieldName, 'risk_description_id']);
			if (!riskDescriptionId) {
				form.setFieldValue(
					[...formFieldName, 'risk_description_id'],
					defaultData?.risk_description_id ??
						subStepKeyDefaultRisks.risk_description_id ??
						stepKeyDefaultRisks.risk_description_id
				);
			}

			const riskDamageId: string = form.getFieldValue([...formFieldName, 'risk_damage_id']);
			if (!riskDamageId) {
				form.setFieldValue(
					[...formFieldName, 'risk_damage_id'],
					defaultData?.risk_damage_id ??
						subStepKeyDefaultRisks.risk_damage_id ??
						stepKeyDefaultRisks.risk_damage_id
				);
			}

			const exposureId: string = form.getFieldValue([...formFieldName, 'exposure_id']);
			if (!exposureId) {
				form.setFieldValue([...formFieldName, 'exposure_id'], defaultData?.exposure_id);
			}

			const severityId: string = form.getFieldValue([...formFieldName, 'severity_id']);
			if (!severityId) {
				form.setFieldValue([...formFieldName, 'severity_id'], defaultData?.severity_id);
			}

			const vulnerabilityId: string = form.getFieldValue([...formFieldName, 'vulnerability_id']);
			if (!vulnerabilityId) {
				form.setFieldValue([...formFieldName, 'vulnerability_id'], defaultData?.vulnerability_id);
			}
		}
	}, [gettingStepKeyDefaultRisks, gettingSubStepKeyDefaultRisks, gettingCustomReportDefaultData]);

	useEffect(() => {
		if (!gettingRpnScore && !gettingCustomReportDefaultData && severityId) {
			let rpn = {
				result: customReportDefaultData?.step_key_result?.result ?? rpnScore.result,
				score: customReportDefaultData?.step_key_result?.score ?? rpnScore.score
			};
			if (subStepKeyId && customReportDefaultData) {
				rpn.result =
					customReportDefaultData?.sub_step_key_results?.find((f) => f?.sub_step_key_id === subStepKeyId)
						?.result ?? rpn.result;
				rpn.score =
					customReportDefaultData?.sub_step_key_results?.find((f) => f?.sub_step_key_id === subStepKeyId)
						?.score ?? rpn.score;
			}
			form.setFieldValue([...formFieldName, 'result'], rpn.result);
			form.setFieldValue([...formFieldName, 'score'], rpn.score);
		}
	}, [gettingRpnScore, gettingCustomReportDefaultData]);

	return (
		<Row gutter={[0, 50]}>
			<Col sm={24} lg={12}>
				<Row justify="start">
					<Col sm={24} lg={18}>
						<RiskCategories formFieldName={formFieldName} />
						<RiskDescriptions formFieldName={formFieldName} />
						<RiskDamages formFieldName={formFieldName} />
					</Col>
				</Row>
			</Col>
			<Col sm={24} lg={12}>
				<Row justify="start">
					<Col sm={24} lg={18}>
						<Severities formFieldName={formFieldName} />
						<Vulnerabilities formFieldName={formFieldName} />
						<Exposures formFieldName={formFieldName} disabled={disableExposure} />
					</Col>
				</Row>
			</Col>
			<Col sm={24} lg={12}>
				{children}
			</Col>
			{gettingRpnScore ? (
				<Col sm={24} lg={12}>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Skeleton active />
						</Col>
					</Row>
				</Col>
			) : (
				<Col sm={24} lg={12}>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Title level={4}>Risk Index:</Title>
						</Col>
						<Col span={24}>
							<RiskLevelCard
								stepKeyId={stepKeyId}
								score={rpnScore.score}
								value={rpnScore.result}
								fileId={ewaJdsD86.file_id}
								subStepKeyId={subStepKeyId}
							/>
						</Col>
					</Row>
				</Col>
			)}
		</Row>
	);
};
