const MAX_ROWS = 6;
const MAX_COLUMNS = 6;

function isPrime(num: number): boolean {
	if (num <= 1) return false;
	if (num <= 3) return true;
	if (num % 2 === 0 || num % 3 === 0) return false;
	for (let i = 5; i * i <= num; i += 6) {
		if (num % i === 0 || num % (i + 2) === 0) return false;
	}
	return true;
}

function getSmallestPrimeDivisor(num: number): number {
	if (num <= 1) return 1;
	if (num % 2 === 0) return 2;
	for (let i = 3; i <= Math.sqrt(num); i += 2) {
		if (num % i === 0) return i;
	}
	return num;
}

export function getGridSize(
	N: number = 0,
	maxColumns: number = MAX_COLUMNS,
	maxRows: number = MAX_ROWS
): { columns: number; rows: number } {
	let columns: number, rows: number;

	if (N <= 3) {
		columns = 1;
		rows = N;
	} else {
		let divisor: number;
		if (isPrime(N)) {
			divisor = getSmallestPrimeDivisor(N - 1);
		} else {
			divisor = getSmallestPrimeDivisor(N);
		}

		columns = divisor;
		rows = Math.ceil(N / columns);

		if (columns > maxColumns || rows > maxRows) {
			columns = Math.min(columns, maxColumns);
			rows = Math.ceil(N / columns);

			if (rows > maxRows) {
				rows = maxRows;
				columns = Math.ceil(N / rows);
			}
		}
	}

	return { columns, rows };
}
