import React from 'react';
import { Form, Grid, Row } from 'antd';
import { useLocation } from 'react-router-dom';
import { Title } from '@/components/Typography';
import { CalendarOutlined } from '@ant-design/icons';

import { unShortenUrl } from '@/views/Dashboard/utils';
import { Card } from '@/views/Dashboard/components/Card';
import { Single } from '@/views/Dashboard/components/Filters/Single';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetDelayedActionPlans } from '@/views/Dashboard/hooks/useGetDelayedActionPlans';
import { StyledDivPopOver } from '@/views/Dashboard/styles';
import * as S from './styles';

const { useBreakpoint } = Grid;

interface ChecklistReportsProps {
	handleClear(fieldNames: string[]): void;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const { useFormInstance } = Form;

export function CreatedResolved({ handleClear }: ChecklistReportsProps) {
	const { organization } = useApplicationContext();
	const query = useQuery();
	const location = useLocation();
	const form = useFormInstance();
	const breakpoints = useBreakpoint();
	const cardSize = isScreenLessThanXXL() ? 'medium' : 'small';

	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');

	const unshortenUrl = unShortenUrl(location.search.substring(1));
	const checklistParams = unshortenUrl?.ap_resolved ?? unshortenUrl?.general?.unique_period;

	const filterParams = {
		organization_id: organization?.id!,
		line_id: line_id ?? undefined,
		sector_id: sector_id ?? undefined,
		company_id: company_id ?? undefined,
		workstation_id: workstation_id ?? undefined,
		end_date: checklistParams?.end_date ?? undefined,
		start_date: checklistParams?.start_date ?? undefined
	};

	const { isLoading, data, isError, refetch } = useGetDelayedActionPlans(filterParams);

	function handleOnSubmit(): void {
		form.submit();
	}

	function isScreenLessThanXXL(): boolean {
		return !breakpoints.xxl;
	}

	return (
		<Card.Container size={cardSize} isLoading={isLoading} isError={isError} onRefetch={refetch}>
			<Card.Header
				hasIcon
				icon={<CalendarOutlined />}
				title="Actions created and resolved"
				popoverComponent={
					<StyledDivPopOver>
						<Single
							hasGranularity={false}
							fieldName={['ap_resolved']}
							handleClear={handleClear}
							handleFormSubmit={handleOnSubmit}
						/>
					</StyledDivPopOver>
				}
			/>
			<Card.Content>
				<Row gutter={[0, 40]}>
					<S.StyledCol xs={24} xxl={12}>
						<Row>
							<S.StyledCol span={24}>
								<Title level={1}>{data?.total.toLocaleString(navigator.language)}</Title>
							</S.StyledCol>
							<S.StyledCol span={24}>
								<Title level={5}>Actions created</Title>
							</S.StyledCol>
						</Row>
					</S.StyledCol>
					<S.StyledCol xs={24} xxl={12}>
						<Row>
							<S.StyledCol span={24}>
								<Title level={1}>{data?.delayed.toLocaleString(navigator.language)}</Title>
							</S.StyledCol>
							<S.StyledCol span={24}>
								<Title level={5}>Delayed actions</Title>
							</S.StyledCol>
						</Row>
					</S.StyledCol>
				</Row>
			</Card.Content>
		</Card.Container>
	);
}
