import React, { useState } from 'react';
import axios from 'axios';
import { Row, Col, message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useQueryClient } from '@tanstack/react-query';

import { QUERY_KEYS } from '@/utils/query-keys';
import { Title } from '@/components/Typography';
import { useAddEvidence } from '@/hooks/v2/useAddEvidence';
import { useGetEvidences } from '@/hooks/v2/useGetEvidences';

import { Drop } from './Drop';
import { Footer } from './Footer';
import { FilePreview } from './FilePreview';
import { compressImage, getHeaders } from '../utils';
import { ModalWrapper, StyledDivider } from './styles';
import { EvidencesProps } from './types';

export function Evidences({ actionPlanTaskId, companyId, organizationId, isOpen, onCancel, taskName }: EvidencesProps) {
	const queryClient = useQueryClient();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { data: attachments, error: attachmentsError } = useGetEvidences({
		company_id: companyId,
		organization_id: organizationId,
		action_plan_task_id: actionPlanTaskId
	});

	const { mutateAsync: addEvidence } = useAddEvidence();

	async function handleOnDropFile(file: File): Promise<void> {
		setIsLoading(true);
		try {
			const fileForUpload = file.type === 'image' ? await compressImage(file) : file;
			const payload = {
				company_id: companyId,
				size: fileForUpload.size,
				file_name: fileForUpload.name,
				organization_id: organizationId,
				content_type: fileForUpload.type,
				action_plan_task_id: actionPlanTaskId
			};

			const { url } = await addEvidence(payload);
			const headers = getHeaders(fileForUpload);
			await axios.put(url, fileForUpload, headers);
			await queryClient.invalidateQueries([QUERY_KEYS.GET_EVIDENCES]);

			message.success(I18n.get('File uploaded successfully'));
		} catch (error) {
			message.error(I18n.get('Failed to upload file'));
		} finally {
			setIsLoading(false);
		}
	}

	function handleOnSubmit(): void {
		onCancel();
	}

	if (attachmentsError) {
		message.error(I18n.get('Failed to load data'));
		return null;
	}

	return (
		<ModalWrapper open={isOpen} footer={false} width={770} centered onCancel={onCancel}>
			<Row gutter={[0, 5]}>
				<Col span={24}>
					<Title level={3}>{I18n.get('Attach evidences')}</Title>
				</Col>
				<Col span={24}>
					<Title level={5}>{taskName}</Title>
				</Col>

				<Col span={24}>
					<Drop
						disabled={isLoading || (attachments && attachments.length === 4)}
						onFileDrop={handleOnDropFile}
					/>
				</Col>
				{attachments && attachments.length > 0 && (
					<Col span={24}>
						<FilePreview files={attachments} companyId={companyId} organizationId={organizationId} />
					</Col>
				)}
				<Col span={24}>
					<StyledDivider />
				</Col>
				<Col span={24}>
					<Row gutter={[0, 30]}>
						<Col span={24} style={{ marginTop: '1rem' }}>
							<Footer loading={isLoading} onCancel={onCancel} onSubmit={handleOnSubmit} />
						</Col>
					</Row>
				</Col>
			</Row>
		</ModalWrapper>
	);
}
