import { Mapper } from './base-mapper';
import { ActionPlanDelayed } from '../domain/action-plan-delayed';
import { ActionPlanDelayedDTO } from '../dto/action-plan-delayed';

export class ActionPlanDelayedMapper implements Mapper<ActionPlanDelayed> {
	private readonly total: number;
	private readonly delayed: number;

	constructor({ total, delayed }: ActionPlanDelayedDTO) {
		this.total = total;
		this.delayed = delayed;
	}

	toDomain(): ActionPlanDelayed {
		return new ActionPlanDelayed({
			total: this.total,
			delayed: this.delayed
		});
	}
}
