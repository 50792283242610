import React from 'react';
import { Bar } from 'react-chartjs-2';
import { I18n } from '@aws-amplify/core';
import { ChartData, ChartOptions } from 'chart.js';

interface DoubleBarChartProps {
	labels?: string[];
	riskColors?: string[];
	leftBarData?: number[];
	rightBarData?: number[];
}

export function DoubleBarChart({
	labels = [],
	riskColors = [],
	leftBarData = [],
	rightBarData = []
}: Readonly<DoubleBarChartProps>) {
	function decreaseHexColorOpacity(color: string): string {
		return `${color}30`;
	}

	function createStripedPattern(color: string) {
		const colorDecreasedOpacity = decreaseHexColorOpacity(color);

		const patternCanvas = document.createElement('canvas');
		const patternContext = patternCanvas.getContext('2d');
		if (!patternContext) return colorDecreasedOpacity;

		patternCanvas.width = 10;
		patternCanvas.height = 10;
		patternContext.fillStyle = colorDecreasedOpacity;
		patternContext.fillRect(0, 0, 10, 10);
		patternContext.strokeStyle = 'rgba(255,255,255,0.5)';
		patternContext.lineWidth = 2;
		patternContext.beginPath();
		patternContext.moveTo(0, 0);
		patternContext.lineTo(10, 10);
		patternContext.stroke();

		return patternContext.createPattern(patternCanvas, 'repeat') || colorDecreasedOpacity;
	}

	const commonOptions = {
		label: I18n.get('Number of reports'),
		maxBarThickness: 60,
		barPercentage: 0.8,
		categoryPercentage: 0.6,
		borderRadius: { topLeft: 6, topRight: 6 }
	};

	const chartData: ChartData<'bar'> = {
		labels,
		datasets: [
			{
				...commonOptions,
				data: leftBarData,
				backgroundColor: riskColors.map(createStripedPattern)
			},
			{
				...commonOptions,
				data: rightBarData,
				backgroundColor: riskColors
			}
		]
	};

	const options: ChartOptions<'bar'> = {
		animation: false,
		responsive: true,
		maintainAspectRatio: false,
		layout: {
			padding: {
				top: 25
			}
		},
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: I18n.get('Number of reports'),
					padding: 10,
					font: {
						size: 16
					},
					color: '#000000'
				},
				ticks: {
					font: {
						size: 14
					}
				},
				grid: {
					display: false
				}
			},
			x: {
				stacked: false,
				ticks: {
					font: {
						size: 14
					}
				},
				grid: {
					display: false
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
			datalabels: {
				anchor: 'end' as const,
				align: 'top' as const,
				padding: 0,
				font: {
					size: 14,
					weight: 600
				},
				formatter: (value: number) => value.toLocaleString(navigator.language)
			}
		}
	};

	return (
		<div style={{ height: '100%' }}>
			<Bar data={chartData} options={options} />
		</div>
	);
}
