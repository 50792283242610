import { Mapper } from './base-mapper';
import { SeraComparison } from '@/core/domain/sera-comparison';
import { SeraComparisonDTO, SeraComparisonItemDTO } from '@/core/dto/sera-comparison';

export class SeraComparisonMapper extends Mapper<SeraComparison> {
	private readonly data: SeraComparisonDTO;

	constructor(data: SeraComparisonDTO) {
		super();
		this.data = data;
	}

	toTransform(value: SeraComparisonItemDTO): SeraComparison {
		return new SeraComparison({
			id: value.id,
			name: value.name,
			total_rpn: parseInt(value.total_rpn, 10)
		});
	}

	toDomain(): SeraComparison[] {
		return this.data.map(this.toTransform.bind(this));
	}
}
