import { Mapper } from './base-mapper';
import { CustomReport } from '@/core/domain/custom-report';
import { CustomReportDTO, CustomReportItemDTO } from '@/core/dto/custom-report';

export class CustomReportFindAllMapper extends Mapper<CustomReport> {
	private readonly custom_reports: CustomReportDTO;

	constructor(custom_reports: CustomReportDTO) {
		super();
		this.custom_reports = custom_reports;
	}

	toTransform(value: CustomReportItemDTO): CustomReport {
		return new CustomReport({
			id: value.id,
			name: value.name,
			description: value.description,
			acronym: value.acronym
		});
	}

	toDomain(): CustomReport[] {
		return this.custom_reports.map(this.toTransform.bind(this));
	}
}
