interface ActionPlanCreateCommentsItemAttributes {
	id: string;
	action_plan_id: string;
	description: string;
	updated_at: Date;
	created_at: Date;
}

export class ActionPlanCreateComments {
	public readonly id: string;
	public readonly action_plan_id: string;
	public readonly description: string;
	public readonly updated_at: Date;
	public readonly created_at: Date;

	constructor({ id, action_plan_id, description, updated_at, created_at }: ActionPlanCreateCommentsItemAttributes) {
		this.id = id;
		this.action_plan_id = action_plan_id;
		this.description = description;
		this.updated_at = updated_at;
		this.created_at = created_at;
	}
}

export interface ActionPlanCreateCommentsDomain {
	data: ActionPlanCreateComments[];
}
