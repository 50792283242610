import React from 'react';
import { Moment } from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Switch, Form } from 'antd';
import { handleDatePeriod } from './predetermined-helper';

const { useFormInstance } = Form;

interface FiscalYearProps {
	formName: string[];
}

type InputDatePeriod = { fiscal_year: boolean; predetermined: string };
type InputDate = { start_date: Moment; end_date: Moment };

export function FiscalYear({ formName }: Readonly<FiscalYearProps>) {
	const form = useFormInstance();

	function handleGetInputDatePeriod(): InputDatePeriod {
		const fiscal_year = form.getFieldValue([...formName, 'fiscal_year']);
		const predetermined = form.getFieldValue([...formName, 'time_frame']);
		return { fiscal_year, predetermined };
	}

	function handleSetPeriodDate({ start_date, end_date }: InputDate): void {
		form.setFieldValue([...formName, 'start_date'], start_date);
		form.setFieldValue([...formName, 'end_date'], end_date);
	}

	function handleOnChange(): void {
		const { fiscal_year, predetermined } = handleGetInputDatePeriod();
		const { start_date, end_date } = handleDatePeriod(fiscal_year, predetermined);
		handleSetPeriodDate({ start_date, end_date });
	}

	return (
		<Row align="middle">
			<Col>
				<Form.Item name={[...formName, 'fiscal_year']} valuePropName="checked" style={{ margin: 0 }}>
					<Switch onChange={handleOnChange} size="small" />
				</Form.Item>
			</Col>
			<Col>
				<span style={{ marginLeft: '5px' }}>{I18n.get('Fiscal year (FY)')}</span>
			</Col>
		</Row>
	);
}
