import React from 'react';
import * as S from './styles';

export type StatusType = 'Not started' | 'Doing' | 'Done' | string;

type StatusProps = {
	bordered?: boolean;
	status?: StatusType;
	children?: string;
} & typeof defaultProps;

const defaultProps = { children: '', bordered: false, status: '' };

export function Status({ children, status, bordered }: Readonly<StatusProps>) {
	return (
		<S.Status $type={status} $bordered={bordered}>
			{children}
		</S.Status>
	);
}
