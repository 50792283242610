interface ActionPlanHistoryItemAttributes {
	id: string;
	action_plan_id: string;
	type: string;
	description: string;
	author: string;
	created_at: string | null;
}

export class ActionPlanHistory {
	public readonly id: string;
	public readonly action_plan_id: string;
	public readonly type: string;
	public readonly description: string;
	public readonly author: string;
	public readonly created_at: string | null;

	constructor({ id, action_plan_id, type, description, author, created_at }: ActionPlanHistoryItemAttributes) {
		this.id = id;
		this.action_plan_id = action_plan_id;
		this.type = type;
		this.description = description;
		this.author = author;
		this.created_at = created_at;
	}
}

export interface ActionPlanHistoryDomain {
	data: ActionPlanHistory[];
}
