import styled from 'styled-components';

export const Column = styled.div`
	padding: 1rem;
	display: flex;
	border-radius: 10px;
	flex-direction: column;
	height: calc(100vh - 200px);
	background-color: ${({ color }: { color: string }) => color};
`;

export const CardContainer = styled.div`
	flex-grow: 1;
	overflow-y: auto;
	margin-top: 1.5rem;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 3px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;

export const Totalizer = styled.span`
	background-color: ${({ color }: { color: string }) => color};
	border-radius: 5px;
	margin-left: 10px;
	font-weight: bold;
	padding: 2px 8px;
	margin-top: 10px;
	font-size: 1rem;
	color: #fff;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const Content = styled.div`
	flex: 1;
	overflow-y: auto;
	padding: 8px;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 3px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;
