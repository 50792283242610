import { Mapper } from './base-mapper';
import { BeraSixToo } from '../domain/bera-six-too';
import { BeraSixTooDTO } from '../dto/bera-six-too';

export class BeraSixTooMapper implements Mapper<BeraSixToo> {
	private readonly items: BeraSixTooDTO;

	constructor(items: BeraSixTooDTO) {
		this.items = items;
	}

	toDomain(): BeraSixToo {
		return new BeraSixToo({
			too_high: this.getSumRPN('Too High'),
			too_low: this.getSumRPN('Too Low'),
			too_far: this.getSumRPN('Too Far'),
			too_many_times: this.getSumRPN('Too Many'),
			too_heavy: this.getSumRPN('Too Much'),
			too_long: this.getSumRPN('Too Long')
		});
	}

	private getSumRPN(name: string): number {
		return this.items.find((item) => item.six_too_name === name)?.sum_rpn || 0;
	}
}
