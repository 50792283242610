import React, { useMemo } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { handleBackClick, full_hierarchy, navigation_hierarchy } from './navigation-helpers';
import * as S from '../styles';

interface Entity {
	id: string;
	name: string;
	entity: string;
}

export function Navigation(): JSX.Element | null {
	const history = useHistory();
	const location = useLocation();

	function handleReduceNavigation(search_params: URLSearchParams): Entity[] {
		return navigation_hierarchy.reduce<Entity[]>((acc, entity) => {
			const id = search_params.get(entity);
			const name = search_params.get(`${entity}_name`);

			if (id && name) {
				acc.push({ entity, id, name });
			} else {
				return acc;
			}

			return acc;
		}, []);
	}

	const entities = useMemo(() => {
		const search_params = new URLSearchParams(location.search);
		const navigation_reduced = handleReduceNavigation(search_params);
		return navigation_reduced;
	}, [location.search]);

	if (entities.length === 0) {
		return null;
	}

	const active_index = entities.length - 1;

	function handleOnClickReturn(): void {
		handleBackClick(location, history);
	}

	function handleOnClickSetItem(index: number): void {
		const entity = entities[index];
		const hierarchy_index = full_hierarchy.indexOf(entity.entity);
		const new_search_params = new URLSearchParams(location.search);

		for (let i = hierarchy_index + 1; i < full_hierarchy.length; i++) {
			new_search_params.delete(full_hierarchy[i]);
			new_search_params.delete(`${full_hierarchy[i]}_name`);
		}

		history.push({
			pathname: location.pathname,
			search: `?${new_search_params.toString()}`
		});
	}

	return (
		<S.HeaderNavigation>
			<S.NavigationWrapper>
				<S.BackButton onClick={handleOnClickReturn}>
					<S.NavItem>
						<LeftOutlined style={{ fontSize: '12px', marginRight: '0.1rem' }} />
					</S.NavItem>
				</S.BackButton>
				<S.Separator> | </S.Separator>
				{entities.map((item: Entity, index: number) => (
					<React.Fragment key={item.entity}>
						{index > 0 && index !== active_index && <S.Separator> | </S.Separator>}
						<S.NavItem onClick={() => handleOnClickSetItem(index)} active={index === active_index}>
							{item.name}
						</S.NavItem>
					</React.Fragment>
				))}
			</S.NavigationWrapper>
		</S.HeaderNavigation>
	);
}
