export const labels = [
	'Not identified',
	'Acceptable',
	'Investigate',
	'Investigate and implement changes',
	'Implement changes'
];

export const bodyPartNameMapper: { [key: string]: string } = {
	left_side: 'Left side',
	right_side: 'Right side'
};
