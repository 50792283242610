import React from 'react';
import { I18n } from '@aws-amplify/core';
import { FormFieldProps } from '../../types';
import * as S from './styles';

export function Description({ formName = [''], isRequired = false }: Readonly<FormFieldProps>) {
	const title = I18n.get('Description');

	return (
		<S.CustomFormItem
			label={title}
			required={isRequired}
			labelCol={{ span: 24 }}
			name={[...formName, 'description']}
		>
			<S.CustomTextArea maxLength={500} rows={7} showCount placeholder={title} />
		</S.CustomFormItem>
	);
}
