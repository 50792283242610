import React from 'react';
import { Col } from 'antd';
import { Empty } from '@/components/ui/Empty';

interface ContentProps {
	children: JSX.Element | JSX.Element[];
	isEmpty?: boolean;
}

export function Content({ children, isEmpty = false }: Readonly<ContentProps>) {
	if (isEmpty) {
		return (
			<Col span={24}>
				<Empty />
			</Col>
		);
	}

	return <Col span={24}>{children}</Col>;
}
