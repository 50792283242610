import { ActionPlanTaskAttachmentDownloadUrlRequestDTO } from '@/core/dto/action-plan-evidences';
import { Service } from '@/infra/services/action-plan';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';

export const useGetDownloadUrlTaskAttachment = (params: ActionPlanTaskAttachmentDownloadUrlRequestDTO) => {
	return useQuery([QUERY_KEYS.GET_TASK_ATTACHMENT_DOWNLOAD_URL, params], () =>
		Service.getTaskAttachmentDownloadUrl(params)
	);
};
