import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { ArrowUpIcon } from '@/assets/icons/arrow-up-icon';
import { ArrowDownIcon } from '@/assets/icons/arrow-down-icon';
import * as S from './styles';

interface VariationProps {
	children: number;
}

export function Variation({ children }: Readonly<VariationProps>) {
	const locale = moment().locale();
	const formattedValue = Math.abs(children).toFixed(1);
	const value = new Intl.NumberFormat(locale).format(parseFloat(formattedValue));

	function renderArrowIcon(): JSX.Element {
		if (children > 0) {
			return <ArrowUpIcon />;
		}

		if (children < 0) {
			return <ArrowDownIcon />;
		}

		return <></>;
	}

	return (
		<Row align="middle">
			<S.IconWrapper>{renderArrowIcon()}</S.IconWrapper>
			<Col>
				<span style={{ fontWeight: 600 }}>{value}%</span>
			</Col>
		</Row>
	);
}
