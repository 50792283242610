import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import * as S from './styles';

interface PeriodSubtitlesProps {
	startDate?: Date;
	endDate?: Date;
	color: string;
}

export function PeriodSubtitles({ startDate, endDate, color }: Readonly<PeriodSubtitlesProps>) {
	const defaultStartDate = moment('2020-01-01');
	const defaultendDate = moment().endOf('day');

	const initialDate = startDate ?? defaultStartDate;
	const finalDate = endDate ?? defaultendDate;

	return (
		<Row align="middle" gutter={[8, 0]}>
			<Col>
				<S.RiskLevelIndicator $color={color} />
			</Col>
			<Col>
				<span>
					{moment(initialDate).format('L')} {I18n.get('to')} {moment(finalDate).format('L')}
				</span>
			</Col>
		</Row>
	);
}
