import React, { useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { I18n } from '@aws-amplify/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartOptions,
	ChartData
} from 'chart.js';
import * as S from './styles';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface BarChartProps {
	data?: number[];
	labels: string[];
}

export function BarChart({ data = [], labels }: Readonly<BarChartProps>) {
	const chartRef = useRef<ChartJS>(null);

	useEffect(() => {
		const resizeChart = () => {
			if (chartRef.current) {
				chartRef.current.resize();
			}
		};
		resizeChart();
		window.addEventListener('resize', resizeChart);
		return () => window.removeEventListener('resize', resizeChart);
	}, []);

	const chartData: ChartData<'bar'> = {
		labels: labels,
		datasets: [
			{
				label: I18n.get('Sum of RPN'),
				data: data,
				backgroundColor: '#2F54EB',
				barThickness: 40,
				borderRadius: 6
			}
		]
	};

	const options: ChartOptions<'bar'> = {
		animation: false,
		responsive: true,
		maintainAspectRatio: false,
		layout: {
			padding: {
				top: 30,
				left: 20,
				right: 20,
				bottom: 20
			}
		},
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: I18n.get('Sum of RPN'),
					font: {
						size: 17
					},
					color: '#000'
				},
				ticks: {
					font: {
						size: 13
					}
				},
				grid: {
					display: false
				}
			},
			x: {
				ticks: {
					font: {
						size: 13
					}
				},
				grid: {
					display: false
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
			datalabels: {
				align: 'top' as const,
				anchor: 'end' as const,
				font: {
					size: 16,
					weight: 'bold'
				},
				formatter: (value: number) => value.toLocaleString(navigator.language)
			}
		}
	};

	return (
		<S.Container>
			<Bar data={chartData} options={options} />
		</S.Container>
	);
}
