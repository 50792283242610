import { AxiosError } from 'axios';

import { useMutation } from '@tanstack/react-query';
import { Service } from '@/infra/services/action-plan';
import { DeleteAttachmentResponseDTO } from '@/core/dto/action-plan-attachment';
import type { Response } from '@/types';

export interface DeleteAttachmentParams {
	company_id: string;
	organization_id: string;
	action_plan_attachment_id: string;
}

export const useDeleteAttachment = () => {
	return useMutation<DeleteAttachmentResponseDTO, AxiosError<Response>, DeleteAttachmentParams>((params) =>
		Service.deleteAttachment(params)
	);
};
