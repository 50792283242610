import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '@/infra/services/action-plan';

export interface GetOriginsRequest {
	organization_id: string;
	company_id: string;
	workstation_id: string;
	report_name?: string;
	limit?: number;
	offset?: number;
}

export const useGetOrigins = (params: GetOriginsRequest) => {
	return useQuery([QUERY_KEYS.GET_ACTION_PLAN_ORIGINS, params], () => Service.origins(params), {
		enabled: !!params.company_id && !!params.workstation_id,
		retry: 0
	});
};
