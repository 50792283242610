import React from 'react';
import { UploadFile, message } from 'antd';
import Icon, { EyeOutlined, DeleteOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';

import { MessageOutlined } from '@/assets/icons/action-plans/message-outlined';
import { useGetDownloadUrlAttachment } from '@/hooks/v2/useGetDownloadUrlAttachment';
import { StyledCol, StyledRow, IconWrapper, COLOR_ICON_DANGER, COLOR_ICON_ENABLED } from './styles';

interface UploadFileItem extends UploadFile {
	author: string;
	created_at: string;
}

interface ActionsProps {
	file: UploadFile;
	companyId: string;
	isImageFile: boolean;
	organizationId: string;
	uploadFileItem: UploadFileItem;
	handlePreview: (file: UploadFile) => void;
	handleDelete: (uploadFileItem: UploadFileItem) => void;
	handleShowInfo: (uploadFileItem: UploadFileItem) => void;
	handleCommentClick: (uploadFileItem: UploadFileItem) => void;
}

export function Actions({
	file,
	companyId,
	isImageFile,
	handleDelete,
	handlePreview,
	uploadFileItem,
	handleShowInfo,
	organizationId,
	handleCommentClick
}: Readonly<ActionsProps>) {
	const { isLoading, data, isError } = useGetDownloadUrlAttachment({
		company_id: companyId,
		organization_id: organizationId,
		action_plan_attachment_id: uploadFileItem.uid
	});

	function handleOnDownload(): void {
		data && window.open(data, '_blank');
	}

	if (isError) {
		message.error('Error downloading file');
		return null;
	}

	return (
		<div className="custom-upload-actions">
			<StyledRow>
				<StyledCol span={12}>
					<IconWrapper>
						<InfoCircleOutlined
							style={{ color: COLOR_ICON_ENABLED }}
							onClick={() => handleShowInfo(uploadFileItem)}
						/>
					</IconWrapper>
				</StyledCol>
				<StyledCol span={12}>
					<IconWrapper>
						{isImageFile ? (
							<EyeOutlined
								disabled={!isImageFile}
								style={{ color: COLOR_ICON_ENABLED }}
								onClick={() => handlePreview(file)}
							/>
						) : (
							<DownloadOutlined
								disabled={isLoading}
								onClick={handleOnDownload}
								style={{ color: COLOR_ICON_ENABLED }}
							/>
						)}
					</IconWrapper>
				</StyledCol>
				<StyledCol span={12}>
					<IconWrapper>
						<Icon component={MessageOutlined} onClick={() => handleCommentClick(uploadFileItem)} />
					</IconWrapper>
				</StyledCol>
				<StyledCol span={12}>
					<IconWrapper>
						<DeleteOutlined
							style={{ color: COLOR_ICON_DANGER }}
							onClick={() => handleDelete(uploadFileItem)}
						/>
					</IconWrapper>
				</StyledCol>
			</StyledRow>
		</div>
	);
}
