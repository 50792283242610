import { Mapper } from './base-mapper';
import { SeraSumRPN } from '@/core/domain/sera-sum-rpn';
import { SeraSumRPNDTO } from '@/core/dto/sera-sum-rpn';

export class SeraSumRPNMapper extends Mapper<SeraSumRPN> {
	private readonly sum_rpn: number;

	constructor({ sum_rpn }: SeraSumRPNDTO) {
		super();
		this.sum_rpn = sum_rpn;
	}

	toDomain(): SeraSumRPN {
		return new SeraSumRPN({ sum_rpn: this.sum_rpn });
	}
}
