import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParams } from '@/hooks/v2/useQueryParams';
import type { FormFieldProps } from '../../types';
import * as S from './styles';

export function Sort({ isRequired }: Readonly<FormFieldProps>) {
	const history = useHistory();
	const location = useLocation();
	const query = useQueryParams();

	const title = I18n.get('Sort');
	const value = query.get('sort');

	function handleOnChange(value: any): void {
		value ? setUrl(value) : removeFromUrl();
	}

	function removeFromUrl(): void {
		query.delete('sort');

		history.replace({
			pathname: location.pathname,
			search: query.toString()
		});
	}

	function setUrl(value: string): void {
		query.set('sort', value);

		history.replace({
			pathname: location.pathname,
			search: query.toString()
		});
	}

	const options = [
		{ value: 'alph_asc', label: I18n.get('Alphabetical A-Z') },
		{ value: 'alph_desc', label: I18n.get('Alphabetical Z-A') },
		{ value: 'most_recent', label: I18n.get('Most recent') },
		{ value: 'least_recent', label: I18n.get('Least recent') },
		{ value: 'nearest_date', label: I18n.get('Nearest due date') },
		{ value: 'furthest_date', label: I18n.get('Furthest due date') },
		{ value: 'high_priority', label: I18n.get('Highest priority') },
		{ value: 'low_priority', label: I18n.get('Lowest priority') },
		{ value: 'high_investment', label: I18n.get('Highest investment') },
		{ value: 'lower_investment', label: I18n.get('Lowest investiment') }
	];

	return (
		<S.CustomFormItem name={['sort']} required={isRequired}>
			<S.HighlightedSelect
				allowClear
				options={options}
				placeholder={title}
				$isSelected={!!value}
				onChange={handleOnChange}
			/>
		</S.CustomFormItem>
	);
}
