import { Mapper } from './base-mapper';
import { KimManualHandlingRiskCount } from '@/core/domain/kim-mho-risk-count';
import { KimManualHandlingRiskCountDTO } from '@/core/dto/kim-mho-risk-count';

export class KimManualHandlingCountMapper extends Mapper<KimManualHandlingRiskCount[]> {
	private readonly risks: KimManualHandlingRiskCountDTO[];

	constructor(risks: KimManualHandlingRiskCountDTO[]) {
		super();
		this.risks = risks;
	}

	toDomain(): KimManualHandlingRiskCount[] {
		return this.risks;
	}
}
