import React, { ReactNode } from 'react';
import { Row, Col, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Title } from './Title';
import { Subtitle } from './Subtitle';
import * as S from './styles';

interface HeaderProps {
	title: string;
	subtitle?: string;
	isTitleBold?: boolean;
	hasIcon?: boolean;
	icon?: ReactNode;
	popoverComponent: JSX.Element;
	hasDisplayDate?: boolean;
	displayDate?: { start: string; end: string };
	onClick?(): void;
}

export function Header({
	title,
	subtitle = '',
	isTitleBold = false,
	hasIcon = false,
	hasDisplayDate = false,
	icon,
	popoverComponent,
	displayDate,
	onClick
}: Readonly<HeaderProps>) {
	const tooltipText = `${I18n.get(title)} ${I18n.get(subtitle)}`;

	return (
		<Col span={24}>
			<Row>
				<Col span={24} style={{ display: 'flex', justifyContent: 'space-between', padding: '0 6px' }}>
					<Tooltip title={tooltipText}>
						<S.HeaderContent>
							<Title bold={isTitleBold}>{title}</Title>
							<Subtitle>{subtitle}</Subtitle>
						</S.HeaderContent>
					</Tooltip>

					{hasIcon && (
						<S.HeaderContentWrapper>
							{hasDisplayDate && (
								<S.DisplayDate>
									<span>
										{displayDate?.start} {I18n.get('to')} {displayDate?.end}
									</span>
								</S.DisplayDate>
							)}
							<S.CustomPopover placement="bottomLeft" trigger="click" content={popoverComponent}>
								<S.IconWrapper onClick={onClick}>{icon}</S.IconWrapper>
							</S.CustomPopover>
						</S.HeaderContentWrapper>
					)}
				</Col>
				<Col span={24}>
					<S.DividerWrapper />
				</Col>
			</Row>
		</Col>
	);
}
