import { BaseService } from './base-service';
import { LibertyMutualPercentileByGender } from '@/core/domain/liberty-mutual-percentile-by-gender';
import {
	LibertyMutualPercentileByGenderDTO,
	LibertyMutualPercentileByGenderRequestDTO
} from '@/core/dto/liberty-mutual-percentile-by-gender';
import { LibertyMutualPercentileByGenderMapper } from '@/core/mapper/liberty-mutual-percentile-by-gender';

class LibertyMutual extends BaseService<any> {
	constructor(public readonly basePath: string = '/ergonomic-tool/liberty-mutual') {
		super();
	}

	async percentileByGender(
		params: LibertyMutualPercentileByGenderRequestDTO
	): Promise<LibertyMutualPercentileByGender> {
		const url = this.basePath + '/percentile-by-gender';
		const { data } = await this.getInstance().get<LibertyMutualPercentileByGenderDTO>(url, { params });
		return new LibertyMutualPercentileByGenderMapper(data).toDomain();
	}
}

const Service = Object.freeze(new LibertyMutual());
export { Service };
