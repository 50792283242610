import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/bera';
import { BeraGenderNeutralStation } from '@/core/domain/bera-gns';

interface GetBeraGendrNeutralStationParams {
	organization_id: string;
	workstation_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	granularity: 'day' | 'week' | 'month' | 'quarter' | 'semester' | 'year';
	is_gender_neutral?: boolean;
	fiscal_year?: boolean;
	start_date?: Date;
	end_date?: Date;
}

export const getGenderNeutralReports = (params: GetBeraGendrNeutralStationParams) => {
	return Service.genderNeutral(params);
};

export const useGetBeraGenderNeutralStation = (params: GetBeraGendrNeutralStationParams) => {
	return useQuery<BeraGenderNeutralStation, Error>(
		[QUERY_KEYS.GET_BERA_GNS, params],
		() => Service.genderNeutral(params),
		{
			enabled: !!params.organization_id
		}
	);
};
