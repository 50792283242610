import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';

interface GetUserPreferenceColumnsParams {
	organization_id: string;
	company_id: string;
}

export const getUserPreferenceColumns = (params: GetUserPreferenceColumnsParams) => {
	return Service.getUserPreferenceColumns(params);
};

export const useGetUserPreferenceColumnsActionPlan = (params: GetUserPreferenceColumnsParams) => {
	return useQuery([QUERY_KEYS.GET_ACTION_PLAN_USER_PREFERENCE_COLUMNS, params], () => Service.getUserPreferenceColumns(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
