import { Mapper } from './base-mapper';
import { SeraMainRisk } from '../domain/sera-main-risk';
import { SeraMainRiskDTO } from '../dto/sera-main-risk';

export class SeraMainRiskMapper implements Mapper<SeraMainRisk> {
	constructor(private readonly items: SeraMainRiskDTO) {}

	toDomain(): SeraMainRisk {
		return new SeraMainRisk(this.items);
	}
}
