import React from 'react';
import { I18n } from '@aws-amplify/core';
import type { FormFieldProps } from './types';
import * as S from './styles';

export function Priority({
	formName = [''],
	isLoading = false,
	isDisabled = false,
	isRequired = false
}: Readonly<FormFieldProps>) {
	const title = I18n.get('Priority');

	const options = [
		{ label: I18n.get('Low'), value: 1 },
		{ label: I18n.get('Medium'), value: 2 },
		{ label: I18n.get('High'), value: 3 }
	];

	return (
		<S.CustomFormItem label={title} labelCol={{ span: 24 }} name={[...formName, 'priority']} required={isRequired}>
			<S.CustomSelect
				allowClear
				options={options}
				loading={isLoading}
				placeholder={title}
				disabled={isDisabled}
			/>
		</S.CustomFormItem>
	);
}
