import styled from 'styled-components';
import { Col } from 'antd';

const GNR = '#17A93B';
const NOT_GNR = '#E80707';

export const colors: string[] = [GNR, NOT_GNR];

export const StyledCol = styled(Col)`
	display: flex;
	flex-direction:  column;
	justify-content: center;
	align-items: center;
	height: 100%;

	h1 {
		font-size: 3.5rem;
		margin-bottom: 0;
	}
`;

export const ChartContainer = styled.div`
	width: 100%;
	position: relative;
`;

export const LegendContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0.5rem;
`;

export const LegendItem = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	margin-right: 1rem;

	&:last-child {
		margin-right: 0;
	}
`;

export const LegendColor = styled.div<{ color: string }>`
	width: 25px;
	height: 25px;
	background-color: ${(props) => props.color};
	margin-right: 8px;
	border-radius: 6px;
`;

export const LegendLabel = styled.span`
	font-size: 15px;
`;
