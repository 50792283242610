import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '@/infra/services/line';
import { LineDTO } from '@/core/dto/line';

export interface GetLineOptionsParams {
	organization_id: string;
	company_id: string;
	sector_id: string;
}

const transformLineOptions = (data: LineDTO[]) => {
	return data.map((line) => ({ value: line.id, label: line.name }));
};

export const useGetLineOptions = (params: GetLineOptionsParams) => {
	return useQuery([QUERY_KEYS.GET_LINES, params], () => Service.listOptions(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.sector_id,
		select: transformLineOptions
	});
};
