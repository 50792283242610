import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Text, Title } from '@/components/Typography';
import * as S from './styles';

interface RefetchProps {
	onClick?(): void;
}

export function Refetch({ onClick }: Readonly<RefetchProps>) {
	return (
		<Col span={24}>
			<Row justify="center" gutter={[0, 25]}>
				<S.CenteredCol span={24}>
					<Row>
						<S.CenteredCol span={24}>
							<Title level={2} style={{ margin: 0 }}>
								Oops!
							</Title>
						</S.CenteredCol>
						<S.CenteredCol span={24}>
							<Text style={{ fontSize: '1rem' }}>This page was not found.</Text>
						</S.CenteredCol>
					</Row>
				</S.CenteredCol>
				<S.CenteredCol span={24}>
					<S.RefetchButton type="primary" onClick={onClick}>
						{I18n.get('Try Again')}
					</S.RefetchButton>
				</S.CenteredCol>
			</Row>
		</Col>
	);
}
