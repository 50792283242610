import { message } from 'antd';
import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Response } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';
import { ActionPlanCardBulkDeleteResponseDTO } from '@/core/dto';

interface ActionPlanBulkDeleteParams {
	ids: string[];
	company_id: string;
	organization_id: string;
}

export const useDeleteActionPlan = () => {
	const queryClient = useQueryClient();

	return useMutation<ActionPlanCardBulkDeleteResponseDTO, AxiosError<Response>, ActionPlanBulkDeleteParams>(
		(params) => Service.bulkDelete(params),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_CARD_LIST]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_LIST]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLANS_BY_REPORT]);
				message.success(I18n.get('Action plans deleted successfully'));
			}
		}
	);
};
