import React from 'react';
import { Col, Image } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { StyledPreviewIcon } from '../styles';
import { FilePreviewProps } from './types';

export function FilePreview({ previewType, previewImage }: Readonly<FilePreviewProps>) {
	return (
		<Col span={12}>
			{previewType === 'image' ? (
				<Image alt="preview" src={previewImage} preview={false} className="responsive-image" />
			) : (
				<StyledPreviewIcon>
					<FileOutlined className="file-icon" />
				</StyledPreviewIcon>
			)}
		</Col>
	);
}
