export const RISK_COLORS = {
	ACCEPTABLE: '#2CC852',
	MODERATE: '#F8D627',
	HIGH: '#F78A38',
	VERY_HIGH: '#E74150',
	SERIOUS_IMMINENT: '#9B54E2'
};

export enum Tabs {
	ERGNOMIC_RISK = 'ergonomic_risk',
	ERGNOMIC_TOOLS = 'ergonomic_tools',
	JOHN_DEERE_ERGNOMIC_TOOLS = 'jd_ergonomic_tools'
}
