import styled from 'styled-components';

export const ButtonWrapper = styled.button`
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	padding: 0;
	position: relative;

	&:hover {
		cursor: pointer;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}

	svg {
		color: #2f54eb;
		font-size: 2rem;
	}
`;

export const ProgressContainer = styled.div`
	width: 95px;
	position: relative;

	p {
		font-size: 13px;
	}
`;

export const ProgressBar = styled.div`
	width: 100%;
	height: 4px;
	background-color: #f0f0f0;
	margin-top: 8px;
	border-radius: 2px;
	overflow: hidden;
	position: relative;
`;

export const ProgressFill = styled.div<{ progress: number }>`
	position: absolute;
	left: 0;
	top: 0;
	width: ${({ progress }) => `${progress}%`};
	height: 100%;
	background-color: #1890ff;
	border-radius: 2px;
	transition: width 0.3s ease-out;
	transform: translateZ(0);
`;

export const PortalContainer = styled.div`
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 1000;
	background: white;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	padding: 8px;
`;
