import { BaseService } from './base-service';
import { BeraSumRPN } from '@/core/domain/bera-sum-rpn';
import { BeraSixToo } from '@/core/domain/bera-six-too';
import { BeraSumRPNMapper } from '@/core/mapper/bera-sum-rpn';
import { BeraSixTooMapper } from '@/core/mapper/bera-six-too';
import { BeraComparison } from '@/core/domain/bera-comparison';
import { BeraGenderNeutralStation } from '@/core/domain/bera-gns';
import { BeraComparisonMapper } from '@/core/mapper/bera-comparison';
import { BeraGenderNeutralStationMapper } from '@/core/mapper/bera-gns';
import { BeraSumRPNDTO, BeraSumRPNRequestDTO } from '@/core/dto/bera-sum-rpn';
import { BeraSixTooDTO, BeraSixTooRequestDTO } from '@/core/dto/bera-six-too';
import { BeraComparisonDTO, GetBeraComparisonRequestDTO } from '@/core/dto/bera-comparison';
import { BeraGenderNeutralStationGeneratedDTO, BeraGenderNeutralStationRequestDTO } from '@/core/dto/bera-gns';

class BeraService extends BaseService<BeraComparisonDTO> {
	constructor(public readonly basePath: string = '/bera/report') {
		super();
	}

	async getHierarchySumRpn(params: GetBeraComparisonRequestDTO): Promise<BeraComparison[]> {
		const url = this.basePath + '/dashboard/hierarchy-sum-rpn';
		const { data } = await this.getInstance().get<BeraComparisonDTO>(url, { params });
		return new BeraComparisonMapper(data).toDomain();
	}

	async getSixTooSumRPN(params: BeraSixTooRequestDTO): Promise<BeraSixToo> {
		const url = this.basePath + '/dashboard/six-too-sum-rpn';
		const { data } = await this.getInstance().get<BeraSixTooDTO>(url, { params });
		return new BeraSixTooMapper(data).toDomain();
	}

	async getSumRPN(params: BeraSumRPNRequestDTO): Promise<BeraSumRPN> {
		const url = this.basePath + '/dashboard/sum-rpn';
		const { data } = await this.getInstance().get<BeraSumRPNDTO>(url, { params });
		return new BeraSumRPNMapper(data).toDomain();
	}

	async genderNeutral(params: BeraGenderNeutralStationRequestDTO): Promise<BeraGenderNeutralStation> {
		const url = this.basePath + '/dashboard/gender-neutral';
		const { data } = await this.getInstance().get<BeraGenderNeutralStationGeneratedDTO>(url, { params });
		return new BeraGenderNeutralStationMapper(data.report, data.labels).toDomain();
	}
}

const Service = Object.freeze(new BeraService());
export { Service };
