import { Mapper } from './base-mapper';
import { KimPushPullRiskCount } from '@/core/domain/kim-pp-risk-count';
import { KimPushPullRiskCountDTO } from '@/core/dto/kim-pp-risk-count';

export class KimPushPullCountMapper extends Mapper<KimPushPullRiskCount[]> {
	private readonly risks: KimPushPullRiskCountDTO[];

	constructor(risks: KimPushPullRiskCountDTO[]) {
		super();
		this.risks = risks;
	}

	toDomain(): KimPushPullRiskCount[] {
		return this.risks;
	}
}
