import styled from 'styled-components';
import { Col } from 'antd';

export const colors = ['#B3B3B3', '#2CC852', '#F8D627', '#E74150', '#9B54E2'];

export const StyledCol = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	min-height: 320px;
`;

export const LabelWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
`;

export const DonutChartWrapper = styled.div`
	width: 100%;
	max-width: 280px;
	height: 0;
	padding-bottom: 100%;
	position: relative;
	margin: auto;
`;
