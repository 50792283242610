import styled from 'styled-components';
import { getResultRPN, riskColors } from '@/utils/riskRange';

interface ScoreCardProps {
	$score: number;
}

export const RiskContainer = styled.div<ScoreCardProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	height: 2.4rem;
	font-weight: 600;
	font-size: 1.2rem;
	border-radius: 0.5rem;
	background-color: ${({ $score }) => riskColors[getResultRPN($score)]};
`;

export const RiskLabel = styled.div`
	display: flex;
	align-items: center;
	height: 2rem;
	font-size: 1.1rem;
	margin-bottom: 0.5rem;
`;
