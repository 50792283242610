import React from 'react';
import * as S from './styles';

type AvatarProps = {
	responsibleName?: string;
	url?: string;
} & typeof defaultProps;

const defaultProps = { responsibleName: '', url: '' };

export function Avatar({ responsibleName, url }: Readonly<AvatarProps>) {
	const initialLetters = getSplitResponsibleName(responsibleName);
	const backgroundColor = getBackgroundColorByInitialLatters(initialLetters);

	function getSplitResponsibleName(name: string): string {
		return name
			.split(' ')
			.map((name) => name[0])
			.join('');
	}

	function getBackgroundColorByInitialLatters(value: string): string {
		const firstChar = value.charCodeAt(0);
		const secondChar = value.charCodeAt(1);
		const binaryValue = (firstChar << 8) | secondChar;
		const rotatedValue = ((binaryValue << 2) | (binaryValue >> 14)) & 0xffff;
		const r = (rotatedValue >> 11) & 0x1f;
		const g = (rotatedValue >> 6) & 0x1f;
		const b = rotatedValue & 0x3f;
		const normalizedR = Math.floor((r / 31) * 255);
		const normalizedG = Math.floor((g / 31) * 255);
		const normalizedB = Math.floor((b / 63) * 255);
		return `rgb(${normalizedR}, ${normalizedG}, ${normalizedB})`;
	}

	return (
		<S.AvatarWrapper $backgroundColor={backgroundColor} src={url} size="large">
			{initialLetters}
		</S.AvatarWrapper>
	);
}
