import { BaseService } from './base-service';
import { Mosaic } from '@/core/domain/mosaic';
import { CustomReportWorstScores } from '@/core/domain';
import { CustomReport } from '@/core/domain/custom-report';
import { ReportsGenerated } from '@/core/domain/reports-generated';
import { MosaicListDTO, GetMosaicRequestDTO } from '@/core/dto/mosaic';
import { CustomReportWorstScoresDTO, GetWorstScoresRequestDTO } from '@/core/dto';
import { CustomReportDTO, GetCustomReportListDTO } from '@/core/dto/custom-report';
import { ReportsGeneratedDTO, GetReportRequestDTO } from '@/core/dto/reports-generated';
import { MosaicFindAllMapper } from '@/core/mapper/mosaic-find-all';
import { CustomReportRiskCount } from '@/core/domain/custom-report-risk-count';
import { CustomReportFindAllMapper } from '@/core/mapper/custom-report-find-all';
import { CustomReportRiskCountMapper } from '@/core/mapper/custom-report-risk-count';
import { ReportsGeneratedFindOneMapper } from '@/core/mapper/reports-generated-find-one';
import { CustomReportWorstScoresMapper } from '@/core/mapper/custom-report-worst-scores';
import { CustomReportRiskCountDTO, GetCustomReportRiskCountRequestDTO } from '@/core/dto/custom-report-risk-count';

class CustomReportService extends BaseService<CustomReportDTO> {
	constructor(public readonly basePath: string = '/custom-report') {
		super();
	}

	async list(params: GetCustomReportListDTO): Promise<CustomReport[]> {
		const url = this.basePath + '/list';
		const { data } = await this.getInstance().get<CustomReportDTO>(url, { params });
		return new CustomReportFindAllMapper(data).toDomain();
	}

	async listOptions(params: GetCustomReportListDTO): Promise<CustomReportDTO> {
		const url = this.basePath + '/list';
		const { data } = await this.getInstance().get<CustomReportDTO>(url, { params });
		return data;
	}

	async getMosaic(params: GetMosaicRequestDTO): Promise<Mosaic> {
		const url = this.basePath + '/mosaic';
		const { data } = await this.getInstance().get<MosaicListDTO>(url, { params });
		return new MosaicFindAllMapper(data).toDomain();
	}

	async getChecklist(params: GetReportRequestDTO): Promise<ReportsGenerated> {
		const url = this.basePath + '/result/get-count-checklist';
		const { data } = await this.getInstance().get<ReportsGeneratedDTO>(url, { params });
		return new ReportsGeneratedFindOneMapper(data.report, data.labels).toDomain();
	}

	async getHierarchyWorstScores(params: GetWorstScoresRequestDTO): Promise<CustomReportWorstScores[][]> {
		const url = this.basePath + '/result/hierarchy-worst-scores';
		const { data } = await this.getInstance().get<CustomReportWorstScoresDTO[]>(url, { params });
		return new CustomReportWorstScoresMapper(data).toDomain();
	}

	async getCountByRisk(params: GetCustomReportRiskCountRequestDTO): Promise<CustomReportRiskCount[]> {
		const url = this.basePath + '/result/count-by-risk';
		const { data } = await this.getInstance().get<CustomReportRiskCountDTO>(url, { params });
		return new CustomReportRiskCountMapper(data).toDomain();
	}
}

const Service = Object.freeze(new CustomReportService());
export { Service };
