import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions, ChartData, Plugin, TooltipItem } from 'chart.js';

import { ChartContainer, LegendRow, ColorIndicator, LabelText, PercentageSpan, LegendCol, colors } from './styles';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface DoughnutChartProps {
	labels?: string[];
	data?: number[];
}

export function DoughnutChart({ labels = [], data = [] }: Readonly<DoughnutChartProps>) {
	const chartData: ChartData<'doughnut'> = {
		labels: labels,
		datasets: [
			{
				data: data,
				backgroundColor: colors,
				borderColor: '#FFFFFF',
				borderWidth: 1,
				borderRadius: 3,
				hoverBorderWidth: 3
			}
		]
	};

	const options: ChartOptions<'doughnut'> = {
		responsive: true,
		animation: false,
		maintainAspectRatio: false,
		cutout: '60%',
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: function (context: TooltipItem<'doughnut'>) {
						const label = context.label || '';
						const percentage = (context.dataset.data[context.dataIndex] ?? 0) as number;

						return [I18n.get(label), `${percentage}%`];
					}
				}
			},
			datalabels: {
				color: '#FFFFFF',
				font: {
					weight: 'bold' as const,
					size: 12
				},
				formatter: (value: number, context: any) => {
					const percentage = (context.dataset.data[context.dataIndex] ?? 0) as number;
					const formattedPercentage = percentage ? `${Math.trunc(percentage)}%` : '';
					return value > 7 ? formattedPercentage : '';
				}
			}
		}
	};

	const centerTextPlugin: Plugin<'doughnut'> = {
		id: 'centerText',
		afterDraw: (chart) => {
			const {
				ctx,
				chartArea: { left, top, right, bottom }
			} = chart;
			const centerX = (left + right) / 2;
			const centerY = (top + bottom) / 2;

			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';

			ctx.font = 'bold 16px Arial';
			ctx.fillStyle = '#000000';
			ctx.fillText(I18n.get('Biomechanical'), centerX, centerY - 10);

			ctx.font = '12px Arial';
			ctx.fillText(I18n.get('Highest incidence'), centerX, centerY + 10);
			ctx.fillText(I18n.get('category'), centerX, centerY + 30);
		}
	};

	const hasData = data.some((value) => value > 0);

	return (
		<Row gutter={[16, 16]} align="middle">
			<Col span={hasData ? 10 : 24}>
				<ChartContainer>
					<Doughnut data={chartData} options={options} plugins={[centerTextPlugin]} />
				</ChartContainer>
			</Col>
			{hasData && (
				<LegendCol span={14}>
					{chartData.labels!.map(
						(label, index) =>
							data[index] > 0 && (
								<LegendRow key={index} align="middle">
									<Col span={2}>
										<ColorIndicator color={colors[index]} />
									</Col>
									<Col span={18}>
										<LabelText>{label as React.ReactNode}</LabelText>
									</Col>
									<Col span={4}>
										<PercentageSpan color={colors[index]}>{Math.trunc(data[index])}%</PercentageSpan>
									</Col>
								</LegendRow>
							)
					)}
				</LegendCol>
			)}
		</Row>
	);
}
