import { Mapper } from './base-mapper';
import { RebaBodySideExposureScore, RebaBodySideExposureScoreData } from '../domain/reba-body-side-exposure-score';
import { RebaBodySideExposureScoreDTO, RebaBodySideExposureScoreDataDTO } from '../dto/reba-body-side-exposure-score';

export class RebaBodySideExposureScoreMapper extends Mapper<RebaBodySideExposureScore> {
  private readonly data: RebaBodySideExposureScoreDTO;

  constructor(data: RebaBodySideExposureScoreDTO) {
    super();
    this.data = data;
  }

  private mapBodySide(bodySide: RebaBodySideExposureScoreDataDTO): RebaBodySideExposureScoreData {
    return {
      name: bodySide.name,
      totalSeconds: bodySide.total_seconds,
      data: bodySide.data.map(item => ({
        risk: item.risk,
        percentage: item.percentage,
      })),
    };
  }

  toDomain(): RebaBodySideExposureScore {
    const bodySides = this.data.map(this.mapBodySide);
    return new RebaBodySideExposureScore(bodySides);
  }
}
