import React, { useEffect, useState } from 'react';
import { Col, Divider, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomButton, RPNScore, Score, ScoreTitle, Title } from './styles';
import { ExistingPreventionMeasures } from './ExistingPreventionMeasures';
import { useApplicationContext } from '@/context/v1/Application/context';
import { TaskDTO } from '@/components/ui/Inputs/types/response/task';
import { CreateActionPlanModal } from './CreateActionPlanModal';
import { SeraRPNService } from '../../services/seraRPNService';
import { TasksListDTO } from '../../hooks/types/response';
import { RiskDescriptions } from './RiskDescriptions';
import { Vulnerabilities } from './Vulnerabilities';
import { useSeraStepsContext } from '../../context';
import { Specifications } from './Specifications';
import { RiskCategories } from './RiskCategories';
import { RiskDamages } from './RiskDamages';
import { Severities } from './Severities';
import { Exposures } from './Exposures';
import { Tasks } from './Tasks';
import {
	useExposures,
	useRiskCategories,
	useRiskDamages,
	useRiskDescriptions,
	useSeraSteps,
	useSeverities,
	useVulnerabilities
} from '../../hooks/hooks';

const { useWatch, useFormInstance } = Form;

interface SeraReportFormProps {
	collapseKey: number;
	taskList: TasksListDTO[];
}

export const SeraReportForm: React.FC<SeraReportFormProps> = ({ collapseKey, taskList }) => {
	const form = useFormInstance();
	const [visible, setVisible] = useState<boolean>(false);
	const { organization, company } = useApplicationContext();
	const { mapRPNToPriority, seraFiles } = useSeraStepsContext();

	const custom_report_id = seraFiles?.files?.rows[0]?.CustomReportsFiles?.custom_report_id;

	const { data: stepsList } = useSeraSteps({
		organization_id: organization?.id,
		company_id: company.id,
		custom_report_id
	});

	const risk_category_id = useWatch([collapseKey, 'risk_category_id'], form);
	const risk_description_id = useWatch([collapseKey, 'risk_description_id'], form);
	const stepKeyId = stepsList[0]?.step_key[0]?.id;
	const {
		data: riskDescriptions,
		isLoading: gettingRiskDescriptions,
		isFetching: fetchingRiskDescriptions
	} = useRiskDescriptions({
		organization_id: organization?.id,
		company_id: company?.id,
		risk_category_id
	});
	const {
		data: riskCategories,
		isLoading: gettingRiskCategories,
		isFetching: fetchingRiskCategories
	} = useRiskCategories({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKeyId
	});
	const {
		data: riskDamages,
		isLoading: gettingRiskDamages,
		isFetching: fetchingRiskDamages
	} = useRiskDamages({
		organization_id: organization?.id,
		company_id: company?.id,
		risk_description_id
	});
	const {
		data: exposures,
		isLoading: gettingExposures,
		isFetching: fetchingExposures
	} = useExposures({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKeyId
	});
	const {
		data: severities,
		isLoading: gettingSeverities,
		isFetching: fetchingSeverities
	} = useSeverities({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKeyId
	});
	const {
		data: vulnerabilities,
		isLoading: gettingVulnerabilities,
		isFetching: fetchingVulnerabilities
	} = useVulnerabilities({
		organization_id: organization?.id,
		company_id: company?.id,
		step_key_id: stepKeyId
	});

	const seraRPNService = new SeraRPNService({ exposures, severities, vulnerabilities });

	const taskId = useWatch([collapseKey, 'task_id'], form);
	const exposureId = useWatch([collapseKey, 'exposure_id'], form);
	const vulnerabilityId = useWatch([collapseKey, 'vulnerability_id'], form);
	const severityId = useWatch([collapseKey, 'severity_id'], form);

	const taskRPN = seraRPNService.calculateTaskRPN({ exposureId, severityId, vulnerabilityId });
	const rpnPriority = mapRPNToPriority(taskRPN?.rpn);
	const fileId = taskList.find((task) => task.id === taskId)?.file[0].id;
	const tasks: TaskDTO[] = taskList?.map((task) => ({
		id: task.id,
		name: task.name,
		label: task.description
	}));

	useEffect(() => {
		if (taskRPN) {
			form.setFieldValue([collapseKey, 'taskRPN'], taskRPN);
		}
	}, [collapseKey, exposureId, severityId, vulnerabilityId]);

	function handleCreateActionPlan() {
		setVisible(!visible);
	}

	return (
		<Row>
			<Col xs={24}>
				<Row gutter={48}>
					<Col offset={1} xs={24} lg={7}>
						<Row>
							<Tasks fieldName={[collapseKey, 'task_id']} tasks={tasks} />
						</Row>
						<Row>
							<RiskCategories
								collapseKey={collapseKey}
								riskCategories={riskCategories}
								isLoading={gettingRiskCategories}
								isFetching={fetchingRiskCategories}
								fieldName={[collapseKey, 'risk_category_id']}
								damagesFieldName={[collapseKey, 'risk_damage_id']}
								descriptionFieldName={[collapseKey, 'risk_description_id']}
							/>
						</Row>
						<Row>
							<RiskDescriptions
								collapseKey={collapseKey}
								riskDescriptions={riskDescriptions}
								isLoading={gettingRiskDescriptions}
								isFetching={fetchingRiskDescriptions}
								fieldName={[collapseKey, 'risk_description_id']}
								damagesFieldName={[collapseKey, 'risk_damage_id']}
							/>
						</Row>
						<Row>
							<RiskDamages
								stepKeyId={stepKeyId}
								riskDamages={riskDamages}
								collapseKey={collapseKey}
								isLoading={gettingRiskDamages}
								isFetching={fetchingRiskDamages}
								fieldName={[collapseKey, 'risk_damage_id']}
							/>
						</Row>
					</Col>
					<Col xs={24} lg={6}>
						<Row style={{ height: '100%' }} justify="space-between">
							<Col xs={24}>
								<Row>
									<Specifications fieldName={[collapseKey, 'specifications']} />
								</Row>
							</Col>
							<Col xs={24}>
								<Row style={{ marginTop: '2rem' }}>
									<ExistingPreventionMeasures
										fieldName={[collapseKey, 'existing_prevention_measures']}
									/>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col xs={24} lg={10}>
						<Row>
							<Col xs={12}>
								<Row>
									<Exposures
										fieldName={[collapseKey, 'exposure_id']}
										exposures={exposures}
										isLoading={gettingExposures}
										isFetching={fetchingExposures}
									/>
								</Row>
								<Row>
									<Vulnerabilities
										fieldName={[collapseKey, 'vulnerability_id']}
										vulnerabilities={vulnerabilities}
										isLoading={gettingVulnerabilities}
										isFetching={fetchingVulnerabilities}
									/>
								</Row>
								<Row>
									<Severities
										fieldName={[collapseKey, 'severity_id']}
										severities={severities}
										isLoading={gettingSeverities}
										isFetching={fetchingSeverities}
									/>
								</Row>
							</Col>
							<Col>
								<Divider
									type="vertical"
									style={{ height: '100%', borderColor: '#00000033', margin: '0rem 1.5rem' }}
								/>
							</Col>
							<Col xs={6}>
								<Row gutter={48} style={{ height: '100%' }} justify="space-between">
									<Col xs={24}>
										<Row>
											<Col xs={24}>
												<Title>{I18n.get('Task RPN')}:</Title>
											</Col>
											<Col xs={24}>
												{taskRPN?.rpn && rpnPriority && (
													<RPNScore style={{ fontSize: '1rem' }} priority={rpnPriority.color}>
														{taskRPN?.rpn}
													</RPNScore>
												)}
											</Col>
										</Row>
									</Col>
									<Col xs={24}>
										<Row>
											<Col xs={24}>
												<Title>{I18n.get('Priority')}:</Title>
											</Col>
											<Col xs={24}>
												{rpnPriority && (
													<RPNScore priority={rpnPriority.color}>
														{I18n.get(rpnPriority.priority)}
													</RPNScore>
												)}
											</Col>
										</Row>
									</Col>
									<Col xs={24} style={{ padding: '0 0 0 1.5rem' }}>
										<Row style={{ height: '100%' }} align="bottom">
											{taskId && taskRPN?.rpn && (
												<CustomButton onClick={handleCreateActionPlan} type="primary">
													{I18n.get('Create action plan')}
												</CustomButton>
											)}
											{fileId && (
												<CreateActionPlanModal
													file_id={fileId}
													visible={visible}
													score={taskRPN?.rpn}
													onCloseModal={handleCreateActionPlan}
												/>
											)}
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
						<Divider
							type="horizontal"
							style={{ width: '100%', borderColor: '#00000033', margin: '1.5rem 0rem' }}
						/>
						<Row justify="space-between">
							<Col>
								<ScoreTitle>{I18n.get('Exposure')}</ScoreTitle>:
								{taskRPN && <Score>{taskRPN.exposureScore}</Score>}
							</Col>
							<Col>
								<ScoreTitle>{I18n.get('Vulnerability')}</ScoreTitle>:
								{taskRPN && <Score>{taskRPN.vulnerabilityScore}</Score>}
							</Col>
							<Col>
								<ScoreTitle>{I18n.get('Severity')}</ScoreTitle>:
								{taskRPN && <Score>{taskRPN.severityScore}</Score>}
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
