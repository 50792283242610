import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import type { FormFieldProps } from '../../types';
import * as S from './styles';

export function InvestmentValue({ formName = [''], isRequired }: Readonly<FormFieldProps>) {
	const locale = moment.locale();

	const title = I18n.get('Investment value');
	const placeholder = formatValue('0');

	function formatValue(value: string | number | undefined): string {
		if (!value || value === '') return '';

		const numbers = value.toString().replace(/\D/g, '');
		const amount = parseFloat(numbers) / 100;

		if (isNaN(amount)) return '';

		return new Intl.NumberFormat(locale, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			useGrouping: true
		}).format(amount);
	}

	function parse(value: string | undefined): string {
		if (!value || value === '') return '';
		return value.replace(/\D/g, '');
	}

	return (
		<S.CustomFormItem
			label={title}
			required={isRequired}
			labelCol={{ span: 24 }}
			name={[...formName, 'investment_value']}
		>
			<S.CustomNumberInput addonBefore="$" placeholder={placeholder} formatter={formatValue} parser={parse} />
		</S.CustomFormItem>
	);
}
