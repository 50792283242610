import { message } from 'antd';
import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import { Service } from '@/infra/services/action-plan';
import { useMutation } from '@tanstack/react-query';
import { ActionPlanUpdateNotificationResponseDTO } from '@/core/dto';

type Preference =
	| 'author_action_plan_status_changed'
	| 'author_action_plan_task_completed'
	| 'author_action_plan_due_date_nearest'
	| 'create_action'
	| 'responsible_action_plan_due_date_nearest';

interface UpdateNotificationParams {
	organization_id: string;
	company_id: string;
	preferences: Preference[];
}

export const updateNotification = (params: UpdateNotificationParams) => {
	return Service.updateNotification(params);
};

export const useUpdateNotificationActionPlan = () => {
	return useMutation<ActionPlanUpdateNotificationResponseDTO, AxiosError<Response>, UpdateNotificationParams>(
		(params) => Service.updateNotification(params),
		{
			onError: (err: any) => {
				message.error(I18n.get(err?.response?.data.message));
			}
		}
	);
};
