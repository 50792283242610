import { QUERY_KEYS } from '../../utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '../../infra/services/action-plan';

export interface GetActionPlansCountParams {
	organization_id: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	workstation_id?: string;
	activity_id?: string;
	title?: string;
	priority?: number;
	investment_range?: number;
	responsible_id?: string;
	start_date?: Date;
	end_date?: Date;
	due_date_start?: Date;
	due_date_end?: Date;
	origin_name?: string;
	status?: string;
}

export const useGetActionPlansCount = (params: GetActionPlansCountParams) => {
	return useQuery([QUERY_KEYS.GET_ACTION_PLANS_COUNT, params], () => Service.countAll(params), {
		enabled: !!params.organization_id
	});
};
