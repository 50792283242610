import React, { useState } from 'react';
import axios from 'axios';
import { Row, Col, Form, message } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';

import { QUERY_KEYS } from '@/utils/query-keys';
import { Title } from '@/components/Typography';

import { useAddAttachment } from '@/hooks/v2/useAddAttachment';
import { useGetAttachments } from '@/hooks/v2/useGetAttachments';
import { useGetRelatedReports } from '@/hooks/v2/useGetRelatedReports';
import { useUpdateRelatedReports } from '@/hooks/v2/useUpdateRelatedReports';
import { Drop } from './Drop';
import { Footer } from './Footer';
import { FilePreview } from './FilePreview';
import { ErgonomicTools } from './ErgonomicTools';
import { compressImage, getHeaders } from '../utils';
import { ModalWrapper, StyledDivider } from './styles';
import { AttachmentsProps, FormValues, RelatedReport, UpdatePayload } from './types';

export function AttachmentsModal({ actionPlanId, companyId, organizationId, isOpen, onCancel }: AttachmentsProps) {
	const [form] = Form.useForm<FormValues>();
	const [loading, setLoading] = useState(false);

	const queryClient = useQueryClient();

	const { data: attachments, error: attachmentsError } = useGetAttachments({
		company_id: companyId,
		action_plan_id: actionPlanId,
		organization_id: organizationId
	});

	const { data: relatedReports, error: reportsError } = useGetRelatedReports({
		company_id: companyId,
		action_plan_id: actionPlanId,
		organization_id: organizationId
	});

	const { mutateAsync: addAttachment } = useAddAttachment();
	const { mutateAsync: updateRelatedReports } = useUpdateRelatedReports();

	async function onFileDrop(file: File) {
		try {
			setLoading(true);
			const fileForUpload = file.type === 'image' ? await compressImage(file) : file;

			const payload = {
				company_id: companyId,
				size: fileForUpload.size,
				action_plan_id: actionPlanId,
				file_name: fileForUpload.name,
				organization_id: organizationId,
				content_type: fileForUpload.type
			};

			const { url } = await addAttachment(payload);
			const headers = getHeaders(fileForUpload);
			await axios.put(url, fileForUpload, headers);
			await queryClient.invalidateQueries([QUERY_KEYS.GET_ATTACHMENTS]);

			message.success(I18n.get('File uploaded successfully'));
		} catch (error) {
			message.error(I18n.get('Failed to upload file'));
		} finally {
			setLoading(false);
		}
	}

	async function handleSubmit(values: FormValues) {
		try {
			setLoading(true);
			const relatedReports = createRelatedReportsPayload(values.selectedTools);

			const payload: UpdatePayload = {
				company_id: companyId,
				action_plan_id: actionPlanId,
				related_reports: relatedReports,
				organization_id: organizationId
			};

			await updateRelatedReports(payload);
			message.success(I18n.get('Tools updated successfully'));
			await queryClient.invalidateQueries([QUERY_KEYS.GET_RELATED_REPORTS]);
			onCancel();
		} catch (error) {
			message.error(I18n.get('Failed to update tools'));
		} finally {
			setLoading(false);
		}
	}

	function createRelatedReportsPayload(selectedTools: string[]): RelatedReport[] {
		return selectedTools
			.map((ergonomicToolId) => {
				const tool = relatedReports?.find((report) => report.ergonomic_tool_id === ergonomicToolId);

				if (!tool?.ergonomic_tool_id) {
					return null;
				}

				return {
					report_id: tool.report_id,
					ergonomic_tool_id: tool.ergonomic_tool_id
				};
			})
			.filter((report): report is RelatedReport => report !== null);
	}

	if (attachmentsError || reportsError) {
		message.error(I18n.get('Failed to load data'));
		return null;
	}

	return (
		<ModalWrapper open={isOpen} footer={false} width={770} centered onCancel={onCancel}>
			<Form form={form} onFinish={handleSubmit} layout="vertical">
				<Row gutter={[0, 5]}>
					<Col span={24}>
						<Title level={3}>{I18n.get('Attach file')}</Title>
					</Col>

					<Col span={24}>
						<Drop disabled={loading || (attachments && attachments.length === 4)} onFileDrop={onFileDrop} />
					</Col>

					{attachments && attachments.length > 0 && (
						<Col span={24}>
							<FilePreview files={attachments} companyId={companyId} organizationId={organizationId} />
						</Col>
					)}

					<Col span={24}>
						<StyledDivider />
					</Col>

					<Col span={24}>
						<Form.Item
							name="selectedTools"
							initialValue={
								relatedReports?.filter((tool) => tool.checked)?.map((tool) => tool.ergonomic_tool_id) ||
								[]
							}
						>
							<ErgonomicTools relatedReports={relatedReports || []} />
						</Form.Item>
					</Col>

					<Col span={24}>
						<Row gutter={[0, 30]}>
							<Col span={24} style={{ marginTop: '1rem' }}>
								<Footer onCancel={onCancel} onSubmit={form.submit} loading={loading} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</ModalWrapper>
	);
}
