import styled from 'styled-components';

interface ScoreProps {
	$color: string;
}

const Span = styled.span`
	width: 100px;
	display: grid;
	padding: 0.3rem;
	text-align: center;
	border-radius: 6px;
`;

export const Score = styled(Span)<ScoreProps>`
	background: ${({ $color }) => $color};
	color: ${({ $color }) => ($color === '#F8D627' ? '#000' : '#fff')};
`;
