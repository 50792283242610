import { Mapper } from './base-mapper';
import { ActionPlanHistory, ActionPlanHistoryDomain } from '../domain/action-plan-history';
import { ActionPlanHistoryDTO, ActionPlanHistoryItemDTO } from '../dto';

export class ActionPlanHistoryMapper implements Mapper<ActionPlanHistoryDomain> {
	private readonly response: ActionPlanHistoryDTO;

	constructor(response: ActionPlanHistoryDTO) {
		this.response = {
			data: Array.isArray(response) ? response : []
		};
	}

	private mapActionPlanItem(item: ActionPlanHistoryItemDTO): ActionPlanHistory {
		return new ActionPlanHistory({
			id: item.id,
			action_plan_id: item.action_plan_id,
			type: item.type,
			description: item.description,
			author: item.author,
			created_at: item.created_at ?? null
		});
	}

	toDomain(): ActionPlanHistoryDomain {
		return {
			data: this.response.data.map((item) => this.mapActionPlanItem(item))
		};
	}
}
