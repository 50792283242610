import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/bera';

interface GetBeraComparisonParams {
	organization_id: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	workstation_id?: string;
	start_date: Date;
	end_date: Date;
}

export const useGetBeraComparison = (params: GetBeraComparisonParams) => {
	return useQuery([QUERY_KEYS.GET_BERA_COMPARISON, params], () => Service.getHierarchySumRpn(params), {
		enabled: !!params.organization_id
	});
};
