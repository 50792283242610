interface ActionPlanStatusParams {
	to_do: number;
	doing: number;
	done: number;
}

interface ActionPlanCountStatusAttributes {
	total: ActionPlanStatusParams;
	delayed: ActionPlanStatusParams;
}

export class ActionPlanStatusItem {
	public readonly to_do: number;
	public readonly doing: number;
	public readonly done: number;

	constructor({ to_do, doing, done }: ActionPlanStatusParams) {
		this.to_do = to_do;
		this.doing = doing;
		this.done = done;
	}
}

export class ActionPlanCountStatus {
	public readonly total: ActionPlanStatusItem;
	public readonly delayed: ActionPlanStatusItem;

	constructor({ total, delayed }: ActionPlanCountStatusAttributes) {
		this.total = total;
		this.delayed = delayed;
	}
}
