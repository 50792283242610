import React from 'react';
import Icon, { FileTextOutlined, FileAddOutlined, ExceptionOutlined } from '@ant-design/icons';
import { JdsD86Icon } from '@/assets/icons/ewa-jds-d86-icon';
import { JdsD92Icon } from '@/assets/icons/jds-d92-icon';
import * as S from '../styles';

interface CustomReportItem {
	name: string;
	icon?: React.ReactNode;
	extra?: React.ReactNode;
	formatted_name: React.ReactNode;
}

export const CUSTOM_REPORTS: CustomReportItem[] = [
	{
		name: 'bera',
		formatted_name: 'B.E.R.A.',
		icon: <FileAddOutlined style={{ fontSize: '30px' }} />
	},
	{
		name: 'sera',
		formatted_name: 'S.E.R.A.',
		icon: <ExceptionOutlined style={{ fontSize: '30px' }} />
	},
	{
		name: 'jds_d86',
		formatted_name: 'JDS-D86',
		icon: <Icon style={{ fontSize: '30px' }} component={JdsD86Icon} />
	},
	{
		name: 'jds_d92',
		formatted_name: 'JDS-D92',
		icon: <Icon style={{ fontSize: '30px' }} component={JdsD92Icon as React.FC} />
	},
	{
		name: 'ewa',
		formatted_name: 'PEA',
		icon: <FileTextOutlined style={{ fontSize: '30px' }} />,
		extra: <S.CustomReportsSpan>(NR17)</S.CustomReportsSpan>
	},
	{
		name: 'ewa_d86',
		formatted_name: 'PEA',
		icon: <FileTextOutlined style={{ fontSize: '30px' }} />,
		extra: <S.CustomReportsSpan>(NR17 + JDS-D86)</S.CustomReportsSpan>
	}
];
