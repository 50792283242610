import React from 'react';

interface FolderSearchIconProps {}

export function FolderSearchIcon(props: Readonly<FolderSearchIconProps>) {
	return (
		<svg viewBox="0 0 84 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M5.2305 48.9789H47.0799C49.9567 48.9789 52.3112 46.6226 52.3112 43.7432V32.8806C51.3951 32.6842 50.5457 32.4226 49.6956 32.0951V43.7432C49.6956 45.1829 48.5183 46.361 47.0799 46.361H5.2305C3.79209 46.361 2.61486 45.1829 2.61486 43.7432V42.4346H36.6174C37.3366 42.4346 37.9256 41.8455 37.9256 41.1261C37.9256 40.4058 37.3366 39.8168 36.6174 39.8168H2.61486V13.6413C2.61486 13.2486 2.87681 12.9862 3.26897 12.9862H13.2735C13.4696 12.9862 13.6005 13.0522 13.7315 13.1826L17.3275 17.0438C17.8507 17.5677 18.6357 17.6329 19.1589 17.109C19.4859 16.9126 19.6161 16.5859 19.6161 16.2584H37.9907C37.9256 15.4077 37.9256 14.4919 37.9907 13.6413H17.7205L15.693 11.4161C15.1048 10.7619 14.1888 10.3691 13.2735 10.3691H3.26897C1.43841 10.3691 0 11.8088 0 13.6413V43.7432C0 46.6226 2.35369 48.9789 5.2305 48.9789Z"
				fill="currentColor"
				fillOpacity={0.25}
			/>
			<path
				d="M74.9505 26.1923C74.1041 25.3836 72.9328 24.8863 71.6959 24.8863C70.9143 24.8863 70.1334 25.073 69.4822 25.4463L68.5709 24.5749C73.7785 18.7265 73.4536 9.89195 67.7245 4.35494C64.7947 1.55544 60.8233 0 56.6567 0C52.4908 0 48.5842 1.55544 45.5897 4.35494C39.4701 10.2033 39.4701 19.6599 45.5897 25.4463C48.5194 28.2458 52.4908 29.8013 56.6567 29.8013C60.3681 29.8013 63.9483 28.5565 66.7477 26.2551L67.6589 27.1257C66.6828 28.8059 66.9429 30.9834 68.4405 32.3521L76.1226 39.6932C76.969 40.5019 78.1403 41 79.3772 41C80.6148 41 81.7861 40.5639 82.6325 39.6932C84.4558 37.9511 84.4558 35.1516 82.6325 33.4095L74.9505 26.1923ZM65.7716 23.7042C63.2322 26.1304 59.9121 27.3744 56.5919 27.3744C53.2717 27.3744 49.9515 26.1304 47.4129 23.7042C42.3343 18.8512 42.3343 10.9501 47.4129 6.09706C49.9515 3.73291 53.2717 2.48885 56.5919 2.48885C59.9121 2.48885 63.2322 3.73291 65.7716 6.15904C70.9143 11.012 70.9143 18.8512 65.7716 23.7042ZM80.7444 38.0131C80.354 38.3864 79.8332 38.5731 79.3123 38.5731C78.7915 38.5731 78.2707 38.3864 77.8803 38.0131L70.1982 30.672C69.4166 29.9252 69.4166 28.6812 70.1982 27.9345C70.5887 27.5611 71.1095 27.3744 71.6303 27.3744C72.1511 27.3744 72.672 27.5611 73.0624 27.9345L80.7444 35.2763C81.5261 36.0223 81.5261 37.2671 80.7444 38.0131Z"
				fill="currentColor"
				fillOpacity={0.25}
			/>
			<path
				d="M57.02 7C55.0821 7 53.3113 8.19801 52.5055 10.0375L52.1423 10.8055C51.9824 11.1916 51.9417 11.6209 52.101 12.0028C52.2236 12.389 52.5055 12.7319 52.8694 12.901C53.5938 13.2871 54.4809 12.9448 54.8441 12.1329L55.2047 11.3649C55.5279 10.5926 56.2517 10.1237 57.02 10.1237C57.9851 10.1237 58.8315 10.8487 58.9914 11.8769C59.0727 12.4759 58.8721 12.988 58.4676 13.3302L56.051 15.2963C55.6872 15.5955 55.4459 16.0679 55.4459 16.5368V18.42C55.4459 19.2744 56.0917 20 56.9381 20C57.7438 20 58.427 19.314 58.427 18.42V17.3055L60.283 15.8084C61.5712 14.7404 62.217 13.0311 61.9344 11.321C61.4893 8.84015 59.3959 7 57.02 7Z"
				fill="currentColor"
				fillOpacity={0.25}
			/>
			<path
				d="M58.5 22.4984C58.5 23.3281 57.8312 24 57.0014 24C56.172 24 55.5 23.3281 55.5 22.4984C55.5 21.6719 56.172 21 57.0014 21C57.8312 21 58.5 21.6719 58.5 22.4984Z"
				fill="currentColor"
				fillOpacity={0.25}
			/>
		</svg>
	);
}
