import React from 'react';
import { message, UploadFile } from 'antd';
import Icon, { EyeOutlined, DeleteOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';

import { MessageOutlined } from '@/assets/icons/action-plans/message-outlined';
import { useGetDownloadUrlTaskAttachment } from '@/hooks/v2/useGetDownloadUrlTaksAttachment';
import { StyledCol, StyledRow, IconWrapper, COLOR_ICON_DANGER, COLOR_ICON_ENABLED } from './styles';

interface UploadFileItem extends UploadFile {
	author: string;
	created_at: string;
}

interface ActionsProps {
	file: UploadFile;
	companyId: string;
	isImageFile: boolean;
	organizationId: string;
	uploadFileItem: UploadFileItem;
	handlePreview: (file: UploadFile) => void;
	handleDelete: (uploadFileItem: UploadFileItem) => void;
	handleShowInfo: (uploadFileItem: UploadFileItem) => void;
	handleCommentClick: (uploadFileItem: UploadFileItem) => void;
}

export function Actions({
	file,
	companyId,
	isImageFile,
	handleDelete,
	handlePreview,
	organizationId,
	uploadFileItem,
	handleShowInfo,
	handleCommentClick
}: Readonly<ActionsProps>) {
	const {
		data: downloadUrlAttachment,
		isError: isErrorDownloadUrlAttachment,
		isLoading: isLoadingDownloadUrlAttachment
	} = useGetDownloadUrlTaskAttachment({
		company_id: companyId,
		organization_id: organizationId,
		action_plan_task_attachment_id: uploadFileItem.uid
	});

	async function handleDownload() {
		if (downloadUrlAttachment) {
			window.open(downloadUrlAttachment, '_blank');
		}
	}

	if (isErrorDownloadUrlAttachment) {
		message.error('Error downloading file');
		return null;
	}

	return (
		<div className="custom-upload-actions">
			<StyledRow>
				<StyledCol span={12}>
					<IconWrapper>
						<InfoCircleOutlined
							style={{ color: COLOR_ICON_ENABLED }}
							onClick={() => handleShowInfo(uploadFileItem)}
						/>
					</IconWrapper>
				</StyledCol>
				<StyledCol span={12}>
					<IconWrapper>
						{isImageFile ? (
							<EyeOutlined
								disabled={!isImageFile}
								style={{ color: COLOR_ICON_ENABLED }}
								onClick={() => handlePreview(file)}
							/>
						) : (
							<DownloadOutlined
								onClick={handleDownload}
								style={{ color: COLOR_ICON_ENABLED }}
								disabled={isLoadingDownloadUrlAttachment}
							/>
						)}
					</IconWrapper>
				</StyledCol>
				<StyledCol span={12}>
					<IconWrapper>
						<Icon component={MessageOutlined} onClick={() => handleCommentClick(uploadFileItem)} />
					</IconWrapper>
				</StyledCol>
				<StyledCol span={12}>
					<IconWrapper>
						<DeleteOutlined
							style={{ color: COLOR_ICON_DANGER }}
							onClick={() => handleDelete(uploadFileItem)}
						/>
					</IconWrapper>
				</StyledCol>
			</StyledRow>
		</div>
	);
}
