import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '@/infra/services/action-plan';

export interface GetEvidencesParams {
	company_id: string;
	action_plan_task_id: string;
	organization_id: string;
}

export const useGetEvidences = (params: GetEvidencesParams) => {
	return useQuery([QUERY_KEYS.GET_EVIDENCES, params], () => Service.getEvidences(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.action_plan_task_id
	});
};
