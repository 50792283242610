import { BaseService } from './base-service';
import { StrainIndexRSI } from '@/core/domain/strain-index-rsi';
import { StrainIndexRSIMapper } from '@/core/mapper/strain-index-rsi';
import { StrainIndexRSIDTO, StrainIndexRSIRequestDTO } from '@/core/dto/strain-index-rsi';

class StrainIndex extends BaseService<any> {
	constructor(public readonly basePath: string = '/ergonomic-tool/strain-index') {
		super();
	}

	async getRSIRisk(params: StrainIndexRSIRequestDTO): Promise<StrainIndexRSI> {
		const url = this.basePath + '/greatest-score-rsi';
		const { data } = await this.getInstance().get<StrainIndexRSIDTO>(url, { params });
		return new StrainIndexRSIMapper(data).toDomain();
	}
}

const Service = Object.freeze(new StrainIndex());
export { Service };
