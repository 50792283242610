import { BaseService } from './base-service';
import { SeraSumRPN } from '@/core/domain/sera-sum-rpn';
import { SeraMainRisk } from '@/core/domain/sera-main-risk';
import { SeraComparison } from '@/core/domain/sera-comparison';
import { SeraSumRPNMapper } from '@/core/mapper/sera-sum-rpn';
import { SeraMainRiskMapper } from '@/core/mapper/sera-main-risk';
import { SeraComparisonMapper } from '@/core/mapper/sera-comparison';
import { SeraSumRPNDTO, SeraSumRPNRequestDTO } from '@/core/dto/sera-sum-rpn';
import { SeraMainRiskDTO, SeraMainRiskRequestDTO } from '@/core/dto/sera-main-risk';
import { SeraComparisonDTO, GetSeraComparisonRequestDTO } from '@/core/dto/sera-comparison';

class SeraService extends BaseService<SeraMainRiskDTO> {
	constructor(public readonly basePath: string = '/sera/report') {
		super();
	}

	async getIncidenceCategories(params: SeraMainRiskRequestDTO): Promise<SeraMainRisk> {
		const url = this.basePath + '/dashboard/incidence-categories';
		const { data } = await this.getInstance().get<SeraMainRiskDTO>(url, { params });
		return new SeraMainRiskMapper(data).toDomain();
	}

	async getSumRPN(params: SeraSumRPNRequestDTO): Promise<SeraSumRPN> {
		const url = this.basePath + '/dashboard/sum-rpn';
		const { data } = await this.getInstance().get<SeraSumRPNDTO>(url, { params });
		return new SeraSumRPNMapper(data).toDomain();
	}

	async getHierarchySumRpn(params: GetSeraComparisonRequestDTO): Promise<SeraComparison[]> {
		const url = this.basePath + '/dashboard/hierarchy-sum-rpn';
		const { data } = await this.getInstance().get<SeraComparisonDTO>(url, { params });
		return new SeraComparisonMapper(data).toDomain();
	}
}

const Service = Object.freeze(new SeraService());
export { Service };
