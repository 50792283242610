import React from 'react';
import { Row, Col, Skeleton } from 'antd';

import { TaskItem } from './TaskItem';
import { Creation } from './Creation';
import { ProgressBar } from './ProgressBar';
import { Text } from '@/components/Typography';
import { useEditActionPlanContext } from '../context';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetActionPlanTasks } from '@/hooks/v2/useGetActionPlanTasks';
import { TasksContainer } from './styles';

export function Tasks() {
	const { actionPlan } = useEditActionPlanContext();
	const { organization, company } = useApplicationContext();

	const {
		isLoading: isGettingTasks,
		data: tasks,
		isError: hasErrorTask
	} = useGetActionPlanTasks({
		organization_id: organization?.id,
		company_id: company?.id,
		action_plan_id: actionPlan.id
	});

	if (isGettingTasks) {
		return (
			<Row gutter={[0, 30]}>
				<Col span={24}>
					<Row gutter={[0, 12]}>
						<Col span={24}>
							<Text style={{ fontSize: '1.1rem' }}>Tasks</Text>
						</Col>
						<Col span={24}>
							<ProgressBar completed={0} total={0} />
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Skeleton active />
				</Col>
			</Row>
		);
	}

	if (hasErrorTask) {
		return (
			<Row gutter={[0, 30]}>
				<Col span={24}>
					<Row gutter={[0, 12]}>
						<Col span={24}>
							<Text style={{ fontSize: '1.1rem' }}>Tasks</Text>
						</Col>
						<Col span={24}>
							<ProgressBar completed={0} total={0} />
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<span>error</span>
				</Col>
			</Row>
		);
	}

	return (
		<Row gutter={[0, 30]}>
			<Col span={24}>
				<Row gutter={[0, 12]}>
					<Col span={24}>
						<Text style={{ fontSize: '1.1rem' }}>Tasks</Text>
					</Col>
					<Col span={24}>
						<ProgressBar completed={tasks?.total_completed} total={tasks?.data.length} />
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Creation />
			</Col>
			<Col span={24}>
				<TasksContainer gutter={[0, 20]}>
					{tasks?.data?.map((task) => {
						return (
							<Col key={task.id} span={24}>
								<TaskItem data={task} />
							</Col>
						);
					})}
				</TasksContainer>
			</Col>
		</Row>
	);
}
