interface ActionPlanDeleteCommentsItemAttributes {
	id: string;
}

export class ActionPlanDeleteComments {
	public readonly id: string;

	constructor({ id }: ActionPlanDeleteCommentsItemAttributes) {
		this.id = id;
	}
}

export interface ActionPlanDeleteCommentsDomain {
	status: string;
	message: string;
	data: ActionPlanDeleteComments[];
}
