import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParams } from '@/hooks/v2/useQueryParams';
import * as S from './styles';

const { useFormInstance } = Form;

interface FooterProps {
	formName: string[];
}

export function Footer({ formName }: Readonly<FooterProps>) {
	const history = useHistory();
	const form = useFormInstance();
	const location = useLocation();
	const query = useQueryParams();

	function handleOnSubmit(): void {
		clearUrlParams();
		setFilterValuesToUrl();
		replaceUrlParams();
	}

	function handleOnClear(): void {
		clearUrlParams();
		replaceUrlParams();
	}

	function setFilterValuesToUrl(): void {
		const values = form.getFieldValue(formName);
		values && query.set('filter', 'true');
		Object.keys(values).forEach((key) => {
			const value = values[key];

			if (value) {
				query.set(key, value);
			}
		});
	}

	function clearUrlParams(): void {
		const values = form.getFieldValue(formName);
		values && query.delete('filter');
		Object.keys(values).forEach((key) => {
			query.delete(key);
		});
	}

	function replaceUrlParams(): void {
		history.replace({
			pathname: location.pathname,
			search: query.toString()
		});
	}

	return (
		<Row justify="center" gutter={[30, 0]} style={{ marginBottom: 30 }}>
			<Col>
				<S.PrimaryButton type="primary" icon={<SearchOutlined />} onClick={handleOnSubmit}>
					{I18n.get('Search')}
				</S.PrimaryButton>
			</Col>
			<Col>
				<S.SecondaryButton onClick={handleOnClear}>{I18n.get('Clear')}</S.SecondaryButton>
			</Col>
		</Row>
	);
}
