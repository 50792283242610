import React, { ReactNode, createContext, useContext } from 'react';
import { GetActionPlanResponse } from '@/hooks/v2/useGetActionPlan';
import { useGetResponsableOptions } from '@/hooks/v2/useGetResponsableOptions';
import type { Context } from './types';

interface ActionPlanProviderProps {
	children: ReactNode;
	data: GetActionPlanResponse;
}

const EditActionPlanContext = createContext<Context>({} as Context);

export function EditActionPlanProvider({ children, data }: Readonly<ActionPlanProviderProps>) {
	const { data: users } = useGetResponsableOptions({
		organization_id: data.organization.id,
		company_id: data.company.id
	});

	const contextValues = {
		users,
		actionPlan: data
	};

	return <EditActionPlanContext.Provider value={contextValues}>{children}</EditActionPlanContext.Provider>;
}

export function useEditActionPlanContext() {
	const context = useContext(EditActionPlanContext);
	return context;
}
