interface BeraSixTooAttributes {
	too_high: number;
	too_low: number;
	too_far: number;
	too_many_times: number;
	too_heavy: number;
	too_long: number;
}

export class BeraSixToo {
	public readonly too_high: number;
	public readonly too_low: number;
	public readonly too_far: number;
	public readonly too_many_times: number;
	public readonly too_heavy: number;
	public readonly too_long: number;

	constructor({ too_high, too_low, too_far, too_many_times, too_heavy, too_long }: BeraSixTooAttributes) {
		this.too_high = too_high;
		this.too_low = too_low;
		this.too_far = too_far;
		this.too_many_times = too_many_times;
		this.too_heavy = too_heavy;
		this.too_long = too_long;
	}
}
