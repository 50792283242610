export interface RebaBodySideExposureScoreItem {
  risk: number | null;
  percentage: number;
}

export interface RebaBodySideExposureScoreData {
  name: string;
  totalSeconds: number;
  data: RebaBodySideExposureScoreItem[];
}

export class RebaBodySideExposureScore {
  constructor(public readonly bodySides: RebaBodySideExposureScoreData[]) {}
}
