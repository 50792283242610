import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/sera';
import { SeraSumRPN } from '@/core/domain/sera-sum-rpn';

interface GetSeraSumRPNParams {
	organization_id: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	workstation_id?: string;
	start_date?: Date;
	end_date?: Date;
}

export const useGetSeraSumRPN = (params: GetSeraSumRPNParams) => {
	return useQuery<SeraSumRPN, Error>([QUERY_KEYS.GET_SERA_SUM_RPN, params], () => Service.getSumRPN(params), {
		enabled: !!params.organization_id
	});
};
