import { QUERY_KEYS } from '@/utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '@/infra/services/action-plan';

export interface GetRelatedReportsParams {
	company_id: string;
	action_plan_id: string;
	organization_id: string;
}

export const useGetRelatedReports = (params: GetRelatedReportsParams) => {
	return useQuery([QUERY_KEYS.GET_RELATED_REPORTS, params], () => Service.getRelatedReports(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.action_plan_id
	});
};
