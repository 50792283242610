import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';

import { Actions } from './Actions';
import { Comment } from './Comments';
import { ModalDelete } from './ModalDelete';
import { Informations } from './Informations';
import { ImagePreview } from './ImagePreview';

import { truncateFileName, formatFileSize } from '../../utils';
import { StyledFileIconContainer, StyledFilePreview, StyledUpload, ResponsiveImage } from './styles';

interface UploadFileItem extends UploadFile {
	author: string;
	created_at: string;
	description?: string;
}

interface FilePreviewProps {
	companyId: string;
	organizationId: string;
	files: UploadFileItem[];
}

type PreviewType = 'image' | 'file';

export function FilePreview({ files, companyId, organizationId }: FilePreviewProps) {
	const [previewState, setPreviewState] = useState({
		image: '',
		open: false,
		type: 'image' as PreviewType
	});

	const [modalState, setModalState] = useState({
		deleteOpen: false,
		commentOpen: false,
		visibleInfoUid: null as string | null
	});

	const [selectedFile, setSelectedFile] = useState<UploadFileItem | null>(null);

	function handleModalStateChange(key: keyof typeof modalState, value: any) {
		setModalState((prev) => ({ ...prev, [key]: value }));
	}

	function isImageFile(file: UploadFile) {
		return file.type === 'image';
	}

	function handlePreview(file: UploadFile) {
		if (isImageFile(file)) {
			setPreviewState({
				open: true,
				type: 'image',
				image: file.url || (file.preview as string)
			});
		}
	}

	function handleShowInfo(file: UploadFileItem) {
		handleModalStateChange('visibleInfoUid', file.uid);
	}

	function handleCommentClick(file: UploadFileItem) {
		setSelectedFile(file);
		setPreviewState({
			open: false,
			type: isImageFile(file) ? 'image' : 'file',
			image: isImageFile(file) ? file.url || (file.preview as string) : ''
		});
		handleModalStateChange('commentOpen', true);
	}

	function handleDelete(file: UploadFileItem) {
		setSelectedFile(file);
		handleModalStateChange('deleteOpen', true);
	}

	function renderFilePreview(file: UploadFileItem) {
		return isImageFile(file) ? (
			<ResponsiveImage src={file.preview} alt={file.name} />
		) : (
			<StyledFileIconContainer>
				<FileOutlined className="file-icon" />
			</StyledFileIconContainer>
		);
	}

	function renderFileInfo(file: UploadFileItem) {
		return (
			<StyledFilePreview>
				<p>{truncateFileName(file.name)}</p>
				<p>{formatFileSize(file.size || 0)} MB</p>
			</StyledFilePreview>
		);
	}

	function handleInformationsVisibility(uid: string | null) {
		if (modalState.visibleInfoUid === uid) {
			handleModalStateChange('visibleInfoUid', null);
			return;
		}
		handleModalStateChange('visibleInfoUid', uid);
	}

	return (
		<>
			<StyledUpload
				listType="picture-card"
				fileList={files}
				onPreview={handlePreview}
				itemRender={(_, file) => {
					const uploadFileItem = file as UploadFileItem;
					return (
						<div className="custom-upload-item">
							<Informations
								uploadFileItem={uploadFileItem}
								visibleInfoUid={modalState.visibleInfoUid}
								setVisibleInfoUid={handleInformationsVisibility}
							/>
							{renderFilePreview(uploadFileItem)}
							<Actions
								file={file}
								companyId={companyId}
								handleDelete={handleDelete}
								handlePreview={handlePreview}
								uploadFileItem={uploadFileItem}
								handleShowInfo={handleShowInfo}
								isImageFile={isImageFile(file)}
								organizationId={organizationId}
								handleCommentClick={handleCommentClick}
							/>
							<Row>
								<Col span={24}>{renderFileInfo(uploadFileItem)}</Col>
							</Row>
						</div>
					);
				}}
			/>

			<ImagePreview
				previewOpen={previewState.open}
				previewImage={previewState.image}
				handleCancel={() => setPreviewState((prev) => ({ ...prev, open: false }))}
			/>

			<Comment
				companyId={companyId}
				previewType={previewState.type}
				organizationId={organizationId}
				previewImage={previewState.image}
				commentModalOpen={modalState.commentOpen}
				attachmentId={selectedFile?.uid || null}
				initialComment={selectedFile?.description}
				handleCommentCancel={() => handleModalStateChange('commentOpen', false)}
			/>

			<ModalDelete
				file={selectedFile}
				companyId={companyId}
				open={modalState.deleteOpen}
				organizationId={organizationId}
				onCancel={() => handleModalStateChange('deleteOpen', false)}
			/>
		</>
	);
}
