import React from 'react';
import { Form, Row } from 'antd';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined, WarningOutlined } from '@ant-design/icons';

import { ActionPlansChart } from './BarChart';
import { Title } from '@/components/Typography';
import { unShortenUrl } from '@/views/Dashboard/utils';
import { Card } from '@/views/Dashboard/components/Card';
import { Single } from '@/views/Dashboard/components/Filters/Single';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetStatusActionPlans } from '@/views/Dashboard/hooks/useGetStatusActionPlans';
import { StyledDivPopOver } from '@/views/Dashboard/styles';
import * as S from './styles';

interface ChecklistReportsProps {
	handleClear(fieldNames: string[]): void;
}
const { useFormInstance } = Form;

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export function ActionPlanStatus({ handleClear }: ChecklistReportsProps) {
	const { organization } = useApplicationContext();
	const query = useQuery();
	const form = useFormInstance();
	const location = useLocation();

	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');

	const unshortenUrl = unShortenUrl(location.search.substring(1));
	const checklistParams = unshortenUrl?.ap_status ?? unshortenUrl?.general?.unique_period;

	const filterParams = {
		organization_id: organization?.id!,
		line_id: line_id ?? undefined,
		sector_id: sector_id ?? undefined,
		company_id: company_id ?? undefined,
		workstation_id: workstation_id ?? undefined,
		end_date: checklistParams?.end_date ?? undefined,
		start_date: checklistParams?.start_date ?? undefined
	};

	const { isLoading, data, isError, refetch } = useGetStatusActionPlans(filterParams);

	const total_data = [data?.total?.to_do ?? 0, data?.total?.doing ?? 0, data?.total?.done ?? 0];
	const late_data = [data?.delayed?.to_do ?? 0, data?.delayed?.doing ?? 0, data?.delayed?.done ?? 0];

	function handleOnSubmit() {
		form.submit();
	}

	return (
		<Card.Container size="medium" isLoading={isLoading} isError={isError} onRefetch={refetch}>
			<Card.Header
				hasIcon
				title="Action plan status"
				icon={<CalendarOutlined />}
				popoverComponent={
					<StyledDivPopOver>
						<Single
							hasGranularity={false}
							fieldName={['ap_status']}
							handleClear={handleClear}
							handleFormSubmit={handleOnSubmit}
						/>
					</StyledDivPopOver>
				}
			/>
			<Card.Content>
				<Row style={{ height: 300, paddingRight: '0.5rem' }} gutter={[0, 5]}>
					<S.StyledCol span={24}>
						<ActionPlansChart total_data={total_data} late_data={late_data} />
					</S.StyledCol>
					<S.StyledCol span={24}>
						<h3 style={{ marginRight: '5px' }}>
							<WarningOutlined />
						</h3>
						<Title level={5}>Late</Title>
					</S.StyledCol>
				</Row>
			</Card.Content>
		</Card.Container>
	);
}
