import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';

import { DoughnutChart } from './DoughnutChart';
import { unShortenUrl } from '@/views/Dashboard/utils';
import { Card } from '@/views/Dashboard/components/Card';
import { Single } from '@/views/Dashboard/components/Filters/Single';
import { useGetStrainIndexRsi } from '../../hooks/useGetStrainIndexRsi';
import { useApplicationContext } from '@/context/v1/Application/context';
import { COLORS, ChartContainer, LegendContainer, LegendItem, LegendColor, LegendLabel } from './styles';
import { StyledDivPopOver } from '@/views/Dashboard/styles';

interface ChecklistReportsProps {
	handleClear(fieldNames: string[]): void;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const { useFormInstance } = Form;

export function Rsi({ handleClear }: Readonly<ChecklistReportsProps>) {
	const { organization } = useApplicationContext();
	const query = useQuery();
	const form = useFormInstance();
	const location = useLocation();

	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');

	const unshortened_url = unShortenUrl(location.search.substring(1));
	const rsiParams = unshortened_url?.rsi ?? unshortened_url?.general?.unique_period;

	const filter_params = {
		organization_id: organization?.id!,
		company_id: company_id ?? undefined,
		sector_id: sector_id ?? undefined,
		line_id: line_id ?? undefined,
		workstation_id: workstation_id ?? undefined,
		end_date: rsiParams?.end_date ?? undefined,
		start_date: rsiParams?.start_date ?? undefined
	};

	const { isLoading, data, isError, refetch } = useGetStrainIndexRsi(filter_params);

	function handleOnSubmit(): void {
		form.submit();
	}

	const chartData = data ? data?.items.map((item) => item.percentage) : [];
	const labels = data ? data?.items.map((item) => item.description) : [];

	return (
		<Card.Container size="large" isLoading={isLoading} isError={isError} onRefetch={refetch}>
			<Card.Header
				hasIcon
				isTitleBold
				title={I18n.get('Revised Strain Index')}
				subtitle={I18n.get('Risk')}
				icon={<CalendarOutlined />}
				popoverComponent={
					<StyledDivPopOver>
						<Single
							fieldName={['rsi']}
							hasGranularity={false}
							handleClear={handleClear}
							handleFormSubmit={handleOnSubmit}
						/>
					</StyledDivPopOver>
				}
			/>
			<Card.Content>
				<ChartContainer>
					{data && <DoughnutChart data={chartData} labels={labels} totalReports={data.total} />}
				</ChartContainer>
				{data?.total ? (
					<LegendContainer>
						{labels.map((label, index) => (
							<LegendItem key={label}>
								<LegendColor color={COLORS[index]} />
								<LegendLabel>{label}</LegendLabel>
							</LegendItem>
						))}
					</LegendContainer>
				) : (
					<React.Fragment />
				)}
			</Card.Content>
		</Card.Container>
	);
}
