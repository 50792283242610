import React from 'react';
import { Bar } from 'react-chartjs-2';
import { I18n } from '@aws-amplify/core';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import {
	Chart as ChartJS,
	BarElement,
	CategoryScale,
	LinearScale,
	Tooltip,
	Legend,
	ChartData,
	ChartOptions
} from 'chart.js';
import { INDISDE_COLORS, COLORS, BACKGROUND_COLORS, BORDER_COLORS, BORDER_COLORS_SECONDARY } from './styles';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);

interface ActionPlansChartProps {
	total_data?: number[];
	late_data?: number[];
}

export function ActionPlansChart({ total_data = [], late_data = [] }: ActionPlansChartProps): JSX.Element {
	const rest_data = total_data.map((total, index) => total - late_data[index]);

	const data: ChartData<'bar', number[], string> = {
		labels: [I18n.get('Not started'), I18n.get('Doing'), I18n.get('Done')],
		datasets: [
			{
				label: I18n.get('Late'),
				data: late_data,
				backgroundColor: BACKGROUND_COLORS,
				borderColor: BORDER_COLORS,
				borderWidth: 3,
				maxBarThickness: 80,
				datalabels: {
					font: {
						weight: 'bolder',
						size: 18
					},
					display: (context: any) => {
						const value = context.dataset.data[context.dataIndex];
						if (value <= 0) {
							return false;
						}

						const dataIndex = context.dataIndex;
						const maxValue = Math.max(...(context.dataset.data.filter(Boolean) as number[]));
						const totalValue = total_data[dataIndex];

						return totalValue / maxValue >= 0.15;
					},
					formatter: (value: number) => `⚠ ${value.toLocaleString(navigator.language)}`,
					color: (context: Context) => {
						const dataIndex = context.dataIndex;
						const lateValue = context.dataset.data[dataIndex] as number;
						const totalValue = total_data[dataIndex];
						const percentage = lateValue / totalValue;

						if (percentage <= 0.5) {
							return INDISDE_COLORS[dataIndex];
						}

						return COLORS[dataIndex];
					},
					anchor: 'end',
					align: 'start',
					clip: false,
					offset: (context: Context) => {
						const dataIndex = context.dataIndex;
						const lateValue = context.dataset.data[dataIndex] as number;
						const totalValue = total_data[dataIndex];

						const nonZeroValues = total_data.filter((value) => value !== 0);
						const maximumValue = Math.max(...nonZeroValues);
						const minimumValue = Math.min(...nonZeroValues);

						const percentage = lateValue / totalValue;
						const maximumPercentage = (minimumValue / maximumValue) * 100;

						if (maximumPercentage < 5 && percentage >= 0.5 && totalValue === minimumValue) {
							return -25;
						}

						return percentage <= 0.5 ? -30 : 2;
					}
				}
			},
			{
				label: I18n.get('Total'),
				data: rest_data,
				backgroundColor: COLORS,
				borderColor: BORDER_COLORS_SECONDARY,
				borderRadius: { topLeft: 6, topRight: 6 },
				maxBarThickness: 80,
				datalabels: {
					font: {
						size: 17,
						weight: 'bold'
					},
					display: true,
					formatter: (value: number, context: Context) => {
						const dataIndex = context.dataIndex;
						const totalValue = late_data[dataIndex] + value;
						return `${totalValue.toLocaleString(navigator.language)}`;
					},
					color: 'black',
					anchor: 'end',
					align: 'end',
					offset: () => {
						return 15;
					},
					clip: false
				}
			}
		]
	};

	const options: ChartOptions<'bar'> = {
		animation: false,
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				mode: 'index',
				intersect: false
			}
		},
		layout: {
			padding: {
				top: 45,
				bottom: 5
			}
		},
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false
				}
			},
			y: {
				beginAtZero: true,
				stacked: true,
				ticks: {
					stepSize: 20
				},
				title: {
					display: true,
					padding: { bottom: 10 },
					text: I18n.get('Number of actions'),
					font: {
						size: 14,
						weight: 'bold'
					}
				},
				grid: {
					display: false
				}
			}
		}
	};

	return (
		<div style={{ width: '100%', height: '100%' }}>
			<Bar data={data} options={options} />
		</div>
	);
}
