import React from 'react';
import { I18n } from '@aws-amplify/core';
import type { FormFieldProps } from '../../types';
import type { DefaultOptionType, SelectProps } from 'antd/lib/select';
import * as S from './styles';

interface OriginProps extends FormFieldProps, SelectProps {
	options?: DefaultOptionType[];
}

export function Origin({
	onSearch,
	options = [],
	formName = [''],
	isLoading = false,
	isDisabled = false,
	isRequired = false
}: Readonly<OriginProps>) {
	const title = I18n.get('Origin');

	return (
		<S.CustomFormItem label={title} labelCol={{ span: 24 }} name={[...formName, 'origin']} required={isRequired}>
			<S.CustomSelect
				allowClear
				showSearch
				options={options}
				loading={isLoading}
				placeholder={title}
				onSearch={onSearch}
				disabled={isDisabled}
			/>
		</S.CustomFormItem>
	);
}
