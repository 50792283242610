import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Button, Dropdown, Form } from 'antd';
import { CheckOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, PaperClipOutlined } from '@ant-design/icons';

import { ModalDelete } from '../../ModalDelete';
import { UserAvatar } from '../../../UserAvatar';
import { useEditActionPlanContext } from '../context';
import { ResponsibleDropdown, TaskName } from '../../../Fields';
import { DueDateDropdown } from '../../../Fields/DueDateDropdown';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useDeleteActionPlanTask } from '@/hooks/v2/useDeleteActionPlanTask';
import { useUpdateActionPlanTask } from '@/hooks/v2/useUpdateActionPlanTask';
import type { GetActionPlanTasksResponse } from '@/hooks/v2/useGetActionPlanTasks';
import { useUpdateActionPlanTaskStatus } from '@/hooks/v2/useUpdateActionPlanTaskStatus';
import { Evidences } from '@/components/ui/Modals/Evidences';
import * as S from './styles';

const { useFormInstance, useWatch } = Form;

interface TaskItemProps {
	data: GetActionPlanTasksResponse;
}

export function TaskItem({ data }: Readonly<TaskItemProps>) {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
	const [isEvidencesOpen, setIsEvidencesOpen] = useState<boolean>(false);

	const form = useFormInstance();
	const { users } = useEditActionPlanContext();
	const { organization, company } = useApplicationContext();
	const { mutateAsync: deleteTask } = useDeleteActionPlanTask();
	const { mutateAsync: updateTask } = useUpdateActionPlanTask();
	const { mutateAsync: updateTaskStatus } = useUpdateActionPlanTaskStatus();

	const fieldName = ['edit_task', data.id];
	const isChecked = useWatch([...fieldName, 'is_completed']);

	async function handleOnDelete(): Promise<void> {
		const payload = {
			organization_id: organization?.id,
			company_id: company?.id,
			action_plan_task_id: data.id
		};

		await deleteTask(payload);
	}

	async function handleOnSaveEdition(): Promise<void> {
		const formValues = form.getFieldValue(fieldName);

		const payload = {
			organization_id: organization?.id,
			company_id: company?.id,
			title: formValues.task_name,
			action_plan_task_id: data.id,
			due_date: formValues.due_date,
			responsible_user_id: formValues.responsible_id
		};

		await updateTask(payload);
		handleToggleEdition();
	}

	async function handleToggleCheck(): Promise<void> {
		const payload = {
			organization_id: organization?.id,
			company_id: company?.id,
			action_plan_task_id: data.id
		};

		await updateTaskStatus(payload);
	}

	function handleToggleEdition(): void {
		setIsEditing(!isEditing);
	}

	function handleToggleDelete(): void {
		setIsDeleteOpen(!isDeleteOpen);
	}

	function handleToggleEvidences(): void {
		setIsEvidencesOpen(!isEvidencesOpen);
	}

	function setInitialFieldsValue(): void {
		form.setFieldValue([...fieldName, 'task_name'], data.title);
		form.setFieldValue([...fieldName, 'is_completed'], data.is_completed);
		form.setFieldValue([...fieldName, 'due_date'], moment(data.due_date));
		form.setFieldValue([...fieldName, 'responsible_id'], data.responsible_user_id);
	}

	useEffect(() => {
		setInitialFieldsValue();
	}, []);

	const dropdownActions = [
		{
			key: 'edit',
			label: (
				<div style={{ color: '#2F54EB', fontSize: '1.5rem' }}>
					<EditOutlined />
				</div>
			),
			onClick: handleToggleEdition
		},
		{
			key: 'delete',
			label: (
				<div style={{ color: '#E74150', fontSize: '1.5rem' }}>
					<DeleteOutlined />
				</div>
			),
			onClick: handleToggleDelete
		}
	];

	if (isEditing) {
		return (
			<Row justify="space-between" align="middle">
				<Col span={19}>
					<TaskName formName={fieldName} />
				</Col>
				<Col>
					<DueDateDropdown formName={fieldName} />
				</Col>
				<Col>
					<ResponsibleDropdown formName={fieldName} options={users} />
				</Col>
				<Col>
					<Button
						size="small"
						type="primary"
						shape="circle"
						icon={<CheckOutlined />}
						onClick={handleOnSaveEdition}
					/>
				</Col>
			</Row>
		);
	}

	return (
		<>
			<Row justify="space-between" align="middle">
				<Col span={18}>
					<Form.Item name={[...fieldName, 'is_completed']} style={{ margin: 0 }} valuePropName="checked">
						<S.Description $checked={isChecked} onClick={handleToggleCheck}>
							{data?.title}
						</S.Description>
					</Form.Item>
				</Col>
				<Col>
					<S.TransparentButton style={{ fontSize: '1.3rem' }} onClick={handleToggleEvidences}>
						<PaperClipOutlined />
					</S.TransparentButton>
				</Col>
				<Col>
					<span style={{ fontSize: '0.8rem' }}>{moment(data?.due_date).format('L')}</span>
				</Col>
				<Col>
					<UserAvatar name={data.responsible_user?.name} />
				</Col>
				<Col>
					<Dropdown trigger={['click']} placement="bottomRight" menu={{ items: dropdownActions }}>
						<S.TransparentButton style={{ fontSize: 25 }}>
							<EllipsisOutlined />
						</S.TransparentButton>
					</Dropdown>
				</Col>
			</Row>
			<ModalDelete
				open={isDeleteOpen}
				onConfirm={handleOnDelete}
				onCancel={handleToggleDelete}
				description="Are you sure you want to delete the selected task?"
			/>
			<Evidences
				taskName={data.title}
				isOpen={isEvidencesOpen}
				companyId={company?.id}
				actionPlanTaskId={data.id}
				onCancel={handleToggleEvidences}
				organizationId={organization?.id}
			/>
		</>
	);
}
