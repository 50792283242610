import { URLSearchParams } from 'url';
import { navigation_hierarchy } from './navigation-helpers';

export interface Entity {
	id: string;
	name: string;
	entity: string;
}

export function getEntitiesFromSearchParams(search_params: URLSearchParams): Entity[] {
	const entities: Entity[] = [];

	for (const entity of navigation_hierarchy) {
		const id = search_params.get(entity);
		const name = search_params.get(`${entity}_name`);

		if (id && name) {
			entities.push({ entity, id, name });
		} else {
			break;
		}
	}

	return entities;
}
