import styled from 'styled-components';

export const CommentsWrapper = styled.div`
	height: 200px;
	overflow: auto;
	padding-right: 1rem;

	::-webkit-scrollbar {
		width: 5px;
		max-height: 100px;
	}
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	::-webkit-scrollbar-thumb {
		background-color: #9c9c9c;
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;
