import React, { useMemo } from 'react';
import moment from 'moment';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ModalWrapper } from './ModalWrapper';
import { Title } from '@/components/Typography';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { ModalEditProps } from './types';
import { DefaultOptionType } from 'antd/lib/select';
import { PRIORITY_OPTIONS, FIELD_CONFIGS } from './config';
import * as S from './styles';

export const MODAL_TITLE_CONFIG = {
	priority: I18n.get('Priority editing'),
	due_date: I18n.get('Due Date editing'),
	responsible_user_id: I18n.get('Responsible editing'),
	delete: ''
} as const;

export function ModalEdit({
	form,
	users,
	handleOk,
	selectType,
	isModalOpen,
	handleOnCancel,
	handleOnSubmit,
	handleOnDelete,
	handleSelectChange
}: Readonly<ModalEditProps>) {
	const selectOptions = useMemo<DefaultOptionType[]>(() => {
		if (!selectType || selectType === 'delete' || selectType === 'due_date') {
			return [];
		}

		if (selectType === 'responsible_user_id') {
			return users;
		}

		return [...PRIORITY_OPTIONS];
	}, [selectType, users]);

	function getModalTitle(): string {
		return selectType ? MODAL_TITLE_CONFIG[selectType] || '' : '';
	}

	function renderFormField() {
		if (!selectType || selectType === 'delete') return null;

		const field = FIELD_CONFIGS[selectType];
		if (!field) return null;

		const isDateField = selectType === 'due_date';

		return (
			<Col sm={24}>
				<Form.Item
					name={field.name}
					label={
						<span>
							{field.label} <span style={{ color: 'red' }}>*</span>
						</span>
					}
					rules={[{ required: true, message: field.message }]}
					required={false}
				>
					{isDateField ? (
						<S.DatePickerWrapper
							placeholder={field.placeholder}
							disabledDate={(current) => current < moment().subtract(1, 'day')}
							format={moment().locale(navigator.language).localeData().longDateFormat('L')}
						/>
					) : (
						<S.StyledSelect
							allowClear
							options={selectOptions}
							placeholder={field.placeholder}
							loading={selectType === 'responsible_user_id' && !users}
							onChange={(value) => handleSelectChange(value as string | undefined)}
						/>
					)}
				</Form.Item>
			</Col>
		);
	}

	function renderActionButtons() {
		const isDeleteAction = selectType === 'delete';
		const isFormInvalid =
			!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length;

		return (
			<Row justify="center" gutter={[12, 0]}>
				<Col>
					<CustomButton key="cancel" onClick={handleOnCancel}>
						{I18n.get('Cancel')}
					</CustomButton>
				</Col>
				<Col>
					<CustomButton
						type="primary"
						disabled={isFormInvalid}
						key={isDeleteAction ? 'delete' : 'submit'}
						htmlType={isDeleteAction ? 'button' : 'submit'}
						onClick={isDeleteAction ? handleOnDelete : handleOnSubmit}
					>
						{I18n.get(isDeleteAction ? 'Yes' : 'Save')}
					</CustomButton>
				</Col>
			</Row>
		);
	}

	return (
		<ModalWrapper open={isModalOpen} onCancel={handleOnCancel} onOk={() => handleOk(null)}>
			<Form form={form} layout="vertical" style={{ width: '100%' }}>
				<Row justify="center">
					<Col sm={24}>
						<Title level={4}>{getModalTitle()}</Title>
						{selectType === 'delete' && (
							<Row justify="center" style={{ paddingBottom: '1.5rem' }}>
								<Col span={24}>
									<S.StyledDeleteIcon />
								</Col>
								<Col span={24}>
									<S.DeleteTypography>
										{I18n.get('Are you sure you want to delete the selected items?')}
									</S.DeleteTypography>
								</Col>
							</Row>
						)}
					</Col>
					{renderFormField()}
					<Col sm={24}>
						<Form.Item shouldUpdate>{renderActionButtons}</Form.Item>
					</Col>
				</Row>
			</Form>
		</ModalWrapper>
	);
}
