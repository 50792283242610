import * as React from 'react';

interface JDSD86ChecklistProps {
	width?: string;
	height?: string;
}

export const JDSD86Checklist = ({ width = '1rem', height = '1rem' }: Readonly<JDSD86ChecklistProps>) => {
	return (
		<svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_d_6997_1719)">
				<rect x="5" y="4" width="20" height="20" fill="#2B51EA" />
				<rect x="6" y="5" width="18" height="18" stroke="#2B51EA" strokeWidth="2" />
			</g>
			<defs>
				<filter
					id="filter0_d_6997_1719"
					x="0"
					y="0"
					width="30"
					height="30"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						type="matrix"
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset dy="1" />
					<feGaussianBlur stdDeviation="2.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6997_1719" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6997_1719" result="shape" />
				</filter>
			</defs>
		</svg>
	);
};
