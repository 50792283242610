import styled from 'styled-components';
import { Button } from 'antd';

export const CustomButton = styled(Button)`
	color: #2f54eb;
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	border: 1px solid #2f54eb;
	box-shadow: 0px 1px 0px 0px #0000000b;
`;
