import { message } from 'antd';
import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Response } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';
import { ActionPlanCardBulkUpdateResponseDTO } from '@/core/dto';

interface ActionPlanBulkUpdateParams {
	ids: string[];
	company_id: string;
	organization_id: string;
	field: {
		name: string | null;
		value: string | null;
	};
}

export const useBulkUpdateUpdateActionPlan = () => {
	const queryClient = useQueryClient();

	return useMutation<ActionPlanCardBulkUpdateResponseDTO, AxiosError<Response>, ActionPlanBulkUpdateParams>(
		(params) => Service.bulkUpdate(params),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_CARD_LIST]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_LIST]);
				message.success(I18n.get('Action plans updated successfully'));
			}
		}
	);
};
