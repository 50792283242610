import React from 'react';
import * as S from './styles';

interface ModalProps {
	open?: boolean;
	children: React.ReactNode;
	onOk: () => void;
	onCancel: () => void;
}

export function ModalWrapper({ open = false, children, onOk, onCancel }: Readonly<ModalProps>) {
	return (
		<S.ModalWrapper open={open} onOk={onOk} onCancel={onCancel} centered footer={false} closable={false}>
			{children}
		</S.ModalWrapper>
	);
}
