import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/reba';

import { RebaBodySideExposureScore } from '@/core/domain/reba-body-side-exposure-score';

interface GetRebaExposureBodyParams {
	organization_id: string;
	workstation_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	start_date?: Date;
	end_date?: Date;
}

export const useGetRebaExposureBody = (params: GetRebaExposureBodyParams) => {
	return useQuery<RebaBodySideExposureScore, Error>(
		[QUERY_KEYS.GET_REBA_EXPOSURE_BODY, params],
		() => Service.getBodySideExposureScore(params),
		{
			enabled: !!params.organization_id
		}
	);
};
