import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/niosh';

interface GetNioshRisksCountParams {
	organization_id: string;
	company_id: string;
	sector_id?: string;
	line_id?: string;
	workstation_id?: string;
	start_date?: Date;
	end_date?: Date;
}

export const useGetNioshRisksCount = (params: GetNioshRisksCountParams) => {
	return useQuery([QUERY_KEYS.GET_NIOSH_RISKS_COUNT, params], () => Service.getCountByRisk(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
