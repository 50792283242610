import React from 'react';
import { AvatarProps, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import * as S from './styles';

interface UserAvatarProps extends AvatarProps {
	name?: string;
}

export function UserAvatar({ name, ...props }: Readonly<UserAvatarProps>) {
	function getAcronymName(): string | JSX.Element {
		if (!name) {
			return <UserOutlined />;
		}

		const splittedFullName = name.split(' ');

		const firstName = splittedFullName[0];
		const secondName = splittedFullName[1];

		if (!secondName) {
			return firstName[0];
		}

		return firstName[0] + secondName[0];
	}

	function getBackgroundColorByInitialLatters(value?: string): string {
		if (!value) {
			return S.GRAY_COLOR;
		}

		const firstChar = value.charCodeAt(0);
		const secondChar = value.charCodeAt(1) || firstChar;
		const binaryValue = (firstChar << 8) | secondChar;

		const hueStep = binaryValue % 360;
		const saturation = 65;
		const lightness = 75;

		return `hsl(${hueStep}, ${saturation}%, ${lightness}%)`;
	}

	const title = name ?? 'N/A';
	const backgroundColor = getBackgroundColorByInitialLatters(name);

	return (
		<Tooltip title={title}>
			<S.CustomAvatar $backgroundColor={backgroundColor} {...props}>
				{getAcronymName()}
			</S.CustomAvatar>
		</Tooltip>
	);
}
