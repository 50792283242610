import styled from 'styled-components';
import { Row, Button, Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
	.ant-modal-content {
		border-radius: 30px !important;
	}

	.ant-modal-body {
		padding: 2rem 2rem;
	}
`;

export const IconButton = styled(Button)`
	display: flex;
	height: 2.5rem;
	font-size: 1rem;
	font-weight: 600;
	padding: 0 0.8rem;
	border-radius: 7px;
	align-items: center;
	justify-content: center;

	.anticon {
		line-height: 0;
	}

	span {
		margin: 0 !important;
	}

	svg {
		width: 1.2rem;
		font-size: 1.1rem;

		@media (min-width: 1600px) {
			margin-right: 0.6rem;
		}
	}
`;

export const PrimaryIconButton = styled(Button)`
	display: flex;
	height: 2.4rem;
	font-size: 1rem;
	padding: 0 0.8rem;
	border-radius: 5px;
	align-items: center;
	background-color: #2f54eb;
	border: 1px solid #2f54eb;
	box-shadow: 0px 2px 0px 0px #0000000b;

	.anticon {
		line-height: 0;
	}

	span {
		margin: 0 !important;
	}

	svg {
		font-size: 1.3rem;
		margin-right: 0.6rem;
	}
`;

export const ContainerContent = styled(Row)`
	padding: 1.8rem;
	border-radius: 0.8rem;
	background-color: #ffffff;
`;

export const PrimaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	background-color: #2f54eb;
	box-shadow: 0px 2px 0px 0px #0000000b;
`;

export const SecondaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	box-shadow: 0px 1px 0px 0px #0000000b;
`;
