import styled from 'styled-components';
import { Avatar } from 'antd';

interface AvatarProps {
	$backgroundColor: string;
}

export const AvatarWrapper = styled(Avatar)<AvatarProps>`
	color: #fff;
	vertical-align: middle;
	background-color: ${({ $backgroundColor }) => $backgroundColor};
`;
