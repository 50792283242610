import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import type { DefaultOptionType } from 'antd/lib/select';
import type { FormFieldProps } from '../../types';
import * as S from './styles';

const { useFormInstance, useWatch } = Form;

interface CompanyProps extends FormFieldProps {
	options?: DefaultOptionType[];
}

export function Company({
	options = [],
	formName = [''],
	isLoading = false,
	isDisabled = false,
	isRequired = false
}: Readonly<CompanyProps>) {
	const form = useFormInstance();
	const organizationId = useWatch([...formName, 'organization_id'], form);

	const title = I18n.get('Industrial site');
	const errorMessage = I18n.get('Select the industrial site');

	function handleClearHierarchyFields(): void {
		form.setFieldValue([...formName, 'sector_id'], undefined);
		form.setFieldValue([...formName, 'line_id'], undefined);
		form.setFieldValue([...formName, 'workstation_id'], undefined);
		form.setFieldValue([...formName, 'responsible_id'], undefined);
		form.setFieldValue([...formName, 'origin'], undefined);
	}

	return (
		<S.CustomFormItem
			label={title}
			labelCol={{ span: 24 }}
			name={[...formName, 'company_id']}
			rules={[{ required: isRequired, message: errorMessage }]}
		>
			<S.CustomSelect
				allowClear
				options={options}
				loading={isLoading}
				placeholder={title}
				onClear={handleClearHierarchyFields}
				onChange={handleClearHierarchyFields}
				disabled={isDisabled || !organizationId}
			/>
		</S.CustomFormItem>
	);
}
