import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DollarSignIcon } from '@/assets/icons/dollar-sign';
import type { FormFieldProps } from './types';
import * as S from './styles';

const { useWatch, useFormInstance } = Form;

const options = [
	{
		value: 1,
		icon: <DollarSignIcon />
	},
	{
		value: 2,
		icon: (
			<S.IconsWrapper>
				<DollarSignIcon />
				<DollarSignIcon />
			</S.IconsWrapper>
		)
	},
	{
		value: 3,
		icon: (
			<S.IconsWrapper>
				<DollarSignIcon />
				<DollarSignIcon />
				<DollarSignIcon />
			</S.IconsWrapper>
		)
	}
];

export function InvestmentRange({ formName = [''] }: Readonly<FormFieldProps>) {
	const form = useFormInstance();

	const title = I18n.get('Investment');
	const fieldName = [...formName, 'investment_range'];

	const investmentRange = useWatch(fieldName);

	function handleOnClick(value: number): void {
		const isChecked = value === investmentRange;

		if (isChecked) {
			form.setFieldValue(fieldName, undefined);
		}
	}

	return (
		<S.CustomFormItem label={title} labelCol={{ span: 24 }} name={fieldName}>
			<S.Group>
				<Row gutter={[16, 0]}>
					{options.map(({ value, icon }, index) => (
						<Col key={index} span={8}>
							<S.RadioButton onClick={() => handleOnClick(value)} value={value}>
								{icon}
							</S.RadioButton>
						</Col>
					))}
				</Row>
			</S.Group>
		</S.CustomFormItem>
	);
}
