import React from 'react';

export function ArrowDownIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
	return (
		<svg viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fill="currentColor"
				d="M8 2.62268e-07L8 7L11 7L5.5 14L-6.11959e-07 7L3 7L3 6.99382e-07L8 2.62268e-07Z"
			/>
		</svg>
	);
}
