import React from 'react';
import { Row, Col } from 'antd';
import * as S from './styles';

export function LoadingSkeleton() {
	return (
		<Row gutter={[0, 30]}>
			<Col span={24}>
				<S.CustomSkeleton active />
			</Col>
			<Col span={24}>
				<Row gutter={[60, 0]}>
					<Col span={16} style={{ borderRight: '1px solid #D9D9D9' }}>
						<Row gutter={[40, 30]}>
							<Col span={12}>
								<S.CustomSkeleton active />
							</Col>
							<Col span={12}>
								<S.CustomSkeleton active />
							</Col>
							<Col span={12}>
								<S.CustomSkeleton active />
							</Col>
							<Col span={12}>
								<S.CustomSkeleton active />
							</Col>
							<Col span={24}>
								<S.CustomSkeleton active style={{ height: 300 }} />
							</Col>
							<Col span={24}>
								<S.CustomSkeleton active style={{ height: 40 }} />
							</Col>
							<Col span={24}>
								<S.CustomSkeleton active style={{ height: 100 }} />
							</Col>
							<Col span={24}>
								<S.CustomSkeleton active style={{ height: 100 }} />
							</Col>
						</Row>
					</Col>
					<Col span={8}>
						<Row gutter={[0, 30]}>
							<Col span={24}>
								<S.CustomSkeleton active />
							</Col>
							<Col span={24}>
								<S.CustomSkeleton active style={{ height: 200 }} />
							</Col>
							<Col span={24}>
								<S.CustomSkeleton active style={{ height: 200 }} />
							</Col>
							<Col span={24}>
								<S.CustomSkeleton active style={{ height: 230 }} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
