import { BaseService, BasicInfo } from './base-service';
import { UploadFileItem } from '@/core/domain/attachments';
import { ActionPlanAttachmentsMapper } from '@/core/mapper/action-plan-attachments';
import {
	ActionPlanListDTO,
	ActionPlanListRequestDTO,
	ActionPlanDelayedDTO,
	ActionPlanDelayedRequestDTO,
	ActionPlanCardListRequestDTO,
	ActionPlanCardListResponseDTO,
	ActionPlanCountStatusDTO,
	CreateActionPlanRequestDTO,
	CreateActionPlanResponseDTO,
	ActionPlanOriginRequestDTO,
	ActionPlanOriginResponseDTO,
	UpdateCardRankRequestDTO,
	UpdateCardRankResponseDTO,
	ActionPlanCardBulkDeleteDTO,
	ActionPlanCardBulkDeleteResponseDTO,
	ActionPlanCardBulkUpdateDTO,
	ActionPlanCardBulkUpdateResponseDTO,
	AddAttachmentRequestDTO,
	AddAttachmentResponseDTO,
	DeleteAttachmentResponseDTO,
	DeleteAttachmentRequestDTO,
	GetAttachmentsRequestDTO,
	ActionPlanHistoryDTO,
	UpdateAttachmentRequestDTO,
	UpdateAttachmentResponseDTO,
	GetRelatedReportsRequestDTO,
	ActionPlanHistoryRequestDTO,
	SetRelatedReportsRequestDTO,
	GetRelatedReportsResponseDTO,
	SetRelatedReportsResponseDTO,
	ActionPlanCountStatusRequestDTO,
	ActionPlanCountRequestDTO,
	ActionPlanCountResponseDTO,
	ActionPlanCreateTasksRequestDTO,
	ActionPlanCreateTasksResponseDTO,
	ActionPlanDeleteTaskRequestDTO,
	ActionPlanDeleteTaskResponseDTO,
	ActionPlanTasksRequestDTO,
	ActionPlanTasksResponseDTO,
	ActionPlanUpdateTaskRequestDTO,
	ActionPlanUpdateTaskResponseDTO,
	ActionPlanUpdateTaskStatusRequestDTO,
	ActionPlanUpdateTaskStatusResponseDTO,
	ActionPlanResponseDTO,
	UpdateActionPlanRequestDTO,
	UpdateActionPlanResponseDTO,
	ActionPlanUpdateNotificationRequestDTO,
	ActionPlanUpdateNotificationResponseDTO,
	GetEvidencesRequestDTO,
	AddEvidenceRequestDTO,
	AddEvidenceResponseDTO,
	DeleteEvidenceRequestDTO,
	DeleteEvidenceResponseDTO,
	UpdateEvidenceResponseDTO,
	UpdateEvidenceRequestDTO,
	ActionPlanTaskAttachmentDownloadUrlRequestDTO,
	ActionPlanAttachmentDownloadUrlRequestDTO,
	OriginOptionResponseDTO,
	CreateActionPlanFromReportResponseDTO,
	CreateActionPlanFromReportRequestDTO,
	ActionPlanFromReportRequestDTO,
	ActionPlanFromReportResponseDTO
} from '@/core/dto';
import { ActionPlanHistoryDomain } from '@/core/domain/action-plan-history';
import { ActionPlanHistoryMapper } from '@/core/mapper/action-plan-history';
import { ActionPlanCommentsDomain } from '@/core/domain/action-plan-comments';
import { ActionPlanCommentsMapper } from '@/core/mapper/action-plan-comments';
import { ActionPlanCommentsDTO, ActionPlanCommentsRequestDTO } from '@/core/dto/action-plan-comments';
import {
	ActionPlanCreateCommentsDTO,
	ActionPlanCreateCommentsRequestDTO
} from '@/core/dto/action-plan-comments-create';
import { ActionPlanCreateCommentsDomain } from '@/core/domain/action-plan-comments-create';
import { ActionPlanCreateCommentsMapper } from '@/core/mapper/action-plan-comments.create';
import {
	ActionPlanUpdateCommentsDTO,
	ActionPlanUpdateCommentsRequestDTO
} from '@/core/dto/action-plan-comments.update';
import { ActionPlanUpdateCommentsDomain } from '@/core/domain/action-plan-comments-update';
import { ActionPlanUpdateCommentsMapper } from '@/core/mapper/action-plan-comments-update';
import {
	ActionPlanDeleteCommentsDTO,
	ActionPlanDeleteCommentsRequestDTO
} from '@/core/dto/action-plan-comments-delete';
import { ActionPlanDeleteCommentsDomain } from '@/core/domain/action-plan-comments-delete';
import { ActionPlanDeleteCommentsMapper } from '@/core/mapper/action-plan-comments-delete';
import { ActionPlanDelayedMapper, ActionPlanCountStatusMapper, ActionPlanListMapper } from '@/core/mapper';
import { ActionPlanDelayed, ActionPlanCardList, ActionPlanCountStatus, ActionPlanListDomain } from '@/core/domain';
import {
	ActionPlanCreateUserPreferenceColumnsDTO,
	ActionPlanCreateUserPreferenceColumnsRequestDTO
} from '@/core/dto/action-plan-user-preference-columns';
import { ActionPlanCreateUserPreferenceColumnsDomain } from '@/core/domain/action-plan-user-preference-columns';
import { ActionPlanCreateUserPreferenceColumnsMapper } from '@/core/mapper/action-plan-user-preference-columns';
import { ActionPlanGetUserPreferenceColumnsRequestDTO } from '@/core/dto/action-plan-get-user-preference-columns';
import { ActionPlanPdfEditRequestDTO } from '@/core/dto/action-plan-pdf-edit';

class ActionPlan extends BaseService<any> {
	constructor(public readonly basePath: string = '/action_plan') {
		super();
	}

	async delayed(params: ActionPlanDelayedRequestDTO): Promise<ActionPlanDelayed> {
		const url = this.basePath + '/report/count-delayed';
		const { data } = await this.getInstance().get<ActionPlanDelayedDTO>(url, { params });
		return new ActionPlanDelayedMapper(data).toDomain();
	}

	async countByStatus(params: ActionPlanCountStatusRequestDTO): Promise<ActionPlanCountStatus> {
		const url = this.basePath + '/report/count-by-status';
		const { data } = await this.getInstance().get<ActionPlanCountStatusDTO>(url, { params });
		return new ActionPlanCountStatusMapper(data).toDomain();
	}

	async list(params: ActionPlanListRequestDTO): Promise<ActionPlanListDomain> {
		const url = this.basePath + '/v2/';
		const { data } = await this.getInstance().get<ActionPlanListDTO>(url, { params });
		return new ActionPlanListMapper(data).toDomain();
	}

	async findAllForBoard(params: ActionPlanCardListRequestDTO): Promise<ActionPlanCardList> {
		const url = this.basePath + '/v2/board';
		const { data } = await this.getInstance().get<ActionPlanCardListResponseDTO>(url, { params });
		return data;
	}

	async findAllByReport(params: any): Promise<any> {
		const url = this.basePath + '/v2/report/all';
		const { data } = await this.getInstance().get<any>(url, { params });
		return data;
	}

	async findOne(params: BasicInfo): Promise<ActionPlanResponseDTO> {
		const url = this.basePath + `/v2/${params.id}`;
		const { data } = await this.getInstance().get<ActionPlanResponseDTO>(url, { params });
		return data;
	}

	async findOneFromReport(params: ActionPlanFromReportRequestDTO): Promise<ActionPlanFromReportResponseDTO> {
		const url = this.basePath + `/v2/report`;
		const { data } = await this.getInstance().get<ActionPlanFromReportResponseDTO>(url, { params });
		return data;
	}

	async create(params: CreateActionPlanRequestDTO): Promise<CreateActionPlanResponseDTO> {
		const url = this.basePath + '/v2';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async createFromReport(
		params: CreateActionPlanFromReportRequestDTO
	): Promise<CreateActionPlanFromReportResponseDTO> {
		const url = this.basePath + '/v2/report';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async update(params: UpdateActionPlanRequestDTO): Promise<UpdateActionPlanResponseDTO> {
		const url = this.basePath + `/v2/${params.id}`;
		const { data } = await this.getInstance().put<UpdateActionPlanResponseDTO>(url, params);
		return data;
	}

	async origins(params: ActionPlanOriginRequestDTO): Promise<ActionPlanOriginResponseDTO> {
		const url = this.basePath + '/v2/origin';
		const { data } = await this.getInstance().get<ActionPlanOriginResponseDTO>(url, { params });
		return data;
	}

	async attachment(params: AddAttachmentRequestDTO): Promise<AddAttachmentResponseDTO> {
		const url = this.basePath + '/v2/attachment';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async getAttachments(params: GetAttachmentsRequestDTO): Promise<UploadFileItem[]> {
		const url = this.basePath + '/v2/attachment';
		const { data } = await this.getInstance().get(url, { params });
		return new ActionPlanAttachmentsMapper(data).toDomain();
	}

	async updateAttachment(params: UpdateAttachmentRequestDTO): Promise<UpdateAttachmentResponseDTO> {
		const url = this.basePath + '/v2/attachment';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async deleteAttachment(params: DeleteAttachmentRequestDTO): Promise<DeleteAttachmentResponseDTO> {
		const url = this.basePath + '/v2/attachment';
		const { data } = await this.getInstance().delete(url, { params });
		return data;
	}

	async getRelatedReports(params: GetRelatedReportsRequestDTO): Promise<GetRelatedReportsResponseDTO[]> {
		const url = this.basePath + '/v2/related-report';
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}

	async setRelatedReports(params: SetRelatedReportsRequestDTO): Promise<SetRelatedReportsResponseDTO> {
		const url = this.basePath + '/v2/related-report';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async getHistory(params: ActionPlanHistoryRequestDTO): Promise<ActionPlanHistoryDomain> {
		const url = this.basePath + '/v2/history';
		const { data } = await this.getInstance().get<ActionPlanHistoryDTO>(url, { params });
		return new ActionPlanHistoryMapper(data).toDomain();
	}

	async getComments(params: ActionPlanCommentsRequestDTO): Promise<ActionPlanCommentsDomain> {
		const url = this.basePath + '/v2/comments';
		const { data } = await this.getInstance().get<ActionPlanCommentsDTO>(url, { params });
		return new ActionPlanCommentsMapper(data).toDomain();
	}

	async downloadPdfEdit(params: ActionPlanPdfEditRequestDTO): Promise<string> {
		const url = this.basePath + `/v2/${params.id}/pdf`;
		const { data } = await this.getInstance().post<string>(url, params);
		return data;
	}

	async createComments(params: ActionPlanCreateCommentsRequestDTO): Promise<ActionPlanCreateCommentsDomain> {
		const url = this.basePath + '/v2/comment';
		const { data } = await this.getInstance().post<ActionPlanCreateCommentsDTO>(url, params);
		return new ActionPlanCreateCommentsMapper(data).toDomain();
	}

	async updateComments(params: ActionPlanUpdateCommentsRequestDTO): Promise<ActionPlanUpdateCommentsDomain> {
		const url = this.basePath + '/v2/comment';
		const { data } = await this.getInstance().put<ActionPlanUpdateCommentsDTO>(url, params);
		return new ActionPlanUpdateCommentsMapper(data).toDomain();
	}

	async deleteComments(params: ActionPlanDeleteCommentsRequestDTO): Promise<ActionPlanDeleteCommentsDomain> {
		const url = this.basePath + '/v2/comment';
		const { data } = await this.getInstance().delete<ActionPlanDeleteCommentsDTO>(url, { params });
		return new ActionPlanDeleteCommentsMapper(data).toDomain();
	}

	async updateCardRank(params: UpdateCardRankRequestDTO): Promise<UpdateCardRankResponseDTO> {
		const url = this.basePath + '/v2/update-rank';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async bulkDelete(params: ActionPlanCardBulkDeleteDTO): Promise<ActionPlanCardBulkDeleteResponseDTO> {
		const url = this.basePath + '/v2/';
		const { data } = await this.getInstance().delete(url, { params });
		return data;
	}

	async bulkUpdate(params: ActionPlanCardBulkUpdateDTO): Promise<ActionPlanCardBulkUpdateResponseDTO> {
		const url = this.basePath + '/v2/bulk-update';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async createPreferenceColumns(
		params: ActionPlanCreateUserPreferenceColumnsRequestDTO
	): Promise<ActionPlanCreateUserPreferenceColumnsDomain> {
		const url = this.basePath + '/v2/user-preference/columns';
		const { data } = await this.getInstance().post<ActionPlanCreateUserPreferenceColumnsDTO>(url, params);
		return new ActionPlanCreateUserPreferenceColumnsMapper(data).toDomain();
	}

	async getUserPreferenceColumns(params: ActionPlanGetUserPreferenceColumnsRequestDTO): Promise<string[]> {
		const url = this.basePath + '/v2/user-preference/columns';
		const { data } = await this.getInstance().get<string[]>(url, { params });
		return data;
	}

	async countAll(params: ActionPlanCountRequestDTO): Promise<ActionPlanCountResponseDTO> {
		const url = this.basePath + '/v2/count-all';
		const { data } = await this.getInstance().get<ActionPlanCountResponseDTO>(url, { params });
		return data;
	}

	async getTasks(params: ActionPlanTasksRequestDTO): Promise<ActionPlanTasksResponseDTO[]> {
		const url = this.basePath + '/v2/task';
		const { data } = await this.getInstance().get<ActionPlanTasksResponseDTO[]>(url, { params });
		return data;
	}

	async createTask(params: ActionPlanCreateTasksRequestDTO): Promise<ActionPlanCreateTasksResponseDTO> {
		const url = this.basePath + '/v2/task';
		const { data } = await this.getInstance().post<ActionPlanCreateTasksResponseDTO>(url, params);
		return data;
	}

	async updateTask(params: ActionPlanUpdateTaskRequestDTO): Promise<ActionPlanUpdateTaskResponseDTO> {
		const url = this.basePath + '/v2/task';
		const { data } = await this.getInstance().put<ActionPlanUpdateTaskResponseDTO>(url, params);
		return data;
	}

	async updateTaskStatus(
		params: ActionPlanUpdateTaskStatusRequestDTO
	): Promise<ActionPlanUpdateTaskStatusResponseDTO> {
		const url = this.basePath + '/v2/task/completed';
		const { data } = await this.getInstance().put<ActionPlanUpdateTaskStatusResponseDTO>(url, params);
		return data;
	}

	async deleteTask(params: ActionPlanDeleteTaskRequestDTO): Promise<ActionPlanDeleteTaskResponseDTO> {
		const url = this.basePath + '/v2/task';
		const { data } = await this.getInstance().delete<ActionPlanDeleteTaskResponseDTO>(url, { params });
		return data;
	}

	async getNotifications(): Promise<string[]> {
		const url = this.basePath + '/v2/user-preference/notification';
		const { data } = await this.getInstance().get<string[]>(url);
		return data;
	}

	async updateNotification(
		params: ActionPlanUpdateNotificationRequestDTO
	): Promise<ActionPlanUpdateNotificationResponseDTO> {
		const url = this.basePath + '/v2/user-preference/notification';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}
	async getEvidences(params: GetEvidencesRequestDTO): Promise<UploadFileItem[]> {
		const url = this.basePath + '/v2/task/attachment';
		const { data } = await this.getInstance().get(url, { params });
		return new ActionPlanAttachmentsMapper(data).toDomain();
	}

	async addEvidence(params: AddEvidenceRequestDTO): Promise<AddEvidenceResponseDTO> {
		const url = this.basePath + '/v2/task/attachment';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async deleteEvidence(params: DeleteEvidenceRequestDTO): Promise<DeleteEvidenceResponseDTO> {
		const url = this.basePath + '/v2/task/attachment';
		const { data } = await this.getInstance().delete(url, { params });
		return data;
	}

	async updateEvidence(params: UpdateEvidenceRequestDTO): Promise<UpdateEvidenceResponseDTO> {
		const url = this.basePath + '/v2/task/attachment';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async getAttachmentDownloadUrl(params: ActionPlanAttachmentDownloadUrlRequestDTO): Promise<string> {
		const url = this.basePath + '/v2/attachment/download';
		const { data } = await this.getInstance().get<string>(url, { params });
		return data;
	}

	async getTaskAttachmentDownloadUrl(params: ActionPlanTaskAttachmentDownloadUrlRequestDTO): Promise<string> {
		const url = this.basePath + '/v2/task/attachment/download';
		const { data } = await this.getInstance().get<string>(url, { params });
		return data;
	}

	async getOriginOptions(): Promise<OriginOptionResponseDTO[]> {
		const url = this.basePath + '/v2/origin/option';
		const { data } = await this.getInstance().get<OriginOptionResponseDTO[]>(url);
		return data;
	}
}

const Service = Object.freeze(new ActionPlan());
export { Service };
