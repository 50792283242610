import React from 'react';
import { Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';

import { Title } from '@/components/Typography';
import { unShortenUrl } from '@/views/Dashboard/utils';
import { Card } from '@/views/Dashboard/components/Card';
import { Single } from '@/views/Dashboard/components/Filters/Single';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetSeraSumRPN } from '@/views/Dashboard/hooks/useGetSeraSumRpn';
import { StyledDivPopOver } from '@/views/Dashboard/styles';
import * as S from './styles';

interface ChecklistReportsProps {
	handleClear(fieldNames: string[]): void;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const { useFormInstance } = Form;

export function SeraSumRPN({ handleClear }: ChecklistReportsProps) {
	const { organization } = useApplicationContext();
	const location = useLocation();
	const form = useFormInstance();
	const query = useQuery();

	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');

	const unshortenUrl = unShortenUrl(location.search.substring(1));
	const seraSumRpnParams = unshortenUrl?.sera_sum_rpn ?? unshortenUrl?.general?.unique_period;
	const filterParams = {
		organization_id: organization?.id!,
		line_id: line_id ?? undefined,
		sector_id: sector_id ?? undefined,
		company_id: company_id ?? undefined,
		workstation_id: workstation_id ?? undefined,
		end_date: seraSumRpnParams?.end_date ?? undefined,
		start_date: seraSumRpnParams?.start_date ?? undefined
	};

	const { refetch, isError, isLoading, data } = useGetSeraSumRPN({
		...filterParams
	});

	function handleOnSubmit() {
		form.submit();
	}

	const isEmpty = !data || !data?.sum_rpn || data?.sum_rpn === 0 || data?.sum_rpn?.toString() === '0';

	function getDisplayDate() {
		return {
			start: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(seraSumRpnParams?.start_date),
			end: Intl.DateTimeFormat(navigator.language, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			}).format(seraSumRpnParams?.end_date)
		};
	}

	return (
		<Card.Container size="small" isLoading={isLoading} isError={isError} onRefetch={refetch}>
			<Card.Header
				hasIcon
				isTitleBold
				hasDisplayDate
				displayDate={getDisplayDate()}
				title="SERA"
				subtitle={I18n.get('Sum of RPN')}
				icon={<CalendarOutlined />}
				popoverComponent={
					<StyledDivPopOver>
						<Single
							hasGranularity={false}
							fieldName={['sera_rpn']}
							handleClear={handleClear}
							handleFormSubmit={handleOnSubmit}
						/>
					</StyledDivPopOver>
				}
			/>
			<Card.Content isEmpty={isEmpty}>
				<Row>
					<S.StyledCol span={24}>
						<Title level={1}>{data?.sum_rpn.toLocaleString(navigator.language)}</Title>
					</S.StyledCol>
				</Row>
			</Card.Content>
		</Card.Container>
	);
}
