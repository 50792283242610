import styled from 'styled-components';
import { Button, Drawer } from 'antd';

export const DrawerWrapper = styled(Drawer)`
	.ant-drawer-mask {
		background: unset;
	}

	.ant-drawer-content-wrapper {
		overflow: hidden;
		position: fixed;
		height: 80vh;
		top: unset;
		bottom: 0;
		right: 0;
	}
`;

export const PrimaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	background-color: #2f54eb;
	box-shadow: 0px 2px 0px 0px #0000000b;
`;

export const SecondaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	box-shadow: 0px 1px 0px 0px #0000000b;
`;
