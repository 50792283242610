import React from 'react';

export function KimppIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
	return (
		<svg viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M3.23 0H0.38C0.171 0 0 0.182143 0 0.404762V33.5952C0 33.8179 0.171 34 0.38 34H3.23C3.439 34 3.61 33.8179 3.61 33.5952V0.404762C3.61 0.182143 3.439 0 3.23 0ZM37.62 0H34.77C34.561 0 34.39 0.182143 34.39 0.404762V33.5952C34.39 33.8179 34.561 34 34.77 34H37.62C37.829 34 38 33.8179 38 33.5952V0.404762C38 0.182143 37.829 0 37.62 0ZM31.9817 16.6104L25.9207 11.5155C25.87 11.473 25.8091 11.4466 25.745 11.4393C25.6808 11.4319 25.616 11.444 25.558 11.4741C25.5 11.5042 25.4511 11.5511 25.417 11.6094C25.3829 11.6678 25.3648 11.7351 25.365 11.8039V15.1786H12.635V12.0012C12.635 11.6976 12.3025 11.5256 12.0792 11.7128L6.01825 16.8128C5.97724 16.8466 5.94403 16.8899 5.92118 16.9394C5.89834 16.989 5.88646 17.0435 5.88646 17.0987C5.88646 17.1539 5.89834 17.2083 5.92118 17.2579C5.94403 17.3075 5.97724 17.3508 6.01825 17.3845L12.0745 22.4845C12.2978 22.6717 12.6302 22.5048 12.6302 22.1961V18.8214H25.3603V21.9988C25.3603 22.3024 25.6927 22.4744 25.916 22.2872L31.9722 17.1872C32.1527 17.0405 32.1527 16.7571 31.9817 16.6104Z"
				fill="#262626"
			/>
		</svg>
	);
}
