import React from 'react';
import { Row, Col, Form } from 'antd';
import {
	Line,
	Origin,
	Status,
	Sector,
	Company,
	DueDate,
	Priority,
	Workstation,
	Responsible,
	CreationDate,
	Organization,
	ActionPlanName,
	InvestmentRange
} from '../components/Fields';
import { Footer } from './Footer';
import { Title } from '@/components/Typography';
import { useActionPlanContext } from '../context';
import { useGetLineOptions } from '@/hooks/v2/useGetLineOptions';
import { useGetOriginOptions } from '@/hooks/v2/useGetOriginOptions';
import { useGetSectorOptions } from '@/hooks/v2/useGetSectorOptions';
import { useGetCompanyOptions } from '@/hooks/v2/useGetCompanyOptions';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useWorkstationOptions } from '@/hooks/v2/useGetWorkstationOptions';
import { useGetResponsableOptions } from '@/hooks/v2/useGetResponsableOptions';
import * as S from './styles';

const { useWatch } = Form;

export function Filter() {
	const { organization, company } = useApplicationContext();
	const { isFilterOpen, onToggleFilter } = useActionPlanContext();

	const fieldName = ['filter'];
	const company_id = useWatch([...fieldName, 'company_id']);
	const sector_id = useWatch([...fieldName, 'sector_id']);
	const line_id = useWatch([...fieldName, 'line_id']);

	const {
		isFetching: isGettingCompanies,
		data: companies,
		isError: hasErrorCompanies
	} = useGetCompanyOptions({
		organization_id: organization?.id,
		company_id: company?.id
	});

	const {
		isFetching: isGettingSectors,
		data: sectors,
		isError: hasErrorSectors
	} = useGetSectorOptions({
		organization_id: organization?.id,
		company_id
	});

	const {
		isFetching: isGettingLines,
		data: lines,
		isError: hasErrorLines
	} = useGetLineOptions({
		organization_id: organization?.id,
		company_id,
		sector_id
	});

	const {
		isFetching: isGettingWorkstations,
		data: workstations,
		isError: hasErrorWorkstations
	} = useWorkstationOptions({
		organization_id: organization?.id,
		company_id,
		line_id
	});

	const {
		isFetching: isGettingUsers,
		data: users,
		isError: hasErrorUsers
	} = useGetResponsableOptions({
		organization_id: organization?.id,
		company_id
	});

	const {
		isFetching: isGettingOriginOptions,
		data: originOptions,
		isError: hasErrorOriginOptions
	} = useGetOriginOptions();

	return (
		<S.CustomDrawer
			width={600}
			placement="right"
			open={isFilterOpen}
			onClose={onToggleFilter}
			title={
				<Title level={3} style={{ margin: 0 }}>
					Filter
				</Title>
			}
		>
			<Row align="middle" gutter={[0, 50]}>
				<Col span={24}>
					<Row gutter={[30, 18]}>
						<Col span={24}>
							<ActionPlanName formName={fieldName} />
						</Col>
						<Col span={12}>
							<Organization
								isDisabled
								formName={fieldName}
								options={[
									{
										label: organization?.name,
										value: organization?.id
									}
								]}
							/>
						</Col>
						<Col span={12}>
							<Company
								options={companies}
								formName={fieldName}
								isLoading={isGettingCompanies}
								isDisabled={hasErrorCompanies}
							/>
						</Col>
						<Col span={12}>
							<Sector
								options={sectors}
								formName={fieldName}
								isLoading={isGettingSectors}
								isDisabled={hasErrorSectors}
							/>
						</Col>
						<Col span={12}>
							<Line
								options={lines}
								formName={fieldName}
								isLoading={isGettingLines}
								isDisabled={hasErrorLines}
							/>
						</Col>
						<Col span={12}>
							<Workstation
								formName={fieldName}
								options={workstations}
								isLoading={isGettingWorkstations}
								isDisabled={hasErrorWorkstations}
							/>
						</Col>
						<Col span={12}>
							<Responsible
								options={users}
								formName={fieldName}
								isLoading={isGettingUsers}
								isDisabled={hasErrorUsers || !company_id}
							/>
						</Col>
						<Col span={12}>
							<CreationDate formName={fieldName} />
						</Col>
						<Col span={12}>
							<DueDate type="range" formName={fieldName} />
						</Col>
						<Col span={12}>
							<Priority formName={fieldName} />
						</Col>
						<Col span={12}>
							<Origin
								formName={fieldName}
								options={originOptions}
								isLoading={isGettingOriginOptions}
								isDisabled={hasErrorOriginOptions}
							/>
						</Col>
						<Col span={12}>
							<Status formName={fieldName} />
						</Col>
						<Col span={12}>
							<InvestmentRange formName={fieldName} />
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Footer formName={fieldName} />
				</Col>
			</Row>
		</S.CustomDrawer>
	);
}
