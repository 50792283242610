import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';

interface GetHistoryParams {
	organization_id: string;
	company_id: string;
	action_plan_id: string;
}

export const getHistory = (params: GetHistoryParams) => {
	return Service.getHistory(params);
};

export const useGetHistoryActionPlan = (params: GetHistoryParams) => {
	return useQuery([QUERY_KEYS.GET_ACTION_PLAN_HISTORY, params.action_plan_id], () => Service.getHistory(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
