import { message } from 'antd';
import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Response } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';

interface DeleteTaskRequest {
	organization_id: string;
	action_plan_task_id: string;
	company_id: string;
}

interface DeleteTaskResponse {
	status: string;
	message: string;
	data?: { id: string };
}

export const useDeleteActionPlanTask = () => {
	const queryClient = useQueryClient();

	return useMutation<DeleteTaskResponse, AxiosError<Response>, DeleteTaskRequest>(
		(params) => Service.deleteTask(params),
		{
			onError: (err) => {
				message.error(I18n.get(err?.response?.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_TASKS]);
				message.success(I18n.get('Task deleted successfully'));
			}
		}
	);
};
