import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { useHistory, useLocation } from 'react-router-dom';
import { getGridSize } from '../helpers';
import { GridSkeleton } from './GridSkeleton';
import { MosaicComponent } from './MosaicComponent';
import { useMosaicDashboardContext } from '../context';
import { PaginationCustom } from '@/components/ui/Pagination';
import { useGetMosaic } from '@/views/Dashboard/hooks/useGetMosaic';
import { handleBackClick } from '../Navigation/navigation-helpers';
import { getEntityNotFoundMessage } from './error-helpers';
import * as S from '../styles';

interface ContentProps {
	handleOnClick: (id: string, entity: string, name: string) => void;
}

export function Content({ handleOnClick }: Readonly<ContentProps>) {
	const history = useHistory();
	const location = useLocation();
	const { params, handleSetErrorWarning } = useMosaicDashboardContext();
	const { isLoading, data, isError } = useGetMosaic(params);

	useEffect(() => {
		if (isError) {
			handleSetErrorWarning(I18n.get('Unable to load data'), 'error');
			setTimeout(() => {
				handleBackClick(location, history);
			}, 1000);
		}

		if (!isError && data?.rows?.length === 0) {
			const message = getEntityNotFoundMessage(location);
			handleSetErrorWarning(message, 'warning');
			setTimeout(() => {
				handleBackClick(location, history);
			}, 1000);
		}
	}, [data?.rows, isError, handleSetErrorWarning, history, location]);

	const N = data?.rows?.length;
	const { columns, rows } = getGridSize(N);

	function handleOnPageChange(page: number): void {
		const searchParams = new URLSearchParams(location.search);
		searchParams.set('offset', String(page));
		const url = `${location.pathname}?${searchParams.toString()}`;
		history.push(url);
	}

	if (isLoading) {
		return (
			<S.ContentWrapper>
				<GridSkeleton />
			</S.ContentWrapper>
		);
	}

	return (
		<>
			<S.ContentWrapper>
				<S.MosaicGrid columns={columns} rows={rows}>
					{data?.rows?.map((item) => (
						<MosaicComponent key={item.id} item={item} entity={data.entity} onClick={handleOnClick} />
					))}
				</S.MosaicGrid>
			</S.ContentWrapper>
			<PaginationCustom
				loading={isLoading}
				maxPage={params.limit}
				total={data?.total || 0}
				page={params.offset || 1}
				setPage={handleOnPageChange}
			/>
		</>
	);
}
