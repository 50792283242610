import styled from 'styled-components';
import { Row, Col } from 'antd';
import Icon from '@ant-design/icons';

const NR17_COLOR = '#9CB5FA';
const NR17_JDS_D86_COLOR = '#4F7CD2';
const JDS_D86_COLOR = '#2B51EA';
const JDS_D92_COLOR = '#0C1538';

export const colors: string[] = [NR17_COLOR, NR17_JDS_D86_COLOR, JDS_D86_COLOR, JDS_D92_COLOR];

export const StyledCol = styled(Col)`
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
	justify-content: center;

	p {
		margin: 0;
		font-size: 1rem;
		span {
			font-weight: 600;
		}
	}
`;

export const StyledRow = styled(Row)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const IconWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CustomIcon = styled(Icon)`
	font-size: 2rem;
	margin-right: 0.5rem;
`;

export const IconTextWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
