import { BaseService } from './base-service';
import { CompanyDTO, GetCompanyOptionsParamsDTO } from '@/core/dto/company';

class CompanyService extends BaseService<CompanyDTO> {
	constructor(public readonly basePath: string = '/company') {
		super();
	}

	async listOptions(params: GetCompanyOptionsParamsDTO): Promise<CompanyDTO[]> {
		const url = this.basePath + `/list`;
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}
}

const Service = Object.freeze(new CompanyService());
export { Service };
