interface SeraComparisonAttributes {
	name: string;
	id: string;
	total_rpn: number;
}

export class SeraComparison {
	public readonly name: string;
	public readonly id: string;
	public readonly total_rpn: number;

	constructor({ name, id, total_rpn }: SeraComparisonAttributes) {
		this.name = name;
		this.id = id;
		this.total_rpn = total_rpn;
	}
}
