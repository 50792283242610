import styled from 'styled-components';
import { Col } from 'antd';

const DONE_COLOR = '#17A93B';
const DOING_COLOR = '#EFAC1E';
const NOT_STARTED_COLOR = '#E80707';
const DONE_COLOR_SECONDARY = '#f9dea5';
const DOING_COLOR_SECONDARY = '#f9dea5';
const NOT_STARTED_COLOR_SECONDARY = '#f69c9c';

const BACKGROUND_DONE = 'rgba(23, 169, 59, 0.2)';
const BACKGROUND_DOING_COLOR = 'rgba(239, 172, 30, 0.2)';
const BACKGROUND_NOT_STARTED_COLOR = 'rgba(232, 7, 7, 0.2)';

const BORDER_DONE = 'rgba(23, 169, 59, 0.7)';
const BORDER_DOING_COLOR = 'rgba(239, 172, 30, 0.7)';
const BORDER_NOT_STARTED_COLOR = 'rgba(232, 7, 7, 0.7)';

const BORDER_DONE_SECONDARY = 'rgba(75, 192, 192, 1)';
const BORDER_DOING_COLOR_SECONDARY = 'rgba(255, 205, 86, 1)';
const BORDER_NOT_STARTED_COLOR_SECONDARY = 'rgba(255, 99, 132, 1)';

export const COLORS = [NOT_STARTED_COLOR, DOING_COLOR, DONE_COLOR];
export const INDISDE_COLORS = [NOT_STARTED_COLOR_SECONDARY, DOING_COLOR_SECONDARY, DONE_COLOR_SECONDARY];

export const BACKGROUND_COLORS = [BACKGROUND_NOT_STARTED_COLOR, BACKGROUND_DOING_COLOR, BACKGROUND_DONE];
export const BORDER_COLORS = [BORDER_NOT_STARTED_COLOR, BORDER_DOING_COLOR, BORDER_DONE];
export const BORDER_COLORS_SECONDARY = [
	BORDER_NOT_STARTED_COLOR_SECONDARY,
	BORDER_DOING_COLOR_SECONDARY,
	BORDER_DONE_SECONDARY
];

export const StyledCol = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		font-size: 1rem;
	}
`;
