import * as React from 'react';

interface FlagProps {
	width?: string;
	height?: string;
}

export const Flag = ({ width = '1rem', height = '1rem' }: Readonly<FlagProps>) => {
	return (
		<svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.11719 16.9995L1.11719 1.11719" stroke="#262626" strokeWidth="2" strokeLinecap="round" />
			<path
				d="M1.11719 2.29298C1.11719 2.29298 3.2102 2.97341 4.59599 3.0821C7.66654 3.32291 9.20214 0.809434 12.2643 1.14875C13.8189 1.32102 16.1172 2.29298 16.1172 2.29298V10.8231C16.1172 10.8231 14.1269 9.79369 12.7506 9.52102C9.55184 8.88727 7.82647 11.754 4.59599 11.336C3.19967 11.1553 1.11719 10.3496 1.11719 10.3496"
				stroke="#262626"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
