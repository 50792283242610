import styled from 'styled-components';
import { Modal, Button, DatePicker, Radio, Select, Form } from 'antd';

const { Group } = Radio;

export const ModalWrapper = styled(Modal)`
	.ant-modal-body {
		padding: 2rem 3rem;
	}

	.ant-modal-content {
		border-radius: 30px;
		background-color: #ffffff;
	}

	.ant-modal-close-x {
		margin: 0.7rem 0.7rem 0 0;
		font-size: 0.9rem;
	}
`;

export const StyledDatePicker = styled(DatePicker)`
	border-radius: 6px;
`;

export const StyledRadioGroup = styled(Group)`
	width: 100%;
	height: 45px;
	display: flex;
	overflow: hidden;
	border-radius: 8px;
	flex-direction: row;
	border: 1px solid #d9d9d9;

	.ant-radio-button-wrapper {
		width: 18%;
		height: 100%;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			white-space: nowrap;

			&:last-child {
				font-size: 0.9rem;
			}
		}
	}

	.ant-radio-button-wrapper:focus-within {
		color: #fff;
		box-shadow: none;
		background-color: #2f54eb;
	}

	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		color: #fff;
		box-shadow: none;
		border-radius: 6px;
		background-color: #2f54eb;

		&::before {
			background: none;
		}
	}
`;

export const PrimaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	background-color: #2f54eb;
	border: 1px solid #2f54eb;
	box-shadow: 0px 2px 0px 0px #0000000b;
`;

export const SecondaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	box-shadow: 0px 1px 0px 0px #0000000b;
`;

export const CustomSelect = styled(Select)`
	.ant-select-selector {
		border-radius: 6px !important;
	}
`;

export const CustomFormItem = styled(Form.Item)`
	.ant-form-item-label {
		padding: 0;
	}
`;
