interface StrainIndexRSIItemAttributes {
	description: string;
	percentage: number;
}

interface StrainIndexRSIAttributes {
	items: StrainIndexRSIItem[];
	total: number;
}

export class StrainIndexRSIItem {
	public readonly description: string;
	public readonly percentage: number;

	constructor({ description, percentage }: StrainIndexRSIItemAttributes) {
		this.description = description;
		this.percentage = percentage;
	}
}

export class StrainIndexRSI {
	public readonly items: StrainIndexRSIItem[];
	public readonly total: number;

	constructor({ items, total }: StrainIndexRSIAttributes) {
		this.items = items;
		this.total = total;
	}
}
