import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Service } from '@/infra/services/action-plan';
import { UpdateEvidenceResponseDTO } from '@/core/dto/action-plan-evidences';
import type { Response } from '@/types';

interface UpdateEvidenceParams {
	organization_id: string;
	company_id: string;
	action_plan_task_attachment_id: string;
	description: string;
}

export const useUpdateEvidence = () => {
	return useMutation<UpdateEvidenceResponseDTO, AxiosError<Response>, UpdateEvidenceParams>((params) =>
		Service.updateEvidence(params)
	);
};
