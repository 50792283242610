import React from 'react';
import { Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import { AEPChecklist } from '@/assets/icons/dashboard/aep-icon-checklist';
import { AEPJDSChecklist } from '@/assets/icons/dashboard/aep-jds-icon-checklist';
import { JDSD86Checklist } from '@/assets/icons/dashboard/jds-d86-icon-checklist';
import { JDSD92Checklist } from '@/assets/icons/dashboard/jds-d92-icon-checklist';
import * as S from './styles';

interface LabelItem {
	id: number;
	icon: React.FC;
	text: string;
}

const labels: LabelItem[] = [
	{ id: 0, icon: AEPChecklist, text: 'PEA (NR17)' },
	{ id: 1, icon: AEPJDSChecklist, text: 'PEA (NR17 + JDS D86)' },
	{ id: 2, icon: JDSD86Checklist, text: 'JDS-D86' },
	{ id: 3, icon: JDSD92Checklist, text: 'JDS-D92' }
];

export function Labels() {
	return (
		<Row>
			{labels.map((item) => (
				<S.StyledCol key={item.id} span={6}>
					<S.IconTextWrapper>
						<S.CustomIcon component={item.icon} />
						<p>{I18n.get(item.text)}</p>
					</S.IconTextWrapper>
				</S.StyledCol>
			))}
		</Row>
	);
}
