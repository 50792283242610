import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { DrawerWrapper } from './Drawer';
import { SwitchWrapper } from './Switch';
import { Title } from '@/components/Typography';
import { useActionPlanContext } from '../context';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetNotificationsActionPlan } from '@/hooks/v2/useGetNotificationsActionPlan';
import { useUpdateNotificationActionPlan } from '@/hooks/v2/useUpdateNotificationActionPlan';
import * as S from './styles';

const { useForm } = Form;

type Preference =
	| 'create_action'
	| 'author_action_plan_status_changed'
	| 'author_action_plan_task_completed'
	| 'author_action_plan_due_date_nearest'
	| 'responsible_action_plan_due_date_nearest';

interface InitialFormValues {
	[key: string]: boolean | undefined;
	created_by_me: boolean | undefined;
	assigned_to_me: boolean | undefined;
}

interface FormData {
	create_action: boolean;
	created_by_me: boolean;
	assigned_to_me: boolean;
	author_action_plan_status_changed: boolean;
	author_action_plan_task_completed: boolean;
	author_action_plan_due_date_nearest: boolean;
	responsible_action_plan_due_date_nearest: boolean;
}

export function DrawerNotifications() {
	const [form] = useForm<FormData>();
	const { onToggleNotifications } = useActionPlanContext();
	const { organization, company } = useApplicationContext();
	const { data: dataNotification } = useGetNotificationsActionPlan();
	const { isLoading, mutateAsync: updateNotification } = useUpdateNotificationActionPlan();

	const initialFormValues: InitialFormValues = {
		created_by_me: dataNotification?.some((key) =>
			[
				'author_action_plan_status_changed',
				'author_action_plan_task_completed',
				'author_action_plan_due_date_nearest'
			].includes(key)
		),
		assigned_to_me: dataNotification?.some((key) =>
			['create_action', 'responsible_action_plan_due_date_nearest'].includes(key)
		)
	};

	dataNotification?.forEach((key) => {
		initialFormValues[key] = true;
	});

	function onChangeSubordinate(): void {
		const values = form.getFieldsValue() as FormData;

		const areAuthorSwitchesOff = [
			values.author_action_plan_status_changed,
			values.author_action_plan_task_completed,
			values.author_action_plan_due_date_nearest
		].every((switchValue) => !switchValue);

		const areAuthorSwitchesOn = [
			values.author_action_plan_status_changed,
			values.author_action_plan_task_completed,
			values.author_action_plan_due_date_nearest
		].some((switchValue) => switchValue);

		const areResponsibleSwitchesOff = [values.create_action, values.responsible_action_plan_due_date_nearest].every(
			(switchValue) => !switchValue
		);

		const areResponsibleSwitchesOn = [values.create_action, values.responsible_action_plan_due_date_nearest].some(
			(switchValue) => switchValue
		);

		if (areAuthorSwitchesOff) {
			form.setFieldValue('created_by_me', false);
		}
		if (areAuthorSwitchesOn) {
			form.setFieldValue('created_by_me', true);
		}

		if (areResponsibleSwitchesOff) {
			form.setFieldValue('assigned_to_me', false);
		}

		if (areResponsibleSwitchesOn) {
			form.setFieldValue('assigned_to_me', true);
		}
	}

	function onChangeCreatedByMe(checked: boolean): void {
		form.setFieldValue('author_action_plan_status_changed', checked);
		form.setFieldValue('author_action_plan_task_completed', checked);
		form.setFieldValue('author_action_plan_due_date_nearest', checked);
	}

	function onChangeAssignedToMe(checked: boolean): void {
		form.setFieldValue('create_action', checked);
		form.setFieldValue('responsible_action_plan_due_date_nearest', checked);
	}

	function handleOnSubmit(): void {
		const values = form.getFieldsValue();

		const allowedPreferences: Preference[] = [
			'author_action_plan_status_changed',
			'author_action_plan_task_completed',
			'author_action_plan_due_date_nearest',
			'create_action',
			'responsible_action_plan_due_date_nearest'
		];

		updateNotification({
			organization_id: organization?.id!,
			company_id: company?.id!,
			preferences: allowedPreferences.filter((key) => values[key])
		});
	}

	return (
		<DrawerWrapper>
			<Form
				form={form}
				layout="vertical"
				onFinish={handleOnSubmit}
				initialValues={initialFormValues}
				style={{ width: '100%' }}
			>
				<Row justify="space-between" gutter={[16, 50]}>
					<Col span={24}>
						<Row justify="space-between">
							<Col>
								<Title level={4} style={{ margin: 0 }}>
									{I18n.get('Notification Control')}
								</Title>
							</Col>
							<Col>
								<Button type="text" icon={<CloseOutlined />} onClick={onToggleNotifications} />
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Row gutter={[0, 40]}>
							<Col span={24}>
								<Row gutter={[0, 28]} justify="end">
									<Col span={24}>
										<Form.Item name="created_by_me" valuePropName="checked" noStyle>
											<SwitchWrapper
												disabled
												labelBold
												onChange={onChangeCreatedByMe}
												label={I18n.get('Follow up action plans created by me')}
											/>
										</Form.Item>
									</Col>
									<Col span={23}>
										<Form.Item
											name="author_action_plan_status_changed"
											valuePropName="checked"
											noStyle
										>
											<SwitchWrapper
												disabled
												size="small"
												onChange={onChangeSubordinate}
												label={I18n.get(
													'Receive an e-mail when the status of the action plan changes'
												)}
											/>
										</Form.Item>
									</Col>
									<Col span={23}>
										<Form.Item
											name="author_action_plan_task_completed"
											valuePropName="checked"
											noStyle
										>
											<SwitchWrapper
												size="small"
												disabled
												onChange={onChangeSubordinate}
												label={I18n.get('Receive e-mail when tasks are completed')}
											/>
										</Form.Item>
									</Col>
									<Col span={23}>
										<Form.Item
											name="author_action_plan_due_date_nearest"
											valuePropName="checked"
											noStyle
										>
											<SwitchWrapper
												size="small"
												disabled
												onChange={onChangeSubordinate}
												label={I18n.get('Receive an e-mail when the due date is near')}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[0, 28]} justify="end">
									<Col span={24}>
										<Form.Item name="assigned_to_me" valuePropName="checked" noStyle>
											<SwitchWrapper
												disabled
												labelBold
												onChange={onChangeAssignedToMe}
												label={I18n.get('Follow up on action plans assigned to me')}
											/>
										</Form.Item>
									</Col>
									<Col span={23}>
										<Form.Item name="create_action" valuePropName="checked" noStyle>
											<SwitchWrapper
												size="small"
												onChange={onChangeSubordinate}
												label={I18n.get(
													'Receive an e-mail when a new action plan is assigned to me'
												)}
											/>
										</Form.Item>
									</Col>
									<Col span={23}>
										<Form.Item
											name="responsible_action_plan_due_date_nearest"
											valuePropName="checked"
											noStyle
										>
											<SwitchWrapper
												disabled
												size="small"
												onChange={onChangeSubordinate}
												label={I18n.get('Receive an e-mail when the due date is near')}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Row justify="center" gutter={[12, 0]}>
							{/* <Col>
								<S.SecondaryButton onClick={onToggleNotifications}>
									{I18n.get('Cancel')}
								</S.SecondaryButton>
							</Col> */}
							<Col>
								<S.PrimaryButton type="primary" loading={isLoading} onClick={handleOnSubmit}>
									{I18n.get('Save')}
								</S.PrimaryButton>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</DrawerWrapper>
	);
}
