import React from 'react';
import { I18n } from '@aws-amplify/core';
import type { FormFieldProps } from '../../types';
import * as S from './styles';

export function ActionPlanName({ formName = [''], isRequired }: Readonly<FormFieldProps>) {
	const title = I18n.get('Action plan name');
	const errorMessage = I18n.get('Enter the action plan name');

	return (
		<S.CustomFormItem
			label={title}
			required={isRequired}
			labelCol={{ span: 24 }}
			name={[...formName, 'action_plan_name']}
			rules={[{ required: isRequired, message: errorMessage }]}
		>
			<S.CustomTextInput maxLength={150} placeholder={title} />
		</S.CustomFormItem>
	);
}
