import styled from 'styled-components';
import { Row, Col } from 'antd';

export const Container = styled(Row)`
	padding: 0 0.5rem;
	font-size: 1.1rem;
	border-left: 1px solid #26262680;
`;

export const ScoreTag = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	width: 3.5rem;
	font-size: 1.1rem;
	font-weight: 600;
	color: #ffffff;
	background-color: #2F54EB;
`;

export const IconWrapper = styled(Col)`
	height: 100%;
	display: flex;
	align-items: center;
	margin-right: 0.3rem;

	svg {
		width: 0.7rem;
	}
`;
