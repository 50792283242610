interface ActionPlanUpdateCommentsItemAttributes {
	id: string;
}

export class ActionPlanUpdateComments {
	public readonly id: string;

	constructor({ id }: ActionPlanUpdateCommentsItemAttributes) {
		this.id = id;
	}
}

export interface ActionPlanUpdateCommentsDomain {
	status: string;
	message: string;
	data: ActionPlanUpdateComments[];
}
