import { QUERY_KEYS } from '../../utils/query-keys';
import { useQuery } from '@tanstack/react-query';
import { Service } from '../../infra/services/action-plan';

export interface GetActionPlansParams {
	company_id: string;
	organization_id: string;
	sera_summary_review_id: string;
	file_id: string;
}

export const useGetActionPlansByReport = (params: GetActionPlansParams) => {
	return useQuery([QUERY_KEYS.GET_ACTION_PLANS_BY_REPORT, params], () => Service.findAllByReport(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
