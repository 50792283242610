import React from 'react';
import { Row } from 'antd';
import { AEPChecklist } from '@/assets/icons/dashboard/aep-icon-checklist';
import { AEPJDSChecklist } from '@/assets/icons/dashboard/aep-jds-icon-checklist';
import { JDSD86Checklist } from '@/assets/icons/dashboard/jds-d86-icon-checklist';
import { JDSD92Checklist } from '@/assets/icons/dashboard/jds-d92-icon-checklist';
import * as S from './styles';

interface ReportTotal {
	label: string;
	total: number;
}

interface TotalizerData {
	overallTotal: number;
	reportTotals: ReportTotal[];
}

interface TotalizerProps {
	totalizerData: TotalizerData;
}

export function Totalizer({ totalizerData }: TotalizerProps) {
	const { overallTotal, reportTotals } = totalizerData;

	const iconMap: Record<string, React.FC> = {
		ewa: AEPChecklist,
		ewa_d86: AEPJDSChecklist,
		jds_d86: JDSD86Checklist,
		jds_d92: JDSD92Checklist
	};

	return (
		<Row>
			<S.StyledCol span={24}>
				<p>
					<span>Total:</span> {overallTotal.toLocaleString(navigator.language)}
				</p>
			</S.StyledCol>
			{reportTotals.map((report, index) => (
				<S.StyledCol span={24} key={index}>
					<S.IconTextWrapper>
						<S.CustomIcon component={iconMap[report.label.toLowerCase()]} />
						<p>{report.total.toLocaleString(navigator.language)}</p>
					</S.IconTextWrapper>
				</S.StyledCol>
			))}
		</Row>
	);
}
