import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Service } from '@/infra/services/action-plan';
import { DeleteEvidenceResponseDTO } from '@/core/dto/action-plan-evidences';
import type { Response } from '@/types';

export interface DeleteEvidenceParams {
	organization_id: string;
	company_id: string;
	action_plan_task_attachment_id: string;
}

export const useDeleteEvidenceActionPlan = () => {
	return useMutation<DeleteEvidenceResponseDTO, AxiosError<Response>, DeleteEvidenceParams>((params) =>
		Service.deleteEvidence(params)
	);
};
