import * as React from 'react';

interface JDSD92ChecklistProps {
	width?: string;
	height?: string;
}

export const JDSD92Checklist = ({ width = '1rem', height = '1rem' }: Readonly<JDSD92ChecklistProps>) => {
	return (
		<svg width={width} height={height} viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_d_6997_1722)">
				<path d="M19 4L32.8564 27.25H5.14359L19 4Z" fill="#0C1538" />
				<path d="M6.90369 26.25L19 5.95331L31.0963 26.25H6.90369Z" stroke="#0C1538" strokeWidth="2" />
			</g>
			<defs>
				<filter
					id="filter0_d_6997_1722"
					y="0"
					x="0.140625"
					height="33.25"
					width="37.7188"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						type="matrix"
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset dy="1" />
					<feGaussianBlur stdDeviation="2.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6997_1722" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6997_1722" result="shape" />
				</filter>
			</defs>
		</svg>
	);
};
