import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';

import { Header } from './Header';
import { Item } from './Item/index';
import { RiskList } from './styles';
import { unShortenUrl } from '../../utils';
import { Card } from '../../components/Card';
import { StyledDivPopOver } from '../../styles';
import { Compound } from '../../components/Filters/Compound';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetSeraComparison } from '../../hooks/useGetSeraComparison';

const { useFormInstance } = Form;

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

interface SeraComparisonProps {
	handleClear(fieldNames: string[]): void;
}

export function SeraComparison({ handleClear }: Readonly<SeraComparisonProps>) {
	const { organization } = useApplicationContext();
	const query = useQuery();
	const form = useFormInstance();
	const location = useLocation();

	const workstation_id = query.get('workstation');
	const company_id = query.get('company');
	const line_id = query.get('line');
	const sector_id = query.get('sector');

	const fieldName = 'sera_comparison';
	const urlParams = unShortenUrl(location.search.substring(1));
	const queryFilters = urlParams?.[fieldName] ?? urlParams?.general?.compound_period;

	const defaultParams = {
		organization_id: organization.id,
		company_id: company_id ?? undefined,
		sector_id: sector_id ?? undefined,
		line_id: line_id ?? undefined,
		workstation_id: workstation_id ?? undefined
	};

	const {
		isLoading: isGettingFirstRisks,
		data: firstPeriodRisks,
		isError: isErrorGettingFirstRisks,
		refetch: refetchFirstRisk,
		isRefetching: isRefetchingFirst
	} = useGetSeraComparison({
		...defaultParams,
		start_date: queryFilters?.first_period?.start_date!,
		end_date: queryFilters?.first_period?.end_date!
	});

	const {
		isLoading: isGettingSecondRisks,
		data: secondPeriodRisks,
		isError: isErrorGettingSecondRisks,
		refetch: refetchSecondRisk,
		isRefetching: isRefetchingSecond
	} = useGetSeraComparison({
		...defaultParams,
		start_date: queryFilters?.second_period?.start_date!,
		end_date: queryFilters?.second_period?.end_date!
	});

	const isLoading = isGettingFirstRisks || isGettingSecondRisks || isRefetchingFirst || isRefetchingSecond;
	const isError = isErrorGettingFirstRisks || isErrorGettingSecondRisks;
	const isEmpty = !firstPeriodRisks?.length || !secondPeriodRisks?.length;

	function handleRefetch(): void {
		refetchFirstRisk();
		refetchSecondRisk();
	}

	function handleOnSubmit(): void {
		form.submit();
	}

	const subtitle = `${I18n.get('Comparison of')} ${I18n.get(getHierarchyName())} ${I18n.get('with variation')}`;

	function getHierarchyName(): string {
		if (!!workstation_id) return 'files';
		if (!!line_id) return 'workstations';
		if (!!sector_id) return 'lines';
		if (!!company_id) return 'sectors';
		return 'industrial sites';
	}

	return (
		<Card.Container size="xlarge" isLoading={isLoading} isError={isError} onRefetch={handleRefetch}>
			<Card.Header
				hasIcon
				isTitleBold
				title={I18n.get('SERA')}
				subtitle={subtitle}
				icon={<CalendarOutlined />}
				popoverComponent={
					<StyledDivPopOver>
						<Compound handleClear={handleClear} fieldName={[fieldName]} handleFormSubmit={handleOnSubmit} />
					</StyledDivPopOver>
				}
			/>
			<Card.Content isEmpty={isEmpty}>
				<Row>
					<Col span={24}>
						<Header />
					</Col>
					<Col span={24}>
						<RiskList>
							{firstPeriodRisks &&
								secondPeriodRisks &&
								firstPeriodRisks.map((item, index) => (
									<Col key={index} span={24}>
										<Item
											name={item.name}
											firstValue={item.total_rpn}
											secondValue={secondPeriodRisks[index]?.total_rpn || 0}
										/>
									</Col>
								))}
						</RiskList>
					</Col>
				</Row>
			</Card.Content>
		</Card.Container>
	);
}
