import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';

import { unShortenUrl } from '../../utils';
import { Card } from '../../components/Card';
import { StyledDivPopOver } from '../../styles';
import { DoubleBar } from '../../components/DoubleBarChart';
import { Compound } from '../../components/Filters/Compound';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetNioshRisksCount } from '../../hooks/useGetNioshRisksCount';

const { useFormInstance } = Form;

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

interface RiskEvolutionNioshProps {
	onClear(fieldNames: string[]): void;
}

export function RiskEvolutionNiosh({ onClear }: Readonly<RiskEvolutionNioshProps>) {
	const { organization, company } = useApplicationContext();
	const form = useFormInstance();
	const query = useQuery();
	const location = useLocation();

	const fieldName = `niosh_risk_evolution`;

	const company_id = query.get('company');
	const sector_id = query.get('sector');
	const line_id = query.get('line');
	const workstation_id = query.get('workstation');

	const urlParams = unShortenUrl(location.search.substring(1));
	const queryFilters = urlParams?.[fieldName] ?? urlParams?.general?.compound_period;
	const firstPeriod = queryFilters?.first_period;
	const secondPeriod = queryFilters?.second_period;

	const commomParams = {
		organization_id: organization.id,
		company_id: company_id ?? company.id,
		sector_id: sector_id!,
		line_id: line_id!,
		workstation_id: workstation_id!
	};

	const {
		data: risksFirstPeriod,
		isLoading: isGettingFirstRisksCount,
		refetch: refetchFirstRisksCount,
		isError: hasErrorFirstRisksCount
	} = useGetNioshRisksCount({
		...commomParams,
		start_date: firstPeriod?.start_date,
		end_date: firstPeriod?.end_date
	});
	const {
		data: risksSecondPeriod,
		isLoading: isGettingSecondRisksCount,
		refetch: refetchSecondRisksCount,
		isError: hasErrorSecondRisksCount
	} = useGetNioshRisksCount({
		...commomParams,
		start_date: secondPeriod?.start_date,
		end_date: secondPeriod?.end_date
	});

	const isError = hasErrorFirstRisksCount || hasErrorSecondRisksCount;
	const isLoading = isGettingFirstRisksCount || isGettingSecondRisksCount;
	const isEmpty = risksFirstPeriod?.length === 0 || risksSecondPeriod?.length === 0 || isAllValuesZero();

	const nioshRiskLabelMapper: Record<string, string> = {
		very_low: 'Very low risk',
		low: 'Low risk',
		moderate: 'Moderate risk',
		high: 'High risk',
		very_high: 'Very high risk'
	};

	const first = risksFirstPeriod?.map(({ total }) => total);
	const second = risksSecondPeriod?.map(({ total }) => total);
	const labels = risksFirstPeriod?.map(({ risk_name }) => String(I18n.get(nioshRiskLabelMapper[risk_name])));

	const riskRangeColors = ['#2CC852', '#F8D627', '#F78A38', '#E74150', '#9B54E2'];

	function handleOnSubmit(): void {
		form.submit();
	}

	function handleOnRefetch(): void {
		refetchFirstRisksCount();
		refetchSecondRisksCount();
	}

	function isAllValuesZero(): boolean {
		const isFirstValuesZero = risksFirstPeriod?.every(({ total }) => total === 0);
		const isSecondValueZero = risksSecondPeriod?.every(({ total }) => total === 0);
		return !!isFirstValuesZero && !!isSecondValueZero;
	}

	return (
		<Card.Container size="medium" isLoading={isLoading} isError={isError} onRefetch={handleOnRefetch}>
			<Card.Header
				hasIcon
				isTitleBold
				title="NIOSH/11228"
				subtitle="Risk evolution"
				icon={<CalendarOutlined />}
				popoverComponent={
					<StyledDivPopOver>
						<Compound fieldName={[fieldName]} handleFormSubmit={handleOnSubmit} handleClear={onClear} />
					</StyledDivPopOver>
				}
			/>
			<Card.Content isEmpty={isEmpty}>
				<Row style={{ height: '310px' }} gutter={[0, 20]}>
					<Col span={24}>
						<DoubleBar.Chart
							labels={labels}
							leftBarData={first}
							rightBarData={second}
							riskColors={riskRangeColors}
						/>
					</Col>
					<Col span={24}>
						<Row justify="center" align="middle" gutter={[30, 0]}>
							<Col>
								<DoubleBar.LegendItem
									color="white"
									endDate={firstPeriod?.end_date}
									startDate={firstPeriod?.start_date}
								/>
							</Col>
							<Col>
								<DoubleBar.LegendItem
									color="black"
									endDate={secondPeriod?.end_date}
									startDate={secondPeriod?.start_date}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Card.Content>
		</Card.Container>
	);
}
