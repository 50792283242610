import styled from 'styled-components';
import { Row } from 'antd';

const EwaD86RiskColor: { [key: number]: string } = {
	1: '#2cc852',
	2: '#FFDE31',
	3: '#F78A38',
	4: '#E74150',
	5: '#9B54E2'
};

interface ContainerProps {
	$score: number;
}

export const Container = styled(Row)<ContainerProps>`
	min-width: 9rem;
	border-radius: 6px;
	background-color: ${({ $score }) => EwaD86RiskColor[$score]};
	border: 2px solid ${({ $score }) => EwaD86RiskColor[$score]};

	span {
		color: #ffffff;
		font-weight: 600;
		font-size: 0.8rem;
	}

	@media (min-width: 1600px) {
		min-width: 10rem;

		span {
			font-size: 1rem;
		}
	}
`;
