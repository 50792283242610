import styled from 'styled-components';
import { DatePicker, Form, Input, InputNumber, Radio, Select } from 'antd';

const FIELDS_HEIGHT = '2.4rem';

const BLUE_COLOR = '#2F54EB';
const WHITE_COLOR = '#FFFFFF';
const BLACK_COLOR = '#262626';
const LIGHT_BLUE_COLOR = '#eaeefd';

interface CustomSelectProps {
	$isSelected?: boolean;
}

interface IconButtonProps {
	$isSelected?: boolean;
	$isDisabled?: boolean;
}

export const CustomFormItem = styled(Form.Item)`
	margin: 0;

	label {
		font-size: 1.1rem;
	}

	.ant-form-item-required {
		display: flex;
		justify-content: flex-end;
		flex-direction: row-reverse;
	}
`;

export const CustomNumberInput = styled(InputNumber)`
	width: 100%;
	font-size: 1rem;
	border-radius: 6px;
	height: ${FIELDS_HEIGHT} !important;

	.ant-input-number-group-addon {
		border-radius: 6px 0 0 6px;
	}

	.ant-input-number-input-wrap {
		height: 100%;
	}
	.ant-input-number-input {
		height: 100%;
	}
`;

export const CustomSelect = styled(Select)<CustomSelectProps>`
	width: 100%;
	font-size: 1rem;

	.ant-select-selector {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px !important;
		height: ${FIELDS_HEIGHT} !important;

		.ant-select-selection-item::after {
			display: none;
		}
	}
`;

export const HighlightedSelect = styled(Select)<CustomSelectProps>`
	width: 100%;
	font-size: 1rem;

	.ant-select-selector {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px !important;
		height: ${FIELDS_HEIGHT} !important;
		color: ${({ $isSelected }) => ($isSelected ? WHITE_COLOR : BLACK_COLOR)};
		border: 1px solid ${({ $isSelected }) => ($isSelected ? BLUE_COLOR : '#E9E9E9')} !important;
		background-color: ${({ $isSelected }) => ($isSelected ? BLUE_COLOR : WHITE_COLOR)} !important;
	}

	.ant-select-clear {
		color: ${({ $isSelected }) => ($isSelected ? WHITE_COLOR : BLACK_COLOR)} !important;
		background-color: ${({ $isSelected }) => ($isSelected ? BLUE_COLOR : WHITE_COLOR)} !important;
	}

	.ant-select-arrow {
		color: ${({ $isSelected }) => ($isSelected ? WHITE_COLOR : '#26262666')} !important;
	}
`;

export const CustomTextInput = styled(Input)`
	font-size: 1rem;
	border-radius: 6px;
	height: ${FIELDS_HEIGHT} !important;
`;

export const CustomRangePicker = styled(DatePicker.RangePicker)`
	width: 100%;
	border-radius: 6px;
	height: ${FIELDS_HEIGHT};

	input {
		font-size: 1rem !important;
	}
`;

export const CustomDatePicker = styled(DatePicker)`
	width: 100%;
	border-radius: 6px;
	height: ${FIELDS_HEIGHT};

	input {
		font-size: 1rem !important;
	}
`;

export const Group = styled(Radio.Group)`
	width: 100%;

	.ant-radio-button-wrapper-checked {
		&:first-child {
			border: none;
			color: ${BLUE_COLOR};
		}
	}
`;

export const RadioButton = styled(Radio.Button)`
	width: 100%;
	height: ${FIELDS_HEIGHT};
	border: 1px solid #d9d9d9;
	border-radius: 6px !important;

	&:hover {
		border: none;
		color: ${BLACK_COLOR};
		border: 1px solid ${LIGHT_BLUE_COLOR};
		background-color: ${LIGHT_BLUE_COLOR};
		box-shadow: 2px 2px 10px 0px #00000040;
	}

	.ant-radio-button-checked {
		border-radius: 6px;
		border: 1px solid ${LIGHT_BLUE_COLOR};
		background-color: ${LIGHT_BLUE_COLOR};
	}

	&:focus-within {
		box-shadow: none !important;
	}

	span {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	svg {
		width: 0.6rem;
	}
`;

export const IconsWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
`;

export const IconButton = styled.div<IconButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${FIELDS_HEIGHT};
	height: ${FIELDS_HEIGHT};
	border-radius: 6px;
	box-shadow: 2px 2px 5px 1px #00000026;
	opacity: ${({ $isDisabled }) => ($isDisabled ? '0.5' : '1')};
	border: 1px solid ${({ $isSelected }) => ($isSelected ? BLUE_COLOR : '#e6e6e6')};
	background-color: ${({ $isSelected }) => ($isSelected ? BLUE_COLOR : WHITE_COLOR)};

	&:hover {
		cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
	}

	svg {
		font-size: 1.4rem;
		color: ${({ $isSelected }) => ($isSelected ? WHITE_COLOR : BLUE_COLOR)};
	}
`;

export const InvisibleDatePicker = styled(DatePicker)`
	width: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	visibility: hidden;
`;

export const CustomTextArea = styled(Input.TextArea)`
	textarea {
		font-size: 1rem;
		border-radius: 6px;
	}
`;

export const Score = styled.span<{ isDark?: boolean; color: string }>`
	color: ${({ isDark }) => (isDark ? '#fff' : '#000')};
	background-color: ${({ color }) => color};
	border-radius: 6px;
	padding: 5px 10px;
	font-weight: 400;
	font-size: 14px;
	width: 100%;
`;

export const ColorDot = styled.span`
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${({ color }) => color};
	margin-right: 8px;
`;
