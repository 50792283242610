import React from 'react';
import { Col, Grid, Spin } from 'antd';
import { ActionPlansStatus } from '../../constants';
import { Text } from '@/components/Typography';
import * as S from './styles';

const { useBreakpoint } = Grid;

const DEFAULT_TITLE = 'Total';

const titleMapper: Record<string, string> = {
	[ActionPlansStatus.TO_DO]: 'To Do',
	[ActionPlansStatus.DOING]: 'Doing',
	[ActionPlansStatus.DONE]: 'Done'
};

interface StatusCardProps {
	status?: string;
	value?: number;
	isLoading: boolean;
}

export function StatusCard({ status = '', value = 0, isLoading }: Readonly<StatusCardProps>) {
	const { xxl } = useBreakpoint();

	const title = titleMapper[status] || DEFAULT_TITLE;

	function shouldRenderTitle(): boolean {
		return !!xxl || title === DEFAULT_TITLE;
	}

	return (
		<S.Container align="middle" $status={status}>
			{shouldRenderTitle() && (
				<Col style={{ marginRight: 10 }}>
					<Text>{title}</Text>:
				</Col>
			)}
			<Col>{isLoading ? <Spin /> : <span className="status-value">{value}</span>}</Col>
		</S.Container>
	);
}
