import React from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CustomFormItem, StyledDatePicker } from '../styles';

interface EndDateProps {
	formName: string[];
	disabled: boolean;
}

export function EndDate({ formName, disabled }: Readonly<EndDateProps>) {
	return (
		<Row>
			<Col span={24}>
				<CustomFormItem
					style={{ margin: 0 }}
					labelCol={{ span: 24 }}
					label={I18n.get('End date')}
					name={[...formName, 'end_date']}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value: moment.Moment) {
								const startDate = getFieldValue([...formName, 'start_date']);

								if (value && value.isBefore(startDate)) {
									return Promise.reject(I18n.get('End date must be after start date'));
								}

								return Promise.resolve();
							}
						})
					]}
				>
					<StyledDatePicker
						disabled={disabled}
						disabledDate={(current) => current && current.valueOf() >= Date.now()}
						format={(value) => {
							if (value) {
								const date = value.toDate();
								return new Intl.DateTimeFormat(navigator.language, {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit'
								}).format(date);
							}
							return '';
						}}
					/>
				</CustomFormItem>
			</Col>
		</Row>
	);
}
