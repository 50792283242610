import React from 'react';
import * as S from './styles';

type ProgressBarProps = {
	total?: number;
	completed?: number;
};

export function ProgressBar({ completed = 0, total = 0 }: Readonly<ProgressBarProps>) {
	const percent = Math.trunc((completed / total) * 100);
	return <S.CustomProgress percent={percent} />;
}
