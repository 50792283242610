import styled from 'styled-components';
import { Checkbox, Progress, Row } from 'antd';

interface DescriptionProps {
	$checked: boolean;
}

export const TransparentButton = styled.button`
	padding: 0;
	line-height: 0;
	border-radius: 5px;
	background: transparent;
	border: 1px solid transparent;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		border: 1px solid #d9d9d9;
	}
`;

export const Description = styled(Checkbox)<DescriptionProps>`
	font-size: 1rem;
	text-decoration: ${({ $checked }) => ($checked ? 'line-through' : 'none')};
`;

export const Action = styled.div`
	font-size: 1rem;

	.edit {
		color: blue;
	}
`;

export const CustomProgress = styled(Progress)`
	.ant-progress-bg {
		background-color: #2f54eb;
	}
`;

export const TasksContainer = styled(Row)`
	max-height: 16rem;
	overflow: auto;
	padding-right: 0.7rem;

	::-webkit-scrollbar {
		width: 5px;
		max-height: 100px;
	}
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	::-webkit-scrollbar-thumb {
		background-color: #9c9c9c;
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;
