import React, { useState } from 'react';
import { Row, Col, Form } from 'antd';
import moment, { Moment } from 'moment';
import { CalendarOutlined } from '@ant-design/icons';
import type { FormFieldProps } from './types';
import * as S from './styles';

const { useWatch } = Form;

interface DueDateDropdownProps extends FormFieldProps {
	limitDate?: Date | Moment;
}

export function DueDateDropdown({
	formName = [''],
	isRequired = false,
	limitDate = moment()
}: Readonly<DueDateDropdownProps>) {
	const [open, setOpen] = useState(false);

	const fieldName = [...formName, 'due_date'];
	const dueDate = useWatch(fieldName);

	function handleToggleDatePicker(): void {
		setOpen(!open);
	}

	return (
		<Row>
			<Col>
				<S.IconButton $isSelected={!!dueDate} onClick={handleToggleDatePicker}>
					<CalendarOutlined />
				</S.IconButton>
			</Col>
			<Col>
				<S.CustomFormItem required={isRequired} name={fieldName}>
					<S.InvisibleDatePicker
						open={open}
						placement="bottomRight"
						onOpenChange={handleToggleDatePicker}
						disabledDate={(current) => current > moment(limitDate)}
					/>
				</S.CustomFormItem>
			</Col>
		</Row>
	);
}
