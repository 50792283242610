import { Mapper } from './base-mapper';
import { ActionPlanDeleteComments, ActionPlanDeleteCommentsDomain } from '../domain/action-plan-comments-delete';
import { ActionPlanDeleteCommentsDTO, ActionPlanDeleteCommentsItemDTO } from '../dto/action-plan-comments-delete';

export class ActionPlanDeleteCommentsMapper implements Mapper<ActionPlanDeleteCommentsDomain> {
	private readonly response: ActionPlanDeleteCommentsDTO;

	constructor(response: ActionPlanDeleteCommentsDTO) {
		this.response = {
			status: response.status,
			message: response.message,
			data: Array.isArray(response.data) ? response.data : []
		};
	}

	private mapActionPlanItem(item: ActionPlanDeleteCommentsItemDTO): ActionPlanDeleteComments {
		return new ActionPlanDeleteComments({
			id: item.id
		});
	}

	toDomain(): ActionPlanDeleteCommentsDomain {
		return {
			status: this.response.message,
			message: this.response.message,
			data: this.response.data.map((item) => this.mapActionPlanItem(item))
		};
	}
}
