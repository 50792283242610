import styled from 'styled-components';
import { Avatar } from 'antd';

export const GRAY_COLOR = '#E6E6E6';
export const WHITE_COLOR = '#ffffff';
export const BLACK_COLOR = '#262626';

interface CustomAvatarProps {
	$backgroundColor: string;
}

export const CustomAvatar = styled(Avatar)<CustomAvatarProps>`
	font-weight: 600;
	color: ${BLACK_COLOR};
	background-color: ${({ $backgroundColor }) => $backgroundColor};
`;
