export const labels = [
	'Not identified',
	'Acceptable',
	'Investigate',
	'Investigate and implement changes',
	'Implement changes'
];

export const bodyPartNameMapper: { [key: string]: string } = {
	neck: 'Neck',
	trunk: 'Torso',
	left_upper_arm: 'Left shoulder',
	right_upper_arm: 'Right shoulder',
	left_lower_arm: 'Left elbow',
	right_lower_arm: 'Right elbow',
	left_knee: 'Left knee',
	right_knee: 'Right knee'
};

export const orderedBodyParts = [
	'neck',
	'trunk',
	'left_upper_arm',
	'right_upper_arm',
	'left_lower_arm',
	'right_lower_arm',
	'left_knee',
	'right_knee'
];
