import { Mapper } from './base-mapper';
import { LibertyMutualPercentileByGender } from '../domain/liberty-mutual-percentile-by-gender';
import { LibertyMutualPercentileByGenderDTO } from '../dto/liberty-mutual-percentile-by-gender';

export class LibertyMutualPercentileByGenderMapper implements Mapper<LibertyMutualPercentileByGender> {
	private readonly man: number;
	private readonly woman: number;

	constructor({ man, woman }: LibertyMutualPercentileByGenderDTO) {
		this.man = man;
		this.woman = woman;
	}

	toDomain(): LibertyMutualPercentileByGender {
		return new LibertyMutualPercentileByGender({
			man: this.man,
			woman: this.woman
		});
	}
}
