import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/liberty-mutual';

export interface PercentileByGender {
	man: number;
	woman: number;
}

interface GetPercentileByGenderParams {
	organization_id: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	workstation_id?: string;
	start_date?: Date;
	end_date?: Date;
}

export const useGetCustomReportLibertyMutual = (params: GetPercentileByGenderParams) => {
	return useQuery([QUERY_KEYS.GET_PERCENTILE_BY_GENDER, params], () => Service.percentileByGender(params), {
		enabled: !!params.organization_id
	});
};
