export interface SeraMainRiskItem {
	risk_category_id: string;
	description: string;
	percentage: number;
	count: number;
}

export class SeraMainRisk {
	constructor(public readonly items: SeraMainRiskItem[]) {}
}
