import React from 'react';
import { I18n } from '@aws-amplify/core';
import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col, message } from 'antd';
import { useQueryClient } from '@tanstack/react-query';

import { QUERY_KEYS } from '@/utils/query-keys';
import { Paragraph } from '@/components/Typography';
import { useDeleteAttachment } from '@/hooks/v2/useDeleteAttachment';

import * as S from './styles';
import { ModalDeleteProps } from './types';

export function ModalDelete({ open, file, onCancel, companyId, organizationId }: ModalDeleteProps) {
	const { mutateAsync: deleteAttachment } = useDeleteAttachment();
	const queryClient = useQueryClient();

	async function handleOnDeleteConfirm(): Promise<void> {
		if (!file?.uid) {
			message.error(I18n.get('Invalid file to delete'));
			return;
		}

		try {
			await deleteAttachment({
				company_id: companyId,
				organization_id: organizationId,
				action_plan_attachment_id: file.uid
			});

			await queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.GET_ATTACHMENTS]
			});

			message.success(I18n.get('File deleted successfully'));
			onCancel();
		} catch (error) {
			message.error(I18n.get('Failed to delete file'));
		}
	}

	return (
		<S.ModalStyled width={500} footer={null} open={open} onCancel={onCancel} centered>
			<Row justify="center">
				<DeleteOutlined
					style={{
						color: S.COLOR_DANGER,
						fontSize: 70
					}}
				/>
			</Row>
			<Row justify="center">
				<Col span={20} style={{ marginTop: 10 }}>
					<Paragraph className="paragraph-modal" style={{ fontSize: '24px', textAlign: 'center' }}>
						{I18n.get('Are you sure you want to remove the attachment')}
						<strong>"{file?.name}"</strong>?
					</Paragraph>
				</Col>
			</Row>
			<Row justify="center">
				<S.SecondaryButton key="back" onClick={onCancel} style={{ marginRight: 10 }}>
					{I18n.get('Cancel')}
				</S.SecondaryButton>
				<S.PrimaryButton type="primary" onClick={handleOnDeleteConfirm} style={{ marginLeft: 10 }}>
					{I18n.get('Confirm')}
				</S.PrimaryButton>
			</Row>
		</S.ModalStyled>
	);
}
