import React from 'react';
import { Refetch } from '@/components/ui/Refetch';
import * as S from './styles';

export type CardSize = 'small' | 'medium' | 'large' | 'xlarge' | 'intermediary' | 'xxlarge';

interface ContainerProps {
	isLoading?: boolean;
	children: JSX.Element | JSX.Element[];
	isError?: boolean;
	size?: CardSize;
	onRefetch?(): void;
}

export function Container({
	isLoading = false,
	children,
	isError = false,
	size = 'small',
	onRefetch
}: Readonly<ContainerProps>) {
	if (isLoading) {
		return (
			<S.Container $size={size} justify="center" align="middle">
				<S.GradientSpinner />
			</S.Container>
		);
	}

	if (isError) {
		return (
			<S.Container $size={size} align="middle">
				<Refetch onClick={onRefetch} />
			</S.Container>
		);
	}

	return (
		<S.Container align="top" $size={size}>
			{children}
		</S.Container>
	);
}
