import React from 'react';
import { Row } from 'antd';

import * as S from './styles';
import { Text } from '@/components/Typography';
import { FolderSearchIcon } from '@/assets/icons/folder-search-icon';

export function Empty() {
	return (
		<Row gutter={[0, 10]}>
			<S.IconWrapper span={24}>
				<FolderSearchIcon />
			</S.IconWrapper>
			<S.TextWrapper span={24}>
				<Text>No data available.</Text>
			</S.TextWrapper>
		</Row>
	);
}
