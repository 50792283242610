import React, { useCallback, useEffect } from 'react';
import { Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CustomReport } from './CustomReport';
import { useMosaicDashboardContext } from '../context';
import { useGetCustomReports } from '@/views/Dashboard/hooks/useGetCustomReports';
import * as S from '../styles';

interface HeaderProps {
	handleOnClick: (id: string, entity: string, name: string) => void;
}

export function Header({ handleOnClick }: Readonly<HeaderProps>) {
	const { organization_id, company_id, params, handleSetErrorWarning } = useMosaicDashboardContext();

	const {
		isLoading,
		data: custom_reports,
		isError
	} = useGetCustomReports({
		organization_id,
		company_id
	});

	const handleOnChangeReport = useCallback(
		(id: string) => handleOnClick(id, 'custom_report_id', ''),
		[handleOnClick]
	);

	useEffect(() => {
		if (!params.custom_report_id && custom_reports && custom_reports.length > 0) {
			handleOnChangeReport(custom_reports[0].id);
		}
	}, [params.custom_report_id, custom_reports, handleOnChangeReport]);

	if (isLoading) {
		return <Skeleton.Input active />;
	}

	if (isError) {
		handleSetErrorWarning(I18n.get('Unable to load custom reports'), 'error');
		return null;
	}

	return (
		<S.Header>
			<S.StyledRadioGroup
				buttonStyle="solid"
				value={params.custom_report_id}
				onChange={(e) => handleOnChangeReport(e.target.value)}
			>
				{custom_reports?.map((item) => (
					<CustomReport id={item.id} name={item.name} key={item.id} />
				))}
			</S.StyledRadioGroup>
		</S.Header>
	);
}
