import * as React from 'react';

interface CalendarProps {
	width?: string;
	height?: string;
	color?: string;
}

export const Calendar = ({ width = '1rem', height = '1rem', color = '#262626' }: Readonly<CalendarProps>) => {
	return (
		<svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1 5.6975V18H16V5.6975M1 5.6975H16M1 5.6975V3.94848H4.79518M16 5.6975L16 3.94848H12.0241M12.0241 3.94848L12.0241 1M12.0241 3.94848H4.79518M4.79518 3.94848V1"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<mask id="path-2-inside-1_7988_14484" fill="white">
				<rect x="4" y="9" width="2" height="2" rx="0.5" />
			</mask>
			<rect
				x="4"
				y="9"
				width="2"
				height="2"
				rx="0.5"
				fill={color}
				stroke={color}
				strokeWidth="2"
				mask="url(#path-2-inside-1_7988_14484)"
			/>
			<mask id="path-3-inside-2_7988_14484" fill="white">
				<rect x="4" y="12.5" width="2" height="2" rx="0.5" />
			</mask>
			<rect
				x="4"
				y="12.5"
				width="2"
				height="2"
				rx="0.5"
				fill={color}
				stroke={color}
				strokeWidth="2"
				mask="url(#path-3-inside-2_7988_14484)"
			/>
			<mask id="path-4-inside-3_7988_14484" fill="white">
				<rect x="7.5" y="9" width="2" height="2" rx="0.5" />
			</mask>
			<rect
				x="7.5"
				y="9"
				width="2"
				height="2"
				rx="0.5"
				fill={color}
				stroke={color}
				strokeWidth="2"
				mask="url(#path-4-inside-3_7988_14484)"
			/>
			<mask id="path-5-inside-4_7988_14484" fill="white">
				<rect x="7.5" y="12.5" width="2" height="2" rx="0.5" />
			</mask>
			<rect
				x="7.5"
				y="12.5"
				width="2"
				height="2"
				rx="0.5"
				fill={color}
				stroke={color}
				strokeWidth="2"
				mask="url(#path-5-inside-4_7988_14484)"
			/>
			<mask id="path-6-inside-5_7988_14484" fill="white">
				<rect x="11" y="9" width="2" height="2" rx="0.5" />
			</mask>
			<rect
				x="11"
				y="9"
				width="2"
				height="2"
				rx="0.5"
				fill={color}
				stroke={color}
				strokeWidth="2"
				mask="url(#path-6-inside-5_7988_14484)"
			/>
			<mask id="path-7-inside-6_7988_14484" fill="white">
				<rect x="11" y="12.5" width="2" height="2" rx="0.5" />
			</mask>
			<rect
				x="11"
				y="12.5"
				width="2"
				height="2"
				rx="0.5"
				fill={color}
				stroke={color}
				strokeWidth="2"
				mask="url(#path-7-inside-6_7988_14484)"
			/>
		</svg>
	);
};
