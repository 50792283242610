import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { getToolIcon, TOOL_ORDER } from './utils';
import { ToolCard, ToolIcon, ToolInfo, ToolName, ToolDescription } from './styles';
import { GetRelatedReportsResponseDTO } from '@/core/dto/action-plan-attachment';

interface ErgonomicToolsProps {
	relatedReports: GetRelatedReportsResponseDTO[];
	value?: string[];
	onChange?: (value: string[]) => void;
}

export function ErgonomicTools({ relatedReports, value = [], onChange }: ErgonomicToolsProps) {
	const [selectedTools, setSelectedTools] = useState<string[]>(() => {
		return value.length > 0
			? value
			: relatedReports
					?.filter((tool) => tool.checked)
					?.map((tool) => tool.ergonomic_tool_id)
					?.filter((id): id is string => id !== null && id !== undefined) || [];
	});

	useEffect(() => {
		if (Array.isArray(value)) {
			setSelectedTools(value);
		}
	}, [value]);

	const sortedReports = [...relatedReports].sort((a, b) => {
		return TOOL_ORDER[a.ergonomic_tool.name] - TOOL_ORDER[b.ergonomic_tool.name];
	});

	const handleToolClick = (tool: GetRelatedReportsResponseDTO) => {
		if (!tool.report_id) return;

		const ergonomicToolId = tool.ergonomic_tool_id;

		const newSelected = selectedTools.includes(ergonomicToolId)
			? selectedTools.filter((id) => id !== ergonomicToolId)
			: [...selectedTools, ergonomicToolId];

		setSelectedTools(newSelected);
		onChange?.(newSelected);
	};

	return (
		<Row gutter={[16, 16]}>
			{sortedReports.map((tool, index) => (
				<Col key={index} span={12}>
					<ToolCard
						onClick={() => handleToolClick(tool)}
						$isSelected={selectedTools.includes(tool.ergonomic_tool_id || '')}
						$isDisabled={!tool.report_id}
					>
						<ToolIcon>{getToolIcon(tool.ergonomic_tool.name)}</ToolIcon>
						<ToolInfo>
							<ToolName>{I18n.get(tool.ergonomic_tool.title)}</ToolName>
							<ToolDescription>{I18n.get(tool.ergonomic_tool.subtitle)}</ToolDescription>
						</ToolInfo>
					</ToolCard>
				</Col>
			))}
		</Row>
	);
}
