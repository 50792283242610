import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Service } from '@/infra/services/action-plan';
import { UpdateAttachmentResponseDTO } from '@/core/dto/action-plan-attachment';
import type { Response } from '@/types';

interface UpdateAttachmentParams {
	organization_id: string;
	company_id: string;
	action_plan_attachment_id: string;
	description: string;
}

export const useUpdateAttachment = () => {
	return useMutation<UpdateAttachmentResponseDTO, AxiosError<Response>, UpdateAttachmentParams>((params) =>
		Service.updateAttachment(params)
	);
};
