import { useInfiniteQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';
import { ActionPlanCardList } from '@/core/domain/action-plan-card-list';

export interface GetCardListParams {
  organization_id: string;
  company_id?: string;
  sector_id?: string;
  line_id?: string;
  workstation_id?: string;
  activity_id?: string;
  title?: string;
  priority?: number;
  investment_range?: number;
  responsible_id?: string;
  start_date?: Date;
  end_date?: Date;
  due_date_start?: Date;
  due_date_end?: Date;
  limit: number;
  origin_name?: string;
  status?: string;
  sort?: string;
}

export const useActionPlanGetCardList = (params: GetCardListParams) => {
  const queryKey = [
    QUERY_KEYS.GET_ACTION_PLAN_CARD_LIST,
    params.status,
    params.organization_id,
    params.title,
    params.sort,
    params.company_id,
    params.sector_id,
    params.line_id,
    params.workstation_id,
    params.activity_id,
    params.responsible_id,
    params.priority,
    params.investment_range,
    params.origin_name,
    params.start_date?.toISOString(),
    params.end_date?.toISOString(),
    params.due_date_start?.toISOString(),
    params.due_date_end?.toISOString()
  ];

  return useInfiniteQuery<ActionPlanCardList>(
    queryKey,
    async ({ pageParam = 0 }) => {
      const response = await Service.findAllForBoard({ ...params, offset: pageParam });
      return response;
    },
    {
      enabled: !!params.organization_id,
      getNextPageParam: (lastPage, allPages) => {
        const currentOffset = allPages.length * params.limit;
        return currentOffset < lastPage.total ? currentOffset : undefined;
      },
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );
};
