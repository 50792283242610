import React from 'react';

export function NioshIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
	return (
		<svg viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M13.8139 6.75132H17.7522V19.959C17.7522 20.1308 17.944 20.2713 18.1785 20.2713H21.376C21.6105 20.2713 21.8023 20.1308 21.8023 19.959V6.75132H25.7512C26.1083 6.75132 26.3054 6.4507 26.0869 6.24768L20.1183 0.711634C20.0784 0.674303 20.0275 0.644113 19.9693 0.62335C19.9111 0.602587 19.8473 0.591797 19.7826 0.591797C19.7179 0.591797 19.654 0.602587 19.5958 0.62335C19.5377 0.644113 19.4867 0.674303 19.4468 0.711634L13.4782 6.24378C13.2597 6.4507 13.4569 6.75132 13.8139 6.75132Z"
				fill="#262626"
			/>
			<rect x="2.09375" y="25.708" width="33.4071" height="9.79168" rx="1.5" stroke="#262626" strokeWidth="3" />
		</svg>
	);
}
